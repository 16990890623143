import React, { Component } from 'react'
import packageJson from '../../../../package.json'
import Checkbox from './helpers/Checkbox'
import InputNumber from './helpers/InputNumber'
import Colors from '../Colors'
import { connect } from 'react-redux'
const cellstyle = {
    color: 'black',
}
const padding = { padding: '0.5em', color: 'black' }
const paddingNoRight = { padding: '0.5em', paddingRight: 0 }
const paddingNoLeft = { paddingLeft: 0 }
const blank = { height: '0.5em' }
//start panel inital value object key
const startPanelKeyObj = {
    bic1: 52,
    bic2: 53,
    bif1: 55,
    bif2: 56,
    usbtx: 65,
    bid1: 54,
    bid2: 63,
    asgn1: 66,
    asgn2: 67,
    asgn3: 68,
    asgn4: 69,
    asgn5: 70,
    BtTx1: 71,
    BtTx2: 72,
    BtTx3: 73,
    BtTx4: 74,
    BtTx5: 75,
}
const startPanelDecisonObjectName = {
    bic1: 'Bicounter1',
    bic2: 'Bicounter2',
    bid1: 'BiData1',
    bid2: 'BiData2',
    bif1: 'Biflag1',
    bif2: 'Biflag2',
    btRte: 'BT Remote',
    usbrx: 'USB RX',
}

class StartPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    onChange = (key, value) => {
        //playC 793 fix
        sessionStorage.setItem('isSave', JSON.stringify(false))

        //playC 1058 - 0.1 PeeCee - Start Panel - Web Bugs;
        const { state, onChange, startState, logic } = this.props

        state[key] = value
        if (!value) {
            var keys_arr = []
            if (key.includes('bic')) {
                keys_arr.push('assignCount' + key)
                keys_arr.push('valueCount' + key)
                keys_arr.push('valueNumCount' + key)
            } else if (key.includes('bid') || key.includes('bif')) {
                keys_arr.push('assign' + key)
                keys_arr.push('value' + key)
                keys_arr.push('valuenum' + key)
            } else if (key.includes('iot')) {
                for (var i = 1; i <= 10; i++) {
                    keys_arr.push('assignIOT' + i)
                    keys_arr.push('valueIOT' + i)
                    keys_arr.push('valuenumIOT' + i)
                }
                keys_arr.push('IOT_counter')
                keys_arr.push('IOTROW')
            }

            for (var deleteKey in keys_arr) {
                this.props.bottomPanelDeleteKey(keys_arr[deleteKey])
            }
        }

        onChange(state)

        if (
            [
                'Humanoid',
                'Hexapod',
                'Ace',
                'Roboki',
                'Klaw',
                'Atr',
                'Toki',
                'Moki',
            ].includes(sessionStorage.getItem('connectedDevice'))
        ) {
            let program = logic.program
            let humanoidProgramData = JSON.parse(
                sessionStorage.getItem('humanoidProgramData')
            )

            /***
             Delete the redux state and session storage node state
             */
            const deletReduxState = (program) => {
                program.forEach((subProg) => {
                    //delete state in action panel state
                    if (subProg.type == 'hardware') {
                        let stateProp = subProg.state
                        for (let prop in stateProp) {
                            let val = stateProp[prop]
                            if (prop.toLowerCase().includes(key.toLowerCase()))
                                delete stateProp[prop]
                            if (typeof val == 'string' && val.includes(key))
                                stateProp[prop] = ''
                        }
                        //default value for bid1 & bid2
                        if (startState.bid1 && startState.bid2) {
                            stateProp['valueBid2Assign'] = 'bid1'
                            stateProp['valueBid1Assign'] = 'bid2'
                        }

                        // update the session Storage
                        if (
                            !['Ace', 'Roboki', 'Atr', 'Toki', 'Moki'].includes(
                                sessionStorage.getItem('connectedDevice')
                            )
                        ) {
                            let sessonStorageNode =
                                humanoidProgramData[subProg.nodeId].state
                            if (['bid1', 'bid2'].includes(key)) {
                                sessonStorageNode[startPanelKeyObj[key]].value =
                                    [false, false, 0, false, 'default']
                                sessonStorageNode[
                                    startPanelKeyObj[key]
                                ].isActionEnabled = false
                            } else if (key === 'btTx') {
                                ;[71, 72, 73, 74, 75].map((ele) => {
                                    sessonStorageNode[ele].value = 0
                                    sessonStorageNode[
                                        ele
                                    ].isActionEnabled = false
                                })
                            } else if (
                                [
                                    'asgn1',
                                    'asgn2',
                                    'asgn3',
                                    'asgn4',
                                    'asgn5',
                                ].includes(key)
                            ) {
                                sessonStorageNode[startPanelKeyObj[key]].value =
                                    ['default', 'default']
                                sessonStorageNode[
                                    startPanelKeyObj[key]
                                ].isActionEnabled = false
                            } else {
                                if (!['btRx', 'btRte', 'usbrx'].includes(key)) {
                                    sessonStorageNode[
                                        startPanelKeyObj[key]
                                    ].value = 0
                                    sessonStorageNode[
                                        startPanelKeyObj[key]
                                    ].isActionEnabled = false
                                }
                            }
                            sessionStorage.setItem(
                                'humanoidProgramData',
                                JSON.stringify(humanoidProgramData)
                            )
                        }
                    }

                    //Delete the Decesion Panel State
                    if (
                        subProg.type == 'sensor' ||
                        subProg.type == 'condition'
                    ) {
                        if (
                            key === 'btRx' &&
                            [
                                'BT RX1',
                                'BT RX2',
                                'BT RX3',
                                'BT RX4',
                                'BT RX5',
                            ].includes(subProg.state.source)
                        ) {
                            subProg.state = {}
                        } else if (
                            subProg.state.source ===
                            startPanelDecisonObjectName[key]
                        ) {
                            subProg.state = {}
                        }

                        //update the session storage
                        if (
                            !['Ace', 'Roboki', 'Atr', 'Toki', 'Moki'].includes(
                                sessionStorage.getItem('connectedDevice')
                            )
                        ) {
                            let sessonStorageNode =
                                humanoidProgramData[subProg.nodeId]
                            sessonStorageNode.state = {
                                port: '',
                                value: 0,
                                value1: 0,
                            }
                            sessionStorage.setItem(
                                'humanoidProgramData',
                                JSON.stringify(humanoidProgramData)
                            )
                        }
                    }

                    if (
                        subProg.type == 'condition' ||
                        subProg.type == 'sensor' ||
                        subProg.type == 'loop'
                    ) {
                        deletReduxState(subProg.subprogram)
                    }
                })
            }
            deletReduxState(program)
            this.props.updateLogic(logic)
        }
    }
    hexTypeCheck = () => {
        this.props.hexTypeCheck('start')
    }
    render() {
        const { state } = this.props
        // console.log("bottom",this.props)
        return (
            <div style={{ overflowX: 'auto' }}>
                <table
                    style={{
                        color: '#FFF',
                        position: 'absolute',
                        top: '7%',
                        left: '17%',
                        fontWeight: 'bold',
                        width: '70%',
                        height: '50vh',
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={blank} colSpan={6} />
                        </tr>

                        <tr>
                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.bic1 || false}
                                    onChange={(value) =>
                                        this.onChange('bic1', value)
                                    }
                                    label="Counter 1"
                                />{' '}
                            </td>

                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.bic2 || false}
                                    onChange={(value) =>
                                        this.onChange('bic2', value)
                                    }
                                    label="Counter 2"
                                />
                            </td>

                            <td
                                style={{
                                    color: 'black',
                                    width: '33%',
                                }}
                                colSpan={2}
                            >
                                {' '}
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.btTx || false}
                                    onChange={(value) =>
                                        this.onChange('btTx', value)
                                    }
                                    label="BT TX"
                                />
                            </td>
                        </tr>

                        <tr>
                            <td style={cellstyle} colSpan={2}>
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.bif1 || false}
                                    onChange={(value) =>
                                        this.onChange('bif1', value)
                                    }
                                    label="Flag 1"
                                />
                            </td>

                            <td style={cellstyle} colSpan={2}>
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.bif2 || false}
                                    onChange={(value) =>
                                        this.onChange('bif2', value)
                                    }
                                    label="Flag 2"
                                />
                            </td>

                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    checked={state.btRx || false}
                                    onChange={(value) =>
                                        this.onChange('btRx', value)
                                    }
                                    hexComponentType="start"
                                    label="BT RX"
                                />
                            </td>
                        </tr>

                        <tr style={{}}>
                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    checked={state.btRte || false}
                                    onChange={(value) =>
                                        this.onChange('btRte', value)
                                    }
                                    hexComponentType="start"
                                    label="BT Remote"
                                />
                            </td>
                            {/* <td style={cellstyle} colSpan={2}>
                                <Checkbox
                                    checked={state.btRte || false}
                                    onChange={(value) =>
                                        this.onChange('btRte', value)
                                    }
                                    hexComponentType="start"
                                    label="BT Remote"
                                />
                            </td> */}
                            <td style={cellstyle} colSpan={2}>
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.usbtx || false}
                                    onChange={(value) =>
                                        this.onChange('usbtx', value)
                                    }
                                    label="USB TX"
                                />
                            </td>

                            <td style={cellstyle} colSpan={2}>
                                <Checkbox
                                    hexComponentType="start"
                                    checked={state.usbrx || false}
                                    onChange={(value) =>
                                        this.onChange('usbrx', value)
                                    }
                                    label="USB RX"
                                />
                            </td>
                        </tr>

                        <tr style={{}}>
                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    checked={state.bid1 || false}
                                    onChange={(value) =>
                                        this.onChange('bid1', value)
                                    }
                                    hexComponentType="start"
                                    label="BI Data 1"
                                />
                            </td>
                            <td style={cellstyle} colSpan={2}>
                                {' '}
                                <Checkbox
                                    checked={state.bid2 || false}
                                    onChange={(value) =>
                                        this.onChange('bid2', value)
                                    }
                                    hexComponentType="start"
                                    label="BI Data 2"
                                />
                            </td>
                            {packageJson['dev'] && (
                                <td style={cellstyle} colSpan={2}>
                                    {' '}
                                    <Checkbox
                                        checked={state.asgn1 || false}
                                        onChange={(value) =>
                                            this.onChange('asgn1', value)
                                        }
                                        hexComponentType="start"
                                        label="Assign 1"
                                    />
                                </td>
                            )}
                        </tr>
                        {packageJson['dev'] && (
                            <>
                                <tr style={{}}>
                                    <td style={cellstyle} colSpan={2}>
                                        {' '}
                                        <Checkbox
                                            checked={state.asgn2 || false}
                                            onChange={(value) =>
                                                this.onChange('asgn2', value)
                                            }
                                            hexComponentType="start"
                                            label="Assign 2"
                                        />
                                    </td>
                                    <td style={cellstyle} colSpan={2}>
                                        {' '}
                                        <Checkbox
                                            checked={state.asgn3 || false}
                                            onChange={(value) =>
                                                this.onChange('asgn3', value)
                                            }
                                            hexComponentType="start"
                                            label="Assign 3"
                                        />
                                    </td>
                                    <td style={cellstyle} colSpan={2}>
                                        {' '}
                                        <Checkbox
                                            checked={state.asgn4 || false}
                                            onChange={(value) =>
                                                this.onChange('asgn4', value)
                                            }
                                            hexComponentType="start"
                                            label="Assign 4"
                                        />
                                    </td>
                                </tr>
                                <tr style={{}}>
                                    <td style={cellstyle} colSpan={2}>
                                        {' '}
                                        <Checkbox
                                            checked={state.asgn5 || false}
                                            onChange={(value) =>
                                                this.onChange('asgn5', value)
                                            }
                                            hexComponentType="start"
                                            label="Assign 5"
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLogic: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
    }
}
StartPanel = connect(mapStateToProps, mapDispatchToProps)(StartPanel)
export default StartPanel
