import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import allReducer from './redux/reducers'
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { browserName, browserVersion, isMobile } from 'react-device-detect'
import packageJson from '../package.json'
import Index from './Components/Home/index'

import { BrowserRouter, HashRouter } from 'react-router-dom'
import * as atatus from 'atatus-spa'
import $ from 'jquery'
if (!packageJson['dev']) {
    console.log = function () {}
    console.warn = function () {}
    atatus.config('79c22e4b82f14c579a891b4995165a75').install()
}

const store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
)
var supportedBrowser = false
if (
    browserName == 'Chrome' ||
    browserName == 'Edge' ||
    browserName == 'Opera'
    // && !isMobile
) {
    console.log(parseInt(browserVersion), isMobile, 'versionbrowser')

    if (parseInt(browserVersion) > 89) {
        console.log(parseInt(browserVersion), 'versionbrowser')
        supportedBrowser = true
    }
}

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            {/* <React.StrictMode> */}
            {/* {isMobile
        ? 
        window.alert(
            "If you would like to connect the hardware kindly use this website on a Desktop/Laptop or download the Plode App from the PlayStore/AppStore"
          )
        : null}{" "} */}
            {supportedBrowser ? <App></App> : <Index></Index>}

            {/* </React.StrictMode> */}
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
