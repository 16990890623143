//humanoid and hexapod

import { PORTDATA } from '../Components/Humanoid/defaultData'
import { generateDeviceDataKey } from '../Components/ReusableComponents/ByteTransfer/utils'

const deleteDecisionPanelProgram = ({ node, nodeKey }) => {
    delete node[nodeKey].state['source']
    delete node[nodeKey].state['value']
    delete node[nodeKey].state['value2']
    delete node[nodeKey].state['condition']
    delete node[nodeKey].state['hour']
    delete node[nodeKey].state['hour2']
    delete node[nodeKey].state['minute']
    delete node[nodeKey].state['minute2']
}

const removeSensorProgramData = (node, port, type) => {
    console.log('EDWIN', node, port, type)
    //commented due to logic error by edwin
    // if (port && port.match(/^[ABCDEFGHI]$/)) {
    //     port = `${port[0]}1`

    // }
    if (sessionStorage.getItem('connectedDevice') === 'Atr') {
        if (port === 'S1') {
            port = 'C1'
        }
        if (port === 'S2') {
            port = 'C2'
        }
    }
    if (
        ['Humanoid', 'Hexapod', 'Klaw'].includes(
            sessionStorage.getItem('connectedDevice')
        )
    ) {
        let humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
        for (let i in humanoidProgramData) {
            //take the conditional panel port number from 'humanoidProgramData'
            let portNum = humanoidProgramData[i].state.port

            //in humanoidProgramData if present the port number is empty string or battery, that time it does not enter the if condition
            if (
                ['condition'].includes(humanoidProgramData[i].type) &&
                !['battery', ''].includes(portNum)
            ) {
                let PORTDATAKey = generateDeviceDataKey(
                    sessionStorage.getItem('connectedDevice'),
                    sessionStorage.getItem('deviceVersion')
                )
                let defaultPortData = PORTDATA[PORTDATAKey]

                //here check the remove 'port' is present or not in the defaultPortData.[portNum].name
                if (defaultPortData[portNum].name.includes(port)) {
                    //if B port connect any output removed than counter1 also remove from condition panel so i put restriction
                    if (
                        !(
                            defaultPortData[portNum].name === 'Bicounter1' &&
                            port === 'B'
                        )
                    ) {
                        let defaultState = {
                            port: '',
                            value: 0,
                            value1: 0,
                            value2: 0,
                            condition: 'gt',
                        }
                        humanoidProgramData[i].state = defaultState
                    }
                }
            } else {
                for (let j in humanoidProgramData[i].state) {
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}1`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}2`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                }
            }
        }
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(humanoidProgramData)
        )
    }
    for (var nodeKey in node) {
        var obj = node[nodeKey].state

        if (
            obj['source'] == port ||
            obj['source'] == `${port}1` ||
            obj['source'] == `${port}2`
        ) {
            deleteDecisionPanelProgram({ node, nodeKey })
        } else if (
            (port == 'A' &&
                type === 'pcAiTool' &&
                [
                    'ai_mic',
                    'ai_lidar',
                    'ai_face_recognition',
                    'ai_face_detection',
                    'ai_color_detection',
                    'ai_motion',
                ].includes(obj['source'])) ||
            obj['source']?.includes('ai_face_tracking_X') ||
            obj['source']?.includes('ai_face_tracking_Y') ||
            obj['source']?.includes('ai_color_tracking_X') ||
            obj['source']?.includes('ai_color_tracking_Y')
        ) {
            deleteDecisionPanelProgram({ node, nodeKey })
        }
        if (
            (node[nodeKey].type == 'variable_output' ||
                node[nodeKey].type == 'wait' ||
                node[nodeKey].type == 'hardware') &&
            port
        ) {
            if (port.match(/^[ABCDEFGHIM]$/)) {
                if (`assign${port}1` in obj)
                    delete node[nodeKey].state['assign' + port + '1']

                if (`value${port}1` in obj)
                    delete node[nodeKey].state['value' + port + '1']

                if (`assign${port}2` in obj)
                    delete node[nodeKey].state['assign' + port + '2']

                if (`value${port}2` in obj)
                    delete node[nodeKey].state['value' + port + '2']

                if (JSON.parse(sessionStorage.getItem('peeCeeATR'))) {
                    if (
                        ['H', 'G'].includes(port) &&
                        [
                            'dc_motor',
                            'mini_geared_motor',
                            'geared_motor',
                            'dynamex_motor',
                        ].includes(type)
                    ) {
                        if (
                            sessionStorage
                                .getItem('deviceVersion')
                                ?.startsWith('1')
                        ) {
                            if (port == 'H') {
                                ;['F1', 'F2'].map((port) => {
                                    if (`assign${port}` in obj)
                                        delete node[nodeKey].state[
                                            'assign' + port
                                        ]
                                    if (`value${port}` in obj)
                                        delete node[nodeKey].state[
                                            'value' + port
                                        ]
                                })
                            }
                            if (port == 'G') {
                                ;['E1', 'E2'].map((port) => {
                                    if (`assign${port}` in obj)
                                        delete node[nodeKey].state[
                                            'assign' + port
                                        ]
                                    if (`value${port}` in obj)
                                        delete node[nodeKey].state[
                                            'value' + port
                                        ]
                                })
                            }
                        } else {
                            if (port == 'H') {
                                ;['A2', 'F2'].map((port) => {
                                    if (`assign${port}` in obj)
                                        delete node[nodeKey].state[
                                            'assign' + port
                                        ]
                                    if (`value${port}` in obj)
                                        delete node[nodeKey].state[
                                            'value' + port
                                        ]
                                })
                            }
                            if (port == 'G') {
                                ;['C2', 'F1'].map((port) => {
                                    if (`assign${port}` in obj)
                                        delete node[nodeKey].state[
                                            'assign' + port
                                        ]
                                    if (`value${port}` in obj)
                                        delete node[nodeKey].state[
                                            'value' + port
                                        ]
                                })
                            }
                        }
                    }
                }
            } else {
                if (`assign${port}` in obj || obj['assign' + port])
                    delete node[nodeKey].state['assign' + port]

                if (`value${port}` in obj || obj['assign' + port])
                    delete node[nodeKey].state['value' + port]

                if (JSON.parse(sessionStorage.getItem('peeCeeATR'))) {
                    if (
                        ['S1', 'S2'].includes(port) &&
                        [
                            'servo_motor',
                            'servo_motor_360',
                            'servo_motor_270',
                        ].includes(type)
                    ) {
                        if (port == 'S1') {
                            if (`assignA1` in obj)
                                delete node[nodeKey].state['assignA1']
                            if (`valueA1` in obj)
                                delete node[nodeKey].state['valueA1']
                        }
                        if (port == 'S2') {
                            if (`assignC1` in obj)
                                delete node[nodeKey].state['assignC1']
                            if (`valueC1` in obj)
                                delete node[nodeKey].state['valueC1']
                        }
                    }
                }
            }

            if (type === 'dual_splitter') {
                const port2 = `${port[0]}2`
                if (`assign${port2}` in obj) {
                    delete node[nodeKey].state['assign' + port2]
                }
                if (`value${port2}` in obj) {
                    delete node[nodeKey].state['value' + port2]
                }
            }

            if (type === 'pcAiTool') {
                delete node[nodeKey].state['assignai_LEDA1']
                delete node[nodeKey].state['assignai_audioA1']
                delete node[nodeKey].state['valueai_LEDA1']
                delete node[nodeKey].state['valueai_audioA1']
            }

            if (type === 'OLED') {
                delete node[nodeKey].state['assignOLEDOne']
                delete node[nodeKey].state['assignOLEDTwo']
                delete node[nodeKey].state['assignOLEDThree']
                delete node[nodeKey].state['assignOLEDFour']
                delete node[nodeKey].state['valueOLEDOne']
                delete node[nodeKey].state['valueOLEDTwo']
                delete node[nodeKey].state['valueOLEDThree']
                delete node[nodeKey].state['valueOLEDFour']
            }
            if (type === 'RGB') {
                for (let i = 1; i <= 10; i++) {
                    delete node[nodeKey].state[`assignRGBComp${i}`]
                    delete node[nodeKey].state[`valueRGBComp${i}R`]
                    delete node[nodeKey].state[`valueRGBComp${i}G`]
                    delete node[nodeKey].state[`valueRGBComp${i}B`]
                }
                delete node[nodeKey].state[`countRGBComp`]
            }

            if (type == 'dot_matrix') {
                for (var key in obj) {
                    if (key.startsWith('dot_matrix')) {
                        delete node[nodeKey].state[key]
                    }
                }
            }
            if (type == '7segment_display') {
                for (var key in obj) {
                    if (key.includes('valueB') || key.includes('valueC')) {
                        delete node[nodeKey].state[key]
                    }
                }
            }
            ////////////
            // PLAYC-153
            if (
                [
                    'dc_motor',
                    'geared_motor',
                    'mini_geared_motor',
                    'dynamex_motor',
                ].includes(type)
            ) {
                delete node[nodeKey].state['assign' + `${port[0]}1`]
                delete node[nodeKey].state['value' + `${port[0]}1`]
                delete node[nodeKey].state['assign' + `${port[0]}2`]
                delete node[nodeKey].state['value' + `${port[0]}2`]
            }
            ////////////

            for (const key in obj) {
                if (port == 'SS' && key.match(/[assign, value]SS[1-5, Time]/))
                    delete node[nodeKey].state[key]
                else if (port == 'MOTOR1' && key.match(/[assign, value]M[23]/))
                    delete node[nodeKey].state[key]
                else if (
                    port == 'M3' &&
                    (key == `assignM3` ||
                        key == `assignM4` ||
                        key == `valueM3` ||
                        key == `valueM4`)
                ) {
                    delete node[nodeKey].state[key]
                }
            }
        }
        if (node[nodeKey].subprogram) {
            removeSensorProgramData(node[nodeKey].subprogram, port, type)
        }
    }
    return node
}

export const removeAssemblyPageAiComponents = ({
    node,
    port,
    type,
    isRemove,
}) => {
    if (
        ['Humanoid', 'Hexapod', 'Klaw'].includes(
            sessionStorage.getItem('connectedDevice')
        )
    ) {
        let humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
        for (let i in humanoidProgramData) {
            //take the conditional panel port number from 'humanoidProgramData'
            let portNum = humanoidProgramData[i].state.port

            //in humanoidProgramData if present the port number is empty string or battery, that time it does not enter the if condition
            if (
                ['condition'].includes(humanoidProgramData[i].type) &&
                !['battery', ''].includes(portNum)
            ) {
                let PORTDATAKey = generateDeviceDataKey(
                    sessionStorage.getItem('connectedDevice'),
                    sessionStorage.getItem('deviceVersion')
                )
                let defaultPortData = PORTDATA[PORTDATAKey]

                //here check the remove 'port' is present or not in the defaultPortData.[portNum].name
                if (
                    defaultPortData[portNum].reduxName.includes(
                        port.slice(0, -2)
                    )
                ) {
                    let defaultState = {
                        port: '',
                        value: 0,
                        value1: 0,
                        value2: 0,
                        condition: 'gt',
                    }
                    humanoidProgramData[i].state = defaultState
                }
            } else {
                for (let j in humanoidProgramData[i].state) {
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}1`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                    if (
                        humanoidProgramData[i].state[j].name == `Port ${port}2`
                    ) {
                        humanoidProgramData[i].state[j].value = 0
                        humanoidProgramData[i].state[j].isActionEnabled = false
                        // humanoidProgramData[i].state[j].isLiveBtnActive = false
                    }
                }
            }
        }
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(humanoidProgramData)
        )
    }
    for (let nodeKey in node) {
        let obj = node[nodeKey].state

        //ai decision panel program remove
        switch (type) {
            case 'ai_motion': {
                if (isRemove && ['ai_motion'].includes(obj['source'])) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                } else if (
                    !isRemove &&
                    ([
                        'ai_face_recognition',
                        'ai_face_detection',
                        'ai_color_detection',
                    ].includes(obj['source']) ||
                        obj['source']?.includes('ai_face_tracking_X') ||
                        obj['source']?.includes('ai_face_tracking_Y') ||
                        obj['source']?.includes('ai_color_tracking_X') ||
                        obj['source']?.includes('ai_color_tracking_Y'))
                ) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                }
                break
            }

            case 'ai_color_detection':
            case 'ai_color_tracking': {
                console.log(
                    isRemove,
                    type,
                    obj['source'],
                    [type].includes(obj['source'])
                )
                if (
                    isRemove &&
                    ([type].includes(obj['source']) ||
                        obj['source']?.includes(`${type}_X`) ||
                        obj['source']?.includes(`${type}_Y`))
                ) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                } else if (
                    (!isRemove &&
                        [
                            'ai_motion',
                            'ai_face_recognition',
                            'ai_face_detection',
                        ].includes(obj['source'])) ||
                    obj['source']?.includes('ai_face_tracking_X') ||
                    obj['source']?.includes('ai_face_tracking_Y')
                ) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                }
                break
            }

            case 'ai_face_detection':
            case 'ai_face_recognition':
            case 'ai_face_tracking': {
                if (
                    isRemove &&
                    ([type].includes(obj['source']) ||
                        obj['source']?.includes(`${type}_X`) ||
                        obj['source']?.includes(`${type}_Y`))
                ) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                } else if (
                    (!isRemove &&
                        ['ai_motion', 'ai_color_detection'].includes(
                            obj['source']
                        )) ||
                    obj['source']?.includes('ai_color_tracking_X') ||
                    obj['source']?.includes('ai_color_tracking_Y')
                ) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                }
                break
            }

            default: {
                if (isRemove && [type].includes(obj['source'])) {
                    deleteDecisionPanelProgram({ node, nodeKey })
                }
            }
        }

        //ai action Panel program remove
        if (
            (node[nodeKey].type == 'variable_output' ||
                node[nodeKey].type == 'wait' ||
                node[nodeKey].type == 'hardware') &&
            port
        ) {
            if (`assign${port}` in obj || obj['assign' + port])
                delete node[nodeKey].state['assign' + port]

            if (`value${port}` in obj || obj['assign' + port])
                delete node[nodeKey].state['value' + port]
        }

        if (node[nodeKey].subprogram) {
            removeAssemblyPageAiComponents({
                node: node[nodeKey].subprogram,
                port,
                type,
                isRemove,
            })
        }
    }
}

export default removeSensorProgramData
