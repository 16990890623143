import React from 'react'
import {
    Line,
    XAxis,
    YAxis,
    Brush,
    CartesianGrid,
    Tooltip,
    LineChart,
    ResponsiveContainer,
    BarChart,
    Bar,
} from 'recharts'
import { GraphPlotStorage } from '../GraphPlotStorage'
import { connect } from 'react-redux'
import style from './Line.module.css'
import { graphSidebarData } from '../GraphsensorsPortData'
function LineGraphChart({ graphPlotStorage, currentActiveData, bar }) {
    // console.log('gsk bar ', currentActiveData[currentActiveData.length - 1])
    const reduxInternalaccessories = graphPlotStorage.internalaccessories

    const renderSensors = (sensorData, ind) => {
        let reduxName = graphSidebarData[sensorData].reduxName
        if (
            [
                'distancesensor',
                'gesturesensor',
                'colorsensorRed',
                'colorsensorBlue',
                'colorsensorGreen',
                'lightsensor',
            ].includes(sensorData)
        ) {
            if (!reduxInternalaccessories.Four_in_one_sensor[reduxName]) return
        } else if (!reduxInternalaccessories[reduxName]) {
            return
        }

        if (bar) {
            return (
                <Bar
                    barSize={100}
                    dataKey={graphSidebarData[sensorData].dataKey}
                    fill={graphSidebarData[sensorData].bgColour}
                />
            )
        } else {
            return (
                <Line
                    dataKey={graphSidebarData[sensorData].dataKey}
                    stroke={graphSidebarData[sensorData].bgColour}
                    strokeWidth="0.3%"
                />
            )
        }
    }

    const renderPortData = (portData) => {
        const activePorts = portData.ports.filter((port) => port.isActive)
        // console.log('gsk port active', currentActiveData)
        return activePorts.map((port) => (
            <>
                {bar ? (
                    <Bar
                        dataKey={`${port.portName}`}
                        fill={`${port.isColor}`}
                        barSize={100}
                    />
                ) : (
                    <Line
                        dataKey={`${port.portName}`}
                        stroke={`${port.isColor}`}
                        strokeWidth="0.3%"
                    />
                )}
            </>
        ))
    }

    return (
        <ResponsiveContainer
            min-width={300}
            className={
                bar ? style.Bar_recharts_container : style.recharts_container
            }
        >
            {bar ? (
                <BarChart
                    data={[currentActiveData[currentActiveData.length - 1]]}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <XAxis allowDecimals={true} dataKey="mic" />
                    <YAxis domain={[0, 1]} />

                    {/* render the enable internal sensors graph */}
                    {Object.keys(graphSidebarData).map((accessoriesKey, ind) =>
                        renderSensors(accessoriesKey, ind)
                    )}

                    {graphPlotStorage.internalaccessories.portsData.map(
                        (portData) => (
                            <>{renderPortData(portData)}</>
                        )
                    )}
                </BarChart>
            ) : (
                <LineChart data={currentActiveData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <XAxis dataKey="pv" allowDecimals={true} />
                    <YAxis domain={[0, 1]} />

                    {/* render the enable internal sensors graph */}
                    {Object.keys(graphSidebarData).map((accessoriesKey, ind) =>
                        renderSensors(accessoriesKey, ind)
                    )}

                    {graphPlotStorage.internalaccessories.portsData.map(
                        (portData) => (
                            <>{renderPortData(portData)}</>
                        )
                    )}

                    {/* {isPauseBtn == false && currentActiveData.length > 10 ? (
                    <Brush height={15} dataKey="pv" />
                ) : null} */}
                    <Brush
                        startIndex={
                            currentActiveData.length <= 20
                                ? 0
                                : currentActiveData.length - 20
                        }
                    />
                </LineChart>
            )}
        </ResponsiveContainer>
    )
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(LineGraphChart)
