import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AdvanceSettingStyle from './AdvanceSetting.module.css'
import renderImage from '../../source/importImg'
import { connect } from 'react-redux'

function OperationMode(props) {
    const [automicBtnCheck, setAutomicBtnCheck] = useState(false)
    const [FREEFALBtnCheck, setFREEFALBtnCheck] = useState(false)

    let history = useHistory()
    const gobackUrl = () => {
        history.goBack()
    }

    useEffect(() => {
        props.worker.postMessage({
            type: 'WriteArrayFormat1310',
            value: [
                'C'.charCodeAt(0),
                'O'.charCodeAt(0),
                'N'.charCodeAt(0),
                'F'.charCodeAt(0),
            ],
        })

        const messageHandel = (e) => {
            if (e.data.type === 'actionRead' && e.data?.value?.arrayData) {
                let arrayData = e.data.value.arrayData
                const regexCFER = /^CF.*ER$/
                if (regexCFER.test(e.data.value.stringData)) {
                    /*
                    progam 1
                    auto 0

                    send


                    */
                    console.log('VVVVVVVVVV', arrayData)
                    if (arrayData[2] == 1) {
                        setAutomicBtnCheck(true)
                    } else {
                        setAutomicBtnCheck(false)
                    }

                    if (arrayData[4] == 1) {
                        setFREEFALBtnCheck(true)
                    } else {
                        setFREEFALBtnCheck(false)
                    }
                }
            }
        }

        props.worker.addEventListener('message', messageHandel)
        return () => {
            props.worker.removeEventListener('message', messageHandel)
        }
    }, [props.webSerial.isConnected])

    const handleClick = () => {
        let value = [
            'S'.charCodeAt(0),
            'H'.charCodeAt(0),
            'E'.charCodeAt(0),
            'X'.charCodeAt(0),
        ]
        if (automicBtnCheck) {
            value.push(0)
        } else {
            value.push(1)
        }
        props.worker.postMessage({
            type: 'writeArray',
            value: value,
        })
        setAutomicBtnCheck(!automicBtnCheck)
    }

    const handleClickFREEFAL = () => {
        let value = [
            'F'.charCodeAt(0),
            'F'.charCodeAt(0),
            'E'.charCodeAt(0),
            'N'.charCodeAt(0),
        ]
        if (FREEFALBtnCheck) {
            value.push(0)
        } else {
            value.push(1)
        }
        props.worker.postMessage({
            type: 'writeArray',
            value: value,
        })
        setFREEFALBtnCheck(!FREEFALBtnCheck)
    }
    return (
        <div className={AdvanceSettingStyle.Main_Select}>
            <div className={AdvanceSettingStyle.Select_Header}>
                <div>
                    <img
                        className={AdvanceSettingStyle.Back_Btn}
                        src={renderImage('backBtn')}
                        onClick={gobackUrl}
                    ></img>
                </div>
                <div style={{ position: 'relative' }}>
                    <p className={AdvanceSettingStyle.text}>Advance Setting</p>
                </div>
                <div id={AdvanceSettingStyle.Help_Bttn}>
                    <div className="header_button">
                        <img
                            className={AdvanceSettingStyle.Help_Bttn_Img}
                            src={renderImage('helpBtnInActive')}
                            // onClick={handleHelpBtn}
                        ></img>
                        <span className="tooltiptext" style={{ left: '90.3%' }}>
                            {' '}
                            <p>Help</p>
                        </span>
                    </div>
                </div>
            </div>

            <div className={AdvanceSettingStyle.setting_Body}>
                <div className={AdvanceSettingStyle.swipeButtonDiv}>
                    <div className={AdvanceSettingStyle['OperationMod-btn']}>
                        <div
                            className={AdvanceSettingStyle['toggle-button']}
                            onClick={handleClick}
                        >
                            <div
                                className={`${AdvanceSettingStyle['slider']} ${
                                    automicBtnCheck
                                        ? AdvanceSettingStyle['right']
                                        : AdvanceSettingStyle['left']
                                }`}
                            ></div>
                            <span
                                className={`${AdvanceSettingStyle['button-text']} ${AdvanceSettingStyle['connect-text']}`}
                            >
                                Automatic <br></br>
                                Mode
                            </span>
                            <span
                                className={`${AdvanceSettingStyle['button-text']} ${AdvanceSettingStyle['disconnect-text']}`}
                            >
                                Program <br></br>
                                Mode
                            </span>
                        </div>
                    </div>
                    {/* <div
                        style={{ marginTop: '20px' }}
                        className={AdvanceSettingStyle['OperationMod-btn']}
                    >
                        <div
                            className={AdvanceSettingStyle['toggle-button']}
                            onClick={handleClickFREEFAL}
                        >
                            <div
                                className={`${AdvanceSettingStyle['slider']} ${
                                    FREEFALBtnCheck
                                        ? AdvanceSettingStyle['right']
                                        : AdvanceSettingStyle['left']
                                }`}
                            ></div>
                            <span
                                className={`${AdvanceSettingStyle['button-text']} ${AdvanceSettingStyle['connect-text']}`}
                            >
                                Fall-Detection:<br></br>
                                On
                            </span>
                            <span
                                className={`${AdvanceSettingStyle['button-text']} ${AdvanceSettingStyle['disconnect-text']}`}
                            >
                                Fall-Detection:<br></br>
                                off
                            </span>
                        </div>
                    </div> */}
                </div>
            </div>

            <div></div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}
// export default DragSource("data", cardSource, collect1)(Sidebar);
export default connect(mapStateToProps)(OperationMode)
