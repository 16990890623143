import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import { useHistory } from 'react-router'
import selectbarY from '../../Assets/Bisoft_UI/AppMode/selectbar.png'
import nextY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/next.png'
import backY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import RecognizeText from '../../Assets/Bisoft_UI/AppMode/RecognizeText.png'
import faceY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/face.png'
import faceXActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/faceActive.png'
import objectY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/object.png'
import objectXActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/objectActive.png'
import emotionY from '../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/emotion.png'
import emotionXActive from '../../Assets/Bisoft_UI/Buttons/Active without shadow/emotionActive.png'
import closeBtn from '../../Assets/img/close.png'
import Slider from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../source/programImg'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

const SelectDetection = () => {
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    let s2 = {
        face: null,
        emotion: null,
        object: null,
    }
    let session = JSON.parse(sessionStorage.getItem('enable'))
    if (session.s2 === undefined) {
        s2 = {
            face: false,
            emotion: false,
            object: false,
        }
    } else {
        s2 = {
            face: session.s2.face,
            emotion: session.s2.emotion,
            object: session.s2.object,
        }
    }

    const history = useHistory()
    const [face, setFace] = useState(s2.face)
    const [emotion, setEmotion] = useState(s2.emotion)
    const [object, setObject] = useState(s2.object)

    s2 = {
        face: face,
        emotion: emotion,
        object: object,
    }
    session['s2'] = s2
    useEffect(() => {
        var allDetails =
            JSON.parse(sessionStorage.getItem('allDetails')) || undefined
        if (allDetails === undefined) return
        if (face === false) {
            Object.keys(allDetails).map((key) => {
                if (allDetails[key].option === 'Face Detected')
                    allDetails[key].option = null
            })
        }
        if (emotion === false) {
            Object.keys(allDetails).map((key) => {
                if (allDetails[key].option === 'Emotion Detected')
                    allDetails[key].option = null
            })
        }
        if (object === false) {
            Object.keys(allDetails).map((key) => {
                if (allDetails[key].option === 'Object Detected')
                    allDetails[key].option = null
            })
        }
        sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
    }, [face, emotion, object])
    sessionStorage.setItem('enable', JSON.stringify(session))

    const clickHandler = () => {
        setSlideDirection(false)
        sessionStorage.setItem('slideDirection', false)
        let session = JSON.parse(sessionStorage.getItem('enable'))
        if (session.s1.cam === true && session.s2.face === true) {
            history.push('/uploadImage')
        } else if (session.s1.cam === true && session.s2.emotion === true) {
            history.push('/programmingPage')
        } else if (session.s1.cam === true && session.s2.object === true) {
            history.push('/programmingPage')
        }
    }

    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    return (
        <div>
            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="20%"
                    headers={[
                        { name: 'Enable', color: 'white' },
                        { name: 'Control', color: 'black' },
                        { name: 'Run', color: 'black' },
                    ]}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>
            {/* Main */}
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
                style={{ position: 'relative' }}
            >
                <img
                    src={RecognizeText}
                    alt="RecognizeText"
                    draggable="false"
                    style={{
                        position: 'absolute',
                        top: '20vh',
                        left: '50%',
                        transform: 'translate(-50%,50%)',
                    }}
                />
                {face ? (
                    <>
                        {' '}
                        <img
                            src={faceXActive}
                            draggable="false"
                            alt="faceActive"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '34vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setFace(false)
                            }}
                        ></img>
                    </>
                ) : (
                    <>
                        {' '}
                        <img
                            src={faceY}
                            draggable="false"
                            alt="face"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '34vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setFace(true)
                                setEmotion(false)
                                setObject(false)
                            }}
                        ></img>
                    </>
                )}
                {emotion ? (
                    <>
                        {' '}
                        <img
                            src={emotionXActive}
                            draggable="false"
                            alt="emotionActive"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '50vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setEmotion(false)
                            }}
                        ></img>
                    </>
                ) : (
                    <>
                        {' '}
                        <img
                            src={emotionY}
                            draggable="false"
                            alt="emotion"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '50vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setEmotion(true)
                                setFace(false)
                                setObject(false)
                            }}
                        ></img>
                    </>
                )}
                {object ? (
                    <>
                        {' '}
                        <img
                            src={objectXActive}
                            draggable="false"
                            alt="objectActive"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '66vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setObject(false)
                            }}
                        ></img>
                    </>
                ) : (
                    <>
                        {' '}
                        <img
                            src={objectY}
                            draggable="false"
                            alt="object"
                            style={{
                                margin: 0,
                                position: 'absolute',
                                top: '50vh',
                                left: '66vw',
                                width: '10vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={() => {
                                setObject(true)
                                setEmotion(false)
                                setFace(false)
                            }}
                        ></img>
                    </>
                )}
                {/* Element to Move Dynamically */}
                <h2
                    style={{
                        position: 'absolute',
                        top: '61vh',
                        transform: 'translate(-50%,-50%)',
                        left: '33.7%',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '16px',
                    }}
                >
                    <strong>Face</strong>
                </h2>

                {/* Element to Move Dynamically */}
                <h2
                    style={{
                        position: 'absolute',
                        top: '61vh',
                        transform: 'translate(-50%,-50%)',
                        left: '50%',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '16px',
                    }}
                >
                    <strong>Emotion</strong>
                </h2>

                {/* Element to Move Dynamically */}
                <h2
                    style={{
                        position: 'absolute',
                        top: '61vh',
                        transform: 'translate(-50%,-50%)',
                        left: '66%',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '16px',
                    }}
                >
                    <strong>Object</strong>
                </h2>
            </div>

            {/* Footer */}
            <div>
                <img
                    src={nextY}
                    draggable="false"
                    alt="next"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        left: '95.8vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={clickHandler}
                />
                <p
                    style={{
                        textAlign: 'center',
                        marginTop: '75vh',
                        // font: "Halcyon Regular",
                        // fontFamily: "Halcyon Regular",
                        fontSize: '16px',
                        color: 'grey',
                        position: 'absolute',
                        top: '16%',
                        left: '50%',
                        transform: 'translate(-50%,0)',
                    }}
                >
                    Tap on the icons to select the smart features you want to
                    use
                </p>
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        history.push('/chooseDevice')
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                    }}
                />
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('faceH')}
                                style={{
                                    width: '100%',
                                }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '56%',
                                    left: '32.2%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                    zIndex: '1000000',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature to train the app to
                                    recognise your face when running the
                                    program. In order for the App Mode to
                                    recognise your face, it needs to capture
                                    multiple photo's of your face
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('emotionH')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '56%',
                                    left: '41.5%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                    zIndex: '1000000',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature to use Mic as the AI
                                    based programming. Here,speech based word
                                    detection will be enabled
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('objectH')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '56%',
                                    left: '55.3%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                    zIndex: '1000000',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Enable this feature to use selected device
                                    in sync with the App Mode to perform certain
                                    actions or take input
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}
        </div>
    )
}
export default SelectDetection
