import * as atatus from 'atatus-spa'
import React, { Component, useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import renderImage from '../../source/importImg'
import PortConnections from '../Assembly/PortConnections'
import components from '../concept/data' //component details
import './googleloginbtn.scss'
import './login.css'
import GoogleLoginBtn from './googleLoginBtn'
import animationStyle from './loginAnimation.module.css'
import renderPrgImage from '../../source/programImg'
import ReactLoading from 'react-loading'

class login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowLoader: false,
            slideDirection: sessionStorage.getItem('slideDirection'),
        }
        this.isWindowOnFocus = sessionStorage.getItem('isWindowOnFocus')
        sessionStorage.clear()
    }

    timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    cookiePostRequest = async () => {
        let data = await fetch('https://c.plode.org/verify', {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                token: localStorage.getItem('accessToken'),
            }),
        })
        return data
    }

    componentDidMount = async () => {
        // sessionStorage.clear()
        sessionStorage.setItem('isWindowOnFocus', this.isWindowOnFocus)
        var concept = { counter: [], componentProps: {} }
        var assembly = {
            components: components,
            PortConnections: PortConnections,
            workspace: {
                // bibox: { top: 100, left: 250 },
                bibox: { top: 208, left: 328 },
                components: {
                    // Other components come here
                    // eg. "led": [{top: 20, left: 80, connectedTo: 'A1'}, ...], ...
                },
                offset: { top: 0, left: 0 },
                scale: 1,
            },

            height: document.body.clientHeight,
            width: document.body.clientWidth,
        }
        var logic = {
            program: [
                {
                    type: 'start',
                    state: {
                        bic1: false,
                        bic2: false,
                        bic3: false,
                        bid2: false,
                        bif1: false,
                        bif2: false,
                        bif3: false,
                    },
                    bic1: false,
                    bic2: false,
                    bic3: false,
                    bid1: false,
                    bid2: false,
                    bif1: false,
                    bif2: false,
                    bif3: false,
                    bid3: false,
                    bmp3: false,
                },
            ],
            end: { type: 'end', state: 'repeat' },
            insertState: false,

            offset: { left: 0, top: 0 },
            scale: 1,
            currentProgramGuide: 0,
            active: [-1, -1],
            bottomPanel: 'border',
        }

        if (this.props.webSerial.port) {
            console.log('yes')
        }
        console.log('LOGIC', concept, assembly, logic, this)
        this.props.clearComponent()
        this.props.selecteComponent(concept)
        this.props.assemblyComponent(assembly)
        this.props.logicComponent(logic)
        if (localStorage.getItem('accessToken')) {
            let sendingTime = Date.now()
            try {
                //Tushar why this peice of code is here,
                // i Have commented it because it was causing the loader to show up for a new user
                // setTimeout(() => {
                //     this.setIsShowLoader(true)
                // }, 4200)

                let data = await Promise.race([
                    this.cookiePostRequest(),
                    this.timeoutPromise(5000), //
                ])

                if (!data.ok) {
                    localStorage.removeItem('userJWT')
                } else if (localStorage.getItem('userJWT')) {
                    let getTime = Date.now()
                    let waitTime = 4200 - (getTime - sendingTime)
                    waitTime = waitTime <= 0 ? 0 : waitTime

                    setTimeout(() => {
                        this.props.history.push('/kitSelection')
                    }, waitTime)
                }
            } catch (e) {
                console.log('ERROR', e)
                if (localStorage.getItem('userJWT')) {
                    let getTime = Date.now()
                    let waitTime = 4200 - (getTime - sendingTime)
                    waitTime = waitTime <= 0 ? 0 : waitTime

                    setTimeout(() => {
                        this.props.history.push('/kitSelection')
                        alert(
                            "Our build server is currently down due to high demand. Some features may be temporarily unavailable. We're on it and appreciate your patience!"
                        )
                    }, waitTime)
                }
            }
            // fetch('https://c.plode.org/verify', {
            //     method: 'POST',
            //     headers: new Headers({
            //         'Content-Type': 'application/json',
            //     }),
            //     body: JSON.stringify({
            //         token: localStorage.getItem('accessToken'),
            //     }),
            // })
            //     .then((res) => {
            //         console.log('GGGGGGGGGG', localStorage.getItem('userJWT'))
            //         if (!res.ok) localStorage.removeItem('userJWT')
            //         else if (localStorage.getItem('userJWT')) {
            //             setTimeout(() => {
            //                 this.props.history.push('/kitSelection')
            //             }, 4200)
            //         }
            //     })
            //     .catch((e) => {
            //         if (localStorage.getItem('userJWT')) {
            //             setTimeout(() => {
            //                 this.props.history.push('/kitSelection')
            //                 alert(
            //                     "Our build server is currently down due to high demand. Some features may be temporarily unavailable. We're on it and appreciate your patience!"
            //                 )
            //             }, 4200)
            //         }
            //     })
        }
    }

    setIsShowLoader = (value) => {
        this.setState({ isShowLoader: value })
    }
    render() {
        // sessionStorage.clear()
        sessionStorage.removeItem('connectText')
        sessionStorage.removeItem('concept')
        let userJwt = localStorage.getItem('userJWT') ? true : false
        let accessToken = localStorage.getItem('accessToken') ? true : false
        const loginFail = (response) => {
            window.alert(
                'Sign in with Google failed try again with a different Google account'
            )
            console.log(response)
        }
        const loginSuccess = (response) => {
            sessionStorage.setItem(
                'userData',
                JSON.stringify(response.profileObj)
            )
            atatus.setUser(
                response.profileObj.googleId,
                response.profileObj.email,
                response.profileObj.givenName +
                    ' ' +
                    response.profileObj.familyName
            )
            this.props.history.push('/kitSelection')
        }
        const { slideDirection } = this.state
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#4A1FCC',
                }}
                className={`${slideDirection === 'true' ? 'slide-right' : ''}`}
            >
                <div className={animationStyle.newMainSec}>
                    <p className={animationStyle.newMainSecText}>
                        Playful Coding
                    </p>
                </div>

                <div className={animationStyle.manpage}>
                    <div className={animationStyle.iconDiv}>
                        <img
                            className={animationStyle.iconImage}
                            src={renderImage('plodeIcon')}
                        />
                    </div>

                    <p className={animationStyle.iconText}>Plode</p>

                    {(!userJwt || !accessToken) && (
                        <div id={animationStyle.NewGoogle_Login}>
                            <div>
                                {' '}
                                <GoogleLoginBtn
                                    history={this.props.history}
                                    setIsShowLoader={this.setIsShowLoader}
                                ></GoogleLoginBtn>
                            </div>
                        </div>
                    )}
                    {this.state.isShowLoader && (
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: 'rgb(210, 211, 211, 0.5)',
                                height: '100vh',
                                width: '100vw',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <ReactLoading
                                type="spin"
                                color="gray"
                                height={100}
                                width={100}
                            />
                        </div>
                    )}

                    {/* --------------- youdu robotics logo ------------------ */}
                    <div className={animationStyle.bottomTextDiv}>
                        <p className={animationStyle.copyRightText}>
                            <span style={{ fontSize: '1.6rem' }}>&copy;</span>
                            {'  '}
                            PLAYFULLY CRAFTED BY YUDU ROBOTICS
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        webSerial: state.webSerial,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'COMPONENT', payload: data })
        },

        assemblyComponent: (data) => {
            dispatch({ type: 'ASSEMBLY', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_RESET', payload: data }) //
        },
        clearComponent: (data) => {
            dispatch({ type: 'CLEAR_COMPONENT_SELECTION' }) //INTERNAL_ACCESSORIES
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(login))

// export default login
