import styles from './wifiSetting.module.css'
import { LinearProgress, Typography, withStyles } from '@material-ui/core'
// import "./ProcessB?ar.css";

const Processbar = ({ processStep, isProcessCompleted }) => {
    const totalSteps = 8

    const stepPercentage = (processStep / totalSteps) * 100

    return (
        <div className={styles.processBarModel}>
            {isProcessCompleted ? (
                <div className={styles['step-text']}>
                    Wifi Connected Successfully.
                </div>
            ) : (
                <div className={styles['step-text']}>Processing...</div>
            )}
            <LinearProgressStyled
                variant="determinate"
                value={stepPercentage}
            />
        </div>
    )
}

// Custom styled LinearProgress using makeStyles
const LinearProgressStyled = withStyles({
    root: {
        height: 7,
        width: '350px',
        borderRadius: 15,
        backgroundColor: '#798e79', // Light grey background
    },
    bar: {
        backgroundColor: '#4caf50', // Green color for the filled portion
    },
})(LinearProgress)

export default Processbar
