let isZingV1 =
    (sessionStorage.getItem('deviceVersion') || '0.0.0')[0] == 1 &&
    ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))
let isZingV2 =
    (sessionStorage.getItem('deviceVersion') || '0.0.0')[0] == 2 &&
    ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))

let PortsAndPortbytes = {
    A1: ['A1'],
    B1: ['B1'],
    C1: ['C1'],
    D1: ['D1'],
    E1: ['E1'],
    A2: ['A2'],
    B2: ['B2'],
    C2: ['C2'],
    D2: ['D2'],
    E2: ['E2'],
    F1: ['F1'],
    F2: ['F2'],
    G1: ['G1'],
    G2: ['G2'],
    H1: ['H1'],
    H2: ['H2'],
    I1: ['I1'],
    I2: ['I2'],
    M1: ['M1'],
    M2: ['M2'],
    M3: ['M3'],
    M4: ['M4'],

    MOTOR1: ['MOTOR1'],
    MOTOR2: ['MOTOR2'],
    MOTOR3: ['MOTOR3'],
    MOTOR4: ['MOTOR4'],
    Beeper: ['Beeper'],
    bid1: ['BiData1'],
    usbrx: ['BiData2'],
    usbtx: ['Bidata3'],
    bif1: ['Biflag1'],
    bif2: ['Biflag2'],
    btRx: ['Biflag3'],
    bic1: ['Bicounter1 - assign'],
    bic2: ['Bicounter2 - assign'],
    btTx: ['Bicounter3 - assign'],
    Countbic1: ['Bicounter1 - '],
    Countbic2: ['Bicounter2 - '],
    Countbic3: ['Bicounter3 - '],
    btr: ['Btremote'],
    bpr: ['Beeper'],
    IOT1: ['IOT1'],
    IOT2: ['IOT2'],
    IOT3: ['IOT3'],
    IOT4: ['IOT4'],
    IOT5: ['IOT5'],
    IOT6: ['IOT6'],
    IOT7: ['IOT7'],
    IOT8: ['IOT8'],
    IOT9: ['IOT9'],
    IOT10: ['IOT10'],

    // RGB: ["RGB"],
    // FOUR_in_ONE_Sensor: ["FOUR_in_ONE_Sensor"],
    // Buzzer: ["Buzzer"],
    // MICROPHONE: ["MICROPHONE"],

    Eye: ['Eye'], // both left and right eye
    Four_in_one_sensor: ['Four_in_one_sensor'],

    buzzer: ['buzzer'],
    Mic: ['Mic'], //microphone

    SmileOne: ['SmileOne'],
    SmileTwo: ['SmileTwo'],
    SmileThree: ['SmileThree'],
    SmileFour: ['SmileFour'],

    BuzzerFrequency: ['BuzzerFrequency'],
    BuzzerTone: ['BuzzerTone'],

    LeftEyeR: ['LeftEyeR'],
    LeftEyeG: ['LeftEyeG'],
    LeftEyeB: ['LeftEyeB'],

    RightEyeR: ['RightEyeR'],
    RightEyeG: ['RightEyeG'],
    RightEyeB: ['RightEyeB'],

    Temperature: ['Temperature'],
    Mp3: ['Mp3'],
    OLED: ['OLED'],
    OLEDOne: ['OLEDOne'],
    OLEDTwo: ['OLEDTwo'],
    OLEDThree: ['OLEDThree'],
    OLEDFour: ['OLEDFour'],
    TouchZero: ['TouchZero'],
    TouchOne: ['TouchOne'],
    TouchTwo: ['TouchTwo'],
    TouchZeroOutput: ['TouchZeroOutput'],
    TouchOneOutput: ['TouchOneOutput'],
    TouchTwoOutput: ['TouchTwoOutput'],
    TouchThreeOutput: ['TouchThreeOutput'],

    RGBComp1: ['RGBComp1'],
    RGBComp2: ['RGBComp2'],
    RGBComp3: ['RGBComp3'],
    RGBComp4: ['RGBComp4'],
    RGBComp5: ['RGBComp5'],
    RGBComp6: ['RGBComp6'],
    RGBComp7: ['RGBComp7'],
    RGBComp8: ['RGBComp8'],
    RGBComp9: ['RGBComp9'],
    RGBComp10: ['RGBComp10'],
    //PCv1.0
    TouchThree: ['TouchThree'],
    Battery: ['Battery'],
    Accelerometer: ['Accelerometer'],
    Gyro: ['Gyro'],
    AccelerometerX: ['AccelerometerX'],
    AccelerometerY: ['AccelerometerY'],
    AccelerometerZ: ['AccelerometerZ'],
    GyroX: ['GyroX'],
    GyroY: ['GyroY'],
    GyroZ: ['GyroZ'],
    EYES: ['EYES'],
    Eye1: ['Eye1'],
    Eye2: ['Eye2'],
    Eye3: ['Eye3'],
    Eye4: ['Eye4'],
    Eye5: ['Eye5'],
    Eye6: ['Eye6'],
    Eye7: ['Eye7'],
    Eye8: ['Eye8'],
    Eye9: ['Eye9'],
    Eye10: ['Eye10'],
    Eye11: ['Eye11'],
    Eye12: ['Eye12'],
    servo_motor1: ['servo_motor1'],
    servo_motor2: ['servo_motor2'],
    // humanoid Actions
    Attention: ['Attention'],
    Forward: ['Forward'],
    Backward: ['Backward'],
    Left: ['Left'],
    Right: ['Right'],
    Wave: ['Wave'],
    Fight: ['Fight'],
    Dance: ['Dance'],
    Booting: ['Booting'],
    Defence: ['Defence'],
    Attack: ['Attack'],
    Bow: ['Bow'],
    WakeUpFront: ['WakeUpFront'],
    WakeUpBack: ['WakeUpBack'],
    WakeUpBack2: ['WakeUpBack2'],
    Squat: ['Squat'],
    BoxForward: ['BoxForward'],
    LeftKick: ['LeftKick'],
    RightKick: ['RightKick'],
    BreakDance: ['BreakDance'],
    SlideToLeft: ['SlideToLeft'],
    SlideToRight: ['SlideToRight'],
    Greetings: ['Greetings'],
    TurnAround: ['TurnAround'],
    HookLeft: ['HookLeft'],
    HookRight: ['HookRight'],
    PushUp: ['PushUp'],
    SitUP: ['SitUP'],
    Salute: ['Salute'],
    CustomAction: ['CustomAction'],
    LeftShoot: ['LeftShoot'],
    RightShoot: ['RightShoot'],

    S1: ['S1'],
    S2: ['S2'],
    S3: ['S3'],
    S4: ['S4'],
    S5: ['S5'],
    S6: ['S6'],
    S7: ['S7'],
    S8: ['S8'],
    S9: ['S9'],
    S10: ['S10'],
    S11: ['S11'],
    S12: ['S12'],
    S13: ['S13'],
    S14: ['S14'],
    S15: ['S15'],
    S16: ['S16'],
    S17: ['S17'],
    S18: ['S18'],
    ServoTime: ['ServoTime'],
    Battery: ['Battery'],
    Ultrasonic: ['Ultrasonic'],
    Accelerometer: ['Accelerometer'],
    AccelerometerX: ['AccelerometerX'],
    AccelerometerY: ['AccelerometerY'],
    AccelerometerZ: ['AccelerometerZ'],
    Zingmp3: ['Zingmp3'],
    ZingMp3: ['ZingMp3'],
    GyroX: ['GyroX'],
    GyroY: ['GyroY'],
    GyroZ: ['GyroZ'],
    HeadServo: ['HeadServo'],
    // isIndivdualServo: ['isIndivdualServo'],
    ActionGroup: ['ActionGroup'],
    ActionServo: ['ActionServo'],
    SimpleServo: ['SimpleServo'],
    //armSheild
    SSTime: ['SSTime'],
    SS1: ['SS1'],
    SS2: ['SS2'],
    SS3: ['SS3'],
    SS4: ['SS4'],
    SS5: ['SS5'],
    P0: ['P0'],
    P1: ['P1'],
    P2: ['P2'],
    P3: ['P3'],
    SPK: ['SPK'],
    //assign
    asgn1: ['asgn1'],
    Switch1: ['C1'],
    Switch2: ['C2'],

    ////Klaw////
    // Joystick1: ['Joystick1'],
    // Joystick1: ['Joystick1'],
    // Joystick1: ['Joystick1'],
    // Switch1: ['Switch1'],
    // Switch2: ['Switch2'],
    // Switch3: ['Switch3'],

    // Pot1: ['C1'],
    // Pot2: ['C2'],
    // Pot3: ['D1'],
    // Pot4: ['D2'],
    // Pot5: ['E1'],
    // Pot6: ['E2'],
    serial_servo1: ['serial_servo1'],
    serial_servo2: ['serial_servo2'],
    PW1: ['PW1'],
    PW2: ['PW2'],
    PW3: ['PW3'],
    PW4: ['PW4'],
    PW5: ['PW5'],
    PW6: ['PW6'],
    O1: ['O1'],
    O2: ['O2'],

    //for Ai 1 tool
    ai_LEDA1: ['ai_LEDA1'],
    ai_audioA1: ['ai_audioA1'],

    AtrMotor1M1: ['AtrMotor1M1'],
    AtrMotor1M2: ['AtrMotor1M2'],
    AtrMotor2M3: ['AtrMotor2M3'],
    AtrMotor2M4: ['AtrMotor2M4'],
    AtrMotor3E1: ['AtrMotor3E1'],
    AtrMotor3E2: ['AtrMotor3E2'],

    RandomAction: ['RandomAction'],
}
let CorrespondingPortCode = {
    // setting the program bytes
    // RGB: "L",
    // Buzzer: "B",
    // FOUR_in_ONE_Sensor: "G",
    // MICROPHONE: "M",

    Eye: 'L', // both eye left n right
    Four_in_one_sensor: 'G',

    buzzer: 'B',
    Mic: 'M', //microphone

    SmileOne: 'O',
    SmileTwo: 'O',
    SmileThree: 'O',
    SmileFour: 'O',

    Temperature: 'T',
    TouchZero: 'T',
    TouchOne: 'T',
    TouchTwo: 'T',
    TouchZeroOutput: 'O',
    TouchOneOutput: 'O',
    TouchTwoOutput: 'O',
    TouchThreeOutput: 'O',

    led: 'P',
    led_1c: 'P',
    laser: 'O',
    dual_splitter: 'O',
    beeper: 'O',
    geared_motor: 'P',
    mini_geared_motor: 'P',
    servo_motor: 'S',
    servo_motor_270: 'S',
    servo_motor_360: 'R',
    motor_driver: 'O',
    dc_motor: 'P',
    dynamex_motor: 'D',
    '7segment_display': 'O',
    '4_CH_relay': 'O',
    relay: 'O',
    electromagnet: 'P',
    mp3: 'M',
    led_strip: 'O',
    dot_matrix: 'D',
    metal_detector: 'I',
    ultrasonic_sensor: 'U',
    heartbeat_sensor: 'I',
    rfid: 'R',
    color_sensor: 'C',
    tact_switch: 'I',
    tact_switch_2c: 'I',
    dip_switch: 'I',
    dual_switch: 'I',
    touch_sensor: 'I',
    pir_sensor: 'I',
    gesture_sensor: 'G',
    '4_in_1_sensor': 'G',
    battery: 'O',
    gyro_sensor: 'G',
    compass: 'P',
    G1: 'O',
    G2: 'O',
    light_sensor: 'A',
    bend_sensor: 'A',
    gas_sensor: 'A',
    distance_sensor: 'A',
    sound_sensor: 'A',
    temperature_sensor: 'A',
    rain_sensor: 'A',
    rain_sensor_module_analog: 'A',
    rain_sensor_module_digital: 'I',
    // humidity_sensor: "A",
    hall_sensor: 'A',
    rotational_sensor: 'A',
    pot: 'A',
    linear_pot: 'A',
    humidity: 'A',
    // temp: "A",
    gas: 'A',
    extender: 'A',

    temp_gas: 'A',
    rotatory: 'A',
    temp_dew: 'A',

    accelerometer: 'A',
    solar_panel: 'A',
    joystick: 'A',
    //PCv1.0
    TouchThree: 'T',
    Battery: 'B',
    Accelerometer: 'A',
    EYES: 'L',
    //gsk edit for humanoid
    HeadServo: 'S',
    SmipleServo: 'S',
    // Zingmp3: 'M',
    Zingmp3: 'O',

    isGyroX: 'G',
    isGyroY: 'G',
    isGyroZ: 'G',
    isHeadServo: 'S',
    isIndivdualServo: 'O',
    ActionGroup: 'A',
    ActionServo: 'I',
    SimpleServo: 'S',
    isLeftEye: 'O',
    isRightEye: 'O',
    Battery: 'B',
    Ultrasonic: 'U',
    Accelerometer: 'A',
    Gyro: 'G',
    AccelerometerX: 'A',
    AccelerometerY: 'A',
    AccelerometerZ: 'A',
    Switch1: 'I',
    Switch2: 'I',

    //klaw
    // isJoysick1: 'A',
    // isJoysick2: 'A',
    // isJoysick3: 'A',
    // isSwitch1: 'I',
    // isSwitch2: 'I',
    // isSwitch3: 'I',
    ispot1: 'A',
    ispot2: 'A',
    ispot3: 'A',
    ispot4: 'A',
    ispot5: 'A',
    ispot6: 'A',
    PWM: 'P',
    serial_servo_motor: 'I',
    pcAiTool: 'X',

    // M1:'M',
    // M2:'M'
    //Toki setting bytes
    // LED_blue: 'P',
    // LED_green: 'P',
    // LED_red: 'P',
    // LED_white: 'P',
    // laser_diode: 'O',
    // LDR_sensor: 'A',
    // PIR_sensor: 'I',
    // DHT11: 'A',
    // capacitative_touch_sensor: 'I',
    // IR_proximity: 'A',
    // L293D_motor_driver: 'P',
    Analog_3_Pin_Input: 'A',
    Analog_2_Pin_Input: 'A',
    PWM_3_Pin_Output: 'P',
    PWM_2_Pin_Output: 'P',
    Digital_3_Pin_Input: 'I',
    Digital_2_Pin_Input: 'I',
    Digital_3_Pin_Output: 'O',
    Digital_2_Pin_Output: 'O',
}

let PortByteValuesIf = {
    A1: '1',
    A2: '2',
    B1: '3',
    B2: '4',
    C1: '5',
    C2: '6',
    D1: '7',
    D2: '8',
    E1: '9',
    E2: '10',
    F1: '11',
    F2: '12',
    G1: '13',
    G2: '14',
    H1: '15',
    H2: '16',
    I1: '17',
    I2: '18',
    M1: '19',
    M2: '20',
    M3: '21',
    M4: '22',
    bid1: 'Bidata1',
    usbrx: 'Bidata2',
    usbtx: 'Bidata3',
    bif1: 'Biflag1',
    bif2: 'Biflag2',
    btRx: 'Biflag3',
    bic1: 'Bicounter1',
    bic2: 'Bicounter2',
    btTx: 'Bicounter3',
    remote: 'Btremote',
    slider: 'Btslider',
    IOT1: ['IOT1'],
    IOT2: ['IOT2'],
    IOT3: ['IOT3'],
    IOT4: ['IOT4'],
    IOT5: ['IOT5'],
    IOT6: ['IOT6'],
    IOT7: ['IOT7'],
    IOT8: ['IOT8'],
    IOT9: ['IOT9'],
    IOT10: ['IOT10'],
}

let PortByteNumericalValuesIf = {
    A1: '1',
    A2: '2',
    B1: '3',
    B2: '4',
    C1: '5',
    C2: '6',
    D1: '7',
    D2: '8',
    E1: '9',
    E2: '10',
    F1: '11',
    F2: '12',
    G1: '13',
    G2: '14',
    H1: '15',
    H2: '16',
    I1: '17',
    I2: '18',
    M1: '19',
    M2: '20',
    M3: '21',
    M4: '22',
    // Bicounter1: '31',
    // Bicounter2: '32',
    // Bicounter3: '33',
    Bicounter1: '35',
    Bicounter2: '36',
    'reserved for future': '34',
    // Biflag1: '35',
    // Biflag2: '36',
    Biflag1: '37',
    Biflag2: '38',
    // Biflag3: '37',

    Btremote: '55',
    'BT Remote': 55,
    Btslider: '39',
    BiData1: '39',
    BiData2: '40',
    Bidata3: '44',
    U1: '45',
    U2: '46',
    _R: '47',
    G: '70',
    B: '71',
    D: '73',
    M: '74',
    L: '72',
    Mp3: '47',
    OLEDOne: '48',
    OLEDTwo: '49',
    OLEDThree: '50',
    OLEDFour: '52',
    rfid: '50',
    timeElapsed: '51',
    //gsk edit for humanoid
    battery: isZingV1 || isZingV2 ? '54' : '24', //24
    ultrasonic: '33',
    accelerometer_x: isZingV1 || isZingV2 ? '48' : '27', //27
    accelerometer_y: isZingV1 || isZingV2 ? '49' : '28', //28
    accelerometer_z: isZingV1 ? '50' : '29', //29
    gyro_x: isZingV1 || isZingV2 ? '51' : '30', //30
    gyro_y: isZingV1 || isZingV2 ? '52' : '31', //31
    gyro_z: isZingV1 || isZingV2 ? '53' : '32', //32

    time: '63',
    // 'IOT1': '52',
    IOT1: '52',
    IOT2: '53',
    IOT3: '54',
    IOT4: '55',
    IOT5: '56',
    IOT6: '57',
    IOT7: '58',
    IOT8: '59',
    IOT9: '60',
    IOT10: '61',

    FOUR_in_ONE_Sensor_MOTION: '30',

    FOUR_in_ONE_Sensor_DIST: '26',
    FOUR_in_ONE_Sensor_GESTURE: '28',
    FOUR_in_ONE_Sensor_LIGHT: '27',
    FOUR_in_ONE_Sensor_RED: '23',
    FOUR_in_ONE_Sensor_GREEN: '24',
    FOUR_in_ONE_Sensor_BLUE: '25',
    // "4-IN-1 SENSOR  →  DIST": "26",
    // "4-IN-1 SENSOR  →  GESTURE": "28",
    // "4-IN-1 SENSOR  →  LIGHT": "27",
    // "4-IN-1 SENSOR  →  RED": "23",
    // "4-IN-1 SENSOR  →  GREEN": "24",
    // "4-IN-1 SENSOR  →  BLUE": "25",

    MICROPHONE: '29',
    TEMPERATURE: '33',

    TouchZero: '30',
    TouchOne: '31',
    TouchTwo: '32',
    //PCv1.0
    TouchThree: '47',
    BATTERY: '54',
    ACCELEROMETER_X: '48',
    ACCELEROMETER_Y: '49',
    ACCELEROMETER_Z: '50',
    GYRO_X: '51',
    GYRO_Y: '52',
    GYRO_Z: '53',
    //Oled4
    Temperature: '33',
    Mic: '29',
    Battery: '54',
    AccelerometerX: '48',
    AccelerometerY: '49',
    AccelerometerZ: '50',
    GyroX: '51',
    GyroY: '52',
    GyroZ: '53',
    DistanceSensors: '26',
    GestureSensor: '28',
    LightSensor: '27',
    RED: '23',
    GREEN: '24',
    BLUE: '25',
    default: '1',
    touchZero: '30',
    touchOne: '31',
    touchTwo: '32',
    microphone: '29',
    temperature: '33',
    bic1: '35',
    bic2: '36',
    Bif1: '37',
    Bif2: '38',
    bif1: '37',
    bif2: '38',
    Usbtx: '39',
    'USB RX': '41',
    'BT RX1': '42',
    'BT RX2': '43',
    'BT RX3': '44',
    'BT RX4': '45',
    'BT RX5': '46',
    Switch_One: '5', //this is switch 1 internal accessories for Roboki
    Switch_Two: '6', //this is switch 2 internal accessories for Roboki

    switch_1: '32', //this is switch 1 internal accessories for Klaw
    switch_2: '47', //this is switch 2 internal accessories for Klaw

    //klaw
    pot_1: '5',
    pot_2: '6',
    pot_3: '7',
    pot_4: '8',
    pot_5: '9',
    pot_6: '10',

    //for pcAITool if port
    ai_mic: '57',
    ai_lidar: '58',
    ai_motion: '59',
    ai_color_detection: '59',
    ai_face_detection: '59',
    ai_face_recognition: '60',

    ai_color_tracking_X: '61',
    ai_color_tracking_Y: '62',

    ai_face_tracking_X: '61',
    ai_face_tracking_Y: '62',
    servo_motor1: '31',
    servo_motor2: '32',

    IR: '69',
}

let PortByteNumericalValuesOutput = {
    A1: '1',
    A2: '2',
    B1: '3',
    B2: '4',
    C1: '5',
    C2: '6',
    D1: '7',
    D2: '8',
    E1: '9',
    E2: '10',
    F1: '11',
    F2: '12',
    G1: '13',
    G2: '14',
    H1: '15',
    H2: '16',
    I1: '17',
    I2: '18',
    M1: '19',
    M2: '20',
    M3: '21',
    M4: '22',
    Mp3: '47',
    OLEDOne: '48',
    OLEDTwo: '49',
    OLEDThree: '50',
    OLEDFour: '51',
    R: '69',
    G: '46',
    B: '71',
    D: '73',
    M: '74',
    L: '72',

    // portNumber of buzzer
    Buzzer: '21',
    RGBComp1: 75,
    RGBComp2: 76,
    RGBComp3: 77,
    RGBComp4: 78,
    RGBComp5: 79,
    RGBComp6: 80,
    RGBComp7: 81,
    RGBComp8: 82,
    RGBComp9: 83,
    RGBComp10: 84,

    // PortNumber
    LeftEyeR: '24',
    LeftEyeG: '25',
    LeftEyeB: '26',
    RightEyeR: '27',
    RightEyeG: '28',
    RightEyeB: '29',

    SmileOne: '19',
    SmileTwo: '20',
    SmileThree: '21',
    SmileFour: '22',

    BuzzerFrequency: '23',
    BuzzerTone: '30',

    TouchZero: '31',
    TouchOne: '32',
    TouchTwo: '33',

    servo_motor1: '31',
    servo_motor2: '32',

    Attention: '53',
    Forward: '53',
    Backward: '53',
    Left: '53',
    Right: '53',
    Wave: '53',
    Bow: '53',
    WakeUpFront: '53',
    WakeUpBack: '53',
    WakeUpBack2: '53',
    Squat: '53',
    BoxForward: '53',
    LeftKick: '53',
    RightKick: '53',
    BreakDance: '53',
    SlideToLeft: '53',
    SlideToRight: '53',
    Greetings: '53',
    TurnAround: '53',
    HookLeft: '53',
    HookRight: '53',
    PushUp: '53',
    SitUP: '53',
    Salute: '53',
    LeftShoot: '53',
    RightShoot: '53',
    CustomAction: ['1', '2'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? '102'
        : '53',
    Fight: '53',
    Dance: '53',
    Booting: '53',
    Attack: '53',
    Defence: '53',
    HeadServo: '31',
    SimpleServo: '32',
    ServoTime: '52',
    S1: '54',
    S2: '55',
    S3: '56',
    S4: '57',
    S5: '58',
    S6: '59',
    S7: '60',
    S8: '61',
    S9: '62',
    S10: '63',
    S11: '64',
    S12: '65',
    S13: '66',
    S14: '67',
    S15: '68',
    S16: '69',
    S17: '70',
    S18: '71',

    // ZingMp3: '30',
    ZingMp3: '104',

    //armSheild
    SSTime: '52',
    SS1: '54',
    SS2: '55',
    SS3: '56',
    SS4: '57',
    SS5: '58',
    P0: '1',
    P1: '3',
    P2: '5',
    SPK: '59',
    //PCv1.0
    Eye1: '24',
    Eye2: '25',
    Eye3: '26',
    Eye4: '27',
    Eye5: '28',
    Eye6: '29',
    Eye7: '85',
    Eye8: '86',
    Eye9: '87',
    Eye10: '88',
    Eye11: '89',
    Eye12: '90',
    //counter
    Bic1: '35',
    Bic2: '36',
    Bif1: '37',
    Bif2: '38',
    //biData
    Bid1: '39',
    Bid2: '40',
    //Usb
    Usbtx: '46',
    //BT tx
    BtTx1: '41',
    BtTx2: '42',
    BtTx3: '43',
    BtTx4: '44',
    BtTx5: '45',
    //assign
    Asgn1: '92',
    Asgn2: '93',
    Asgn3: '94',
    Asgn4: '95',
    Asgn5: '96',
    buzzer: '23',
    TouchThree: '47',

    ///Klaw///
    PW1: 19, // '14',
    PW2: 20, //'15',
    PW3: 21, //'16',
    PW4: 22, //'17',
    PW5: 31, //'18',
    PW6: 32, //'31',
    serial_servo1: '39',
    serial_servo2: '39',
    //not in use
    O1: '26',
    O2: '27',

    //for pcAiTool
    ai_LEDA1: '105',
    ai_audioA1: '106',

    AtrMotor1M1: 19,
    AtrMotor1M2: 20,
    AtrMotor2M3: 21,
    AtrMotor2M4: 22,
    AtrMotor3E1: 9,
    AtrMotor3E2: 10,

    RandomAction: '103',
}
let BiDataValuesOutput = {
    A1: '1',
    A2: '2',
    B1: '3',
    B2: '4',
    C1: '5',
    C2: '6',
    D1: '7',
    D2: '8',
    E1: '9',
    E2: '10',
    F1: '11',
    F2: '12',
    G1: '13',
    G2: '14',
    H1: '15',
    H2: '16',
    I1: '17',
    I2: '18',
    M1: '19',
    M2: '20',
    M3: '21',
    M4: '22',
    R: '69',
    G: '46',
    B: '71',
    D: '73',
    M: '74',
    L: '72',
    Mp3: '47',
    OLEDOne: '48',
    OLEDTwo: '49',
    OLEDThree: '50',
    //armSheild
    P0: '1',
    P1: '3',
    P2: '5',
}

let nodeDefaultCodes = {
    //main changes is here.
    wait: 'w',
    if: 'd',
    if: 'd',

    sensor: 'd',
    variable: 'd',

    endcondition: 'ED',

    else: 'd',
    endsensor: '0ED',
    endvariable: '0ED',

    endif: 'ED',

    endelse: '0ED',
    loop: 'l00',
    endloop: '0EL',
    output: 'o{',
    hardware: 'o{',
    variable_output: 'o{',

    repeat: 'RST',
    end: 'END',
}
const bidataPortValue = {
    isA1: '1',
    isA2: '2',
    isB1: '3',
    isB2: '4',
    isC1: '5',
    isC2: '6',
    isD1: '7',
    isD2: '8',
    isE1: '9',
    isE2: '10',
    isF1: '11',
    isF2: '12',
    isbic1: '23',
    isbic2: '24',
    isbif1: '25',
    isbif2: '26',
    isbid1: '27',
    isbid2: '28',
    isbtTx1: '29',
    isbtTx2: '30',
    isbtTx3: '31',
    isbtTx4: '32',
    isbtTx5: '33',
    isusbtx: '39',
    RED: '43',
    GREEN: '44',
    BLUE: '45',
    LightSensor: '46',
    GestureSensor: '48',
    DistanceSensors: '47',
    isMic: '49',
    isTouchZero: '50',
    isTouchOne: '51',
    isTouchTwo: '52',
    isTemperature: '53',
    isTouchThree: '54',
    isAccelerometerX: '56',
    isAccelerometerY: '57',
    isAccelerometerZ: '58',
    isGyroX: '59',
    isGyroY: '60',
    isGyroZ: '61',
}
const GROUPACTIONS = {
    HUMANOID: {
        50: { name: 'Attention', value: 0 },
        51: { name: 'Forward', value: 1 },
        52: { name: 'Backward', value: 2 },
        53: { name: 'Left', value: 3 },
        54: { name: 'Right', value: 4 },
        55: { name: 'Wave', value: 5 },
        56: { name: 'Bow', value: 6 },
        57: { name: 'Wake Up Front', value: 7 },
        58: { name: 'Wake Up Back', value: 8 },
        59: { name: 'Wake Up Back 2', value: 9 },
        60: { name: 'Squat', value: 10 },
        61: { name: 'Box Forward', value: 11 },
        // 62: { name: 'Left Kick', value: 12 },
        // 63: { name: 'Right Kick', value: 13 },
        // 64: { name: 'Break Dance', value: 14 },
        // 65: { name: 'Gangnam Style', value: 15 },
        // 66: { name: 'Apple Dance', value: 16 },
        // 67: { name: 'Slide To Left', value: 17 },
        // 68: { name: 'Slide To Right', value: 18 },
        // 69: { name: 'Hook Left', value: 19 },
        // 70: { name: 'Hook Right', value: 20 },
        // 71: { name: 'Push Up', value: 21 },
        // 72: { name: 'Sit UP', value: 22 },
        // 73: { name: 'Salute', value: 23 },
        // 74: { name: 'CustomAction', value: 24 },
        62: { name: 'Left Kick', value: 19 },
        63: { name: 'Right Kick', value: 20 },
        64: { name: 'Break Dance', value: 16 },
        65: { name: 'Gangnam Style', value: 15 },
        66: { name: 'Apple Dance', value: 16 },
        67: { name: 'Slide To Left', value: 17 },
        68: { name: 'Slide To Right', value: 18 },
        69: { name: 'Hook Left', value: 19 },
        70: { name: 'Hook Right', value: 20 },
        71: { name: 'Push Up', value: 21 },
        72: { name: 'Sit UP', value: 22 },
        73: { name: 'Salute', value: 23 },
        74: { name: 'CustomAction', value: 24 },
        // 75: { name: 'Left Shoot', value: 12 },
        // 76: { name: 'Right Shoot', value: 13 },
        76: { name: 'Random Action', value: 28 },
    },
    HEXAPOD: {
        50: { name: 'Attention', value: 0 },
        51: { name: 'Forward', value: 1 },
        52: { name: 'Backward', value: 2 },
        53: { name: 'Left', value: 3 },
        54: { name: 'Right', value: 4 },
        55: { name: 'SlideToLeft', value: 5 },
        56: { name: 'SlideToRight', value: 6 },
        57: { name: 'Defence', value: 7 },
        58: { name: 'Fight', value: 8 },
        59: { name: 'Greetings', value: 9 },
        60: { name: 'Attack', value: 10 },
        61: { name: 'TurnAround', value: 11 },
        62: { name: 'Booting', value: 12 },
        63: { name: 'Dance', value: 13 },
        74: { name: 'CustomAction', value: 24 },
        76: { name: 'Random Action', value: 28 },
    },
}
//For PeeCee 1.0 the bytes of TouchPad 2 and 3 is crisscrossed WRT PeeCee0.1
if (
    sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
    ['Ace'].includes(sessionStorage.getItem('connectedDevice'))
) {
    PortByteNumericalValuesIf.TouchThree = 32
    PortByteNumericalValuesIf.TouchTwo = 47
}
// if (sessionStorage.getItem('zingVersion') === '1.0.01') {
// PortByteNumericalValuesOutput['M1'] = '31'
// PortByteNumericalValuesOutput['M2'] = '3'
// }
export {
    BiDataValuesOutput,
    nodeDefaultCodes,
    PortsAndPortbytes,
    CorrespondingPortCode,
    PortByteNumericalValuesIf,
    PortByteValuesIf,
    PortByteNumericalValuesOutput,
    GROUPACTIONS,
    bidataPortValue,
}
