import { title } from 'process'
import { connect } from 'react-redux'
import renderPrgImage from '../../../source/programImg'
import './AiModeSelect.css'
import { fetchAudioList } from '../../AppMode/AppModeAI/AppModeAiBytesGeneration'
import { useEffect, useRef } from 'react'
import { removeAssemblyPageAiComponents } from '../../../utils/removeSensorProgramData'
// import { checkOk, timeoutPromise } from '../../../utils/workerReadSupport'

const pcAiAccessories = {
    row1: {
        ai_motion: {
            title: 'Motion',
            activeImg: 'ai_motionActive',
            inActiveImg: 'ai_motion',
        },
    },
    row2: {
        ai_color_detection: {
            title: 'Color Detection',
            activeImg: 'ai_color_detectionActive',
            inActiveImg: 'ai_color_detection',
        },
        ai_color_tracking: {
            title: 'Color Tracking',
            activeImg: 'ai_color_trackingActive',
            inActiveImg: 'ai_color_tracking',
        },
    },
    row3: {
        ai_face_detection: {
            title: 'face Detection',
            activeImg: 'ai_face_detectionActive',
            inActiveImg: 'ai_face_detection',
        },
        ai_face_recognition: {
            title: 'Face Recognition',
            activeImg: 'ai_face_recognitionActive',
            inActiveImg: 'ai_face_recognition',
        },
        ai_face_tracking: {
            title: 'Face Tracking',
            activeImg: 'ai_face_trackingActive',
            inActiveImg: 'ai_face_tracking',
        },
    },
    row4: {
        ai_mic: {
            title: 'Mic',
            activeImg: 'ai_micActive',
            inActiveImg: 'ai_mic',
        },
        ai_lidar: {
            title: 'Lidar',
            activeImg: 'ai_lidarActive',
            inActiveImg: 'ai_lidar',
        },
    },
    row5: {
        ai_LED: {
            title: 'LED',
            activeImg: 'ai_LEDActive',
            inActiveImg: 'ai_LED',
        },
        ai_audio: {
            title: 'Audio',
            activeImg: 'buzzerActive',
            inActiveImg: 'buzzerInActive',
        },
    },
}

function AiModeSelect(props) {
    const assemblyPageAi = { ...props.assembly.assemblyPageAi }

    const sendDataAsPacket = useRef(false)
    const fetchAudioListRef = useRef([])

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    const handelClose = () => {
        let assemblyPageAi = { ...props.assembly.assemblyPageAi }
        assemblyPageAi.isShowAiSelectContainer = false
        props.setAssemblyPageAi(assemblyPageAi)
    }

    const handleClicks = async (elementKey) => {
        if (sendDataAsPacket.current) {
            return
        }

        let newAssemblyPageAi = { ...props.assembly.assemblyPageAi }
        let aiItems = { ...newAssemblyPageAi.aiItems }
        let node = props.logic.program

        if (elementKey === 'ai_audio' && aiItems.ai_audio === false) {
            try {
                props.showAiModelPopUp({
                    elementKey: 'Fetching the audio list....',
                    text: true,
                    time: 7400,
                })

                fetchAudioList(props.worker)
                sendDataAsPacket.current = true

                let replyOK = await Promise.race([
                    checkOk(sendDataAsPacket.current),
                    timeoutPromise(7000), //
                ])
            } catch (e) {
                sendDataAsPacket.current = false
                props.showAiModelPopUp({
                    elementKey: 'Something wrong, place try again.',
                    text: true,
                })

                return
            }
        }

        newAssemblyPageAi = { ...props.assembly.assemblyPageAi }
        aiItems = { ...newAssemblyPageAi.aiItems }

        props.showAiModelPopUp({ elementKey, text: false })
        switch (elementKey) {
            case 'ai_motion':
                removeAssemblyPageAiComponents({
                    node,
                    port: 'ai_motionA1',
                    type: elementKey,
                    isRemove: aiItems.ai_motion,
                })
                aiItems.ai_motion = !aiItems.ai_motion

                aiItems.ai_color_detection = false
                aiItems.ai_color_tracking = false
                aiItems.ai_face_detection = false
                aiItems.ai_face_recognition = false
                aiItems.ai_face_tracking = false
                break

            case 'ai_color_detection':
            case 'ai_color_tracking':
                removeAssemblyPageAiComponents({
                    node,
                    port: `${elementKey}A1`,
                    type: elementKey,
                    isRemove: aiItems[elementKey],
                })
                aiItems[elementKey] = !aiItems[elementKey]

                aiItems.ai_motion = false
                aiItems.ai_face_detection = false
                aiItems.ai_face_recognition = false
                aiItems.ai_face_tracking = false
                break
            case 'ai_face_detection':
            case 'ai_face_recognition':
            case 'ai_face_tracking':
                removeAssemblyPageAiComponents({
                    node,
                    port: `${elementKey}A1`,
                    type: elementKey,
                    isRemove: aiItems[elementKey],
                })
                aiItems[elementKey] = !aiItems[elementKey]

                aiItems.ai_motion = false
                aiItems.ai_color_detection = false
                aiItems.ai_color_tracking = false
                break
            default:
                removeAssemblyPageAiComponents({
                    node,
                    port: `${elementKey}A1`,
                    type: elementKey,
                    isRemove: aiItems[elementKey],
                })
                aiItems[elementKey] = !aiItems[elementKey]
        }

        if (fetchAudioListRef.current.length > 0) {
            newAssemblyPageAi.aiAudioList = fetchAudioListRef.current
            fetchAudioListRef.current = []
        }
        newAssemblyPageAi.aiItems = aiItems
        props.setAssemblyPageAi(newAssemblyPageAi)
    }

    useEffect(() => {
        //read data from serial worker
        const messageHandler = (e) => {
            if (e.data.type === 'actionRead') {
                try {
                    let { arrayData, stringData } = e.data.value
                    // const regexEqu = /(?:\d+,\d+,28,)(.+)/
                    const regexEqu = /(?<=\d+,\d+,28,)(.*?)(?=,\d+,255,245)/
                    let match = arrayData.join(',').match(regexEqu)

                    if (match) {
                        const matchArr = match[0].split(',')
                        const stringData = String.fromCharCode(...matchArr)
                        let stringArr = stringData.split('\n')
                        console.log(stringArr)

                        fetchAudioListRef.current = [
                            'Select Ai Audio',
                            ...stringArr,
                        ]
                        sendDataAsPacket.current = false
                    }
                } catch (e) {
                    console.log('ERROR', e)
                }
            }
        }

        props.worker.addEventListener('message', messageHandler)

        return () => {
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [])

    return (
        <div className="aiModeSelectDiv">
            <img
                src={renderPrgImage('close')}
                style={{
                    position: 'absolute',
                    top: '-3%',
                    right: '-2%',
                    cursor: 'pointer',
                }}
                width="40px"
                alt="close"
                onClick={(e) => handelClose()}
            />
            <div className="aiModeSelect_container">
                {Object.keys(pcAiAccessories).map((rowKey, ind) => {
                    const row = pcAiAccessories[rowKey]
                    return (
                        <div key={rowKey} className="aiModeSelect_row">
                            {Object.keys(row).map((elementKey, ind) => {
                                return (
                                    <img
                                        key={ind}
                                        style={{ cursor: 'pointer' }}
                                        src={
                                            assemblyPageAi.aiItems[elementKey]
                                                ? renderPrgImage(
                                                      row[elementKey].activeImg
                                                  )
                                                : renderPrgImage(
                                                      row[elementKey]
                                                          .inActiveImg
                                                  )
                                        }
                                        // className="imgStyle_v1"
                                        width="20%"
                                        alt={row[elementKey].title}
                                        onClick={(e) =>
                                            handleClicks(elementKey)
                                        }
                                    />
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAssemblyPageAi: (data) => {
            dispatch({ type: 'ASSEMBLY_PAGE_AI', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AiModeSelect)
