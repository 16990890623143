import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import NavBar from './NavBar'
import { useIndexedDB } from 'react-indexed-db'
import Action from './Action'
import ObjectDetails from './Object'
import nextY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/next.png'
import backY from '../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import selectbarX from '../../Assets/Bisoft_UI/AppMode/selectbar@2.png'
import ellipse from '../../Assets/Bisoft_UI/AppMode/ellipse.png'
import close from '../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import buttonPlus from '../../Assets/Bisoft_UI/Buttons/Active without shadow/add.png'
import popupCard from '../../Assets/Bisoft_UI/AppMode/appModepopupCard.png'
import styles from './Appmode.module.css'
import Slider from './Slider.js'
import closeBtn from '../../Assets/img/close.png'
import Sliders from 'react-slick'
import leftArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../source/programImg'
import ProgrammingPageFooter from './ProgrammingPageFooter'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}

let allDetails = {}
const ProgrammingPage = () => {
    const { getAll } = useIndexedDB('SavedFaces')
    const enable = JSON.parse(sessionStorage.getItem('enable'))
    const [imageDetails, setImageDetails] = useState('')
    const { deleteRecord } = useIndexedDB('ProgramData')
    const messagesEndRef = useRef(null)
    const history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [count, setCount] = useState(
        JSON.parse(sessionStorage.getItem('count')) || 0
    )
    const [children, setChildren] = useState(
        JSON.parse(sessionStorage.getItem('arr')) || []
    )
    const [ishelpButton, sethelpButton] = useState(false)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    useEffect(() => {
        getAll().then((result) => {
            setImageDetails(result)
        })
    }, [])

    useEffect(() => {
        sessionStorage.setItem('count', count)
    }, [count])

    useEffect(() => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({
                behavior: 'smooth',
            })
        }
        sessionStorage.setItem('arr', JSON.stringify(children))
    }, [children])

    const Render = (props) => {
        let details = {
            id: props.value,
            usbValue: null,
            speechText: null,
            image: null,
            imageURL: null,
            displayText: null,
            selectedSize: null,
            selectedObject: null,
            option: null,
            sliderValue: '1',
            sliderOption: null,
        }

        const [currentOption, setcurrentOption] = useState(() => {
            if (
                sessionStorage.getItem('allDetails') !== null &&
                `${props.value}` in
                    JSON.parse(sessionStorage.getItem('allDetails')) &&
                JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].option !== null
            ) {
                return JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].option
            } else {
                return 'none'
            }
        })

        const [speechText, setSpeechText] = useState(() => {
            if (
                sessionStorage.getItem('allDetails') !== null &&
                `${props.value}` in
                    JSON.parse(sessionStorage.getItem('allDetails')) &&
                JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].speechText !== null
            ) {
                return JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].speechText
            } else {
                return ''
            }
        })

        const [usbValue, setUsbValue] = useState(() => {
            if (
                sessionStorage.getItem('allDetails') !== null &&
                `${props.value}` in
                    JSON.parse(sessionStorage.getItem('allDetails')) &&
                JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].usbValue !== null
            ) {
                return JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].usbValue
            } else {
                return null
            }
        })

        const [object, setObject] = useState(() => {
            if (
                sessionStorage.getItem('allDetails') !== null &&
                `${props.value}` in
                    JSON.parse(sessionStorage.getItem('allDetails')) &&
                JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].selectedObject !== null
            ) {
                return JSON.parse(sessionStorage.getItem('allDetails'))[
                    `${props.value}`
                ].selectedObject
            } else {
                return 'none'
            }
        })

        const getData = (event) => {
            allDetails = JSON.parse(sessionStorage.getItem('allDetails')) || {}
            if (props.value in allDetails) {
                if (event.target.type === 'text') {
                    allDetails[props.value]['speechText'] = event.target.value
                } else if (
                    event.target.type === 'range' ||
                    event.target.type === 'number'
                ) {
                    allDetails[props.value]['usbValue'] = event.target.value
                } else {
                    if (event.target.type !== 'number') {
                        allDetails[props.value]['selectedObject'] =
                            event.target.value
                        setObject(event.target.value)
                    }
                }
            } else {
                if (event.target.type === 'text') {
                    details['speechText'] = event.target.value
                } else if (
                    event.target.type === 'range' ||
                    event.target.type === 'number'
                ) {
                    details['usbValue'] = event.target.value
                } else {
                    if (event.target.type !== 'number') {
                        details['selectedObject'] = event.target.value
                        setObject(event.target.value)
                    }
                }
                allDetails[`${props.value}`] = details
            }
            sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
        }

        const handleOnChange = (e) => {
            setcurrentOption(e.target.value)
            allDetails = JSON.parse(sessionStorage.getItem('allDetails')) || {}
            if (props.value in allDetails) {
                allDetails[props.value]['option'] = e.target.value
                allDetails[props.value]['selectedObject'] = null
                allDetails[props.value]['speechText'] = null
                allDetails[props.value]['usbValue'] = null
                setSpeechText()
                setUsbValue(0)
                setObject()
            } else {
                details['option'] = e.target.value
                allDetails[`${props.value}`] = details
            }
            sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
        }

        //get device name that show in ui
        const getDeviceName = () => {
            switch (connectedDevice) {
                case 'Ace':
                    return 'PC'
                case 'Humanoid':
                    return 'Zing'
                case 'Hexapod':
                    return 'Crawl-e'
                case 'Klaw':
                    return 'Klaw-b'
                case 'Roboki':
                    return 'Roboki'
                case 'Klaw':
                    return 'klaw-b'
                default:
                    return connectedDevice
            }
        }

        const renderoptions = () => {
            if (
                enable.s1.cam === true &&
                enable.s1.mic === true &&
                enable.s1.pc
            ) {
                if (enable.s2.face === true) {
                    return (
                        <>
                            <option value="Face Detected">Face Detected</option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                            <option value="USB">
                                {' '}
                                {`Data recieved from ${getDeviceName()} (USB TX)`}
                            </option>
                        </>
                    )
                } else if (enable.s2.emotion === true) {
                    return (
                        <>
                            <option value="Emotion Detected">
                                Emotion Detected
                            </option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                            <option value="USB">
                                {' '}
                                {`Data recieved from ${getDeviceName()} (USB TX)`}
                            </option>
                        </>
                    )
                } else if (enable.s2.object === true) {
                    return (
                        <>
                            <option value="Object Detected">
                                Object Detected
                            </option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                            <option value="USB">
                                {' '}
                                {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                            </option>
                        </>
                    )
                }
            } else if (enable.s1.cam === true && enable.s1.mic === true) {
                if (enable.s2.face === true) {
                    return (
                        <>
                            <option value="Face Detected">Face Detected</option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                        </>
                    )
                } else if (enable.s2.emotion === true) {
                    return (
                        <>
                            <option value="Emotion Detected">
                                Emotion Detected
                            </option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                        </>
                    )
                } else if (enable.s2.object === true) {
                    return (
                        <>
                            <option value="Object Detected">
                                Object Detected
                            </option>
                            <option value="Speech Recognized">
                                Speech Recognized
                            </option>
                        </>
                    )
                }
            } else if (enable.s1.cam === true && enable.s1.pc === true) {
                if (enable.s2.face === true) {
                    return (
                        <>
                            <option value="Face Detected">Face Detected</option>
                            <option value="USB">
                                {' '}
                                {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                            </option>
                        </>
                    )
                } else if (enable.s2.emotion === true) {
                    return (
                        <>
                            <option value="Emotion Detected">
                                Emotion Detected
                            </option>
                            <option value="USB">
                                {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                            </option>
                        </>
                    )
                } else if (enable.s2.object === true) {
                    return (
                        <>
                            <option value="Object Detected">
                                Object Detected
                            </option>
                            <option value="USB">
                                {' '}
                                {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                            </option>
                        </>
                    )
                }
            } else if (enable.s1.mic === true && enable.s1.pc === true) {
                return (
                    <>
                        <option value="Speech Recognized">
                            Speech Recognized
                        </option>
                        <option value="USB">
                            {' '}
                            {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                        </option>
                    </>
                )
            } else if (enable.s1.cam === true) {
                if (enable.s2.face === true) {
                    return <option value="Face Detected">Face Detected</option>
                } else if (enable.s2.emotion === true) {
                    return (
                        <option value="Emotion Detected">
                            Emotion Detected
                        </option>
                    )
                } else if (enable.s2.object === true) {
                    return (
                        <option value="Object Detected">Object Detected</option>
                    )
                }
            } else if (enable.s1.mic === true) {
                return (
                    <option value="Speech Recognized">
                        {' '}
                        Speech Recognized{' '}
                    </option>
                )
            } else if (enable.s1.pc === true) {
                return (
                    <>
                        <option value="USB">
                            {' '}
                            {`Data recieved from ${getDeviceName()} (USB TX)`}{' '}
                        </option>
                    </>
                )
            }
        }

        const updateField = (ele) => {
            setSpeechText(ele.target.value)
            getData(ele)
        }

        const renderobject = () => {
            if (currentOption === 'Face Detected') {
                return (
                    <label>
                        {' '}
                        <select
                            className={styles.selectBox}
                            style={{
                                position: 'absolute',
                                right: '-3.9vw',
                                top: '1vh',
                                width: '19vw',
                                height: '5.5vh',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                            id={props.value}
                            onChange={getData}
                            value={object}
                        >
                            <option value="none">Select an option</option>;
                            {Object.values(imageDetails).map((value, index) => {
                                return (
                                    <option key={index} value={value.id}>
                                        {value.id}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                )
            } else if (currentOption === 'Emotion Detected') {
                let emotionList = [
                    'Suprise',
                    'Fear',
                    'Angry',
                    'Neutral',
                    'Sad',
                    'Disgust',
                    'Happy',
                ]
                return (
                    <label>
                        {' '}
                        <select
                            className={styles.selectBox}
                            style={{
                                position: 'absolute',
                                right: '-3.9vw',
                                top: '1vh',
                                width: '19vw',
                                height: '5.5vh',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                            id={props.value}
                            onChange={getData}
                            value={object}
                        >
                            <option value="none">Select an option</option>;
                            {emotionList.map((value, index) => {
                                return (
                                    <option key={index} value={value}>
                                        {value}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                )
            } else if (currentOption === 'Object Detected') {
                return (
                    <label>
                        {' '}
                        <select
                            className={styles.selectBox}
                            style={{
                                position: 'absolute',
                                right: '-3.9vw',
                                top: '1vh',
                                width: '19vw',
                                height: '5.5vh',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                            id={props.value}
                            onChange={getData}
                            value={object}
                        >
                            <option value="none">Select an option</option>;
                            {Object.keys(ObjectDetails).map((value, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={ObjectDetails[value].displayName}
                                    >
                                        {ObjectDetails[value].displayName}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                )
            } else if (currentOption === 'Speech Recognized') {
                return (
                    <>
                        <input
                            style={{
                                margin: 0,
                                position: 'absolute',
                                right: '-15vh',
                                width: '20vw',
                                top: '1vh',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                            }}
                            key="speech"
                            className={styles.inputWrapper}
                            type="text"
                            id={props.value}
                            placeholder="Enter text"
                            value={speechText}
                            onChange={updateField}
                        />
                    </>
                )
            } else if (currentOption === 'USB') {
                return (
                    <Slider
                        save={getData}
                        slider={usbValue}
                        setslider={setUsbValue}
                        value={props.value}
                        top="1.5vh"
                        right="-5vw"
                    />
                )
            } else {
                return null
            }
        }

        const onClickDelete = (e, value) => {
            setChildren((arr) => arr.filter((ele, index) => index !== e))
            allDetails = JSON.parse(sessionStorage.getItem('allDetails'))
            if (allDetails !== null) {
                delete allDetails[value]
                deleteRecord(value)
                sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
                sessionStorage.removeItem(value)
            }
        }

        return (
            <>
                <div
                    id={props.value}
                    style={{
                        position: 'relative',
                        top:
                            enable.s1.pc === true
                                ? `${43 * props.id}vh`
                                : `${35 * props.id}vh`,
                    }}
                >
                    <img
                        src={popupCard}
                        draggable="false"
                        alt="popCard"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '25vh',
                            left: '49.2vw',
                            width: '100vw',
                            height: '29vh',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={ellipse}
                        draggable="false"
                        alt="ellipse"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '25.0vh',
                            left: '8.6vw',
                            width: '1.1vw',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <img
                        src={close}
                        draggable="false"
                        alt="close"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            marginBottom: 'auto',
                            top: '18vh',
                            left: '90.8vw',
                            width: '2vw',
                            cursor: 'pointer',
                        }}
                        onClick={() => onClickDelete(props.id, props.value)}
                    />
                    <div>
                        {/* Element to Move Dynamically */}
                        <h2
                            style={{
                                position: 'absolute',
                                top: '24vh',
                                left: '10vw',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '12px',
                                color: 'black',
                            }}
                        >
                            <strong>if</strong>
                        </h2>
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            top: '21.5vh',
                            right: '25vw',
                        }}
                    >
                        <select
                            className={styles.selectBox}
                            style={{
                                position: 'absolute',
                                right: '39vw',
                                top: '0.1vh',
                                width: '19vw',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                            id={props.value}
                            value={currentOption}
                            onChange={handleOnChange}
                        >
                            <option value="none">Select an Option</option>
                            {renderoptions()}
                        </select>
                    </div>
                    <h2
                        style={{
                            position: 'absolute',

                            top: '24vh',
                            right: '50vw',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '12px',
                            color: 'black',
                        }}
                    >
                        <strong>is</strong>
                    </h2>
                    <div
                        style={{
                            position: 'relative',
                            top: '21.5vh',
                            right: '25vw',
                        }}
                    >
                        {renderobject()}
                    </div>
                    <div>
                        <Action
                            key={props.id}
                            id={props.id}
                            value={props.value}
                            object={details}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            enable.s1.pc === true
                                ? `${43 * props.id}vh`
                                : `${35 * props.id}vh`,
                    }}
                    ref={messagesEndRef}
                ></div>
            </>
        )
    }

    return (
        <div>
            <div>
                <NavBar
                    selectbar={selectbarX}
                    selectbarwidth="55%"
                    headers={[
                        { name: 'Enable', color: 'white' },
                        { name: 'Control', color: 'white' },
                        { name: 'Run', color: 'black' },
                    ]}
                    save={true}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />
            </div>

            <div className={styles.scrollable}>
                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    }`}
                >
                    {children.map((value, index) => (
                        <div
                            key={index}
                            style={{ position: 'relative', top: '-13vh' }}
                        >
                            <Render value={value} id={index} />
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ background: 'clear' }}>
                <ProgrammingPageFooter
                    setSlideDirection={setSlideDirection}
                    enable={enable}
                    count={count}
                    setCount={setCount}
                    children={children}
                    setChildren={setChildren}
                    backY={backY}
                    buttonPlus={buttonPlus}
                    nextY={nextY}
                />
            </div>

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '100000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeBtn}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Sliders
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <img
                                src={renderPrgImage('PrgmH')}
                                style={{
                                    width: '100%',
                                }}
                            />

                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '21%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    This block is for the if condition, to check
                                    whether the<br></br> programmed condition is
                                    satisfied or not. The drop-down <br></br>
                                    options from the if condition is enabled
                                    based on the<br></br> feature enabled at the
                                    previous screens
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('PrgmH1')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '2.5%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '70%',
                                    left: '27.7%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Use this button when you want to add
                                    multiple lines of if & Action blocks for the
                                    app programming
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('PrgmH2')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    // height: "90px",
                                    width: '2.5%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '68%',
                                    left: '37.6%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    At this Action, you can enter any text you
                                    want to display when the if condition is
                                    true. The size of the Text can be selected
                                    from the size drop-down
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('PrgmH3')}
                                style={{ width: '100%' }}
                            />

                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '59.5%',
                                    left: '46%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '3%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    At this Action block, you select any image
                                    (taken from your Mobile gallery/Desktop
                                    folders), to show the image as an output,
                                    when running the program
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img
                                src={renderPrgImage('PrgmH4')}
                                style={{ width: '100%' }}
                            />
                            <div
                                style={{
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '49%',
                                    left: '54.2%',
                                    transform: 'translate(-50%,-50%)',
                                    width: '2.5%',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '4px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    When an if condition is true can use this
                                    action to send a set data value to selected
                                    device
                                </p>
                            </div>
                        </div>
                    </Sliders>
                </div>
            ) : null}
        </div>
    )
}
export default ProgrammingPage
