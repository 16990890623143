import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { webSerialAction } from '../../../../redux/actions/index'
import renderImage from '../../../../source/importImg'
import unicodeToChar from '../../../../utils/unicodeToChar'
import SpchSlider from '../../../ReusableComponents/SpchSlider/SpchSlider'
import MainHeader from '../../../ReusableComponents/Header/MainHeader'
import './speak.scss'
import { sendBytes } from '../../../ReusableComponents/ByteTransfer'
import cacheAssets from '../../../../utils/cacheAssets'
import { languageWords } from '../../../../utils/langaugeSupport'

try {
    var SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition
    var recognition = new SpeechRecognition()
} catch (e) {}
let count = 0
// recognition.start();

function AceSpeech(props) {
    let history = useHistory()
    const languageName = props.device.language
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const version = sessionStorage.getItem('deviceVersion')

    // const gobackUrl = () => {
    //   history.goBack();
    // };

    const [isMic, setMic] = useState(false)

    const handleMic = (e) => {
        if (isMic == true) {
            setMic(false)
            recognition.stop()
            recognition.onend = () => {
                console.log('Stopped listening per click')
            }
        } else {
            setMic(true)
            recognition.start()
            recognition.onend = () => {
                console.log('...continue listening...')
            }
        }

        recognition.onstart = () => {
            console.log('Listening!')
        }

        let finalTranscript = ''
        recognition.onresult = (e) => {
            for (let i = e.resultIndex; i < e.results.length; i++) {
                const transcript = e.results[i][0].transcript
                console.log(transcript)
                if (e.results[i].isFinal) finalTranscript += transcript + ' '
            }
            try {
                document.getElementById('final').innerHTML = finalTranscript
            } catch (e) {}

            const transcriptArray = finalTranscript.split(' ')
            const stopCmd = transcriptArray.slice(-4, -1)
            console.log('stopCmd', stopCmd)
            if (stopCmd[0] === 'Light' && stopCmd[1] === 'off.') {
                console.log('forward emit')
                // socket.emit("/speak", ["S".charCodeAt(), "0".charCodeAt()]);
                let data = ['S'.charCodeAt(), '0'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'White' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on.'
            ) {
                //socket.emit("/speak", ["S".charCodeAt(), "1".charCodeAt()]);
                console.log('white light emit')
                let data = ['S'.charCodeAt(), '1'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'Red' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on.'
            ) {
                // socket.emit("/speak", ["S".charCodeAt(), "2".charCodeAt()]);
                console.log('red light emit')
                let data = ['S'.charCodeAt(), '2'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (finalTranscript.toLowerCase().includes('green', 'on')) {
                //socket.emit("/speak", ["S".charCodeAt(), "3".charCodeAt()]);
                console.log('green light emit')
                let data = ['S'.charCodeAt(), '3'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'Blue' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on.'
            ) {
                console.log('blue light emit')
                // socket.emit("/speak", ["S".charCodeAt(), "4".charCodeAt()]);
                let data = ['S'.charCodeAt(), '4'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (finalTranscript.toLowerCase().includes('disco', 'on')) {
                console.log('disco light emit')
                // socket.emit("/speak", ["S".charCodeAt(), "5".charCodeAt()]);
                let data = ['S'.charCodeAt(), '5'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                finalTranscript.toLowerCase().includes('smile') &&
                finalTranscript.toLowerCase().includes('on')
            ) {
                console.log('smile light emit')
                // socket.emit("/speak", ["S".charCodeAt(), "6".charCodeAt()]);
                let data = ['S'.charCodeAt(), '6'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            }
            //Small Letters
            else if (
                ['light', 'lights', 'smile', 'Smile', 'Disco'].includes(
                    stopCmd[0]
                ) &&
                stopCmd[1] === 'off'
            ) {
                console.log('forward emit')
                // socket.emit("/speak", ["S".charCodeAt(), "0".charCodeAt()]);
                let data = ['S'.charCodeAt(), '0'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'white' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on'
            ) {
                //socket.emit("/speak", ["S".charCodeAt(), "1".charCodeAt()]);
                console.log('white light emit')
                let data = ['S'.charCodeAt(), '1'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'red' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on'
            ) {
                // socket.emit("/speak", ["S".charCodeAt(), "2".charCodeAt()]);
                console.log('red light emit')
                let data = ['S'.charCodeAt(), '2'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (
                stopCmd[0] === 'green' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on'
            ) {
                //socket.emit("/speak", ["S".charCodeAt(), "3".charCodeAt()]);
                let data = ['S'.charCodeAt(), '3'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
                console.log('green light emit')
            } else if (
                stopCmd[0] === 'blue' &&
                stopCmd[1] === 'light' &&
                stopCmd[2] === 'on'
            ) {
                console.log('blue light emit')
                //socket.emit("/speak", ["S".charCodeAt(), "4".charCodeAt()]);
                let data = ['S'.charCodeAt(), '4'.charCodeAt()]
                writePort(data)
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        console.log('Stopped listening per click')
                    }
                }, 1500)
            } else if (stopCmd[0] === 'stop' && stopCmd[1] === 'listening') {
                recognition.stop()
                recognition.onend = () => {
                    console.log('Stopped listening per command')
                    const finalText = transcriptArray.slice(0, -3).join(' ')
                    document.getElementById('final').innerHTML = finalText
                }
            } else {
                setTimeout(() => {
                    setMic(false)
                    recognition.stop()
                    recognition.onend = () => {
                        const finalText =
                            "I didn't quite catch that, click on the mic to try again. "
                        if (document.getElementById('final') !== null)
                            document.getElementById('final').innerHTML =
                                finalText
                    }
                }, 2500)
            }
        }
        recognition.onerror = (event) => {
            console.log('Error occurred in recognition: ' + event.error)
        }
    }

    useEffect(async () => {
        if (
            sessionStorage.getItem('isDeviceConnected') === 'true' &&
            count === 0
        ) {
            sendPlayBytes()
            count = count + 1
        }
        if (sessionStorage.getItem('isDeviceConnected') === 'false') {
            count = 0
        }
    })

    const sendPlayBytes = () => {
        const PLAY = [
            'P'.charCodeAt(),
            'L'.charCodeAt(),
            'A'.charCodeAt(),
            'Y'.charCodeAt(),
        ]
        const M8 = ['M'.charCodeAt(), '8'.charCodeAt()]
        const playBytes = setTimeout(() => {
            writePort(PLAY)
            clearTimeout(playBytes)
        }, 250)
        const clearPin = setTimeout(() => {
            writePort(M8)
            clearTimeout(clearPin)
        }, 350)
    }

    async function writePort(data) {
        if (props.webSerial.port !== undefined) {
            if (
                props.webSerial.isConnected &&
                props.webSerial.port !== undefined
            ) {
                props.worker.postMessage({
                    type: 'writeArray',
                    value: data,
                })
            }
        }
    }

    const [isLanguage, setLanguage] = useState(false)

    const handleLanguageBtn = (e) => {
        if (isLanguage) {
            setLanguage(false)
        } else {
            setLanguage(true)
        }
    }

    const [isHelp, setHelp] = useState(false)

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderImage('speechH'),
            renderImage('speechH1'),
            renderImage('Spch1'),
            renderImage('Spch2'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const getImgUrl = () => {
        if (connectedDevice === 'Roboki') {
            return renderImage('Roboki')
        } else {
            return renderImage('PC')
        }
    }
    return (
        <div className="Speech-Main">
            <MainHeader
                title={languageWords[languageName]?.Speech || 'Speech'}
                helper={<SpchSlider />}
                showBluetoothBtn={true}
                goBack={() => {
                    if (['Ace', 'Roboki'].includes(connectedDevice)) {
                        let data = ['S'.charCodeAt(), '0'.charCodeAt()]
                        writePort(data)
                    }
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/introduction')
                }}
                isGoBack={true}
            />

            <div className="Ace_Speech">
                <div>
                    {['Ace', 'Roboki'].includes(connectedDevice) ? (
                        <>
                            {version?.startsWith('1') &&
                            connectedDevice === 'Ace' ? (
                                <img
                                    className="Ace_img"
                                    src={renderImage('PCv1')}
                                    style={{
                                        width: '75%',
                                        marginTop: '10%',
                                    }}
                                    draggable="false"
                                />
                            ) : (
                                <img
                                    className="Ace_img"
                                    src={getImgUrl()}
                                    draggable="false"
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {connectedDevice === 'Humanoid' && (
                        <img
                            className="Ace_img"
                            src={renderImage('Zing')}
                            draggable="false"
                        ></img>
                    )}
                </div>
                <div>
                    {isMic == false ? (
                        <img
                            className="Mic_imgIA"
                            src={renderImage('SpeechIA_Svg')}
                            onClick={handleMic}
                            draggable="false"
                        ></img>
                    ) : (
                        <img
                            className="Mic_img"
                            src={renderImage('SpeechAc_Svg')}
                            onClick={handleMic}
                            draggable="false"
                        ></img>
                    )}
                </div>
            </div>
            <div className="SoundWave">
                {isMic == false ? null : (
                    <img
                        className="Sound_Wave"
                        src={renderImage('Soundwaves_Svg')}
                        draggable="false"
                    ></img>
                )}
                {/* <img className="Sound_Wave" src={Soundwaves_Svg}></img> */}
            </div>
            <div>
                {isMic == false ? (
                    <h3 className="Speech_Instruc">
                        Tap the microphone to start talking to your Play
                        Computer
                    </h3>
                ) : (
                    <h3 id="final" className="Speech_Instruct">
                        Listening......
                    </h3>
                )}
            </div>
        </div>
    )
}
// export default Speech;

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialAction: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialAction(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AceSpeech)
