/**
 * Compares two versions. If (version1 < version2) returns 1,
 * (version1 > version2) returns -1, (version1 = version2) return 0.
 * @param {string} version1
 * @param {string} version2
 * @returns {number} <1, -1, 0>
 */
const compareVersion = (version1, version2) => {
    version1 = version1.split('.').map((num) => parseInt(num))
    version2 = version2.split('.').map((num) => parseInt(num))

    if (version1.length < 3 || version2.length < 3) return

    console.log(version1, version2)

    try {
        for (let index = 0; index < 3; index++) {
            if (version1[index] < version2[index]) return 1
            else if (version1[index] > version2[index]) return -1
        }
        return 0
    } catch (e) {
        console.log('ERROR', e)
        return 0
    }
}

export { compareVersion }
