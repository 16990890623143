import { Translate } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import renderPrgImage from '../../source/programImg'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import appMenu from '../../Assets/Bisoft_UI/AppMode/appMenu@2x.png'
import style from './Appmode.module.css'
import MainHeader from '../ReusableComponents/Header/MainHeader'
import PrgmSelection from '../ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'
import cacheAssets from '../../utils/cacheAssets'
import { languageWords } from '../../utils/langaugeSupport'
import { isAi1NotPresent } from '../../utils/utils'

const AppModeSelection = (props) => {
    const history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [isRecoverData, setIsRecoverData] = useState({
        recoveryDataPresent: false,
        pathName: 'selection',
    })
    const languageName = props.device.language
    const appModeData = [
        'enable',
        'count',
        'arr',
        'appDetails',
        'img',
        'tensor',
        'allDetails',
        'currentSavedSessionId',
        'appAiModeStorage',
    ]

    const newProject = () => {
        const pathName = window.location.pathname
        const selectedMode = pathName.split('/')[2]
        if (selectedMode === 'selectHardware') {
            // history.push('/chooseTrainingDevice')

            // sessionStorage.setItem('isSave', JSON.stringify(true))

            // let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))

            // let programMode = 'appAiBase'
            // if (
            //     recoveryData &&
            //     'appAiBase' in recoveryData &&
            //     connectedDevice in recoveryData[programMode] &&
            //     deviceVersion in recoveryData[programMode][connectedDevice]
            // ) {
            //     setIsRecoverData({
            //         recoveryDataPresent: true,
            //         pathName: selectedMode,
            //     })
            // } else history.push('/chooseTrainingDevice')

            //set the default state in redux appAiMode
            props.setAppAiModeStorage({
                setAppAiModeStorage: '',
                type: 'RemoveAppAiModeStorage',
            })

            history.push('/chooseTrainingDevice')
        } else {
            history.push('/chooseDevice')
        }
    }

    const openProject = (event) => {
        let file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const sessionData = JSON.parse(evt.target.result)
            Object.entries(sessionData).map((value) => {
                if (appModeData.includes(value[0])) {
                    console.log('DDDDDDD', value[0], value[1])
                    sessionStorage.setItem(value[0], value[1])
                    if (value[0] === 'appAiModeStorage') {
                        props.setAppAiModeStorage({
                            setAppAiModeStorage: JSON.parse(value[1]),
                            type: 'setAppAiModeStorage',
                        })
                    }
                }
            })
            // history.push('/chooseDevice')
            const pathName = window.location.pathname
            const selectedMode = pathName.split('/')[2]
            if (selectedMode === 'selectHardware') {
                history.push('/chooseTrainingDevice')
            } else {
                history.push('/chooseDevice')
            }
        }
        reader.readAsText(file)
    }

    const backButton = () => {
        history.push('/Selection')
    }

    const helpBtn = (e) => {
        console.log('save')
    }

    const container = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '22rem',
        width: '100vw',
        paddingTop: '180px',
        position: 'absolute',
        top: '30vh',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    }

    const menuText = {
        position: 'relative',
        top: '-8.5rem',
        left: '6rem',
        fontSize: '1.3rem',
        cursor: 'pointer',
    }

    //repopulate the local storage save data
    const repopulateData = (pathname) => {
        let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
        let setAppAiModeStorage =
            recoveryData.appAiBase[connectedDevice][deviceVersion]
                .appAiModeStorage
        // console.log(
        //     pathname,
        //     pathname === 'selectHardware',
        //     'DDDDDDDD',
        //     setAppAiModeStorage
        // )
        if (pathname === 'selectHardware') {
            props.setAppAiModeStorage({
                setAppAiModeStorage: setAppAiModeStorage,
                type: 'setAppAiModeStorage',
            })
            history.push('/chooseTrainingDevice')
        } else {
            history.push('/chooseDevice')
        }
    }

    //delete the data from recovery object
    function deleteRecoveryData(newRecoveryData, baseType, device, version) {
        if (newRecoveryData[baseType] && newRecoveryData[baseType][device]) {
            delete newRecoveryData[baseType][device][version]

            // If the device has no more versions, delete the device
            if (Object.keys(newRecoveryData[baseType][device]).length === 0) {
                delete newRecoveryData[baseType][device]
            }

            // If the baseType has no more devices, delete the baseType
            if (Object.keys(newRecoveryData[baseType]).length === 0) {
                delete newRecoveryData[baseType]
            }
        }
        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }
    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderPrgImage('SelectionPrgf1'),
            renderPrgImage('SelectionPrgf2'),
            renderPrgImage('SelectionPrgf3'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])
    return (
        <>
            {/* --------- repopulate the recovery data popup -------- */}
            <DialogModal
                show={isRecoverData.recoveryDataPresent}
                text="Would you like to recover your previous program?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => {
                            repopulateData(isRecoverData.pathName)
                        },
                    },
                    {
                        text: 'NO',
                        do: () => {
                            let recoveryData = JSON.parse(
                                localStorage.getItem('recoveryData')
                            )
                            deleteRecoveryData(
                                recoveryData,
                                'appAiBase',
                                connectedDevice,
                                deviceVersion
                            )
                            props.setAppAiModeStorage({
                                setAppAiModeStorage: '',
                                type: 'RemoveAppAiModeStorage',
                            })
                            history.push('/chooseTrainingDevice')
                        },
                    },
                ]}
            />
            <div>
                {/* Header */}
                <MainHeader
                    help_btnPosition="right"
                    helper={
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <PrgmSelection />
                        </div>
                    }
                    goBack={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        if (
                            !isAi1NotPresent({
                                connectedDevice,
                                deviceVersion,
                            })
                        ) {
                            history.push('/appMode/selectCategory')
                        } else {
                            history.push('/selection')
                        }
                    }}
                    isGoBack={true}
                />

                <img
                    src={appMenu}
                    style={{
                        position: 'absolute',
                        top: '8.5%',
                        left: '12%',
                        transform: 'translate(-50%,-50%)',
                        height: '22%',
                    }}
                />

                {/* <div>
                    
                    <img
                        src={renderPrgImage('backBtn')}
                        draggable="false"
                        onClick={backButton}
                        style={{
                            position: 'absolute',
                            top: '7.5%',
                            left: '4%',
                            transform: 'translate(-50%,-50%)',
                            height: '9%',
                            zIndex: '10',
                            cursor: 'pointer',
                        }}
                    />
                    <img
                        src={appMenu}
                        style={{
                            position: 'absolute',
                            top: '8.5%',
                            left: '12%',
                            transform: 'translate(-50%,-50%)',
                            height: '25%',
                        }}
                    />
                    <img
                        src={renderPrgImage('helpBtnInActive')}
                        onClick={helpBtn}
                        style={{
                            position: 'absolute',
                            top: '7.5%',
                            right: '4%',
                            transform: 'translate(-50%,-50%)',
                            height: '9%',
                            zIndex: '10',
                            cursor: 'pointer',
                        }}
                    />
                </div> */}
                {/* Body */}
                <div style={{ height: '80%', position: 'relative' }}>
                    <div style={container}>
                        <div style={{ width: '19rem', height: '15rem' }}>
                            <img
                                src={renderPrgImage('newfilegroupbutton')}
                                draggable="false"
                                style={{ width: '19rem', cursor: 'pointer' }}
                                onClick={newProject}
                            />

                            <p style={menuText} onClick={newProject}>
                                {languageWords[languageName]?.NewProject ||
                                    'New project'}
                            </p>
                        </div>
                        <div style={{ width: '19rem', height: '15rem' }}>
                            <label htmlFor="file-input">
                                <img
                                    src={renderPrgImage(
                                        'yourprojectsgroupbutton'
                                    )}
                                    draggable="false"
                                    style={{
                                        width: '19rem',
                                        cursor: 'pointer',
                                    }}
                                />
                                <p style={menuText}>
                                    {languageWords[languageName]?.OpenProject ||
                                        'Open project'}
                                </p>
                            </label>
                            <input
                                id="file-input"
                                type="file"
                                accept=".pld"
                                hidden
                                onInput={(event) => openProject(event)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        appAiModeStorage: state.appAiModeStorage,
        device: state.device,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAppAiModeStorage: (data) => {
            dispatch({ type: 'APP_AI_MODE_STORAGE', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppModeSelection)
