import joystickStyle from './joystick.module.css'
import joystickbg from '../../../../../../../Assets/humanoid/joystick.png'

import upBtn from '../../../../../../../Assets/humanoid/Up.png'
import upDarkBtn from '../../../../../../../Assets/humanoid/up_dark.png'
import downBtn from '../../../../../../../Assets/humanoid/down.png'
import downDarkBtn from '../../../../../../../Assets/humanoid/down_dark.png'
import leftBtn from '../../../../../../../Assets/humanoid/left.png'
import leftDarkBtn from '../../../../../../../Assets/humanoid/left_dark.png'
import rightBtn from '../../../../../../../Assets/humanoid/right.png'
import rightDarkBtn from '../../../../../../../Assets/humanoid/right_dark.png'
import midBtn from '../../../../../../../Assets/humanoid/mid.png'
import midDarkBtn from '../../../../../../../Assets/humanoid/mid.png'
import { useEffect, useState } from 'react'

const IMAGES = {
    upBtn,
    upDarkBtn,
    downBtn,
    downDarkBtn,
    leftBtn,
    leftDarkBtn,
    rightBtn,
    rightDarkBtn,
    midBtn,
    midDarkBtn,
}
const CONTROLS = ['up', 'down', 'left', 'right', 'mid']
const ACTIONS = {
    up: 'MoveForward',
    down: 'MoveBackward',
    left: 'MoveLeft',
    right: 'MoveRight',
    mid: 'AttentionMid',
}

const disabledJoyStick = {
    pointerEvents: 'None',
    opacity: '0.5',
}

const Joystick = ({
    actionState,
    isEnabled,
    setContinousAction,
    handleAction,
    handleContinuousAction,
    sendRa,
}) => {
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const [isPressed, SetIsPressed] = useState()
    return (
        <div className={joystickStyle['joystick-image']}>
            {CONTROLS.map((control) => (
                <img
                    // draggable={false}
                    key={control}
                    className={joystickStyle[`${control}-button`]}
                    style={isEnabled ? {} : disabledJoyStick}
                    src={
                        actionState[`is${ACTIONS[control]}`]
                            ? IMAGES[`${control}DarkBtn`]
                            : IMAGES[`${control}Btn`]
                    }
                    onMouseDown={() => {
                        // if (zingVersion[0] === '2' || deviceVersion[0]==='2') {
                        //     handleAction(ACTIONS[control])
                        // } else {
                        if (actionState[`is${isPressed}`]) return
                        console.log(
                            ACTIONS,
                            control,
                            ACTIONS[control],
                            isPressed,
                            actionState[`is${isPressed}`],
                            'gsk error debug'
                        )
                        SetIsPressed(ACTIONS[control])
                        sessionStorage.setItem('isClicked', true)
                        handleContinuousAction(ACTIONS[control])
                        // setContinousAction(() => ACTIONS[control])
                        // }
                    }}
                    onMouseUp={() => {
                        // if (zingVersion[0] !== '2') {
                        sessionStorage.setItem('isClicked', false)
                        sendRa()
                        // setContinousAction(() => {})
                        // }
                    }}
                    onDragEnd={() => {
                        sessionStorage.setItem('isClicked', false)
                        sendRa()
                        // setContinousAction(() => {})
                    }}
                    // onClick={() => handleAction(ACTIONS[control])}
                />
            ))}
        </div>
    )
}
export default Joystick
