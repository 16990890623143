import fs from 'fs'
import CodeGenerationRangeValues, {
    servo_motor1,
} from './CodeGenerationRangeValues_T.js'
import {
    PortsAndPortbytes,
    CorrespondingPortCode,
    PortByteValuesIf,
    PortByteNumericalValuesIf,
    PortByteNumericalValuesOutput,
    BiDataValuesOutput,
    nodeDefaultCodes,
    GROUPACTIONS,
    bidataPortValue,
} from './CodeGenerationConstants_T.js'
import { DEFAULTSERVOVALUES } from '../../Code/CreateAction/defaultData.js'
import version from '../../../../package.json'
import process from 'process'
var MAX_OUTPUT_PORT_CODE = 0
var uuid_gen

var pathToSave
var pathToBisoft

var countIf = 0

pathToBisoft = process.env.LOCALAPPDATA
pathToSave = pathToBisoft + '//Bisoft'
// ////console.log("pathToSave", pathToSave);
var FOLDER1 = pathToSave + '\\code',
    FOLDER2 = pathToSave + '\\binary'

if (process.argv[2] && process.argv[2] != '') {
    uuid_gen = process.argv[2]
    // ////console.log("uuid_gen:: " + uuid_gen);
    var FILE_NAME = 'code_' + uuid_gen + '.txt'
}
var uploadProgram, Program
const connectedDevice = sessionStorage.getItem('connectedDevice')
// const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
// fs.readFile(FOLDER1 + "/" + FILE_NAME, function (err, data) {
//   FILE_NAME = "code_" + uuid_gen + ".txt";
//   uuid_gen = process.argv[2];
//   if (err) {
//     // ////console.log("reading file from readfile: " + err);
//   } else {
//     data = JSON.parse(data);
//     if (data) Program = genCodeString(data);
//     if (Program) uploadProgram = stringToByteStream(Program);
//     //// ////console.log("uploadProgram", uploadProgram);
//     saving_to_file(
//       uploadProgram,
//       FOLDER2 + "/binary_" + uuid_gen + ".txt",
//       function (err, data) {
//         if (err) {
//           // ////console.log(err);
//         } else {
//           // ////console.log("binary_" + uuid_gen + ".txt")
//         }
//       }
//     );
//   }
// });

function saving_to_file(data, FILE_NAME, callback) {
    try {
        data = data.toString()
        //// ////console.log("datatupe" , typeof(data));
        let buffer = new Buffer(data)

        fs.open(FILE_NAME, 'w+', function (err, fd) {
            if (err) {
                throw 'error opening file: ' + err
            }

            fs.write(fd, buffer, 0, buffer.length, null, function (err) {
                if (err) throw 'error writing file: ' + err
                fs.close(fd, function () {
                    // ////console.log('file written');
                })
                callback(null, 'success')
            })
        })
    } catch (e) {
        // ////console.log("saving_to_file error handler " + e);
    }
}

function setBufView(value, arrayIndexToInsert, bufView) {
    // ////console.log( "setBufVioew---->",bufView[arrayIndexToInsert],value)
    bufView[arrayIndexToInsert] = value
    return ++arrayIndexToInsert
}

function evaluateNodeCode(codeTypeArray, arrayIndexToInsert, bufView) {
    for (
        var eachStringValue = 0;
        eachStringValue < codeTypeArray.length;
        eachStringValue++
    ) {
        if (isNaN(codeTypeArray[eachStringValue])) {
            for (var i = 0; i < codeTypeArray[eachStringValue].length; i++) {
                if (isNaN(codeTypeArray[eachStringValue][i])) {
                    arrayIndexToInsert = setBufView(
                        codeTypeArray[eachStringValue][i].charCodeAt(0),
                        arrayIndexToInsert,
                        bufView
                    )
                } else {
                    arrayIndexToInsert = setBufView(
                        parseInt(codeTypeArray[eachStringValue][i]),
                        arrayIndexToInsert,
                        bufView
                    )
                }
            }
        } else {
            arrayIndexToInsert = setBufView(
                codeTypeArray[eachStringValue],
                arrayIndexToInsert,
                bufView
            )
        }
    }
    console.log('arrayIndexToInsert', arrayIndexToInsert, bufView)
    return arrayIndexToInsert
}

// code string to byte by inserting time
function stringToByteStream(str) {
    // // ////console.log("STRING",str);
    /*
When creating the buf array, we add an extra length of 4 because, when str = "SETOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO;END", its length is 66, which is below the required 70.

We need a minimum buffer length of 70 to properly start and stop HEX programming, so I add an extra 4 to ensure this requirement is met.

If we do not add the extra 4 bytes, then in the ATR HEX programming, only two panels—Start and End—will be present. The resulting sent bytes will be:
82, 84, 53, 49, 49, 83, 69, 84, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 79, 116, 12, 0, 0, 0, 0, 82, 83, 84.

At the end, the sequence 116, 12, 0, 0, 0, 0, 82, 83, 84 contains four 0s after 116, 12, followed by 82, 83, 84. These are incorrect bytes, which cause the M1 and M2 motors to run unexpectedly in this programming.
 */
    //RT$5$11SETAPOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO0000000000000000000000000000o{:2;:18;:f1;}d00:1;:00;:26;>o{:2;:a;:19;}w:00;:00;:f2;:59;l00:1;o{:2;:5;:a4;}w:00;:38;:c7;:41;o{:2;:8;:82;}0ELo{:2;:3;:85;}0EDRST
    var buf = new ArrayBuffer(str.length + 4) // 2 bytes for each char
    var bufView = new Uint8Array(buf)
    bufView[0] = 82 //R
    bufView[1] = 84 //T
    bufView[2] = 53 //5
    bufView[3] = 49 //1
    bufView[4] = 49 //1

    // setting time
    var arrayIndexToInsert = 5
    var codeLen = 0
    // ////console.log("string=", "RT5" + str);
    //RT511SETAPOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO0000000000000000000000000000;
    //o{:2:39:104:};d:0:0:1:4:0:62;l00:1;o{:2:21:30:};0EL;w:0:226:62:139;0ED;RST
    console.log('some:DATA', stringArray)
    var stringArray = str.split(';')
    for (var eachCode = 0; eachCode < stringArray.length; eachCode++) {
        var codeTypeArray = stringArray[eachCode].split(':')
        // console.log("codeTypeArray------------------------->", codeTypeArray);

        arrayIndexToInsert = evaluateNodeCode(
            codeTypeArray,
            arrayIndexToInsert,
            bufView
        )
    }
    var currentTime = new Date()
    bufView[64] = 116
    bufView[65] = currentTime.getHours()
    bufView[66] = currentTime.getMinutes()
    codeLen = arrayIndexToInsert //78
    var codeView = new Uint8Array(codeLen)
    ////console.log("Bufview--->", currentTime.getHours(), currentTime.getMinutes(), bufView[64], bufView[65], bufView[67], bufView[68], bufView[69])
    for (var i = 0; i < codeLen; i++) {
        codeView[i] = bufView[i]
    }
    var sendString = codeView.join()
    //  ////console.log("byteStream=",sendString);
    // // ////console.log(codeView);
    return codeView
}

//here set the default value of external components to 0 when its port value is undefine.
const exComPortValue = (portByteSelected, compName) => {
    if (
        connectedDevice == 'Klaw' &&
        ['PW1', 'PW2', 'PW3', 'PW4', 'PW5'].includes(portByteSelected)
    ) {
        return (
            DEFAULTSERVOVALUES[
                connectedDevice.toUpperCase() +
                    sessionStorage.getItem('deviceVersion')[0]
            ][portByteSelected.slice(2)] || 0
        )
    }
    if (portByteSelected == 'HeadServo') {
        return 90
    }
    if (compName) {
        if (
            [
                'led_1c',
                'led',
                'laser',
                'servo_motor',
                'servo_motor_360',
                'servo_motor_270',
                'mini_geared_motor',
                'dc_motor',
            ].includes(compName)
        ) {
            return 0
        } else {
            return (
                DEFAULTSERVOVALUES[
                    connectedDevice.toUpperCase() +
                        sessionStorage.getItem('deviceVersion')[0]
                ][portByteSelected.slice(1)] || 0
            )
        }
    } else {
        if (['M1', 'M2'].includes(portByteSelected)) return 0
        return (
            DEFAULTSERVOVALUES[
                connectedDevice.toUpperCase() +
                    sessionStorage.getItem('deviceVersion')[0]
            ][portByteSelected.slice(1)] || 0
        )
    }
}

//here convert the any string value cam convert to the asci value
const getFileNameAsci = ({ key, nodeDetails }) => {
    try {
        let fName = nodeDetails['value' + key]
        // if (key === 'ZingMp3') {
        //     fName = fName.replace('.mp3', '')
        // }
        const fLength = fName.length

        let fNameAsci = fLength
        for (let i = 0; i < fLength; i++) {
            fNameAsci += ':' + fName.charCodeAt(i)
        }
        return fNameAsci
    } catch (e) {
        console.error(e)
        return ''
    }
}

// genearte code in string
function genCodeString(logicComponents) {
    //For generating program_code.

    console.log('WLECOME TO genCodeString')

    console.log('logicComponents', logicComponents)

    var NUMOFBITS = 16
    var NUMOFBYTES = 2

    function getDecimalValue(Hexa, numOfBytes) {
        console.log('welcome to getDecimalValue')

        console.log('Hexa', Hexa, '\n', 'numOfBytes', numOfBytes)

        var len = Hexa.length
        numOfBytes = numOfBytes * 2
        var loopLimit = numOfBytes - len
        // loop for adding extra zeros to hexa value.
        for (var counter = 1; counter <= loopLimit; counter++) {
            Hexa = '0' + Hexa
        }
        var tempHexa = ''
        var startingIndex = 0
        Hexa += ''
        //loop for seperating bytes with ':'.
        for (
            var currentIndex = 0;
            currentIndex < numOfBytes;
            currentIndex = currentIndex + 2
        ) {
            tempHexa += parseInt(
                Hexa.substring(startingIndex, currentIndex + 2),
                16
            )
            if (currentIndex != numOfBytes - 2) tempHexa += ':'
            startingIndex = currentIndex + 2
        }
        var decimalValue = tempHexa

        return decimalValue
    }

    function CreateNodeList(node, out) {
        console.log('WELCOME TO CreateNodeList')

        // try here

        console.log('node', node, '\n', 'out', out)

        var nodeType, nodeDetails

        nodeType = node.type // start // hardware

        nodeDetails = node.state //assign & Value

        /*
     CHANGING VALUE geared_motor,mini_geared_motor,dc_motor 
     RANGE VALUE For these motor are Frontend : -20 - +20
     in Backend value: 0-40  i.e( -20=0,-19=1,-18=2.............,20=40)   
    */

        var nodeToInsert = {}

        nodeToInsert[nodeType] = nodeDetails

        console.log('nodeToInsert', nodeToInsert)

        out.push(nodeToInsert)

        if (node.subprogram) {
            console.log('SUB_PROGRAM')
            for (var sp = 0; sp < node.subprogram.length; sp++) {
                console.log(node.subprogram[sp], 'node.subprogram[sp]')

                CreateNodeList(node.subprogram[sp], out)
            }
            var endNode = {}
            endNode['end' + nodeType] = nodeDetails
            out.push(endNode)
        }
        return out
    }

    // Flow code cards object evaluation functions
    function hassubProgram(node) {
        console.log('welcome to hassubProgram')
        if (
            node == 'if' ||
            node == 'loop' ||
            node.type == 'sensor' ||
            node.type == 'variable'
        ) {
            return true
        } else {
            return false
        }
    }

    function getSubnode(node, nodeNumber) {
        console.log('WELCOME TO getSubnode')

        var subnodeIndex
        if (
            node.type == 'if' ||
            node.type == 'sensor' ||
            node.type == 'variable' ||
            node.type == 'loop'
        ) {
            if (nodeNumber == 2) {
                //for else condition
                subnodeIndex = node.connections[2].to
            } else {
                subnodeIndex = node.connections[1].to
            }
            if (subnodeIndex == node.cardId) {
                return null
            } else {
                return cardConnections[subnodeIndex]
            }
        } else {
            return null
        }
    }

    function getNextnode(node) {
        console.log('WELCOME TO getNextnode')

        var nextnodeIndex
        if (
            node.type == 'if' ||
            node.type == 'sensor' ||
            node.type == 'variable'
        ) {
            nextnodeIndex = node.connections[3].to
        } else if (node.type == 'loop') {
            nextnodeIndex = node.connections[2].to
        } else if (node.type == 'start') {
            nextnodeIndex = node.connections[0].to
        } else {
            nextnodeIndex = node.connections[1].to
        }
        if (nextnodeIndex == node.cardId) {
            return null
        } else {
            return cardConnections[nextnodeIndex]
        }
    }

    function clone(o) {
        console.log('WELCOME TO clone')

        var ret = {}
        Object.keys(o).forEach(function (val) {
            ret[val] = o[val]
        })
        return ret
    }

    function makeProgram(prog, node) {
        console.log('WLECOME TO makeProgram')

        if (!node || node.type == 'end' || node.length <= 0) return prog
        else {
            if (hassubProgram(node.type)) {
                var subProgramIndex = node.connections[0].to
                if (
                    node.type == 'if' ||
                    node.type == 'sensor' ||
                    node.type == 'variable'
                ) {
                    var nodeElse = clone(node)

                    var subprogram = makeProgram([], getSubnode(node))
                    node['subprogram'] = subprogram

                    var subprogramElse = makeProgram(
                        [],
                        getSubnode(nodeElse, 2)
                    ) //for else,one more node is created
                    nodeElse['subprogram'] = subprogramElse
                    nodeElse.type = 'else'

                    prog.push(node)
                    prog.push(nodeElse)
                } else {
                    var subprogram = makeProgram([], getSubnode(node))
                    node['subprogram'] = subprogram
                    prog.push(node)
                }
            } else {
                prog.push(node)
            }
            return makeProgram(prog, getNextnode(node))
        }
    }

    function getStartCard(cardConnections) {
        console.log('WELCOME TO getStartCard')

        var startCard = cardConnections[0]
        for (var card in cardConnections) {
            if (
                !cardConnections[card].invalid &&
                cardConnections[card].type == 'start'
            ) {
                startCard = cardConnections[card]
            }
        }

        return startCard
    }

    //Assembly program
    var logicData = logicComponents['logic']

    var componentsConnected = logicComponents['components']

    var assemblyCheckboxData = logicComponents['internalaccessories']

    console.log('assemblyCheckboxData', assemblyCheckboxData)
    console.log('componentsConnected', componentsConnected)
    console.log('logicData', logicData)

    var connectionsList = {}
    var portsConnectedArray = []

    for (var eachConnection in componentsConnected) {
        if (componentsConnected[eachConnection]) {
            portsConnectedArray.push(eachConnection)
        }
    }

    console.log('portsConnectedArray', portsConnectedArray)

    if (portsConnectedArray) {
        for (var n = 0; n < portsConnectedArray.length; n++) {
            //console.log((portsConnectedArray[n], componentsConnected[portsConnectedArray[n]].type))

            if (
                portsConnectedArray[n].length == 1 &&
                componentsConnected[portsConnectedArray[n]].type !==
                    'dual_splitter' &&
                componentsConnected[portsConnectedArray[n]].type !==
                    'servo_extender' &&
                componentsConnected[portsConnectedArray[n]].type !==
                    'pc_motor_driver'
            ) {
                //Check here  and indexes
                connectionsList[portsConnectedArray[n] + '1'] =
                    componentsConnected[portsConnectedArray[n]].type
            } else {
                connectionsList[portsConnectedArray[n]] =
                    componentsConnected[portsConnectedArray[n]].type
            }
        }
    }

    console.log('componentsConnected', componentsConnected)

    console.log('connectionsList 55', connectionsList)

    var logicProgramData

    var NewlogicCards, cardConnections
    if (logicComponents.screen == 'flow') {
        var prog = []
        NewlogicCards = logicData.cards
        cardConnections = logicData.cards
        var startCard = getStartCard(cardConnections)
        logicProgramData = makeProgram(prog, startCard)
    }
    if (
        logicComponents.screen == 'hexa' ||
        logicComponents.screen == undefined
    ) {
        logicProgramData = logicData.program
    }

    if (logicComponents.screen == 'python') {
        logicProgramData = logicData.program
    }

    console.log('logicProgramData', logicProgramData)

    //logic program
    var out = []

    for (var p = 0; p < logicProgramData.length; p++) {
        if (!logicProgramData[p].invalid) {
            CreateNodeList(logicProgramData[p], out)
        } else if (logicProgramData[p].invalid == undefined)
            CreateNodeList(logicProgramData[p], out)
    }

    var endNode = {}
    endNode[logicData.end.type] = logicData.end.state // getting end type  end OR loop

    out.push(endNode)

    console.log('OUT', out)

    var uploadData = {}
    uploadData['connections'] = connectionsList
    uploadData['logic'] = out

    uploadData = JSON.stringify(uploadData)

    uploadData = JSON.parse(uploadData)

    console.log(
        uploadData,
        'AFTER STRINGIFY - PARSE ****************************>'
    )

    var connectionsList = {} // connectionsList have object of connections , logic

    connectionsList = uploadData.connections

    connectionsList = {
        ...connectionsList,
        ...assemblyCheckboxData,
    }

    console.log('Connections list ::', connectionsList)

    var Uploadprogram = 'SET'

    // var PortsAndPortbytes = CodeGenerationConstants.PortsAndPortbytes;

    // var CorrespondingPortCode = CodeGenerationConstants.CorrespondingPortCode;

    // var PortByteValuesIf = CodeGenerationConstants.PortByteValuesIf;

    // var PortByteNumericalValuesIf =
    //   CodeGenerationConstants.PortByteNumericalValuesIf;

    // var PortByteNumericalValuesOutput =
    //   CodeGenerationConstants.PortByteNumericalValuesOutput;

    // var BiDataValuesOutput = CodeGenerationConstants.BiDataValuesOutput;
    // var nodeDefaultCodes = CodeGenerationConstants.nodeDefaultCodes;

    for (var key in PortByteNumericalValuesOutput) {
        if (MAX_OUTPUT_PORT_CODE < Number(PortByteNumericalValuesOutput[key])) {
            MAX_OUTPUT_PORT_CODE = Number(PortByteNumericalValuesOutput[key])
        }
    }

    console.log(MAX_OUTPUT_PORT_CODE, 'MAX_OUTPUT_PORT_CODE')

    var PortBytesToComponentcode = {}
    PortBytesToComponentcode['RGB'] = 'O'
    console.log('::MSG', connectionsList)
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    for (var portname in PortsAndPortbytes) {
        // A1: ["A1"], B1: ["B1"],
        var comp, j

        if (
            connectionsList[`isLeft${portname}`] === true ||
            connectionsList[`isRight${portname}`] === true
        ) {
            comp = portname
        } else if (connectionsList[`is${portname}`] === true) {
            comp = portname // for AssemblyCheckbox  to get portName  RBG, Buzzer, 4-in-1-Sensorr
        } else if (
            connectionsList[`is${portname}Output`] === true &&
            portname !== 'TouchZero' &&
            portname !== 'TouchOne' &&
            portname !== 'TouchTwo'
        ) {
            comp = portname // for AssemblyCheckbox  to get portName  RBG, Buzzer, 4-in-1-Sensorr
        } else if (portname !== 'Four_in_one_sensor') {
            comp = connectionsList[portname] // Connections list :: { A1: 'led', B1: 'led' , RBG:'true', "Buzzer":false}  // A1
        }

        if (
            sessionStorage.getItem('connectedDevice') !== 'Roboki' &&
            ['Switch1', 'Switch2'].includes(portname)
        ) {
            continue
        }
        console.log(comp, 'comp2.0')
        for (j = 0; j < PortsAndPortbytes[portname].length; j++) {
            // A1 type LED = 0-100  {P}
            if (comp) {
                console.log(portname, comp)
                if (comp == 'mp3') {
                    PortBytesToComponentcode['Mp3'] = 'M'
                    console.log(
                        PortBytesToComponentcode,
                        PortsAndPortbytes[portname][j],
                        portname,
                        j,
                        'PortBytesToComponentcode42'
                    )
                    PortBytesToComponentcode['Mp3'] = 'M'
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                } else if (comp == 'OLED') {
                    PortBytesToComponentcode['OLED'] = 'D'
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                } else if (comp == 'RGB') {
                    PortBytesToComponentcode['RGB'] = 'R'
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                } else if (comp == 'Eye') {
                    PortBytesToComponentcode['Eye'] = 'L'
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                } else if (comp == 'Four_in_one_sensor') {
                    PortBytesToComponentcode['Four_in_one_sensor'] =
                        Object.entries(connectionsList.Four_in_one_sensor)
                            .map((value) => {
                                if (value[1] == true) {
                                    switch (value[0]) {
                                        case 'isColorSensor':
                                            return 'C'
                                        case 'isDistanceSensors':
                                            return 'D'
                                        case 'isLightSensor':
                                            return 'L'
                                        case 'isGestureSensor':
                                            return 'G'
                                    }
                                }
                            })
                            .filter((value) => {
                                return value != undefined
                            })[0]
                } else if (comp == 'serial_servo_motor') {
                    PortBytesToComponentcode['SerialServo'] = 'I'
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                }
                //  else if (
                //     sessionStorage.getItem('zingVersion')[0] == 1 &&
                //     comp == 'M1'
                // ) {
                //     PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                //         'M'
                // }

                //  else if (sessionStorage.getItem('zingVersion')[0] == 1 && comp == 'M1') {
                //     PortBytesToComponentcode[PortsAndPortbytes[portname][j]] = 'M'
                // }
                else if (
                    ((['1', '2'].includes(
                        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
                    ) &&
                        ['Humanoid'].includes(
                            sessionStorage.getItem('connectedDevice')
                        )) ||
                        sessionStorage.getItem('connectedDevice') ==
                            'Hexapod') &&
                    [
                        'AccelerometerX',
                        'AccelerometerY',
                        'AccelerometerZ',
                        'GyroX',
                        'GyroY',
                        'GyroZ',
                    ].includes(comp)
                ) {
                    PortBytesToComponentcode['Accelerometer'] = 'A'
                } else {
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        CorrespondingPortCode[comp]
                }
            } else {
                if (
                    (PortBytesToComponentcode[PortsAndPortbytes[portname][j]] ==
                        'M' &&
                        PortsAndPortbytes[portname][j] == 'Mp3') ||
                    (PortBytesToComponentcode[PortsAndPortbytes[portname][j]] ==
                        'D' &&
                        PortsAndPortbytes[portname][j] == 'OLED')
                ) {
                    console.log(
                        PortsAndPortbytes[portname][j],
                        'PortBytesToComponentcode43'
                    )
                } else {
                    // console.log(
                    //     comp,
                    //     PortsAndPortbytes[portname][j],
                    //     'YYYYYYYYYYYYY111111',
                    //     { ...PortBytesToComponentcode }
                    // )
                    PortBytesToComponentcode[PortsAndPortbytes[portname][j]] =
                        'O'
                }
            }
        }
    }

    console.log(PortBytesToComponentcode, 'PortBytesToComponentcode44')

    if (connectionsList['A1'] === 'pcAiTool') {
        PortBytesToComponentcode['A2'] = 'X'
    }

    if (
        !(
            ['1', '2'].includes(
                (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
            ) &&
            ['Hexapod', 'Humanoid'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        )
    )
        if (
            connectionsList['isRightEye'] === true ||
            connectionsList['isLeftEye'] === true
        ) {
            PortBytesToComponentcode['Eye'] = 'L'
        }

    if (
        ['1', '2'].includes(
            (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        ) &&
        ['Humanoid', 'Hexapod'].includes(
            sessionStorage.getItem('connectedDevice')
        )
    ) {
        if (
            connectionsList['isEyeLeft'] === true ||
            connectionsList['isEyeRight'] === true
        ) {
            PortBytesToComponentcode['Eye'] = 'L'
        }
    }
    if (
        connectionsList['isRightEye'] === true ||
        connectionsList['isLeftEye'] === true
    ) {
        PortBytesToComponentcode['Eye'] = 'L'
    }
    // LED -> A1 & A2 are PWM
    if (connectionsList['A1'] == 'led') {
        PortBytesToComponentcode['A1'] = 'P'
    }
    if (connectionsList['A1'] == 'led_1c') {
        PortBytesToComponentcode['A1'] = 'P'
    }
    if (connectionsList['C1'] == 'led') {
        PortBytesToComponentcode['C1'] = 'P'
    }
    if (connectionsList['C1'] == 'led_1c') {
        PortBytesToComponentcode['C1'] = 'P'
    }

    // if (connectionsList["B2"] == "laser" || connectionsList["B2"] == "beeper") {
    //   PortBytesToComponentcode["B2"] = "P";
    // }
    if (
        !(
            ['1', '2'].includes(
                (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
            ) &&
            ['Hexapod', 'Humanoid'].includes(
                sessionStorage.getItem('connectedDevice')
            )
        )
    ) {
        if (
            connectionsList['F1'] == 'led' ||
            connectionsList['F1'] == 'led_1c' ||
            connectionsList['F1'] == 'laser' ||
            connectionsList['F1'] == 'beeper'
        ) {
            PortBytesToComponentcode['F1'] = 'O'
        }
        if (
            connectionsList['F2'] == 'led' ||
            connectionsList['F2'] == 'led_1c' ||
            connectionsList['F2'] == 'laser' ||
            connectionsList['F2'] == 'beeper'
        )
            PortBytesToComponentcode['F2'] = 'O'
    }

    const motorMapping = {
        M1: 'SmileOne',
        M2: 'SmileTwo',
        M3: 'SmileThree',
        M4: 'SmileFour',
    }

    const motorTypes = {
        standard: ['dc_motor', 'mini_geared_motor', 'geared_motor'],
        dynamex: ['dynamex_motor'],
    }

    Object.entries(motorMapping).forEach(([motor, smile]) => {
        const connection = connectionsList[motor]
        if (motorTypes.standard.includes(connection)) {
            PortBytesToComponentcode[smile] = 'M'
        } else if (motorTypes.dynamex.includes(connection)) {
            PortBytesToComponentcode[smile] = 'D'
        }
    })

    if (JSON.parse(sessionStorage.getItem('peeCeeATR'))) {
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            if (
                [
                    'dc_motor',
                    'mini_geared_motor',
                    'geared_motor',
                    'dynamex_motor',
                ].includes(connectionsList['E1']) &&
                [
                    'dc_motor',
                    'mini_geared_motor',
                    'geared_motor',
                    'dynamex_motor',
                ].includes(connectionsList['E2'])
            ) {
                PortBytesToComponentcode['G1'] = 'O'
                PortBytesToComponentcode['E1'] = 'P'
                PortBytesToComponentcode['E2'] = 'P'
            }
            if (
                [
                    'dc_motor',
                    'mini_geared_motor',
                    'geared_motor',
                    'dynamex_motor',
                ].includes(connectionsList['F1']) &&
                [
                    'dc_motor',
                    'mini_geared_motor',
                    'geared_motor',
                    'dynamex_motor',
                ].includes(connectionsList['F2'])
            ) {
                PortBytesToComponentcode['H1'] = 'O'
                PortBytesToComponentcode['F2'] = 'P'
                PortBytesToComponentcode['F1'] = 'P'
            }
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    connectionsList['S1']
                )
            ) {
                PortBytesToComponentcode['TouchZero'] = 'S'
            }
            if (
                ['servo_motor', 'servo_motor_270'].includes(
                    connectionsList['S2']
                )
            ) {
                PortBytesToComponentcode['TouchOne'] = 'S'
            }
            if (['servo_motor_360'].includes(connectionsList['S1'])) {
                PortBytesToComponentcode['TouchZero'] = 'R'
            }
            if (['servo_motor_360'].includes(connectionsList['S2'])) {
                PortBytesToComponentcode['TouchOne'] = 'R'
            }
        } else {
            if (
                (connectionsList['A2'] == 'dc_motor' ||
                    connectionsList['A2'] === 'mini_geared_motor' ||
                    connectionsList['A2'] === 'geared_motor') &&
                (connectionsList['F2'] == 'dc_motor' ||
                    connectionsList['F2'] === 'mini_geared_motor' ||
                    connectionsList['F2'] === 'geared_motor')
            ) {
                PortBytesToComponentcode['H1'] = 'O'
                PortBytesToComponentcode['A2'] = 'M'
                PortBytesToComponentcode['F2'] = 'M'
            }
            if (
                (connectionsList['C2'] == 'dc_motor' ||
                    connectionsList['C2'] === 'mini_geared_motor' ||
                    connectionsList['C2'] === 'geared_motor') &&
                (connectionsList['F1'] == 'dc_motor' ||
                    connectionsList['F1'] === 'mini_geared_motor' ||
                    connectionsList['F1'] === 'geared_motor')
            ) {
                PortBytesToComponentcode['G1'] = 'O'
                PortBytesToComponentcode['C2'] = 'M'
                PortBytesToComponentcode['F1'] = 'M'
            }
        }
    }
    console.log(PortBytesToComponentcode, 'PortBytesToComponentcode33')

    // SETTING BYTES LAYOUT
    var FinalCode = ''

    var codeTemplate

    //copy from hornbill or snipe

    switch (sessionStorage.getItem('connectedDevice')) {
        case 'Ace':
            if (sessionStorage.getItem('deviceVersion')?.startsWith('1'))
                codeTemplate =
                    'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEYESFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'
            else
                codeTemplate =
                    'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeFour_in_one_sensorMicTouchZeroTouchOneTouchTwoTemperatureIOT1IOT2IOT3IOT4Mp3OLEDRGB'

            if (
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor1']) ||
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor2'])
            ) {
                codeTemplate = codeTemplate.replace(
                    'TouchZero',
                    PortBytesToComponentcode['servo_motor1']
                )
                codeTemplate = codeTemplate.replace(
                    'TouchOne',
                    PortBytesToComponentcode['servo_motor2']
                )
            }
            for (var portBytes in PortBytesToComponentcode) {
                if (portBytes.includes('Accelerometer'))
                    codeTemplate = codeTemplate.replace(
                        'Gyro',
                        PortBytesToComponentcode['Gyro']
                    )
                codeTemplate = codeTemplate.replace(
                    portBytes,
                    PortBytesToComponentcode[portBytes]
                )
            }
            break
        case 'Humanoid':
            if (
                !['1', '2'].includes(
                    (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
                ) &&
                ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                codeTemplate =
                    'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeUltrasonicZingmp3HeadServoSimpleServoBatteryAccelerometerActionGroupActionServoIOT3IOT4Mp3OLEDRGB'
            } else {
                codeTemplate =
                    'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeUltrasonicZingmp3HeadServoSimpleServoOOActionGroupActionServoAccelerometerIOT3Mp3IOT4BatteryOLEDRGB'
                // codeTemplate =
                //     'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2OOOM1M2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeUltrasonicZingmp3HeadServoSimpleServoActionGroupActionServoAccelerometerIOT3IOT4Mp3OLEDRGB'
            }
            if (
                ['1', '2'].includes(
                    (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
                ) &&
                ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))
            ) {
                if (connectionsList.isM2) {
                    // PortBytesToComponentcode['B1'] = 'M'
                    // PortBytesToComponentcode['B2'] = 'M'
                    PortBytesToComponentcode['SmileThree'] = 'M'
                    PortBytesToComponentcode['SmileFour'] = 'M'
                }
                if (connectionsList.isM1) {
                    // PortBytesToComponentcode['HeadServo'] = 'M'
                    // PortBytesToComponentcode['SimpleServo'] = 'M'
                    PortBytesToComponentcode['SmileOne'] = 'M'
                    PortBytesToComponentcode['SmileTwo'] = 'M'
                }
            }
            console.log(
                'gsk 1',
                PortBytesToComponentcode,
                connectionsList,
                codeTemplate
            )
            for (var portBytes in PortBytesToComponentcode) {
                //gyro and accelerometer same setting bytes
                if (
                    portBytes.includes('Accelerometer') ||
                    portBytes.includes('Gyro')
                ) {
                    codeTemplate = codeTemplate.replace(
                        'Accelerometer',
                        PortBytesToComponentcode['Accelerometer']
                    )
                } else
                    codeTemplate = codeTemplate.replace(
                        portBytes,
                        PortBytesToComponentcode[portBytes]
                    )
            }

            break

        case 'Hexapod':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeUltrasonicZingmp3HeadServoSimpleServoBatteryAccelerometerActionGroupActionServoIOT3IOT4Mp3OLEDRGB'

            if (connectionsList.isM2) {
                // PortBytesToComponentcode['B1'] = 'M'
                // PortBytesToComponentcode['B2'] = 'M'
                PortBytesToComponentcode['SmileThree'] = 'M'
                PortBytesToComponentcode['SmileFour'] = 'M'
            }
            if (connectionsList.isM1) {
                // PortBytesToComponentcode['HeadServo'] = 'M'
                // PortBytesToComponentcode['SimpleServo'] = 'M'
                PortBytesToComponentcode['SmileOne'] = 'M'
                PortBytesToComponentcode['SmileTwo'] = 'M'
            }

            for (var portBytes in PortBytesToComponentcode) {
                //gyro and accelerometer same setting bytes
                if (
                    portBytes.includes('Accelerometer') ||
                    portBytes.includes('Gyro')
                ) {
                    codeTemplate = codeTemplate.replace(
                        'Accelerometer',
                        PortBytesToComponentcode['Accelerometer']
                    )
                } else
                    codeTemplate = codeTemplate.replace(
                        portBytes,
                        PortBytesToComponentcode[portBytes]
                    )
            }

            break

        case 'Roboki':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'

            if (
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor1']) ||
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor2'])
            ) {
                codeTemplate = codeTemplate.replace(
                    'TouchZero',
                    PortBytesToComponentcode['servo_motor1']
                )
                codeTemplate = codeTemplate.replace(
                    'TouchOne',
                    PortBytesToComponentcode['servo_motor2']
                )
            }
            for (var portBytes in PortBytesToComponentcode) {
                if (portBytes.includes('Accelerometer'))
                    codeTemplate = codeTemplate.replace(
                        'Gyro',
                        PortBytesToComponentcode['Gyro']
                    )
                codeTemplate = codeTemplate.replace(
                    portBytes,
                    PortBytesToComponentcode[portBytes]
                )
            }
            break
        case 'Atr':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'

            if (connectionsList.isAtrMotor1) {
                // PortBytesToComponentcode['ActionServo'] = 'O'
                PortBytesToComponentcode['SmileOne'] = 'M'
                PortBytesToComponentcode['SmileTwo'] = 'M'
            }
            if (connectionsList.isAtrMotor2) {
                PortBytesToComponentcode['SmileThree'] = 'M'
                PortBytesToComponentcode['SmileFour'] = 'M'
            }
            if (connectionsList.isAtrMotor3) {
                PortBytesToComponentcode['E1'] = 'P'
                PortBytesToComponentcode['E2'] = 'P'
            }

            if (
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor1']) ||
                ['S', 'R'].includes(PortBytesToComponentcode['servo_motor2'])
            ) {
                codeTemplate = codeTemplate.replace(
                    'TouchZero',
                    PortBytesToComponentcode['servo_motor1']
                )
                codeTemplate = codeTemplate.replace(
                    'TouchOne',
                    PortBytesToComponentcode['servo_motor2']
                )
            }
            for (var portBytes in PortBytesToComponentcode) {
                if (
                    ['C1', 'C2', 'D1'].includes(portBytes) &&
                    PortBytesToComponentcode[portBytes] == 'P'
                ) {
                    codeTemplate = codeTemplate.replace(portBytes, 'O')
                } else {
                    codeTemplate = codeTemplate.replace(
                        portBytes,
                        PortBytesToComponentcode[portBytes]
                    )
                }
            }
            break
        case 'Klaw':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEYESFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'
            //'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeUltrasonicZingmp3HeadServoSimpleServoBatteryAccelerometerActionGroupActionServoIOT3IOT4Mp3OLEDRGB'
            console.log(
                'scsacfasasa',
                connectionsList,
                PortBytesToComponentcode
            )
            if (connectionsList.isSwitch1) {
                PortBytesToComponentcode['TouchThree'] = 'I'
            }
            if (connectionsList.isSwitch2) {
                PortBytesToComponentcode['TouchTwo'] = 'I'
            }
            if (connectionsList.isActionServo) {
                // PortBytesToComponentcode['ActionServo'] = 'O'
                PortBytesToComponentcode['SmileOne'] = 'S'
                PortBytesToComponentcode['SmileTwo'] = 'S'
                PortBytesToComponentcode['SmileThree'] = 'S'
                PortBytesToComponentcode['SmileFour'] = 'S'
                PortBytesToComponentcode['TouchZero'] = 'S'
                PortBytesToComponentcode['TouchOne'] = 'S'
            }
            for (let i = 1; i <= 6; i++) {
                if (connectionsList['isPot' + i]) {
                    switch (i) {
                        case 1:
                            PortBytesToComponentcode['C1'] = 'A'
                            break
                        case 2:
                            PortBytesToComponentcode['C2'] = 'A'
                            break
                        case 3:
                            PortBytesToComponentcode['D1'] = 'A'
                            break
                        case 4:
                            PortBytesToComponentcode['D2'] = 'A'
                            break
                        case 5:
                            PortBytesToComponentcode['E1'] = 'A'
                            break
                        case 6:
                            PortBytesToComponentcode['E2'] = 'A'
                            break
                    }
                }
            }
            // if (connectionsList.M2) {
            //     PortBytesToComponentcode['SmileThree'] = 'M'
            //     PortBytesToComponentcode['SmileFour'] = 'M'
            // }
            // if (connectionsList.M1) {
            //     PortBytesToComponentcode['SmileOne'] = 'M'
            //     PortBytesToComponentcode['SmileTwo'] = 'M'
            // }
            // if (connectionsList.serial_servo1||connectionsList.serial_servo2) {
            //     PortBytesToComponentcode['ActionServo'] = 'I'
            // }
            // if (connectionsList.PW1) {
            //     PortBytesToComponentcode['G2'] = 'P'
            // }
            // if (connectionsList.PW2) {
            //     PortBytesToComponentcode['H1'] = 'P'
            // }
            // if (connectionsList.PW3) {
            //     PortBytesToComponentcode['H2'] = 'P'
            // }
            // if (connectionsList.PW4) {
            //     PortBytesToComponentcode['I1'] = 'P'
            // }
            // if (connectionsList.PW5) {
            //     PortBytesToComponentcode['I2'] = 'P'
            // }
            // if (connectionsList.isJoystick1) {
            //     PortBytesToComponentcode['C1'] = 'A'
            //     PortBytesToComponentcode['C2'] = 'A'
            // }
            // if (connectionsList.isJoystick2) {
            //     PortBytesToComponentcode['D1'] = 'A'
            //     PortBytesToComponentcode['D2'] = 'A'
            // }
            // if (connectionsList.isJoystick3) {
            //     PortBytesToComponentcode['E1'] = 'A'
            //     PortBytesToComponentcode['E2'] = 'A'
            // }
            // if (connectionsList.isSwitch1) {
            //     PortBytesToComponentcode['F1'] = 'I'
            // }
            // if (connectionsList.isSwitch2) {
            //     PortBytesToComponentcode['F2'] = 'I'
            // }
            // if (connectionsList.isSwitch3) {
            //     PortBytesToComponentcode['G1'] = 'I'
            // }

            for (var portBytes in PortBytesToComponentcode) {
                codeTemplate = codeTemplate.replace(
                    portBytes,
                    PortBytesToComponentcode[portBytes]
                )
            }
            break
        case 'Toki':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'
            if (connectionsList.isM1) {
                // PortBytesToComponentcode['ActionServo'] = 'O'
                PortBytesToComponentcode['SmileOne'] = 'M'
                PortBytesToComponentcode['SmileTwo'] = 'M'
            }
            if (connectionsList.isM2) {
                PortBytesToComponentcode['SmileThree'] = 'M'
                PortBytesToComponentcode['SmileFour'] = 'M'
            }
            if (
                ['S', 'R', 'I', 'P'].includes(
                    PortBytesToComponentcode['servo_motor1']
                ) ||
                ['S', 'R', 'I', 'P'].includes(
                    PortBytesToComponentcode['servo_motor2']
                )
            ) {
                codeTemplate = codeTemplate.replace(
                    'TouchZero',
                    PortBytesToComponentcode['servo_motor1']
                )
                codeTemplate = codeTemplate.replace(
                    'TouchOne',
                    PortBytesToComponentcode['servo_motor2']
                )
            }
            for (var portBytes in PortBytesToComponentcode) {
                // if (
                //     ['C1', 'C2'].includes(portBytes)
                //     // PortBytesToComponentcode[portBytes] == 'P'
                // ) {

                // codeTemplate = codeTemplate.replace(portBytes, 'O')
                // }
                // else {
                codeTemplate = codeTemplate.replace(
                    portBytes,
                    PortBytesToComponentcode[portBytes]
                )
                // }
            }
            break
        case 'Moki':
            codeTemplate =
                'A1A2B1B2C1C2D1D2E1E2F1F2G1G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzerEyeFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2AccelerometerTouchTwoMp3OLEDRGBBatteryGyro'
            if (connectionsList.isM1) {
                // PortBytesToComponentcode['ActionServo'] = 'O'
                PortBytesToComponentcode['SmileOne'] = 'M'
                PortBytesToComponentcode['SmileTwo'] = 'M'
            }
            if (connectionsList.isM2) {
                PortBytesToComponentcode['SmileThree'] = 'M'
                PortBytesToComponentcode['SmileFour'] = 'M'
            }
            if (connectionsList.isSwitch1) {
                PortBytesToComponentcode['TouchZero'] = 'I'
            }
            if (connectionsList.isSwitch2) {
                PortBytesToComponentcode['TouchOne'] = 'I'
            }
            if (connectionsList.isIr) {
                PortBytesToComponentcode['Gyro'] = 'R'
            }

            // edit the PortByteNumericalValuesIf, give the moki Switch_One and Switch_Two value, 30, 31.
            PortByteNumericalValuesIf.Switch_One = '30'
            PortByteNumericalValuesIf.Switch_Two = '31'

            for (var portBytes in PortBytesToComponentcode) {
                codeTemplate = codeTemplate.replace(
                    portBytes,
                    PortBytesToComponentcode[portBytes]
                )
            }
            break
    }
    console.log(codeTemplate, 'codeTemplate454')

    FinalCode = codeTemplate
    Uploadprogram += FinalCode
    var codeLen = codeTemplate.length

    console.log(codeTemplate, 'codeTemplate')

    for (var i = codeLen; i <= 58; i++) {
        Uploadprogram += 'O'
    }
    Uploadprogram += ';'

    console.log(Uploadprogram, 'Uploadprogram')

    console.log(Uploadprogram, 'Uploadprogram2.0')

    // PROGRAM Bytes
    function codeForNodes(index, type, nodeDetails) {
        console.log('WELCOME TO codeForNodes WHERE WE CREATE PROGRAM BYETS')

        console.log('type', type)
        console.log('nodeDetails', nodeDetails)

        if (type == 'sensor') {
            console.log('counting countIf', countIf)

            countIf++
        }

        // console.log("nodeDefaultCodes", nodeDefaultCodes);

        if (type == 'endsensor') {
            type = 'endif'
        }

        if (type == 'condition') {
            type = 'if'

            countIf++
        }

        if (type == 'endcondition') {
            type = 'endif'
        }

        // console.log("nodeDetails", nodeDetails);

        var codeForCurrentNode

        if (type == 'wait') {
            var minutes = 0,
                hours = 0,
                milliseconds = ['roboki'].includes(
                    sessionStorage.getItem('connectedDevice')
                )
                    ? 500
                    : 0,
                seconds = 0
            if (nodeDetails.h) {
                hours = nodeDetails.h
            }
            if (nodeDetails.m) {
                minutes = nodeDetails.m
            }
            if (nodeDetails.s) {
                seconds = nodeDetails.s
            }
            if (nodeDetails.ms) {
                milliseconds = nodeDetails.ms
            }
            var timeToWait =
                parseInt(hours * 60 * 60 * 1000) +
                parseInt(minutes * 60 * 1000) +
                parseInt(seconds * 1000) +
                parseInt(milliseconds)
            // ////console.log("timeToWait*************************************",timeToWait)
            timeToWait = parseInt(timeToWait).toString(16)
            timeToWait += ''
            var len = timeToWait.length
            for (var i = 1; i <= 8 - len; i++) {
                timeToWait = '0' + timeToWait
            }
            var tempTimeToWait = ':'
            var w = 0
            timeToWait += ''
            for (var j = 0; j < 8; j = j + 2) {
                tempTimeToWait += parseInt(timeToWait.substring(w, j + 2), 16)
                if (j != 6) tempTimeToWait += ':'
                w = j + 2
            }
            timeToWait = tempTimeToWait + ';'
            codeForCurrentNode = nodeDefaultCodes[type] + timeToWait
        } else if (
            type == 'if' ||
            type == 'else' ||
            type == 'sensor' ||
            type == 'variable'
        ) {
            var nodePort
            var value
            var decimalValue = ''

            console.log('|||||||||||||||||||||||||||||||||')
            console.log('nodeDetails', nodeDetails)
            if (
                nodeDetails.source == 'battery' &&
                sessionStorage.getItem('connectedDevice') !== 'Humanoid'
            ) {
                portCode = '39'
                decimalValue = '0:0'
            } else if (nodeDetails.source) {
                nodePort = nodeDetails.source

                console.log('nodePort:', nodePort)
                console.log('compName: ', compName)

                console.log('HAHAHAs', PortByteNumericalValuesIf[nodePort])

                if (
                    nodePort == '4-IN-1 SENSOR  →  RED' ||
                    nodePort == '4-IN-1 SENSOR  →  GREEN' ||
                    nodePort == '4-IN-1 SENSOR  →  BLUE' ||
                    nodePort == '4-IN-1 SENSOR  →  DIST' ||
                    nodePort == '4-IN-1 SENSOR  →  GESTURE' ||
                    nodePort == '4-IN-1 SENSOR  →  LIGHT' ||
                    nodePort == '4-IN-1 SENSOR  →  MOTION'
                ) {
                    console.log('YESSSSSSSSSSS')

                    let words = nodePort.split(' ')
                    console.log(words)

                    nodePort = `FOUR_in_ONE_Sensor_${words[words.length - 1]}`
                }

                console.log(nodePort, 'nodePort')

                if (PortByteNumericalValuesIf[nodePort] == undefined) {
                    console.log('nodePort = undefined' + nodePort)
                    if (PortByteValuesIf[nodePort]) {
                        nodePort = PortByteValuesIf[nodePort]
                    }
                }
                var portCode = ''

                let aiPortNumberAdd = 0
                if (
                    nodePort.includes('ai_face_tracking_X') ||
                    nodePort.includes('ai_face_tracking_Y') ||
                    nodePort.includes('ai_color_tracking_X') ||
                    nodePort.includes('ai_color_tracking_Y')
                ) {
                    const numberMatch = nodePort.match(/(\d+)(?!.*\d)/)
                    const num = numberMatch ? parseInt(numberMatch[0], 10) : 0

                    aiPortNumberAdd = num * 2

                    let newNodePort = nodePort.replace(/(\d+)(?!.*\d)/, '')

                    portCode = parseInt(PortByteNumericalValuesIf[newNodePort])
                    portCode = (aiPortNumberAdd + portCode).toString()
                } else {
                    portCode = PortByteNumericalValuesIf[nodePort] //B1 = 3
                }

                console.log(portCode, 'portCode')

                value = nodeDetails.value // 255
                if (
                    [
                        'ACCELEROMETER_Y',
                        'ACCELEROMETER_X',
                        'ACCELEROMETER_Z',
                        'gyro_x',
                        'gyro_y',
                        'gyro_z',
                    ].includes(nodeDetails.source)
                ) {
                    value = value + 32768
                }
                if (!value) {
                    value = 0
                }
                if (
                    CodeGenerationRangeValues &&
                    CodeGenerationRangeValues[nodePort] &&
                    CodeGenerationRangeValues[nodePort][compName] &&
                    nodePort != 'Btremote'
                ) {
                    console.log(
                        'CodeGenerationRangeValues[nodePort][compName]',
                        CodeGenerationRangeValues[nodePort][compName]
                    )
                    value = CodeGenerationRangeValues[nodePort][compName](value)
                }
                if (nodePort == '_R' || nodePort == '_G' || nodePort == '_B') {
                    nodePort = 'F'
                } else if (
                    nodePort == 'B2' ||
                    nodePort == 'B3' ||
                    nodePort == 'B4'
                )
                    nodePort = 'B'
                else if (nodePort == 'timeElapsed') {
                    NUMOFBYTES = 4
                    value *= 1000 // converting to mili secs
                }

                console.log(connectionsList, 'connectionsList')
                var compName = connectionsList[nodePort]

                console.log(compName, 'compName 58258') // distance_sensor

                if (compName == 'rfid') {
                    portCode = PortByteNumericalValuesIf[compName]
                }
                if (nodeDetails.condition && type == 'else') {
                    if (nodeDetails.condition == 'ne') {
                        ifCondition = '61' // '='
                    } else if (nodeDetails.condition == 'lt') {
                        ifCondition = '62'
                        --value // '>'
                    } else if (nodeDetails.condition == 'eq') {
                        ifCondition = '33' // '!'
                    } else if (nodeDetails.condition == 'gt') {
                        ifCondition = '60'
                        ++value // '<'
                    } else if (nodeDetails.condition == 'bw') {
                        ifCondition = '64' // '@'
                    } else if (nodeDetails.condition == 'nbw') {
                        ifCondition = '63' // '?'
                    }
                } else if (type == 'else') {
                    ifCondition = '60'
                    ++value // '<'
                }
                var Hexa = ''

                // GENERATING LSM MSB value
                if (nodePort != 'time') {
                    Hexa = '' + value.toString(NUMOFBITS)

                    console.log(value, 'value VALUE')
                    console.log(Hexa, 'HEXA VALUE')

                    decimalValue += getDecimalValue(Hexa, NUMOFBYTES)

                    if (
                        nodeDetails.condition == 'bw' ||
                        nodeDetails.condition == 'nbw'
                    ) {
                        Hexa = '' + nodeDetails.value2.toString(NUMOFBITS)
                        decimalValue += ':' + getDecimalValue(Hexa, NUMOFBYTES)
                    }
                } else {
                    Hexa =
                        '' +
                        (parseInt(nodeDetails.hour) +
                            ':' +
                            parseInt(nodeDetails.minute))
                    decimalValue += Hexa
                    if (
                        nodeDetails.condition == 'bw' ||
                        nodeDetails.condition == 'nbw'
                    ) {
                        Hexa =
                            '' +
                            (parseInt(nodeDetails.hour2) +
                                ':' +
                                parseInt(nodeDetails.minute2))
                        decimalValue += ':' + Hexa
                    }
                }
            } else {
                portCode = '39'
                decimalValue = '0:0'
                // decimalValue=':00;:00;';
                // ifCondition='<';
            }
            var ifCondition

            //  CONDITION GENERATION gt,lt,eq
            if (
                (nodeDetails.condition && type == 'if') ||
                (nodeDetails.condition && type == 'variable') ||
                (nodeDetails.condition && type == 'sensor')
            ) {
                if (nodeDetails.condition == 'ne') {
                    ifCondition = '33' // '!'
                } else if (nodeDetails.condition == 'lt') {
                    ifCondition = '60' // '<'
                } else if (nodeDetails.condition == 'eq') {
                    ifCondition = '61' // '='
                } else if (nodeDetails.condition == 'gt') {
                    ifCondition = '62' // '>'
                } else if (nodeDetails.condition == 'bw') {
                    ifCondition = '63' // '?'
                } else if (nodeDetails.condition == 'nbw') {
                    ifCondition = '64' // '@'
                }
            } else if (type == 'if' || type == 'variable' || type == 'sensor') {
                ifCondition = '62' // '>'
            }
            // if(nodePort == 'F'){
            // portCode = '2D';
            // }

            // we are generating Bytes d:0:0:portName:LSB:MSB:operator

            console.log(decimalValue, 'decimalValue 88')
            if (decimalValue.split(':').length < 4) {
                codeForCurrentNode =
                    nodeDefaultCodes[type] +
                    ':0:0:' +
                    portCode +
                    ':' +
                    decimalValue +
                    ':' +
                    ifCondition +
                    ';'
            } else if (decimalValue.split(':').length >= 4) {
                let decimalValueArray = decimalValue.split(':')
                codeForCurrentNode =
                    nodeDefaultCodes[type] +
                    ':' +
                    decimalValueArray[0] +
                    ':' +
                    decimalValueArray[1] +
                    ':' +
                    portCode +
                    ':' +
                    decimalValueArray[2] +
                    ':' +
                    decimalValueArray[3] +
                    ':' +
                    ifCondition +
                    ';'
            }
        } else if (type == 'endif' || type == 'endelse') {
            console.log('ENDIF________', nodeDefaultCodes[type])

            countIf--
            console.log('COUNT_IF', countIf)

            codeForCurrentNode = countIf + nodeDefaultCodes[type] + ';'
            console.log(
                'codeForCurrentNode endif ------------end----------------->',
                codeForCurrentNode
            )
        } else if (type == 'loop') {
            if (nodeDetails.times) {
                var nodeValue = nodeDetails.times
            } else {
                var nodeValue = 1
            }
            codeForCurrentNode = nodeDefaultCodes[type] + ':' + nodeValue + ';'
        } else if (type == 'endloop') {
            codeForCurrentNode = nodeDefaultCodes[type] + ';'
        } else if (type == 'end') {
            console.log('userprogram 1', nodeDetails)
            if (nodeDetails && typeof nodeDetails !== 'object') {
                codeForCurrentNode = nodeDefaultCodes[nodeDetails]
            } else {
                codeForCurrentNode = nodeDefaultCodes['end']
            }
            // }else if(type=="repeat"){
            // codeForCurrentNode=nodeDefaultCodes[type];
        } else if (
            type == 'output' ||
            type == 'hardware' ||
            type == 'variable_output'
        ) {
            var completePortCode = ''
            var output_object = {}

            // Traversing the object
            /*nodeDetails {
  assignA1: true,
  valueA1: 100,
  assignB1: false,
  assignBuzzer: true,
  valueBuzzer: 53
}*/
            for (var eachItemInDetails in nodeDetails) {
                console.log('eachItemInDetails', eachItemInDetails)

                console.log(
                    'eachItemDetails0..2',
                    eachItemInDetails.indexOf('assign')
                )
                console.log(CurrentPort, 'CurrentPort')
                if (
                    eachItemInDetails == 'assignCustomAction' &&
                    nodeDetails.assignRandomAction
                ) {
                    continue
                }

                if (
                    eachItemInDetails == 'assignCustomAction' &&
                    nodeDetails.assignRandomAction
                ) {
                    continue
                }

                if (
                    eachItemInDetails.indexOf('assign') >= 0 &&
                    eachItemInDetails.replace('assign', '') !== 'LeftEye' &&
                    eachItemInDetails.replace('assign', '') !== 'RightEye'
                ) {
                    // checking assgin value  true
                    if (nodeDetails[eachItemInDetails]) {
                        var CurrentPort = eachItemInDetails.replace(
                            'assign',
                            ''
                        )

                        console.log(CurrentPort, 'CurrentPort') //A1, A2, Buzzer TouchZeroOutput
                        console.log(connectionsList, 'connectionsList')
                        var compName
                        if (CurrentPort === 'Buzzer') continue
                        // TouchZeroOutput
                        if (
                            CurrentPort == 'SmileOne' ||
                            CurrentPort == 'SmileTwo' ||
                            CurrentPort == 'SmileThree' ||
                            CurrentPort == 'SmileFour' ||
                            CurrentPort == 'BuzzerFrequency' ||
                            CurrentPort == 'BuzzerTone' ||
                            CurrentPort == 'TouchZeroOutput' ||
                            CurrentPort == 'TouchOneOutput' ||
                            CurrentPort == 'TouchTwoOutput' ||
                            CurrentPort == 'HeadServo' ||
                            CurrentPort == 'SimpleServo'
                        ) {
                            compName = connectionsList[`is${CurrentPort}`]
                        }
                        if (
                            CurrentPort == 'LeftEyeR' ||
                            CurrentPort == 'LeftEyeG' ||
                            CurrentPort == 'LeftEyeB' ||
                            CurrentPort == 'RightEyeR' ||
                            CurrentPort == 'RightEyeG' ||
                            CurrentPort == 'RightEyeB'
                        ) {
                            let port = CurrentPort.slice(0, -1)
                            compName = connectionsList[`is${port}`]
                        } else if (CurrentPort != 'HeadServo') {
                            compName = connectionsList[CurrentPort]
                            //PCv1.0
                            if (CurrentPort.includes('Eye'))
                                compName = connectionsList['isEYES']
                            //end
                        }
                        if (
                            ['Roboki', 'Atr'].includes(
                                sessionStorage.getItem('connectedDevice')
                            ) &&
                            CurrentPort.includes('Eye')
                        ) {
                            if (['2'].includes(CurrentPort.charAt(3))) {
                                compName = connectionsList['isLeftEye']
                            } else if (['3'].includes(CurrentPort.charAt(3))) {
                                compName = connectionsList['isRightEye']
                            }
                        }

                        console.log('gsk is cool', compName, CurrentPort)

                        var currentPortBytes

                        if (
                            CurrentPort == 'B12' ||
                            CurrentPort == 'C12' ||
                            CurrentPort == 'B34' ||
                            CurrentPort == 'BC'
                        ) {
                            if (compName == 'dot_matrix')
                                currentPortBytes =
                                    PortsAndPortbytes['dot_matrix']
                            else
                                currentPortBytes =
                                    PortsAndPortbytes[CurrentPort]
                            console.log(
                                'currentPortBytes*************************************',
                                currentPortBytes
                            )
                            var i
                            if (currentPortBytes) {
                                for (i = 0; i < currentPortBytes.length; i++) {
                                    var portByteSelected = currentPortBytes[i]
                                    var currentPortCode =
                                        PortByteNumericalValuesOutput[
                                            portByteSelected
                                        ]
                                    var portValue

                                    //console.log("portValue*************************************", portValue, portByteSelected, compName)
                                    if (compName == 'dot_matrix') {
                                        if (nodeDetails[portByteSelected]) {
                                            portValue =
                                                nodeDetails[
                                                    portByteSelected
                                                ].charCodeAt(0)
                                        } else {
                                            portValue = ' '.charCodeAt(0)
                                        }
                                    } else if (compName == 'mp3') {
                                        portValue = nodeDetails['valueF']
                                        console.log('mp3', portValue)
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'dual_splitter') {
                                        portValue = nodeDetails['valueF1']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'dual_splitter') {
                                        portValue = nodeDetails['valueF2']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'dual_splitter') {
                                        portValue = nodeDetails['valueG1']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'dual_splitter') {
                                        portValue = nodeDetails['valueG2']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueB1']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueB2']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueB3']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueB4']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueC1']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueC2']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueC3']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else if (compName == 'octa_splitter') {
                                        portValue = nodeDetails['valueC4']
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                    } else {
                                        // ////console.log("portByteSelected********************",portByteSelected);

                                        portValue =
                                            nodeDetails[
                                                'value' + portByteSelected
                                            ]
                                        portValue =
                                            CodeGenerationRangeValues[
                                                CurrentPort
                                            ][compName](portValue)
                                        // ////console.log("portValue********************",portValue);
                                    }
                                    if (portValue == true) {
                                        portValue = 1
                                    }
                                    if (portValue == false) {
                                        portValue = 0
                                    }
                                    if (!portValue) {
                                        portValue = 0
                                    }
                                    var binary = portValue.toString(2)
                                    var lb = binary.slice(-8)
                                    if (!lb) {
                                        lb = 0
                                    }
                                    var hb = binary.slice(
                                        0,
                                        binary.lastIndexOf(lb)
                                    )
                                    if (!hb) {
                                        hb = 0
                                    }
                                    var hbDec = parseInt(hb, 2).toString(16)
                                    var lbDec = parseInt(lb, 2).toString(16)
                                    var decimalValue = ''
                                    decimalValue += '' + parseInt(hbDec, 16)
                                    decimalValue += ':' + parseInt(lbDec, 16)
                                    // completePortCode+=':'+currentPortCode+':'+decimalValue;
                                    output_object[currentPortCode] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue
                                }
                            }
                        } else if (
                            CurrentPort.includes('bi') ||
                            CurrentPort.includes('btr') ||
                            CurrentPort.includes('IOT')
                        ) {
                            currentPortBytes = PortsAndPortbytes[CurrentPort]
                            var i
                            var decimalValue = ''
                            var currentPortCode
                            if (currentPortBytes) {
                                for (i = 0; i < currentPortBytes.length; i++) {
                                    var portByteSelected = currentPortBytes[i]
                                    if (
                                        CurrentPort.includes('Countbic') &&
                                        !portByteSelected.includes('assign')
                                    ) {
                                        if (
                                            nodeDetails['value' + CurrentPort]
                                        ) {
                                            currentPortCode =
                                                PortByteNumericalValuesOutput[
                                                    portByteSelected +
                                                        'increment'
                                                ]
                                        } else {
                                            currentPortCode =
                                                PortByteNumericalValuesOutput[
                                                    portByteSelected +
                                                        'decrement'
                                                ]
                                        }
                                    } else {
                                        currentPortCode =
                                            PortByteNumericalValuesOutput[
                                                portByteSelected
                                            ]
                                    }
                                    var portValue = 0
                                    if (CurrentPort.includes('Countbic')) {
                                        portValue =
                                            nodeDetails[
                                                'valueNum' + CurrentPort
                                            ]
                                    } else if (CurrentPort.includes('bic')) {
                                        portValue =
                                            nodeDetails[
                                                'valuenum' + CurrentPort
                                            ]
                                    } else if (CurrentPort.includes('Bif')) {
                                        portValue =
                                            nodeDetails['value' + CurrentPort]
                                    } else {
                                        if (
                                            Number(
                                                nodeDetails[
                                                    'valuenum' + CurrentPort
                                                ]
                                            )
                                        ) {
                                            portValue =
                                                nodeDetails[
                                                    'valuenum' + CurrentPort
                                                ]
                                        }
                                    }
                                    if (portValue == true) {
                                        portValue = 1
                                    }
                                    if (portValue == false) {
                                        portValue = 0
                                    }
                                    if (!portValue) {
                                        portValue = 0
                                    }
                                    portValue = Number(portValue)
                                    var binary = portValue.toString(2)
                                    var lb = binary.slice(-8)
                                    if (!lb) {
                                        lb = 0
                                    }
                                    var hb = binary.slice(
                                        0,
                                        binary.lastIndexOf(lb)
                                    )
                                    if (!hb) {
                                        hb = 0
                                    }
                                    var hbDec = parseInt(hb, 2).toString(16)
                                    var lbDec = parseInt(lb, 2).toString(16)

                                    decimalValue += '' + parseInt(hbDec, 16)
                                    decimalValue += ':' + parseInt(lbDec, 16)
                                }
                            }
                            // completePortCode+=':'+currentPortCode+':'+decimalValue;
                            output_object[currentPortCode] =
                                ':' + currentPortCode + ':' + decimalValue
                            if (
                                CurrentPort.includes('bid') ||
                                CurrentPort.includes('IOT')
                            ) {
                                if (nodeDetails['value' + CurrentPort]) {
                                    // completePortCode+=':'+BiDataValuesOutput[nodeDetails["value"+CurrentPort]];
                                    output_object[currentPortCode] +=
                                        ':' +
                                        BiDataValuesOutput[
                                            nodeDetails['value' + CurrentPort]
                                        ]
                                } else {
                                    // completePortCode+=':0';
                                    output_object[currentPortCode] += ':0'
                                }
                            }
                        } else if (
                            CurrentPort.includes('Bic') ||
                            CurrentPort.includes('Bif')
                        ) {
                            if (nodeDetails['assign' + CurrentPort]) {
                                currentPortCode =
                                    PortByteNumericalValuesOutput[CurrentPort]
                                decimalValue =
                                    '0' +
                                    ':' +
                                    ([-1, 0].includes(
                                        nodeDetails['value' + CurrentPort]
                                    )
                                        ? 0
                                        : nodeDetails['value' + CurrentPort] ==
                                          undefined
                                        ? 0
                                        : 1)
                                output_object[currentPortCode] =
                                    ':' + currentPortCode + ':' + decimalValue
                                console.log('from gsk cool', output_object)
                            }
                        } else if (CurrentPort.includes('Bid')) {
                            if (nodeDetails['assign' + CurrentPort]) {
                                if (
                                    nodeDetails[
                                        'assign' + CurrentPort + 'Assign'
                                    ]
                                ) {
                                    currentPortCode =
                                        PortByteNumericalValuesOutput[
                                            CurrentPort
                                        ]
                                    let biPort =
                                        nodeDetails[
                                            'value' + CurrentPort + 'Assign'
                                        ]

                                    decimalValue =
                                        bidataPortValue[`${biPort}`] + ':' + '0'

                                    output_object[currentPortCode] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue
                                } else {
                                    currentPortCode =
                                        PortByteNumericalValuesOutput[
                                            CurrentPort
                                        ]
                                    decimalValue =
                                        '0' +
                                        ':' +
                                        nodeDetails['value' + CurrentPort]

                                    output_object[currentPortCode] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue

                                    console.log('from gsk cool', output_object)
                                }
                            }
                        } else if (CurrentPort.includes('Asgn')) {
                            if (
                                nodeDetails['value' + CurrentPort + 'Input'] !=
                                    'default' &&
                                nodeDetails['value' + CurrentPort + 'Output'] !=
                                    'default'
                            ) {
                                let tempInput =
                                    nodeDetails['value' + CurrentPort + 'Input']
                                let tempOutput =
                                    nodeDetails[
                                        'value' + CurrentPort + 'Output'
                                    ]
                                tempInput =
                                    tempInput[0] == 'i' && tempInput[1] == 's'
                                        ? (tempInput = tempInput.replace(
                                              'is',
                                              ''
                                          ))
                                        : tempInput

                                tempOutput =
                                    tempOutput[0] == 'i' && tempOutput[1] == 's'
                                        ? tempOutput.replace('is', '')
                                        : tempOutput
                                tempInput =
                                    tempInput == 'TouchZero'
                                        ? 30
                                        : tempInput == 'TouchTwo'
                                        ? 31
                                        : tempInput == 'TouchThree'
                                        ? 47
                                        : tempInput == 'TouchFour'
                                        ? 33
                                        : PortByteNumericalValuesOutput[
                                              tempInput
                                          ]
                                tempOutput =
                                    PortByteNumericalValuesOutput[tempOutput]
                                currentPortCode =
                                    PortByteNumericalValuesOutput[CurrentPort]
                                decimalValue = tempInput + ':' + tempOutput
                                output_object[currentPortCode] =
                                    ':' + currentPortCode + ':' + decimalValue

                                // debugger
                            }
                        } else if (
                            CurrentPort.includes('Bif') |
                                CurrentPort.includes('Usbtx') ||
                            CurrentPort.includes('BtTx')
                        ) {
                            if (nodeDetails['assign' + CurrentPort]) {
                                currentPortCode =
                                    PortByteNumericalValuesOutput[CurrentPort]
                                decimalValue =
                                    '0' +
                                    ':' +
                                    nodeDetails['value' + CurrentPort]
                                output_object[currentPortCode] =
                                    ':' + currentPortCode + ':' + decimalValue
                            }
                        } else {
                            var currentPortBytes
                            // if (compName == "mp3" && CurrentPort == "F") {
                            //     currentPortBytes = ["mp3"];

                            // }
                            // else if (compName == "dual_splitter" && CurrentPort == "F") {
                            //     currentPortBytes = ["dual_splitter"];

                            // }
                            // else if (compName == "dual_splitter" && CurrentPort == "G") {
                            //     currentPortBytes = ["dual_splitter"];

                            // }
                            // else if (compName == "octa_splitter" && CurrentPort == "B") {
                            //     currentPortBytes = ["octa_splitter"];
                            // }

                            // else {
                            // }
                            if (compName == 'mp3') {
                                CurrentPort = 'Mp3'
                                currentPortBytes =
                                    PortsAndPortbytes[CurrentPort]
                            }

                            if (
                                CurrentPort == 'TouchZeroOutput' ||
                                CurrentPort == 'TouchOneOutput' ||
                                CurrentPort == 'TouchTwoOutput' ||
                                CurrentPort == 'TouchThreeOutput'
                            ) {
                                if (
                                    sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1') &&
                                    ['Ace'].includes(
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        )
                                    )
                                ) {
                                    if (CurrentPort == 'TouchTwoOutput') {
                                        console.log(
                                            '**** SWAPPING THE TOUCH2->3 BEACUSE OF PORT CHANGE ONLY IN 1.0 ******'
                                        )
                                        currentPortBytes = ['TouchThree']
                                    } else if (
                                        CurrentPort == 'TouchThreeOutput'
                                    ) {
                                        console.log(
                                            '**** SWAPPING THE TOUCH3-2 BEACUSE OF PORT CHANGE ONLY IN 1.0 ******'
                                        )
                                        currentPortBytes = ['TouchTwo']
                                    } else {
                                        currentPortBytes =
                                            PortsAndPortbytes[
                                                CurrentPort.replace(
                                                    'Output',
                                                    ''
                                                )
                                            ]
                                    }
                                } else {
                                    currentPortBytes =
                                        PortsAndPortbytes[
                                            CurrentPort.replace('Output', '')
                                        ]
                                }
                            } else {
                                currentPortBytes =
                                    PortsAndPortbytes[CurrentPort]
                            }

                            var i

                            var decimalValue = ''
                            if (
                                JSON.parse(sessionStorage.getItem('peeCeeATR'))
                            ) {
                                if (
                                    sessionStorage
                                        .getItem('deviceVersion')
                                        ?.startsWith('1') &&
                                    (currentPortBytes.includes('S1') ||
                                        currentPortBytes.includes('S2'))
                                ) {
                                    currentPortBytes.map((arrEle, ind) => {
                                        if (arrEle === 'S1') {
                                            currentPortBytes[ind] = 'TouchZero'
                                        }
                                        if (arrEle === 'S2') {
                                            currentPortBytes[ind] = 'TouchOne'
                                        }
                                    })
                                }
                            }

                            console.log(
                                'decimalValue 3*******************decimalValue',
                                decimalValue,
                                currentPortBytes
                            )
                            // (CurrentPort[0] == 'S' &&
                            // parseInt(CurrentPort[1]) > 0 &&
                            // parseInt(CurrentPort[1]) < 17)
                            // here getting access of port assignA1 and create LSB and MSB AND creating outPut partten {:port:LSB:MSB}

                            if (currentPortBytes) {
                                for (i = 0; i < currentPortBytes.length; i++) {
                                    var portByteSelected = currentPortBytes[i]

                                    //  getting the index value
                                    /* A1 = 1  A2 = 2  B1 = 3  B2 = 4 ..........  Buzzer = 21 */
                                    var currentPortCode = 0
                                    // if (compName == 'pcAiTool') {
                                    //     currentPortCode =
                                    //         PortByteNumericalValuesOutput[
                                    //             'ailLED'
                                    //         ]
                                    // } else {
                                    currentPortCode =
                                        PortByteNumericalValuesOutput[
                                            portByteSelected
                                        ]
                                    // }

                                    console.log(
                                        'currentPortCode*******************currentPortCode',
                                        currentPortCode,
                                        portByteSelected
                                    )

                                    var portValue

                                    // if (portByteSelected == "mp3") {
                                    //     portValue = nodeDetails["valueF"] || 0;
                                    // }
                                    // else if (portByteSelected == "dual_splitter") {
                                    //     portValue = nodeDetails["valueF1"] || 0;
                                    // }
                                    // else if (portByteSelected == "dual_splitter") {
                                    //     portValue = nodeDetails["valueF2"] || 0;
                                    // }
                                    // else if (portByteSelected == "dual_splitter") {
                                    //     portValue = nodeDetails["valueG1"] || 0;
                                    // }
                                    // else if (portByteSelected == "dual_splitter") {
                                    //     portValue = nodeDetails["valueG2"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueB1"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueB2"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueB3"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueB4"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueC1"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueC2"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueC3"] || 0;
                                    // }
                                    // else if (portByteSelected == "octa_splitter") {
                                    //     portValue = nodeDetails["valueC4"] || 0;
                                    // }
                                    // else {
                                    console.log(
                                        'portByteSelected',
                                        portByteSelected,
                                        nodeDetails,
                                        portByteSelected
                                    )

                                    portValue =
                                        nodeDetails[
                                            'value' + portByteSelected
                                        ] || 0

                                    if (
                                        ((sessionStorage.getItem(
                                            'connectedDevice'
                                        ) === 'Humanoid' &&
                                            ['1', '2'].includes(
                                                (sessionStorage.getItem(
                                                    'deviceVersion'
                                                ) || '0.0.0')[0]
                                            )) ||
                                            ['Hexapod', 'Klaw'].includes(
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                )
                                            )) &&
                                        nodeDetails[
                                            'value' + portByteSelected
                                        ] === undefined
                                    ) {
                                        if (portByteSelected === 'ServoTime') {
                                            portValue =
                                                DEFAULTSERVOVALUES[
                                                    connectedDevice.toUpperCase() +
                                                        sessionStorage.getItem(
                                                            'deviceVersion'
                                                        )[0]
                                                ]['T']
                                        } else {
                                            portValue = exComPortValue(
                                                portByteSelected,
                                                compName
                                            )
                                        }
                                    }

                                    if (
                                        portByteSelected == 'TouchZero' ||
                                        portByteSelected == 'TouchOne' ||
                                        portByteSelected == 'TouchTwo' ||
                                        portByteSelected == 'TouchThree'
                                    ) {
                                        portValue =
                                            nodeDetails[
                                                'value' +
                                                    `${portByteSelected}Output`
                                            ]
                                    }

                                    if (portByteSelected == 'Mp3') {
                                        portValue = nodeDetails['valueB1']
                                    }
                                    //PCv1.0

                                    if (
                                        JSON.parse(
                                            sessionStorage.getItem('peeCeeATR')
                                        ) &&
                                        sessionStorage
                                            .getItem('deviceVersion')
                                            ?.startsWith('1')
                                    ) {
                                        if (
                                            ['S1', 'S2'].includes(CurrentPort)
                                        ) {
                                            if (
                                                nodeDetails[
                                                    `assign${CurrentPort}`
                                                ]
                                            ) {
                                                if (
                                                    compName ===
                                                    'servo_motor_360'
                                                ) {
                                                    portValue =
                                                        100 +
                                                        (nodeDetails[
                                                            `value${CurrentPort}`
                                                        ] || 0)
                                                } else {
                                                    portValue =
                                                        nodeDetails[
                                                            `value${CurrentPort}`
                                                        ]
                                                }
                                            }
                                        }
                                    }

                                    if (
                                        (([
                                            'Humanoid',
                                            'Hexapod',
                                            'Ace',
                                        ].includes(
                                            sessionStorage.getItem(
                                                'connectedDevice'
                                            )
                                        ) &&
                                            ['1', '2'].includes(
                                                sessionStorage.getItem(
                                                    'deviceVersion'
                                                )[0]
                                            )) ||
                                            ['Roboki', 'Atr'].includes(
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                )
                                            )) &&
                                        portByteSelected.includes('Eye', 0)
                                    ) {
                                        portValue = []

                                        portValue[0] =
                                            nodeDetails[
                                                `valueEyeR${portByteSelected.slice(
                                                    3
                                                )}`
                                            ] || 0
                                        portValue[1] =
                                            nodeDetails[
                                                `valueEyeG${portByteSelected.slice(
                                                    3
                                                )}`
                                            ] || 0
                                        portValue[2] =
                                            nodeDetails[
                                                `valueEyeB${portByteSelected.slice(
                                                    3
                                                )}`
                                            ] || 0
                                        // if (parseInt(portByteSelected.slice(3)) % 1 == 1) {
                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeR${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeG${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeB${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        //   if (
                                        //     connectionsList[
                                        //       `assignEye${parseInt(portByteSelected.slice(3)) + 1}`
                                        //     ]
                                        //   ) {
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeR${
                                        //           parseInt(portByteSelected.slice(3)) + 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeG${
                                        //           parseInt(portByteSelected.slice(3)) + 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeB${
                                        //           parseInt(portByteSelected.slice(3)) + 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //   } else {
                                        //     portValue.push(0);
                                        //     portValue.push(0);
                                        //     portValue.push(0);
                                        //   }
                                        // } else {
                                        //   if (
                                        //     connectionsList[
                                        //       `assignEye${parseInt(portByteSelected.slice(3)) - 1}`
                                        //     ]
                                        //   ) {
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeR${
                                        //           parseInt(portByteSelected.slice(3)) - 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeG${
                                        //           parseInt(portByteSelected.slice(3)) - 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //     portValue.push(
                                        //       nodeDetails[
                                        //         `valueEyeB${
                                        //           parseInt(portByteSelected.slice(3)) - 1
                                        //         }`
                                        //       ] || 0
                                        //     );
                                        //   } else {
                                        //     portValue.push(0);
                                        //     portValue.push(0);
                                        //     portValue.push(0);
                                        //   }

                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeR${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeG${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        //   portValue.push(
                                        //     nodeDetails[`valueEyeB${portByteSelected.slice(3)}`] ||
                                        //       0
                                        //   );
                                        // }
                                    }

                                    //for roboki eye
                                    // if (
                                    //     sessionStorage.getItem(
                                    //         'connectedDevice'
                                    //     ) === 'Roboki' &&
                                    //     portByteSelected.includes('Eye', 0) &&
                                    //     compName
                                    // ) {
                                    //     console.log('LLLLLLLLLLLLL')
                                    //     portValue = []
                                    //     portValue[0] =
                                    //         nodeDetails[
                                    //             `valueEyeR${portByteSelected.slice(
                                    //                 3
                                    //             )}`
                                    //         ] || 0
                                    //     portValue[1] =
                                    //         nodeDetails[
                                    //             `valueEyeG${portByteSelected.slice(
                                    //                 3
                                    //             )}`
                                    //         ] || 0
                                    //     portValue[2] =
                                    //         nodeDetails[
                                    //             `valueEyeB${portByteSelected.slice(
                                    //                 3
                                    //             )}`
                                    //         ] || 0
                                    // }

                                    //end
                                    //humanoid
                                    if (portByteSelected == 'ZingMp3') {
                                        portValue = nodeDetails['valueZingMp3']
                                    }
                                    if (portByteSelected == 'RandomAction') {
                                        portValue =
                                            nodeDetails['valueCustomAction']
                                    }

                                    console.log(portValue, 'portValue')
                                    if (portByteSelected.includes('RGBComp')) {
                                        portValue = []
                                        portValue.push(
                                            nodeDetails[
                                                `value${portByteSelected}R`
                                            ] || 0
                                        )
                                        portValue.push(
                                            nodeDetails[
                                                `value${portByteSelected}G`
                                            ] || 0
                                        )
                                        portValue.push(
                                            nodeDetails[
                                                `value${portByteSelected}B`
                                            ] || 0
                                        )
                                    }
                                    //console.log("nodeDetai", nodeDetails["value" + portByteSelected], portByteSelected, portValue)
                                    // }
                                    if (portValue === true) {
                                        portValue = 1
                                    }
                                    if (portValue === false) {
                                        portValue = 0
                                    }
                                    if (!portValue) {
                                        portValue = 0
                                    }
                                    //for klaw each PWM motor client send the portValue in between 0 to 300,
                                    // when we are send the port value in klaw its must convert in between 0 to 180
                                    if (
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        ) == 'Klaw' &&
                                        portByteSelected.includes('PW')
                                    ) {
                                        portValue = Math.round(portValue * 0.6)
                                    }

                                    //for Atr device motor value convert the absulate value
                                    if (
                                        portByteSelected.includes('AtrMotor') &&
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        ) == 'Atr'
                                    ) {
                                        portValue = Math.abs(portValue)
                                    }
                                    console.log(
                                        portValue,
                                        portByteSelected,
                                        'portValue2.0'
                                    )
                                    if (
                                        portByteSelected == 'Buzzer' ||
                                        portByteSelected == 'RGBLEDR' ||
                                        portByteSelected == 'RGBLEDG' ||
                                        portByteSelected == 'RGBLEDB' ||
                                        //
                                        portByteSelected == 'LeftEyeR' ||
                                        portByteSelected == 'LeftEyeG' ||
                                        portByteSelected == 'LeftEyeB' ||
                                        portByteSelected == 'RightEyeR' ||
                                        portByteSelected == 'RightEyeG' ||
                                        portByteSelected == 'RightEyeB' ||
                                        portByteSelected == 'BuzzerFrequency' ||
                                        portByteSelected == 'BuzzerTone' ||
                                        portByteSelected == 'SmileOne' ||
                                        portByteSelected == 'SmileTwo' ||
                                        portByteSelected == 'SmileThree' ||
                                        portByteSelected == 'SmileFour' ||
                                        portByteSelected == 'TouchZero' ||
                                        portByteSelected == 'TouchOne' ||
                                        portByteSelected == 'TouchTwo' ||
                                        portByteSelected == 'TouchThree' ||
                                        portByteSelected == 'Mp3' ||
                                        // humanoid
                                        portByteSelected == 'Attention' ||
                                        portByteSelected == 'Forward' ||
                                        portByteSelected == 'Backward' ||
                                        portByteSelected == 'Left' ||
                                        portByteSelected == 'Right' ||
                                        portByteSelected == 'Wave' ||
                                        portByteSelected == 'Bow' ||
                                        portByteSelected == 'WakeUpFront' ||
                                        portByteSelected == 'WakeUpBack' ||
                                        portByteSelected == 'WakeUpBack2' ||
                                        portByteSelected == 'Squat' ||
                                        portByteSelected == 'BoxForward' ||
                                        portByteSelected == 'LeftKick' ||
                                        portByteSelected == 'RightKick' ||
                                        portByteSelected == 'BreakDance' ||
                                        portByteSelected == 'SlideToLeft' ||
                                        portByteSelected == 'SlideToRight' ||
                                        portByteSelected == 'HookLeft' ||
                                        portByteSelected == 'HookRight' ||
                                        portByteSelected == 'PushUp' ||
                                        portByteSelected == 'SitUP' ||
                                        portByteSelected == 'Salute' ||
                                        portByteSelected == 'CustomAction' ||
                                        portByteSelected == 'RandomAction' ||
                                        portByteSelected == 'LeftShoot' ||
                                        portByteSelected == 'RightShoot' ||
                                        portByteSelected == 'HeadServo' ||
                                        portByteSelected == 'SimpleServo' ||
                                        //hexapod
                                        portByteSelected == 'Dance' ||
                                        portByteSelected == 'Attack' ||
                                        portByteSelected == 'Booting' ||
                                        portByteSelected == 'Fight' ||
                                        portByteSelected == 'Defence' ||
                                        portByteSelected == 'TurnAround' ||
                                        portByteSelected == 'Greetings' ||
                                        portByteSelected.includes('AtrMotor') ||
                                        portByteSelected == 'ServoTime' ||
                                        (portByteSelected[0] == 'S' &&
                                            parseInt(portByteSelected[1]) > 0 &&
                                            parseInt(portByteSelected[1]) <
                                                17) ||
                                        (portByteSelected.includes('PW') &&
                                            parseInt(portByteSelected[2]) > 0 &&
                                            parseInt(portByteSelected[2]) <
                                                7) ||
                                        portByteSelected == 'ZingMp3'
                                    ) {
                                        if (
                                            ['Humanoid', 'Hexapod'].includes(
                                                connectedDevice
                                            )
                                        ) {
                                            if (portByteSelected == 'ZingMp3') {
                                                portValue = getFileNameAsci({
                                                    key: portByteSelected,
                                                    nodeDetails,
                                                })
                                            }

                                            Object.entries(
                                                GROUPACTIONS[
                                                    `${connectedDevice.toUpperCase()}`
                                                ]
                                            ).map((action) => {
                                                let chk =
                                                    action[1].name.replace(
                                                        /\s+/g,
                                                        ''
                                                    )
                                                if (
                                                    chk === 'CustomAction' &&
                                                    chk == portByteSelected &&
                                                    !['1', '2'].includes(
                                                        (sessionStorage.getItem(
                                                            'deviceVersion'
                                                        ) || '0.0.0')[0]
                                                    )
                                                ) {
                                                    portValue = Number(
                                                        nodeDetails[
                                                            'valueCustomAction'
                                                        ]
                                                    )
                                                    return
                                                }
                                                if (
                                                    chk === 'CustomAction' &&
                                                    chk == portByteSelected &&
                                                    ['1', '2'].includes(
                                                        (sessionStorage.getItem(
                                                            'deviceVersion'
                                                        ) || '0.0.0')[0]
                                                    )
                                                ) {
                                                    portValue = getFileNameAsci(
                                                        {
                                                            key: chk,
                                                            nodeDetails,
                                                        }
                                                    )

                                                    return
                                                }
                                                console.log(
                                                    portByteSelected,
                                                    chk == portByteSelected,
                                                    action[1],
                                                    portValue
                                                )
                                                if (chk == portByteSelected) {
                                                    portValue = action[1].value
                                                    return
                                                }
                                            })
                                        }

                                        // switch (portByteSelected) {
                                        //   case "Attention":
                                        //     portValue = 0;
                                        //     break;
                                        //   case "Forward":
                                        //     portValue = 1;
                                        //     break;
                                        //   case "Backward":
                                        //     portValue = 2;
                                        //     break;
                                        //   case "Mourn":
                                        //     portValue = 3;
                                        //     break;
                                        //   case "left":
                                        //     portValue = 4;
                                        //     break;
                                        //   case "Right":
                                        //     portValue = 5;
                                        //     break;
                                        //   case "left":
                                        //     portValue = 4;
                                        //     break;

                                        // }

                                        if (
                                            [
                                                'CustomAction',
                                                'ZingMp3',
                                            ].includes(portByteSelected) &&
                                            [('1', '2')].includes(
                                                (sessionStorage.getItem(
                                                    'deviceVersion'
                                                ) || '0.0.0')[0]
                                            ) &&
                                            ['Humanoid', 'Hexapod'].includes(
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                )
                                            )
                                        ) {
                                            decimalValue = portValue //{:1:0:100:}
                                        } else {
                                            var binary = portValue.toString(2)

                                            var lb = binary.slice(-8)

                                            if (!lb) {
                                                lb = 0
                                            }

                                            var hb = binary.slice(
                                                0,
                                                binary.lastIndexOf(lb)
                                            )
                                            if (!hb) {
                                                hb = 0
                                            }
                                            var hbDec = parseInt(
                                                hb,
                                                2
                                            ).toString(16)
                                            var lbDec = parseInt(
                                                lb,
                                                2
                                            ).toString(16)

                                            console.log(
                                                'hbDec',
                                                hbDec,
                                                'lbDec',
                                                lbDec
                                            )

                                            // decimalValue is final calculation of LSB and MSB
                                            decimalValue +=
                                                '' + parseInt(hbDec, 16)
                                            decimalValue +=
                                                ':' + parseInt(lbDec, 16)
                                        }
                                        console.log(
                                            'decimalValue 1 ----------------------------->',
                                            decimalValue
                                        )
                                    } else if (
                                        portByteSelected == 'OLEDOne' ||
                                        portByteSelected == 'OLEDTwo' ||
                                        portByteSelected == 'OLEDThree' ||
                                        portByteSelected == 'OLEDFour'
                                    ) {
                                        var charCodeArr = []

                                        if (portByteSelected == 'OLEDFour') {
                                            let code = 31
                                            if (portValue === 0) portValue = 0
                                            else
                                                portValue =
                                                    portValue.slice(0, 2) ===
                                                    'is'
                                                        ? portValue.slice(2)
                                                        : portValue
                                            code =
                                                PortByteNumericalValuesIf[
                                                    `${portValue}`
                                                ]

                                            charCodeArr.push(0)
                                            charCodeArr.push(parseInt(code))
                                            decimalValue = charCodeArr
                                                .toString()
                                                .replaceAll(',', ':')
                                        } else {
                                            for (
                                                let i = 0;
                                                i < portValue.length;
                                                i++
                                            ) {
                                                let code =
                                                    portValue.charCodeAt(i)

                                                charCodeArr.push(code)
                                            }
                                            for (
                                                let i = charCodeArr.length;
                                                i < 16;
                                                i++
                                            ) {
                                                charCodeArr.push(32)
                                            }
                                            decimalValue = charCodeArr
                                                .toString()
                                                .replaceAll(',', ':')
                                        }
                                    } else if (
                                        portByteSelected.includes('RGBComp')
                                    ) {
                                        var charCodeArr = []
                                        for (
                                            let i = 0;
                                            i < portValue.length;
                                            i++
                                        ) {
                                            let code = portValue[i]
                                            charCodeArr.push(code)
                                        }

                                        decimalValue = charCodeArr
                                            .toString()
                                            .replaceAll(',', ':')
                                    }
                                    //PCv1.0
                                    else if (
                                        portByteSelected.includes('Eye', 0)
                                    ) {
                                        var charCodeArr = []
                                        for (
                                            let i = 0;
                                            i < portValue.length;
                                            i++
                                        ) {
                                            let code = portValue[i]
                                            charCodeArr.push(code)
                                        }

                                        decimalValue = charCodeArr
                                            .toString()
                                            .replaceAll(',', ':')
                                    }
                                    //pc Ai 1 tool
                                    else if (
                                        ['ai_LEDA1', 'ai_audioA1'].includes(
                                            portByteSelected
                                        )
                                    ) {
                                        if ('ai_audioA1' == portByteSelected) {
                                            let len = portValue.length
                                            let aiAudioAsciString = len
                                            for (let i = 0; i < len; i++) {
                                                aiAudioAsciString +=
                                                    ':' +
                                                    portValue.charCodeAt(i)
                                            }
                                            decimalValue = aiAudioAsciString
                                        } else {
                                            decimalValue = '0:' + portValue
                                        }
                                        // var charCodeArr = []
                                    }
                                    //end
                                    else {
                                        var compName =
                                            connectionsList[portByteSelected]

                                        console.log(
                                            compName,
                                            portByteSelected,
                                            connectionsList,
                                            'compName3.0'
                                        )
                                        let tempPortValue = portValue
                                        /*
     CHANGING VALUE geared_motor,mini_geared_motor,dc_motor 
     RANGE VALUE For these motor are Frontend : -20 - +20
     in Backend value: 0-40  i.e( -20=0,-19=1,-18=2.............,20=40)   
    */
                                        if (
                                            [
                                                'geared_motor',
                                                'mini_geared_motor',
                                                'dc_motor',
                                                'dynamex_motor',
                                            ].includes(compName)
                                        ) {
                                            portValue = Math.abs(portValue)
                                            // if (Math.sign(portValue) == -1) {
                                            //     portValue =
                                            //         20 - Math.abs(portValue)

                                            //     //-ve value
                                            // } else {
                                            //     // +ve value
                                            //     portValue = 20 + portValue
                                            // }
                                        }
                                        // GENERATING LSB MSB ok
                                        if (
                                            CodeGenerationRangeValues &&
                                            CodeGenerationRangeValues[
                                                portByteSelected
                                            ] &&
                                            CodeGenerationRangeValues[
                                                portByteSelected
                                            ][compName]
                                        ) {
                                            portValue =
                                                CodeGenerationRangeValues[
                                                    portByteSelected
                                                ][compName](portValue)

                                            console.log(
                                                'portValue----------------------------->',
                                                portValue,
                                                typeof portValue
                                            )

                                            // calculating LSB  MSB

                                            var binary = portValue.toString(2)
                                            var lb = binary.slice(-8)

                                            if (!lb) {
                                                lb = 0
                                            }

                                            var hb = binary.slice(
                                                0,
                                                binary.lastIndexOf(lb)
                                            )
                                            if (!hb) {
                                                hb = 0
                                            }
                                            var hbDec = parseInt(
                                                hb,
                                                2
                                            ).toString(16)
                                            var lbDec = parseInt(
                                                lb,
                                                2
                                            ).toString(16)

                                            console.log(
                                                'hbDec',
                                                hbDec,
                                                'lbDec',
                                                lbDec
                                            )

                                            // decimalValue is final calculation of LSB and MSB
                                            decimalValue +=
                                                '' + parseInt(hbDec, 16)
                                            decimalValue +=
                                                ':' + parseInt(lbDec, 16)
                                            if (
                                                ([
                                                    'Humanoid',
                                                    'Hexapod',
                                                ].includes(
                                                    sessionStorage.getItem(
                                                        'connectedDevice'
                                                    )
                                                ) ||
                                                    (sessionStorage.getItem(
                                                        'connectedDevice'
                                                    ) === 'Klaw' &&
                                                        !['F1', 'F2'].includes(
                                                            portByteSelected
                                                        ))) &&
                                                [
                                                    'geared_motor',
                                                    'mini_geared_motor',
                                                    'dc_motor',
                                                ].includes(compName)
                                            ) {
                                                if (
                                                    ['1', '2'].includes(
                                                        sessionStorage.getItem(
                                                            'deviceVersion'
                                                        )[0]
                                                    ) ||
                                                    connectedDevice == 'Hexapod'
                                                ) {
                                                    if (tempPortValue > 0) {
                                                        decimalValue =
                                                            '0:0:' +
                                                            PortByteNumericalValuesOutput[
                                                                portByteSelected[0] +
                                                                    '2'
                                                            ] +
                                                            ':0:' +
                                                            portValue
                                                    }
                                                    if (tempPortValue <= 0) {
                                                        decimalValue =
                                                            '0:' +
                                                            portValue +
                                                            ':' +
                                                            PortByteNumericalValuesOutput[
                                                                portByteSelected[0] +
                                                                    '2'
                                                            ] +
                                                            ':0:0'
                                                    }
                                                } else {
                                                    if (tempPortValue > 0) {
                                                        decimalValue =
                                                            '0:0:' +
                                                            PortByteNumericalValuesOutput[
                                                                portByteSelected[0] +
                                                                    '2'
                                                            ] +
                                                            ':0:' +
                                                            portValue
                                                    }
                                                    if (tempPortValue <= 0) {
                                                        decimalValue =
                                                            '0:' +
                                                            portValue +
                                                            ':' +
                                                            PortByteNumericalValuesOutput[
                                                                portByteSelected[0] +
                                                                    '2'
                                                            ] +
                                                            ':0:0'
                                                    }
                                                }
                                            }

                                            console.log(
                                                'decimalValue 1 ----------------------------->',
                                                decimalValue
                                            )
                                        }
                                        if (
                                            ['Humanoid', 'Hexapod'].includes(
                                                sessionStorage.getItem(
                                                    'connectedDevice'
                                                )
                                            )
                                        ) {
                                            if (
                                                ['1', '2'].includes(
                                                    sessionStorage.getItem(
                                                        'deviceVersion'
                                                    )[0]
                                                ) ||
                                                connectedDevice == 'Hexapod'
                                            ) {
                                                if (
                                                    portByteSelected == 'M2' &&
                                                    connectionsList[
                                                        'is' + portByteSelected
                                                    ]
                                                ) {
                                                    tempPortValue > 0
                                                        ? (decimalValue =
                                                              '0:0:' +
                                                              PortByteNumericalValuesOutput[
                                                                  'M4'
                                                              ] +
                                                              ':0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                        : (decimalValue =
                                                              '0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ) +
                                                              ':' +
                                                              PortByteNumericalValuesOutput[
                                                                  'M4'
                                                              ] +
                                                              ':0:0')
                                                }
                                                if (
                                                    portByteSelected == 'M1' &&
                                                    connectionsList[
                                                        'is' + portByteSelected
                                                    ]
                                                ) {
                                                    tempPortValue > 0
                                                        ? (decimalValue =
                                                              '0:0:' +
                                                              PortByteNumericalValuesOutput[
                                                                  'M2'
                                                              ] +
                                                              ':0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                        : (decimalValue =
                                                              '0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ) +
                                                              ':' +
                                                              PortByteNumericalValuesOutput[
                                                                  'M2'
                                                              ] +
                                                              ':0:0')
                                                }
                                            } else {
                                                if (
                                                    portByteSelected == 'M2' &&
                                                    connectionsList[
                                                        'is' + portByteSelected
                                                    ]
                                                ) {
                                                    tempPortValue > 0
                                                        ? (decimalValue =
                                                              '0:0:' +
                                                              PortByteNumericalValuesOutput[
                                                                  'B2'
                                                              ] +
                                                              ':0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                        : (decimalValue =
                                                              '0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                }
                                                if (
                                                    portByteSelected == 'M1' &&
                                                    connectionsList[
                                                        'is' + portByteSelected
                                                    ]
                                                ) {
                                                    tempPortValue > 0
                                                        ? (decimalValue =
                                                              '0:0:' +
                                                              PortByteNumericalValuesOutput[
                                                                  'SimpleServo'
                                                              ] +
                                                              ':0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                        : (decimalValue =
                                                              '0:' +
                                                              Math.abs(
                                                                  portValue
                                                              ))
                                                }
                                            }
                                        }
                                    }

                                    //ycode deleted for tern+
                                }
                            }
                            // completePortCode += ':' + currentPortCode + ':' + decimalValue;
                            console.log(
                                'decimalValue 2----------------------------->',
                                currentPortCode,
                                decimalValue
                            )

                            /////////////PLAYC-731///////////////
                            //bytes order swaping for rgb////////
                            // if (output_object[31]) {
                            // if (
                            //     sessionStorage.getItem('connectedDevice') ==
                            //         'Ace' &&
                            //     sessionStorage
                            //         .getItem('deviceVersion')
                            //         ?.startsWith('1')
                            // ) {
                            //     console.log(
                            //         'code 55 error',
                            //         output_object,
                            //         currentPortCode
                            //     )
                            //     if (currentPortCode === '87') {
                            //         output_object[31] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '31') {
                            //         output_object[87] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '88') {
                            //         output_object[32] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '32') {
                            //         output_object[88] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '89') {
                            //         output_object[33] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '33') {
                            //         output_object[89] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '90') {
                            //         output_object[47] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     } else if (currentPortCode === '47') {
                            //         output_object[90] =
                            //             ':' +
                            //             currentPortCode +
                            //             ':' +
                            //             decimalValue
                            //     }
                            //     //Bug END
                            // }
                            //////// Swapping is happpening because of the overlapping on the OLED screen/////////
                            if (decimalValue !== '0:NaN')
                                if (currentPortCode === '51') {
                                    output_object[48] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue
                                } else if (currentPortCode === '48') {
                                    output_object[51] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue
                                } else if (
                                    ((['1', '2'].includes(
                                        (sessionStorage.getItem(
                                            'deviceVersion'
                                        ) || '0.0.0')[0]
                                    ) &&
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        ) == 'Humanoid') ||
                                        sessionStorage.getItem(
                                            'connectedDevice'
                                        ) == 'Hexapod') &&
                                    currentPortCode === '20'
                                ) {
                                    output_object['21'] =
                                        ':' + '21' + ':' + decimalValue
                                } else {
                                    output_object[currentPortCode] =
                                        ':' +
                                        currentPortCode +
                                        ':' +
                                        decimalValue
                                }
                            console.log(output_object, 'output_object')
                        }
                    }
                }
            }

            console.log(
                'MAX_OUTPUT_PORT_CODE',
                MAX_OUTPUT_PORT_CODE,
                output_object[currentPortCode]
            )

            for (var index = 0; index <= MAX_OUTPUT_PORT_CODE; index++) {
                if (output_object['' + index]) {
                    completePortCode += output_object['' + index]
                }
            }

            console.log('completePortCode', completePortCode)

            if (completePortCode) {
                completePortCode = completePortCode.replace(
                    new RegExp('true', 'g'),
                    '1'
                )
                completePortCode = completePortCode.replace(
                    new RegExp('false', 'g'),
                    '0'
                )
            }

            console.log('TYPE ::', type)
            codeForCurrentNode =
                nodeDefaultCodes[type] + completePortCode + ':};'
            console.log(
                'codeForCurrentNode 1 ----------------------------->',
                codeForCurrentNode
            )
        } else {
            return ''
        }

        console.log(
            'codeForCurrentNode 2 ----------------------------->',
            codeForCurrentNode
        )

        return codeForCurrentNode
    }

    var UserProgram = uploadData.logic

    console.log('UserProgram', UserProgram)

    //   UserProgram.map((val, index) => {
    //     console.log("val----", val);
    //     console.log("val2----", Object.keys(val)[0]);
    //     let type = Object.keys(val)[0];
    //     if (type == "sensor") {
    //       countIf++;
    //     }
    //   });

    //   console.log("COUNT_IF", countIf);

    var k
    var completeLogicProgram = ''

    // PROGRAM BYTES
    for (k = 0; k < UserProgram.length; k++) {
        for (var eachNode in UserProgram[k]) {
            console.log('UserProgram[k]', UserProgram[k][eachNode])

            completeLogicProgram += codeForNodes(
                k,
                eachNode,
                UserProgram[k][eachNode]
            )
        }
    }

    console.log('completeLogicProgram', completeLogicProgram)
    // var a = completeLogicProgram;
    console.log(
        'completeLogicProgram KI Length',
        completeLogicProgram.replace(/ENDRST/g, 'END')
    )

    if (
        completeLogicProgram.slice(completeLogicProgram.length - 6) === 'ENDRST'
    ) {
        Uploadprogram += completeLogicProgram.replace(/ENDRST/g, 'END')
    } else {
        Uploadprogram += completeLogicProgram
    }
    // Uploadprogram += completeLogicProgram;
    uploadProgram = Uploadprogram

    console.log(uploadProgram, 'uploadProgram')

    return Uploadprogram
}

if (require.main === module) {
} else {
    console.log('100')
}
export { genCodeString as genCode, stringToByteStream as stringToByte }

//A1A2B1B2C1C2D1D2E1E2F1F2G1    G2H1H2I1I2SmileOneSmileTwoSmileThreeSmileFourbuzzer     EyeFour_in_one_sensorMicTouchZeroTouchOneTouchThreeTemperatureIOT1IOT2    AccelerometerTouchTwoMp3OLEDRGB  BatteryGyro
