import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import ActionCard from './component/actioncards/ActionCard.js'
import remoteStyle from './remote.module.css'
import klawImage from '../../../../../Assets/klaw/toy-robotic-arm@2x.png'
import unicodeToChar from '../../../../../utils/unicodeToChar.js'
import MainHeader from '../../../../ReusableComponents/Header/MainHeader.jsx'
import RemSlider from '../../../../ReusableComponents/RemSlider/RemSlider.js'

import { sendBytes } from '../../../../ReusableComponents/ByteTransfer/byteTransfer.js'
import {
    KlawByteCodes,
    ZingByteCodes,
} from '../../../../ReusableComponents/ByteTransfer/byteCodes.js'
import { useRef } from 'react'
import cacheAssets from '../../../../../utils/cacheAssets.js'
import renderImage from '../../../../../source/importImg.js'
import { cond } from 'lodash'
import KlawArmPlay from './component/KlawArmPlay/KlawArmPlay.js'
import DialogModal from '../../../../ReusableComponents/DialogModal/DialogModal.jsx'
import { languageWords } from '../../../../../utils/langaugeSupport.js'

const ACTIONS1 = [
    { name: 'Pick', action: 'Pick' },
    { name: 'Pick left Drop right', action: 'Pick_left_drop_right' },
    { name: 'Pick right Drop left', action: 'Pick_right_drop_left' },
    { name: 'Circle', action: 'Circle' },
    { name: 'Wave', action: 'Wave' },
    { name: 'Scribble', action: 'Scribble' },
]

const ORIGINAL_ACTION_STATE_KLAW = {
    isPick: false,
    isPick_left_drop_right: false,
    isPick_right_drop_left: false,
    isCircle: false,
    isWave: false,
    isScribble: false,
}

const Remote = ({ webSerial, worker, device }) => {
    let history = useHistory()

    const [actionState, setActionState] = useState(ORIGINAL_ACTION_STATE_KLAW)
    const [isBtnEnabled, setIsBtnEnabled] = useState(true)
    const [continousAction, setContinousAction] = useState()
    const [intervalId, setIntervalId] = useState(false)
    const isInitialRender = useRef(true)
    const [toggle, setToggle] = useState(true)
    const [pc, setPC] = useState(true)
    const [arm, setArm] = useState(false)
    const [waiting, setWaiting] = useState(null)
    const [showCalibratedPopup, setShowCalibratedPopup] = useState(false)

    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const zingVersion = sessionStorage.getItem('zingVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const languageName = device.language

    // setting isClicked --> false initially
    useEffect(() => {
        sessionStorage.setItem('flags', false)
    }, [])

    const writeData_v2 = (data) => {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })
        setWaiting(
            setTimeout(() => {
                setIsBtnEnabled(true)
                setActionState({
                    ...ORIGINAL_ACTION_STATE_KLAW,
                })
            }, 12000)
        )
    }

    // Handling click events
    const handleAction = async (action) => {
        if (webSerial.isConnected) {
            setIsBtnEnabled(false)
            console.log(`Klaw is ${action}ing...`)

            let newActionState = { ...actionState }
            newActionState[`is${action}`] = true
            console.log('Klaw action state', newActionState)
            setActionState({ ...newActionState })
            writeData_v2(KlawByteCodes[action.toUpperCase()])
        }
    }

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderImage('remoteH'),
            renderImage('remoteH1'),
            renderImage('remoteH2'),
            renderImage('Remt1'),
            renderImage('Remt2'),
            renderImage('Remt3'),
            renderImage('Remt4'),
            renderImage('Remt5'),
            renderImage('Remt6'),
            renderImage('PlayH1'),
            renderImage('PlayH2'),
            renderImage('PlayH3'),
            renderImage('PlayH4'),
            renderImage('PlayH5'),
            renderImage('PlayH6'),
            renderImage('PlayH7'),
            renderImage('PlayH8'),
            renderImage('PlayH9'),
        ]
        cacheAssets(assetsToBeCached)

        if (webSerial.isConnected) {
            const PLAY = [
                'P'.charCodeAt(),
                'L'.charCodeAt(),
                'A'.charCodeAt(),
                'Y'.charCodeAt(),
            ]

            writePort(PLAY)
            setShowCalibratedPopup(true)
            setTimeout(() => setShowCalibratedPopup(false), 10000)
        }
    }, [webSerial.isConnected])

    async function writePort(data) {
        worker.postMessage({
            type: 'writeArray',
            value: data,
        })
        console.log('PlayBytes Sent')
    }
    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read') {
                let combinedResult = e.data.value
                if (
                    combinedResult.includes('Rdone') ||
                    combinedResult.includes('RDONE')
                ) {
                    // return combinedResult
                    setIsBtnEnabled(true)
                    setActionState({ ...ORIGINAL_ACTION_STATE_KLAW })
                    clearTimeout(waiting)
                }
                if (combinedResult.includes('PLAYDONE')) {
                    setShowCalibratedPopup(false)
                }
            }
        }
        worker.addEventListener('message', messageHandler)

        // return worker.removeEventListener('message', messageHandler)
    }, [])
    return (
        <>
            <DialogModal
                show={showCalibratedPopup}
                text="Initializing Klaw, Please wait."
            />
            <div className={remoteStyle['remote-container']}>
                <MainHeader
                    title={languageWords[languageName]?.Joystick || 'Joystick'}
                    showBluetoothBtn={true}
                    // helper={""}
                    goBack={() => {
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/introduction')
                    }}
                />

                {/* {toggle ? (
                <div style={{ height: '80%' }}>
                    <div className={remoteStyle['sub-container']}>
                        <div></div>

                        <div className={remoteStyle['klaw-container']}>
                            <img src={klawImage} />
                        </div>
                      

                        <div className={remoteStyle['action-container-klaw']}>
                            <div
                                className={remoteStyle['actionBar-item-1-klaw']}
                            >
                                {ACTIONS1.map((key) => (
                                    <ActionCard
                                        key={key.action}
                                        name={key.name}
                                        action={key.action}
                                        isOn={actionState[`is${key.action}`]}
                                        isEnabled={isBtnEnabled}
                                        handleAction={handleAction}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : ( */}
                <KlawArmPlay />
                {/* )} */}

                {/* <div className={remoteStyle['toggle-container']}>
                {' '}
                <img src={renderImage('sideToggle_bg')} />
                <img
                    src={renderImage(
                        pc ? 'PC_ButtonActive' : 'PC_ButtonInActive'
                    )}
                    style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        marginTop: pc ? '-25%' : '-40%',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setToggle(!toggle)
                        setPC(!pc)
                        setArm(!arm)
                    }}
                />
                <img
                    src={renderImage(
                        arm ? 'ArmButtonActive' : 'ArmButtonInActive'
                    )}
                    style={{
                        position: 'absolute',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        marginTop: '40%',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setToggle(!toggle)
                        setPC(!pc)
                        setArm(!arm)
                    }}
                />
            </div> */}
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(Remote)
