import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import selectbarY from '../../../Assets/Bisoft_UI/AppMode/selectbar@2.png'

import './ColorTrain.css'
import AiModeFooter from './AiModeFooter/AiModeFooter'
import NavBar from '../NavBar'
import renderImage from '../../../source/importImg'
import AiColorSlider from './AiColorSlider'
import { FFT } from '@tensorflow/tfjs'
import { appAiBytesGeneration } from './AppModeAiBytesGeneration'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
import AiColorSlider2 from './AIColorSlider2'

function ColorFaceTrain(props) {
    const history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const pathName = window.location.pathname
    const selectedTranMode = pathName.split('/')[1]
    const [isNameUpload, setIsNameUpload] = useState(false)
    const [uploadPopupText, setUploadPopupText] = useState('')
    const [uploadPopup, setUploadPopup] = useState(false)

    const sendDataAsPacket = useRef({ byteSend: false, responseMessage: '' })
    const focusInd =
        pathName === '/colorTrain'
            ? props.colorTrain.focusInd
            : props.faceTrain.focusInd
    const tranList =
        pathName === '/colorTrain'
            ? props.colorTrain.tranList
            : props.faceTrain.tranList

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                // sendDataAsPacket.current = {
                //     byteSend: false,
                //     responseMessage: '',
                // }

                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current.byteSend) {
                    return resolve(sendDataAsPacket.current.responseMessage)
                }
                // if (!sendDataAsPacket.current.dataSend) {
                //     sendDataAsPacket.current = { dataSend: false, replay: '' }
                //     return resolve(sendDataAsPacket.current.replay)
                // }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    const errorPopUpSet = async (message) => {
        setUploadPopup(true)

        setUploadPopupText(message)
        await new Promise((reslove) => setTimeout(reslove, 2000))

        // setUploadPopup(false)
        // setUploadPopupText('')
    }
    const localStorageSetAiData = () => {
        let newAiTrainList = {}
        if (localStorage.getItem('aiTrainList')) {
            newAiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        } else {
            newAiTrainList = {
                speechTranList: [],
                colorTranList: [],
                faceTranList: [],
                connectedDevice,
            }
        }
        let trainType =
            pathName === '/colorTrain' ? 'colorTranList' : 'faceTranList'

        newAiTrainList[trainType] = [...tranList]
        localStorage.setItem('aiTrainList', JSON.stringify(newAiTrainList))
    }

    const backButtonClick = () => {
        let bytes = appAiBytesGeneration('stopTrainByte')
        if (props.webSerial.isConnected) {
            props.worker.postMessage({
                type: 'WriteArrayFormat1310',
                value: bytes,
            })
        }
        history.push('/chooseTrainingDevice')
    }

    const addButtonClick = () => {
        if (tranList.length < 3) {
            let newTranList = []
            if (selectedTranMode === 'faceTrain') {
                newTranList = [
                    ...props.faceTrain.tranList,
                    {
                        title: `Face`,
                        faceName: '',
                        isUpload: false,
                        id: 0,
                    },
                ]

                props.faceTrain.tranList = newTranList
                props.faceTrain.focusInd = tranList.length
                props.setAppAiModeStorage({
                    faceTrain: props.faceTrain,
                    type: 'faceTrain',
                })
            } else {
                newTranList = [
                    ...props.colorTrain.tranList,
                    {
                        title: `Color`,
                        value1: 2,
                        value2: 2,
                        isUpload: false,
                        isDisableSlider1: false,
                        isDisableSlider2: true,
                        // redMin: 0,
                        // redMax: 0,
                        // greenMin: 0,
                        // greenMax: 0,
                        // blueMin: 0,
                        // blueMax: 0,
                    },
                ]
                if (newTranList[tranList.length - 1].isDisableSlider1) {
                    newTranList[tranList.length - 1].isDisableSlider2 = true
                }

                let colorTrain = props.colorTrain
                colorTrain.tranList = newTranList
                colorTrain.focusInd = tranList.length
                props.setAppAiModeStorage({
                    colorTrain: colorTrain,
                    type: 'colorTrain',
                })
            }
        }
    }

    const deleteButtonClick = async (ind) => {
        if (selectedTranMode === 'faceTrain') {
            if (tranList.length > 1) {
                // if (selectedTranMode === 'faceTrain') {
                let newTranList = [...props.faceTrain.tranList]
                newTranList.splice(ind, 1)
                props.faceTrain.tranList = newTranList
                if (ind === tranList.length - 1) {
                    props.faceTrain.focusInd = ind - 1
                }
                props.setAppAiModeStorage({
                    faceTrain: props.faceTrain,
                    type: 'faceTrain',
                })

                // } else {
                //     let newTranList = [...props.colorTrain.tranList]
                //     newTranList.splice(ind, 1)
                //     props.colorTrain.tranList = newTranList
                //     if (ind === tranList.length - 1) {
                //         props.faceTrain.focusInd = ind - 1
                //     }
                //     props.setAppAiModeStorage({
                //         colorTrain: props.colorTrain,
                //         type: 'colorTrain',
                //     })
                // }
            }
        } else {
            if (!props.webSerial.isConnected) {
                errorPopUpSet(
                    'Please connect the device before delete button click.'
                )
                return
            }

            try {
                let bytes = appAiBytesGeneration('deleteColorByte', ind + 1)
                if (props.webSerial.isConnected) {
                    props.worker.postMessage({
                        type: 'WriteArrayFormat1310',
                        value: bytes,
                    })
                }
                sendDataAsPacket.current = {
                    byteSend: true,
                    responseMessage: '',
                }
                // await new Promise((reslove) => setTimeout(reslove, 1000))
                let replyOK = await Promise.race([
                    checkOk(),
                    timeoutPromise(1000), //196
                ])

                if (replyOK[3] !== 196 && replyOK[4] !== 1) {
                    errorPopUpSet('Color does not deleted, places tray again!')
                    return
                }

                let newTranList = [...props.colorTrain.tranList]
                let newColors = newTranList[ind]
                newColors.isUpload = false
                newColors.isDisableSlider1 = false
                newColors.isDisableSlider2 = true

                let colorTrain = props.colorTrain
                colorTrain.tranList = newTranList
                props.setAppAiModeStorage({
                    colorTrain: colorTrain,
                    type: 'colorTrain',
                })
            } catch (e) {
                console.log(e)
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: '',
                }
            }
        }

        localStorageSetAiData()
    }

    //handel the tabs focus index change
    const handelFocusIndChange = (ind) => {
        if (isNameUpload) {
            return
        }
        if (pathName === '/faceTrain') {
            let newFaceTrain = {
                ...props.faceTrain,
            }
            newFaceTrain.focusInd = ind
            props.setAppAiModeStorage({
                faceTrain: newFaceTrain,
                type: 'faceTrain',
            })
        } else {
            let newcolorTrain = {
                ...props.colorTrain,
            }
            newcolorTrain.tranList[focusInd].isDisableSlider2 = true

            newcolorTrain.focusInd = ind

            props.setAppAiModeStorage({
                colorTrain: newcolorTrain,
                type: 'colorTrain',
            })
        }
    }

    // const onChangeSliderHandel = (key, minVal, maxVal) => {
    //     let newTranList = [...props.colorTrain.tranList]
    //     let newColors = newTranList[focusInd]
    //     newColors[key + 'Min'] = minVal
    //     newColors[key + 'Max'] = maxVal

    //     props.colorTrain.tranList = newTranList
    //     props.setAppAiModeStorage({
    //         colorTrain: props.colorTrain,
    //         type: 'colorTrain',
    //     })
    // }
    const onChangeSliderHandel = (key, val) => {
        let newTranList = [...props.colorTrain.tranList]
        let newColors = newTranList[focusInd]
        newColors[key] = val

        let colorTrain = props.colorTrain
        colorTrain.tranList = newTranList
        props.setAppAiModeStorage({
            colorTrain: colorTrain,
            type: 'colorTrain',
        })
    }

    const handelFaceInputName = (e) => {
        let newTranList = [...props.faceTrain.tranList]
        let newFace = newTranList[focusInd]
        newFace.faceName = e.target.value.trim()

        props.faceTrain.tranList = newTranList
        props.setAppAiModeStorage({
            faceTrain: props.faceTrain,
            type: 'faceTrain',
        })
    }

    //when 1st time connect the device at colorTrain page, reset the all  RGB Threshold value.
    useEffect(() => {
        if (props.webSerial.isConnected) {
            let newTranList = [...props.colorTrain.tranList]

            newTranList.map((ele, ind) => {
                if (!ele.isUpload) {
                    ele.value1 = 2
                    ele.value2 = 2
                }
            })

            let colorTrain = { ...props.colorTrain }
            colorTrain.tranList = newTranList
            props.setAppAiModeStorage({
                colorTrain: colorTrain,
                type: 'colorTrain',
            })
        }
    }, [props.webSerial.isConnected])

    //start color train byte send
    useEffect(() => {
        const startColorTrain = async () => {
            if (selectedTranMode === 'colorTrain') {
                if (props.colorTrain.tranList[focusInd].isUpload) {
                    return
                }

                try {
                    let bytes = appAiBytesGeneration('startColorByte')
                    if (props.webSerial.isConnected) {
                        props.worker.postMessage({
                            type: 'WriteArrayFormat1310',
                            value: bytes,
                        })
                    }

                    sendDataAsPacket.current = {
                        byteSend: true,
                        responseMessage: '',
                    }
                    // await new Promise((reslove) => setTimeout(reslove, 1000))
                    let replyOK = await Promise.race([
                        checkOk(),
                        timeoutPromise(1000), //
                    ])
                    if (replyOK[3] !== 192 && replyOK[4] !== 1) {
                        errorPopUpSet(
                            'Color train does not start, places tray again!'
                        )
                    }
                } catch (e) {
                    console.log(e)
                    sendDataAsPacket.current = {
                        byteSend: false,
                        responseMessage: '',
                    }
                }
            }
        }
        startColorTrain()
    }, [focusInd, props.webSerial.isConnected])

    const onMouseUpHandel = async (key) => {
        try {
            let bytes = []
            if (key == 'value1') {
                bytes = appAiBytesGeneration(
                    'bondingBoxColorByte',
                    tranList[focusInd].value1
                )
            } else {
                bytes = appAiBytesGeneration(
                    'thresholdColorByte',
                    tranList[focusInd].value2
                )
            }

            if (props.webSerial.isConnected) {
                props.worker.postMessage({
                    type: 'WriteArrayFormat1310',
                    value: bytes,
                })
            }

            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            // await new Promise((reslove) => setTimeout(reslove, 1000))
            let replyOK = await Promise.race([
                checkOk(),
                timeoutPromise(1000), //
            ])

            if (![194, 195].includes(replyOK[3]) && replyOK[4] !== 1) {
                errorPopUpSet('Size does not set, places tray again!')
            }
        } catch (e) {
            console.log(e)
            sendDataAsPacket.current = { byteSend: false, responseMessage: '' }
        }
    }

    const handelTrainStopBytes = async () => {
        try {
            let bytes = appAiBytesGeneration('stopTrainByte')
            props.worker.postMessage({
                type: 'WriteArrayFormat1310',
                value: bytes,
            })

            sendDataAsPacket.current = {
                byteSend: true,
                responseMessage: '',
            }
            // await new Promise((reslove) => setTimeout(reslove, 1000))
            let replyOK = await Promise.race([
                checkOk(),
                timeoutPromise(1000), //196
            ])

            if (replyOK[3] !== 0 && replyOK[4] !== 1) {
                errorPopUpSet(`Face are not trained, please tray again.`)
                await handelTrainStopBytes()
            }
        } catch (e) {
            console.error('ERROR', e)
            sendDataAsPacket.current = {
                byteSend: false,
                responseMessage: '',
            }
        }
    }

    const setFaceId = async (responseArr) => {
        if (responseArr[4] == 0) {
            errorPopUpSet(`Face are not trained, please tray again.`)
            await handelTrainStopBytes()
            return
        }
        let newTranList = [...props.faceTrain.tranList]
        let newFaceObj = newTranList[focusInd]
        let faceTrain = props.faceTrain
        newFaceObj.isUpload = true
        newFaceObj.title = newFaceObj.faceName
        newFaceObj.id = responseArr[4]

        if (newTranList.length < 3) {
            newTranList = [
                ...newTranList,
                {
                    title: `Face`,
                    faceName: '',
                    isUpload: false,
                    id: 0,
                },
            ]
            faceTrain.focusInd = focusInd + 1
        }

        // update the redux
        faceTrain.tranList = newTranList
        props.setAppAiModeStorage({
            faceTrain: faceTrain,
            type: 'faceTrain',
        })

        //set the speechQueryArr in aiTrainList to use in hex programming
        localStorageSetAiData()

        setIsNameUpload(false)
        errorPopUpSet(`Face trained successful.`)
    }

    const uploadButtonClick = async () => {
        if (tranList[focusInd].isUpload) {
            return
        }

        if (!props.webSerial.isConnected) {
            setUploadPopup(true)
            errorPopUpSet('Please connect the device before trained.')
            return
        }

        if (pathName === '/colorTrain') {
            setUploadPopup(true)
            setUploadPopupText('Program upload .....')
            let bytes = appAiBytesGeneration('colorByte', focusInd)

            try {
                props.worker.postMessage({
                    type: 'WriteArrayFormat1310',
                    value: bytes,
                })

                sendDataAsPacket.current = {
                    byteSend: true,
                    responseMessage: '',
                }
                // await new Promise((reslove) => setTimeout(reslove, 1000))
                let replyOK = await Promise.race([
                    checkOk(),
                    timeoutPromise(1000), //
                ])

                if (![193].includes(replyOK[3]) && replyOK[4] !== 1) {
                    errorPopUpSet(
                        `Color ${
                            focusInd + 1
                        } are not trained, please tray again.`
                    )
                    return
                }

                let newTranList = [...props.colorTrain.tranList]
                let newColors = newTranList[focusInd]
                newColors.isUpload = true
                newColors.isDisableSlider1 = true
                newColors.isDisableSlider2 = false

                let colorTrain = props.colorTrain
                colorTrain.tranList = newTranList
                props.setAppAiModeStorage({
                    colorTrain: colorTrain,
                    type: 'colorTrain',
                })

                //set the speechQueryArr in aiTrainList to use in hex programming
                localStorageSetAiData()
            } catch (e) {
                errorPopUpSet(
                    `Color ${focusInd + 1} are not trained, please tray again.`
                )
                console.log('Error', e)
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: '',
                }
                return
            }
            errorPopUpSet(`Color trained successful.`)
        } else {
            if (!tranList[focusInd].faceName.trim()) {
                errorPopUpSet('Enter Face name, before capturing the face.')
                return
            }

            let bytes = appAiBytesGeneration('faceByte', tranList[focusInd])

            try {
                props.worker.postMessage({
                    type: 'WriteArrayFormat1310',
                    value: bytes,
                })

                // sendDataAsPacket.current = {
                //     byteSend: true,
                //     responseMessage: '',
                // }
                setIsNameUpload(true)
                // await new Promise((reslove) => setTimeout(reslove, 1000))

                let replyOK = await Promise.race([
                    checkOk(),
                    timeoutPromise(1000), //
                ])
                // setUploadPopup(false)
                //if came face train error
                // if (replyOK == 'AIFERR') {
                //     errorPopUpSet(`Face are not trained, please tray again.`)
                //     return
                // }
                // if ([70].includes(replyOK[3])) {
                //     setFaceId(replyOK)
                // }
            } catch (e) {
                errorPopUpSet(`Face are not trained, please tray again.`)
                console.log('Error', e)
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: '',
                }
                return
            }
        }

        //upload Successful popup
        // let tranName = pathName === '/colorTrain' ? 'Color' : 'Face'
        // errorPopUpSet(`${tranName} trained successful.`)
    }

    const getBackground = (ind) => {
        if (
            // pathName === '/colorTrain' &&
            // props.colorTrain.tranList[ind].isUpload
            tranList[ind].isUpload
        ) {
            return 'linear-gradient(90deg, green, #fcfcfc)'
        } else if (focusInd === ind) {
            return 'linear-gradient(90deg, rgb(161, 160, 160), rgb(241, 241, 241))'
        } else {
            return ''
        }
    }

    // its save the createActionState data in local storage
    const saverecoveryData = () => {
        let newRecoveryData =
            JSON.parse(
                localStorage.getItem('recoveryData') || JSON.stringify({})
            ) || {}
        let newAppAiModeStorage = JSON.parse(
            sessionStorage.getItem('appAiModeStorage')
        )

        //create the recoveryData object
        function addData(baseType, connectedDevice, deviceVersion, data) {
            if (!newRecoveryData[baseType]) {
                newRecoveryData[baseType] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice]) {
                newRecoveryData[baseType][connectedDevice] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice][deviceVersion]) {
                newRecoveryData[baseType][connectedDevice][deviceVersion] = {}
            }
            newRecoveryData[baseType][connectedDevice][
                deviceVersion
            ].appAiModeStorage = data
        }

        addData(
            'appAiBase',
            connectedDevice,
            deviceVersion,
            newAppAiModeStorage
        )

        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    //red data form worker
    useEffect(() => {
        //read data from serial worker
        const messageHandler = (e) => {
            if (e.data.type === 'actionRead') {
                let { arrayData, stringData } = e.data.value
                const pattern = /^65,73,.*255,245$/
                const isPatternMatch = pattern.test(arrayData.join(','))
                if (isPatternMatch) {
                    if (
                        [70].includes(arrayData[3]) &&
                        pathName === '/faceTrain'
                    ) {
                        setFaceId(arrayData)
                    } else {
                        sendDataAsPacket.current = {
                            byteSend: false,
                            responseMessage: arrayData,
                        }
                    }
                }
            }

            // set the face id and face  name after came back id from ai
            // if (
            //     e.data.type === 'actionRead' &&
            //     e.data.value.stringData.includes('AIF') &&
            //     !['AIFERR'].includes(e.data.value.stringDat)
            // ) {
            //     let newTranList = [...props.faceTrain.tranList]
            //     let newFaceObj = newTranList[focusInd]
            //     let faceTrain = props.faceTrain
            //     newFaceObj.isUpload = true
            //     newFaceObj.title = newFaceObj.faceName
            //     newFaceObj.id = e.data.value.arrayData[3]

            //     if (newTranList.length < 3) {
            //         newTranList = [
            //             ...newTranList,
            //             {
            //                 title: `Face`,
            //                 faceName: '',
            //                 isUpload: false,
            //                 id: 0,
            //             },
            //         ]
            //         faceTrain.focusInd = focusInd + 1
            //     }

            //     // update the redux
            //     faceTrain.tranList = newTranList
            //     props.setAppAiModeStorage({
            //         faceTrain: faceTrain,
            //         type: 'faceTrain',
            //     })

            //     //set the speechQueryArr in aiTrainList to use in hex programming
            //     localStorageSetAiData()

            //     setIsNameUpload(false)
            // }
        }

        props.worker.addEventListener('message', messageHandler)

        return () => {
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [props.faceTrain.tranList])

    //if user close the window its save the createActionState data in local storage
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            saverecoveryData()
        }
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    return (
        <div>
            {/* --------- upload  popup -------- */}
            <DialogModal
                show={uploadPopup}
                text={uploadPopupText}
                showCloseBtn={true}
                handleDialogModalClose={() => setUploadPopup(false)}
            />

            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="54%"
                    headers={[
                        { name: 'Choose Training Model', color: 'white' },
                        { name: 'Train', color: 'white' },
                        { name: '', color: 'black' },
                    ]}
                    save={true}
                    showHelp={true}
                    // handleHelp={handleClickhelpbtn}
                />
            </div>

            {/* ------- body ------- */}
            <div className="colorFaceTrainBody">
                <aside className="trainSidebar">
                    <div className="tranColorFaceListDiv">
                        {tranList.map((ele, ind) => {
                            return (
                                <div
                                    style={{
                                        background: getBackground(ind),
                                        // focusInd === ind
                                        //     ? 'linear-gradient(90deg, #eae8e8, #fcfcfc)' // 'rgb(241, 241, 241)'
                                        //     : '',
                                    }}
                                    key={ind}
                                    className="sidebarDeviceName"
                                    onClick={(e) => {
                                        handelFocusIndChange(ind)
                                    }}
                                >
                                    <p
                                        style={{
                                            marginBottom: '0px',
                                            marginLeft: '30px',
                                            fontSize: '1.3rem',
                                        }}
                                    >
                                        {pathName === '/colorTrain'
                                            ? ele.title + ` ${ind + 1}`
                                            : ele.title}
                                    </p>

                                    <div
                                        style={{
                                            display:
                                                focusInd == ind
                                                    ? 'block'
                                                    : 'none',
                                            marginLeft: '30px',
                                        }}
                                    >
                                        {/* {pathName === '/colorTrain' &&
                                            props.colorTrain.tranList[ind]
                                                .isUpload && (
                                                <img
                                                    src={renderImage(
                                                        'minusButton'
                                                    )}
                                                    alt="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        deleteButtonClick(ind)
                                                    }}
                                                />
                                            )} */}
                                        <img
                                            src={renderImage('arrowRight')}
                                            alt="focus"
                                            style={{ marginLeft: '17px' }}
                                        />
                                    </div>
                                    <div></div>
                                </div>
                            )
                        })}
                    </div>

                    {/* ------- new face & color add icon ------- */}
                    {selectedTranMode === 'colorTrain' && (
                        <img
                            src={renderImage('buttonPlusImg')}
                            draggable="false"
                            alt="add"
                            style={{
                                margin: 0,
                                position: 'fixed',
                                top: '82vh',
                                left: '10%',
                                width: '3vw',
                                cursor: 'pointer',
                                transform: 'translate(-50%, -50%)',
                            }}
                            onClick={(e) => addButtonClick()}
                        />
                    )}
                </aside>
                <div className="mainBody">
                    <div className="trainControlDiv">
                        {/* ---------------COLOR TRAIN BODY ------------------------- */}
                        {selectedTranMode === 'colorTrain' && (
                            <div className="trainColorControl_1_Div">
                                <p style={{ marginBottom: '0px' }}>
                                    Color Training
                                </p>
                                <div className="colorTrainInputs">
                                    <div>
                                        <p>Set training box size</p>
                                        {/* <AiColorSlider2
                                            sliderKey="red"    
                                            min={0}
                                            max={100}
                                            minValue={tranList[focusInd].redMin}
                                            maxValue={tranList[focusInd].redMax}
                                            background={
                                                'linear-gradient(to right,rgb(243, 188, 188),rgb(246, 60, 60)'
                                            }
                                            onChangeSliderHandel={
                                                onChangeSliderHandel
                                            }
                                        /> */}
                                        <AiColorSlider
                                            sliderKey="value1"
                                            min={0}
                                            max={6}
                                            // background={
                                            //     'linear-gradient(to right,rgb(243, 188, 188),rgb(246, 60, 60)'
                                            // }
                                            background="rgb(243,160,81)"
                                            isTexInputReadOnly={true}
                                            value={tranList[focusInd].value1}
                                            onChangeSliderHandel={
                                                onChangeSliderHandel
                                            }
                                            onMouseUpHandel={onMouseUpHandel}
                                            isDisableSlider={
                                                props.colorTrain.tranList[
                                                    focusInd
                                                ].isDisableSlider1
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <p>Set detection box size</p>
                                            {/* <AiColorSlider2
                                                sliderKey="green"
                                                min={0}
                                                max={100}
                                                minValue={
                                                    tranList[focusInd].greenMin
                                                }
                                                maxValue={
                                                    tranList[focusInd].greenMax
                                                }
                                                background={
                                                    'linear-gradient(to right, rgb(168, 248, 163), rgb(1, 182, 1))'
                                                }
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                            /> */}
                                            <AiColorSlider
                                                sliderKey="value2"
                                                min={0}
                                                max={8}
                                                // background={
                                                //     'linear-gradient(to right, rgb(168, 248, 163), rgb(1, 182, 1))'
                                                // }
                                                background="rgb(243,160,81)"
                                                isTexInputReadOnly={true}
                                                value={
                                                    tranList[focusInd].value2
                                                }
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                                onMouseUpHandel={
                                                    onMouseUpHandel
                                                }
                                                isDisableSlider={
                                                    props.colorTrain.tranList[
                                                        focusInd
                                                    ].isDisableSlider2
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {/* <AiColorSlider2
                                                sliderKey="blue"
                                                min={0}
                                                max={100}
                                                minValue={
                                                    tranList[focusInd].blueMin
                                                }
                                                maxValue={
                                                    tranList[focusInd].blueMax
                                                }
                                                background={
                                                    'linear-gradient(to right, rgb(177, 163, 248), rgb(4, 4, 234))'
                                                }
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                            /> */}
                                            {/* <AiColorSlider
                                                sliderKey="blue1"
                                                min={0}
                                                max={100}
                                                background={
                                                    'linear-gradient(to right, rgb(177, 163, 248), rgb(4, 4, 234))'
                                                }
                                                value={tranList[focusInd].blue1}
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* ---------------FACE TRAIN BODY ------------------------- */}
                        {selectedTranMode === 'faceTrain' && (
                            <div className="faceTrainDiv">
                                <p>Enter Face name</p>
                                <input
                                    type="text"
                                    value={tranList[focusInd].faceName}
                                    onChange={(e) => {
                                        handelFaceInputName(e)
                                    }}
                                    disabled={tranList[focusInd].isUpload}
                                />
                                <img
                                    style={
                                        tranList[focusInd].isUpload
                                            ? {
                                                  opacity: '0.5',
                                                  cursor: 'default',
                                              }
                                            : {
                                                  opacity: '1',
                                                  cursor: 'pointer',
                                              }
                                    }
                                    src={renderImage('aiCameraButton')}
                                    alt="camera"
                                    onClick={(e) => uploadButtonClick()}
                                />
                            </div>
                        )}
                    </div>
                    {/* {selectedTranMode === 'colorTrain' && (
                        <div className="trainControlDiv">
                            <div className="trainColorControl_1_Div">
                                <p>RGB Threshold 2.</p>
                                <div className="colorTrainInputs">
                                    <div>
                                        <AiColorSlider
                                            sliderKey="red2"
                                            min={tranList[focusInd].red1}
                                            max={100}
                                            background={
                                                'linear-gradient(to right,rgb(243, 188, 188),rgb(246, 60, 60)'
                                            }
                                            value={tranList[focusInd].red2}
                                            onChangeSliderHandel={
                                                onChangeSliderHandel
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <AiColorSlider
                                                sliderKey="green2"
                                                min={tranList[focusInd].green1}
                                                max={100}
                                                background={
                                                    'linear-gradient(to right, rgb(168, 248, 163), rgb(1, 182, 1))'
                                                }
                                                value={
                                                    tranList[focusInd].green1 >
                                                    tranList[focusInd].green2
                                                        ? tranList[focusInd]
                                                              .green1
                                                        : tranList[focusInd]
                                                              .green2
                                                }
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <AiColorSlider
                                                sliderKey="blue2"
                                                min={0}
                                                max={100}
                                                background={
                                                    'linear-gradient(to right, rgb(177, 163, 248), rgb(4, 4, 234))'
                                                }
                                                value={tranList[focusInd].blue2}
                                                onChangeSliderHandel={
                                                    onChangeSliderHandel
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
            {/* ------- Footer ------- */}
            <AiModeFooter
                isUploadBtnDisabled={
                    pathName === '/colorTrain' &&
                    props.colorTrain.tranList[focusInd].isUpload
                }
                isUploadShow={pathName !== '/faceTrain'}
                isBackShow={true}
                uploadClick={(e) => uploadButtonClick()}
                backClick={(e) => backButtonClick()}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // appAiModeStorage: state.appAiModeStorage,

        colorTrain: state.appAiModeStorage.colorTrain,
        faceTrain: state.appAiModeStorage.faceTrain,
        worker: state.worker,
        webSerial: state.webSerial,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAppAiModeStorage: (data) => {
            dispatch({ type: 'APP_AI_MODE_STORAGE', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorFaceTrain)
