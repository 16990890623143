import { decimalToMSBLSB } from '../../ReusableComponents/ByteTransfer/utils'

//all app ai mode prefix bytes
const aiBytesPrefix = {
    colorBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        6,
        'C'.charCodeAt(0),
    ],
    faceBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'F'.charCodeAt(0),
    ],
    wakeBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'W'.charCodeAt(0),
    ],
    speechBytesPrefix: [
        'A'.charCodeAt(0),
        'I'.charCodeAt(0),
        0, // index 2 show the length of bytes
        'F'.charCodeAt(0),
    ],
}

// generate the  CRC(Cyclic Redundancy Check)
const crc_create0 = (buf) => {
    let crc
    let temp = 0
    for (let i = 0; i < buf.length; i++) {
        console.log('GGGGGG', buf[i])
        temp += buf[i]
    }
    temp = temp & 0xff
    crc = ~temp
    return crc & 0xff
}
const crc_create = (buf) => {
    let crc = 0x00

    for (let i = 0; i < buf.length; i++) {
        // console.log('GGGGGG', buf[i])
        crc ^= buf[i] // XOR the CRC with the current byte

        // Process each bit in the current byte
        for (let j = 0; j < 8; j++) {
            if (crc & 0x80) {
                // If the MSB is set, XOR with the polynomial 0x07
                crc = (crc << 1) ^ 0x07
            } else {
                // Otherwise, just shift the CRC left
                crc = crc << 1
            }

            // Ensure CRC remains an 8-bit value
            crc &= 0xff
        }
    }
    // console.log('VVVVVVVVV', crc)
    return crc
}

const appAiBytesGeneration = (byteType, ele, ind) => {
    try {
        let bytes = ['A'.charCodeAt(0), 'I'.charCodeAt(0)]
        let crc = ''

        switch (byteType) {
            case 'faceByte': {
                // bytes = [...aiBytesPrefix.faceBytesPrefix]
                let length = ele.faceName.length + 2
                bytes.push(length, 'F'.charCodeAt(0))

                // bytes.push(ind + 1) //face id
                let nameAsci = ele.faceName
                    .split('')
                    .map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...nameAsci]

                crc = crc_create([length, 'F'.charCodeAt(0), ...nameAsci])

                break
            }
            case 'stopTrainByte': {
                bytes.push(2, 0x00)
                crc = crc_create([2, 0x00])
                break
            }
            case 'startColorByte': {
                bytes.push(2, 0xc0)
                crc = crc_create([2, 0xc0])
                break
            }
            case 'bondingBoxColorByte': {
                ele = parseInt(ele)
                bytes.push(3, 0xc2, ele)
                crc = crc_create([3, 0xc2, ele])
                break
            }
            case 'thresholdColorByte': {
                ele = parseInt(ele)
                bytes.push(3, 0xc3, ele)
                crc = crc_create([3, 0xc3, ele])
                break
            }
            case 'colorByte': {
                bytes.push(3, 0xc1, ele)
                crc = crc_create([3, 0xc1, ele])
                break
            }
            case 'deleteColorByte': {
                bytes.push(3, 0xc4, ele)
                crc = crc_create([3, 0xc4, ele])
                break
            }
            case 'wakeByte': {
                let length = ele.length + 2
                bytes.push(length, 'W'.charCodeAt(0))

                let wakeAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...wakeAsci]

                crc = crc_create([length, 'W'.charCodeAt(0), ...wakeAsci])
                break
            }
            case 'speechByte': {
                let length = ele.value.length + 3
                bytes.push(length, 'V'.charCodeAt(0))
                bytes.push(ind + 1) //speech id

                let speechAsci = ele.value
                    .split('')
                    .map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...speechAsci]

                crc = crc_create([
                    length,
                    'V'.charCodeAt(0),
                    ind + 1,
                    ...speechAsci,
                ])
                break
            }
            case 'cleanByte': {
                ///change bytes cllear for voice
                let length = 3
                let commend = '0'
                if (ele == 'color') {
                    commend = 'C'
                } else if (ele == 'face') {
                    commend = 'F'
                } else {
                    commend = 'C'
                }

                bytes.push(length, 'D'.charCodeAt(0), commend.charCodeAt(0))

                crc = crc_create([
                    length,
                    'D'.charCodeAt(0),
                    commend.charCodeAt(0),
                ])
                break
            }
            case 'getAudioByte': {
                //change th bytes
                bytes.push(2, 0x1c)
                crc = crc_create([2, 0x1c])
                break
            }
            case 'scanWifiByte': {
                bytes.push(2, 0x2e)
                crc = crc_create([2, 0x2e])
                break
            }
            case 'setWifiSSIDByte': {
                let length = ele.length + 2

                let ssidAsci = ele.split('').map((item) => item.charCodeAt(0))

                bytes = [...bytes, length, 0x3e, ...ssidAsci]
                crc = crc_create([length, 0x3e, ...ssidAsci])
                break
            }
            case 'setWifiPasswordByte': {
                let length = ele.length + 2

                let passwordAsci = ele
                    .split('')
                    .map((item) => item.charCodeAt(0))

                bytes = [...bytes, length, 0x4e, ...passwordAsci]
                crc = crc_create([length, 0x4e, ...passwordAsci])
                break
            }
            case 'wifiConnectByte': {
                bytes.push(2, 0x5e)
                crc = crc_create([2, 0x5e])
                break
            }
            case 'checkWifiConnectByte': {
                bytes.push(2, 0x7e)
                crc = crc_create([2, 0x7e])
                break
            }
            case 'wifiDisconnectByte': {
                bytes.push(2, 0x6e)
                crc = crc_create([2, 0x6e])
                break
            }
            case 'httpJWTByte': {
                bytes[1] = 'L'.charCodeAt('0')
                let length = ele.length + 2

                let msbLsb = decimalToMSBLSB(length)

                let jwtAsci = ele.split('').map((item) => item.charCodeAt(0))

                bytes = [...bytes, ...msbLsb, 0x84, ...jwtAsci]
                crc = crc_create([...msbLsb, 0x84, ...jwtAsci])
                break
            }
            case 'httpHostByte': {
                let host = 'https://iot.plode.org'
                let length = host.length + 2

                let hostAsci = host.split('').map((item) => item.charCodeAt(0))

                bytes = [...bytes, length, 0x82, ...hostAsci]
                crc = crc_create([length, 0x82, ...hostAsci])
                break
            }
            case 'httpPathByte': {
                let path = '/api/v1/services/1'
                let length = path.length + 2

                let passwordAsci = path
                    .split('')
                    .map((item) => item.charCodeAt(0))

                bytes = [...bytes, length, 0x83, ...passwordAsci]
                crc = crc_create([length, 0x83, ...passwordAsci])
                break
            }
            case 'startChatByte': {
                bytes.push(2, 0xcb)
                crc = crc_create([2, 0xcb])
                break
            }
            case 'stopTrainByte': {
                bytes.push(2, 0x00)
                crc = crc_create([2, 0x00])
                break
            }
        }

        bytes.push(crc, 0xff, 0xf9)
        console.log('bytes', bytes)
        return bytes
    } catch (e) {
        console.log('ERROR', e)
        return [
            'R'.charCodeAt(0),
            'E'.charCodeAt(0),
            'S'.charCodeAt(0),
            'T'.charCodeAt(0),
        ]
    }
}

const getEnableAiItems = (hexProgram, aiItems) => {
    try {
        for (let i in hexProgram) {
            let programNode = hexProgram[i]
            if (programNode.type === 'hardware') {
                let nodeState = programNode.state
                Object.keys(nodeState).map((key) => {
                    if (
                        nodeState[key] &&
                        key.includes('ai') &&
                        key.includes('assign')
                    ) {
                        let newKey = key.replace('assign', '')
                        newKey = newKey.replace('A1', '')
                        aiItems[newKey] = true
                    }
                })
            }

            if (
                programNode.type === 'sensor' ||
                programNode.type === 'condition'
            ) {
                let nodeState = programNode.state
                if (nodeState) {
                    if (
                        [
                            'ai_motion',
                            'ai_color_detection',
                            'ai_face_detection',
                            'ai_face_recognition',
                            'ai_mic',
                            'ai_lidar',
                        ].includes(nodeState.source)
                    ) {
                        aiItems[nodeState.source] = true
                    }

                    if (
                        nodeState.source.includes('ai_face_tracking_X') ||
                        nodeState.source.includes('ai_face_tracking_Y')
                    ) {
                        aiItems.ai_face_tracking = true
                    }
                    if (
                        nodeState.source.includes('ai_color_tracking_X') ||
                        nodeState.source.includes('ai_color_tracking_Y')
                    ) {
                        aiItems.ai_color_tracking = true
                    }
                }
            }

            if (programNode?.subprogram && programNode?.subprogram.length > 0) {
                getEnableAiItems(programNode?.subprogram, aiItems)
            }
        }
    } catch (e) {
        console.log('ERROR', e)
    }
}

//generat the peeCee Ai Data in hex programming
const generateAidata = (hexProgram) => {
    let aiItems = {
        ai_motion: false,
        ai_color_detection: false,
        ai_color_tracking: false,
        ai_face_detection: false,
        ai_face_recognition: false,
        ai_face_tracking: false,
        ai_mic: false,
        ai_lidar: false,
        ai_LED: false,
        ai_audio: false,
    }
    getEnableAiItems(hexProgram, aiItems)
    console.log('assemblyPageAi', 'jjjjjjj', aiItems)
    let bytes = ['A'.charCodeAt(0), 'I'.charCodeAt(0), 8, 'S'.charCodeAt(0)]
    bytes.push(0, 0, 0, 0, 0, 0)

    Object.keys(aiItems).map((objKey) => {
        switch (objKey) {
            case 'ai_motion':
                if (aiItems[objKey]) {
                    bytes[4] = 'M'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_color_detection':
            case 'ai_color_tracking':
                if (aiItems[objKey]) {
                    bytes[4] = 'C'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_face_detection':
            case 'ai_face_recognition':
            case 'ai_face_tracking':
                if (aiItems[objKey]) {
                    bytes[4] = 'F'.charCodeAt(0)
                    bytes[5] = 1
                }
                break

            case 'ai_mic':
                if (aiItems[objKey]) {
                    bytes[6] = 1
                }
                break

            case 'ai_lidar':
                if (aiItems[objKey]) {
                    bytes[7] = 1
                }
                break

            case 'ai_LED':
                if (aiItems[objKey]) {
                    bytes[8] = 1
                }
                break
            case 'ai_audio':
                if (aiItems[objKey]) {
                    bytes[9] = 1
                }
                break
        }
    })

    let spliceByte = [...bytes].splice(2, 8)
    let crc = crc_create(spliceByte)

    bytes.push(crc, 0xff, 0xf9)
    console.log('bytes', bytes)

    return bytes
}

//generat ai REST data
const generateAiresetdata = () => {
    let data = ['A'.charCodeAt(0), 'I'.charCodeAt(0), 2, 'X'.charCodeAt(0)]
    let crc = crc_create([2, 'X'.charCodeAt(0)])
    data.push(crc, 0xff, 0xf9)

    return data
}

//fetch the AI audioFiles
const fetchAudioList = async (worker) => {
    try {
        let getAudioBytes = appAiBytesGeneration('getAudioByte')

        worker.postMessage({
            type: 'aiFetchAudioWriteArray',
            value: getAudioBytes,
        })
    } catch (e) {
        console.log('ERROR', e)
    }
}

export {
    appAiBytesGeneration,
    crc_create,
    generateAidata,
    generateAiresetdata,
    fetchAudioList,
}
