export const selecteComponent = (data) => {
    return {
        type: 'COMPONENT_SELECTION',
        payload: data,
    }
}
export const workerConnect = (data) => {
    return {
        type: 'SERIAL_WORKER',
    }
}

export const graphPlotStorage = (data) => {
    return {
        type: 'GRAPH_PLOTSTORAGE',
        payload: data,
    }
}

export const clearComponents = () => {
    return {
        type: 'CLEAR_COMPONENT_SELECTION',
    }
}

export const selecteInternalAcessories = (data) => {
    return {
        type: 'INTERNAL_ACCESSORIES',
        payload: data,
    }
}
export const assemblyComponent = (data) => {
    console.log(data, 'assemblyComponent  =========REDUX')
    return {
        type: 'ASSEMBLY_SELECTION',
        payload: data,
    }
}

export const webSerialAction = (data) => {
    return {
        type: 'WEB_SERIAL_PORT_CONNECTED',
        payload: data,
    }
}

export const webSerialConnect = (data) => {
    sessionStorage.setItem('isDeviceConnected', true)
    localStorage.setItem('plodeDeviceIsConnected', true)
    return {
        type: 'WEB_SERIAL_PORT_CONNECT',
        payload: data,
    }
}

export const webSerialDisconnect = () => {
    sessionStorage.setItem('isDeviceConnected', false)
    localStorage.setItem('plodeDeviceIsConnected', false)
    return {
        type: 'WEB_SERIAL_PORT_DISCONNECT',
    }
}

export const deviceDetails = (data) => {
    return {
        type: 'DEVICE_DETAILS',
        payload: data,
    }
}
export const deviceMismatch = (data) => {
    return {
        type: 'DEVICE_MISMATCH',
        payload: data,
    }
}
export const setLanguageSelect = (data) => {
    return {
        type: 'LANGUAGE_SELECT',
        payload: data,
    }
}
export const showModal = (data) => {
    return {
        type: 'SHOW_MODAL',
        payload: data,
    }
}

export const hideModal = () => {
    return {
        type: 'HIDE_MODAL',
    }
}

export const isConnecting = (data) => {
    return {
        type: 'CONNECTING',
        payload: data,
    }
}
