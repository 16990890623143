import close from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/close.png'
import React, { useEffect, useState } from 'react'
import selectbarX from '../../../Assets/Bisoft_UI/AppMode/selectbar@2.png'
import backY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/back.png'
import nextY from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/next.png'
import { useHistory } from 'react-router'
import NavBar from '../NavBar'
import styles from '../Appmode.module.css'
import { useIndexedDB } from 'react-indexed-db'

const SavedFaces = () => {
    const { deleteRecord, getAll } = useIndexedDB('SavedFaces')
    const [imageDetails, setImageDetails] = useState('')
    const [image, setImage] = useState('')
    const [display, setDisplay] = useState(false)
    const [render, setRender] = useState(false)
    const history = useHistory()
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )

    useEffect(() => {
        getAll().then((imageID) => {
            setImageDetails(imageID)
        })
    }, [render])

    const ScrollableDiv = (props) => {
        const deleteElement = (event) => {
            deleteRecord(event).then(() => {
                setRender(!render)
                setDisplay()
            })
        }

        const displayImage = (name) => {
            imageDetails.map((image) => {
                if (image.id === name) {
                    setImage(image.imageUrl)
                    setDisplay(true)
                }
                return ''
            })
        }

        return (
            <div>
                <div
                    id={props.index}
                    style={{ margin: '0vh', cursor: 'pointer' }}
                    onClick={(event) => displayImage(props.name)}
                >
                    <div
                        style={{
                            position: 'relative',
                            backgroundColor: '#f0f7fa',
                            padding: '2.5vh',
                            borderRadius: '30px',
                            display: 'grid',
                            gridTemplateColumns: '92% 8%',
                        }}
                    >
                        <strong>{props.name}</strong>
                        <img
                            style={{
                                cursor: 'pointer',
                                marginTop: '0.2vh',
                            }}
                            draggable="false"
                            src={close}
                            alt="close"
                            width="20vw"
                            height="20vh"
                            onClick={() => {
                                deleteElement(props.name)
                            }}
                        />
                    </div>
                </div>
                <br></br>
            </div>
        )
    }

    return (
        <div>
            {/* NavBar */}
            <NavBar
                selectbar={selectbarX}
                selectbarwidth="45vw"
                headers={[
                    { name: 'Enable', color: 'white' },
                    { name: 'Train', color: 'white' },
                    { name: 'Control', color: 'black' },
                    { name: 'Run', color: 'black' },
                ]}
            />
            <div
                className={`${
                    slideDirection === 'true' ? 'slide-right' : 'slide-left'
                }`}
                style={{ position: 'relative' }}
            >
                <div>
                    {(render === true || render === false) &&
                    imageDetails !== '' &&
                    Object.keys(imageDetails).length !== 0 ? (
                        <div>
                            <p
                                style={{
                                    position: 'absolute',
                                    marginTop: '38vh',
                                    marginLeft: '5vw',
                                }}
                            >
                                <strong>Saved Faces</strong>
                            </p>
                            <div
                                className={styles.scrollable}
                                style={{
                                    marginLeft: '25vw',
                                    top: '26vh',
                                    height: '40vh',
                                    width: '20vw',
                                }}
                            >
                                {Object.values(imageDetails).map(
                                    (value, index) => {
                                        return (
                                            <ScrollableDiv
                                                key={value.id}
                                                name={value.id}
                                                index={value.id}
                                            />
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    ) : (
                        <p
                            style={{
                                marginTop: '38vh',
                                marginLeft: '5vw',
                                position: 'absolute',
                            }}
                        >
                            <strong>No saved Faces</strong>
                        </p>
                    )}
                    {display === true ? (
                        <img
                            draggable="false"
                            style={{
                                position: 'absolute',
                                right: '30vh',
                                bottom: '28vh',
                                borderRadius: '5px',
                            }}
                            src={image}
                            alt="displayImage"
                            width="25%"
                            height="50%"
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div>
                <img
                    src={nextY}
                    draggable="false"
                    alt="next"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        left: '95.8vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(false)
                        sessionStorage.setItem('slideDirection', false)
                        if (imageDetails !== null) {
                            if (Object.keys(imageDetails).length !== 0) {
                                history.push('/programmingPage')
                            }
                        }
                    }}
                />
                <img
                    src={backY}
                    draggable="false"
                    alt="back"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '92vh',
                        right: '92.7vw',
                        width: '3vw',
                        cursor: 'pointer',
                        transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => {
                        setSlideDirection(true)
                        sessionStorage.setItem('slideDirection', true)
                        history.push('/uploadImage')
                    }}
                />
            </div>
        </div>
    )
}
export default SavedFaces
