import { useHistory } from 'react-router'
import { useState, useRef } from 'react'
import renderPrgImage from '../../../../source/programImg'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import stylee from '../Graph.module.css'
import Ports from './Components/Ports'
import { portsData } from '../GraphPlotStorage'
import { connect } from 'react-redux'
import closeBtn from '../../../../Assets/img/close.png'
import { getHederTextArr } from '../../../../utils/langaugeSupport'

function GraphPorts(props) {
    let history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const [ishelpButton, sethelpButton] = useState(false)
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [isActiveNextBtn, setIsActiveNextBtn] = useState(false)
    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    const languageName = props.indexData.device.language

    const backBtn = () => {
        setSlideDirection(true)
        sessionStorage.setItem('slideDirection', true)
        history.push('/graphSensor')
        // sessionStorage.removeItem('portsData')
    }

    const nextBtn = () => {
        if (isActiveNextBtn) {
            history.push('/graphTime')
            setSlideDirection(false)
            sessionStorage.setItem('slideDirection', false)
        } else {
            setToastMsg('No sensors or ports selected')
            setToastActive(true)
            disableToast()
        }
    }

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }
    console.log('GRAPHPORT', props)

    return (
        <>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <div style={{ height: '15%' }}>
                    <ProgramHeader
                        data={{
                            options: getHederTextArr({
                                languageName,
                                keyArr: [
                                    'SelectSensors',
                                    'SelectPorts',
                                    'SetTime',
                                    'SetGraph',
                                    'Plot',
                                ],
                            }),
                            selected: 1,
                        }}
                        showSave={false}
                        showHelp={true}
                        handleHelp={handleClickhelpbtn}
                    />
                </div>

                <div className={stylee.Sensor_Body} style={{ height: '85%' }}>
                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                left: '35%',
                                top: '90.6%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('backBtn')}
                            onClick={backBtn}
                        />
                    </div>

                    <div
                        style={
                            connectedDevice == 'Roboki'
                                ? { width: '60%', gridTemplateRows: '90%' }
                                : { width: '60%' }
                        }
                        // className={stylee.Ports_Container}
                        className={`${
                            slideDirection === 'true'
                                ? 'slide-right'
                                : 'slide-left'
                        } ${stylee.Ports_Container}`}
                    >
                        {props.indexData.graphPlotStorage.internalaccessories.portsData.map(
                            (item, index) => (
                                <Ports
                                    key={index}
                                    data={item}
                                    setIsActiveNextBtn={setIsActiveNextBtn}
                                    setToastActive={setToastActive}
                                    setToastMsg={setToastMsg}
                                    disableToast={disableToast}
                                />
                            )
                        )}
                    </div>

                    <div style={{ width: '20%', position: 'relative' }}>
                        <img
                            style={{
                                position: 'absolute',
                                right: '15%',
                                top: '90.6%',
                                width: '60px',
                                height: '60px',
                                transform: 'translate(-50%,-50%)',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('nextBtn')}
                            onClick={nextBtn}
                        />
                    </div>
                </div>

                {ishelpButton == true ? (
                    <div className={stylee.helpScreen}>
                        <div
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '47%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                                width: '65vw',
                            }}
                        >
                            <img
                                src={renderPrgImage('graphPortH')}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div
                            className={stylee.help}
                            style={{
                                width: '17%',
                                top: '40.3%',
                                left: '52%',
                            }}
                        >
                            <p className={stylee.help_text}>
                                Select the External Ports, which have the Input
                                Accessories connected to it. You can enable
                                either as digital or Analog ports
                            </p>
                        </div>

                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '18%',
                                left: '81%',
                                transform: 'translate(-50%,-50%)',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                    </div>
                ) : null}

                <div
                    className={`toast-msg
         ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                    ref={toastRef}
                >
                    {toastMsg}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        indexData: state,
    }
}
export default connect(mapStateToProps)(GraphPorts)
