const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
// const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
const connectedDevice = sessionStorage.getItem('connectedDevice')

var deviceInitialState = {
    connectedDevice: connectedDevice,
    deviceVersion: deviceVersion,
    isMemoryPresent: false,
    isArmError: false,
    deviceMismatched: {},
    language: 'English',
}
const reduxDeviceStore = sessionStorage.getItem('reduxDeviceStore')
try {
    if (reduxDeviceStore) {
        deviceInitialState = JSON.parse(reduxDeviceStore)
    }
} catch (e) {
    console.log('ERROR', e)
}

const deviceReducer = (state = deviceInitialState, action) => {
    switch (action.type) {
        case 'DEVICE_DETAILS': {
            console.log('redux1', action.payload)
            let data
            // if (['Ace', 'Hexapod'].includes(action.payload.connectedDevice)) {
            data = {
                ...state,
                connectedDevice: action.payload.connectedDevice,
                deviceVersion: action.payload.deviceVersion,
                // zingVersion: '0.0.0',
            }
            // sessionStorage.setItem('zingVersion', '0.0.0')
            // } else {
            //     data = {
            //         ...state,
            //         connectedDevice: action.payload.connectedDevice,
            //         deviceVersion: '0.0.0',
            //         zingVersion: action.payload.zingVersion,
            //     }
            //     sessionStorage.setItem('deviceVersion', '0.0.0')
            // }
            sessionStorage.setItem('reduxDeviceStore', JSON.stringify(data))
            return data
        }
        case 'DEVICE_MISMATCH': {
            console.log('redux2', action.payload)
            let {
                isMemoryPresent = state.isMemoryPresent,
                isArmError = state.isArmError,
            } = action?.payload ?? {}

            let data = {
                ...state,
                deviceMismatched: action.payload.deviceMismatched,
                isMemoryPresent: isMemoryPresent,
                isArmError: isArmError,
            }
            sessionStorage.setItem('reduxDeviceStore', JSON.stringify(data))
            return data
        }
        case 'LANGUAGE_SELECT': {
            console.log('redux2', action.payload)
            let data = {
                ...state,
                language: action.payload.language,
            }
            sessionStorage.setItem('reduxDeviceStore', JSON.stringify(data))
            return data
        }
        default:
            return state
    }
}

export default deviceReducer
