import React, { Component } from 'react'
import { connect } from 'react-redux'

import { DropTarget } from 'react-dnd-old'
import Card from './DraggableSidebarCard'
import ItemTypes from './ItemTypes'
import renderPrgImage from '../../source/programImg'
import { withRouter } from 'react-router-dom'
import Sizes from '../../helpers/Sizes'
import DraggingInfo from './DraggingInfo'

const sidebarTarget = {
    drop(props, monitor) {
        DraggingInfo.isDragging = false
        const item = monitor.getItem()

        if (DraggingInfo.draggingComponentOld) props.removeFromWorkspace(item)
        else DraggingInfo.sidebarOldOffset = 0
    },
}

/**
 * A boolean which is turned to false which is turned true when a function
 * doesnot want to force a rerender
 * @type {Boolean}
 */
var shouldNotUpdate = false

var scrolling = false,
    scrollingTimeoutId

class Sidebar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isExpand: true,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !shouldNotUpdate
    }
    // componentDidUpdate(prevProps, prevState) {
    //    ReactDOM.findDOMNode(this).scrollTop = this.state.scrollTop;
    // }
    /**
     * Updates the sidebar scroll with the given offset
     * @param {number}   offset The offset to add
     * @param {Function} cb     Callback
     */
    setScroll(offset, cb) {
        offset -= DraggingInfo.sidebarOldOffset
        this.setState(
            {
                scrollTop: this.state.scrollTop - offset,
            },
            cb
        )
    }

    componentDidMount() {
        sessionStorage.setItem('isExpand', this.state.isExpand)
    }

    handleExpand = (action) => {
        let workSpaceDom = document.getElementById('workSpace')
        let connectPortDom = document.getElementById('connectPort')
        switch (action) {
            case 'open': {
                this.setState({
                    isExpand: true,
                })

                sessionStorage.setItem('isExpand', 'true')
                workSpaceDom.style.left = 0
                workSpaceDom.style.top = 0
                workSpaceDom.style.transform = 'none'
                connectPortDom.style.left = 0
                connectPortDom.style.top = 0
                connectPortDom.style.transform = 'none'
                connectPortDom.style.width = '100%'

                break
            }
            case 'close': {
                this.setState({
                    isExpand: false,
                })

                sessionStorage.setItem('isExpand', 'false')
                workSpaceDom.style.left = '27%'
                workSpaceDom.style.top = '50%'
                workSpaceDom.style.transform = 'translate(-50%,-50%)'
                connectPortDom.style.left = '38%'
                connectPortDom.style.top = '50%'
                connectPortDom.style.transform = 'translate(-50%,-50%)'
                connectPortDom.style.width = '123%'
                break
            }
        }
    }

    randomEvent = () => {
        // console.log("clcikc");
    }

    //close the ai selection Screen
    closeAiSelectScreen = () => {
        let assemblyPageAi = {
            ...this.props.selectedComponents.assembly.assemblyPageAi,
        }
        assemblyPageAi.isShowAiSelectContainer = false
        this.props.setAssemblyPageAi(assemblyPageAi)
    }

    backBtn = async () => {
        this.props.onBack()
        this.props.slideDirection()
        console.log('gsk props', this.props)

        this.closeAiSelectScreen()

        if (sessionStorage.getItem('isReadingZingPA') == 'true') {
            this.props.humanoidRead()
            await new Promise((resolve) => setTimeout(resolve, 200))
        }
        this.props.history.push('/selectScreen/ExternalAccessories')
        if (sessionStorage.getItem('deviceVersion')?.startsWith('1')) {
            const intervalId = sessionStorage.getItem('readSetIntervalId')
            if (intervalId !== null) {
                clearInterval(intervalId)
                sessionStorage.removeItem('readSetIntervalId')
                return
            }
        } else {
            try {
                //debugger;
                //await this.props.selectedComponents.webSerial.port.close();
                //await this.props.selectedComponents.webSerial.port.readable.releaseLock();
                if (!this.props.port.readable.locked) return
                this.props.reader.cancel()
                //await reader.cancel();
                this.props.reader.releaseLock()
            } catch (e) {}
        }
        this.props.saveToLocalStorage()
    }

    nextBtn = async () => {
        this.closeAiSelectScreen()
        this.props.check()
    }

    // check = async () => {
    //     let programMode = sessionStorage.programMode
    //     let connectedDevice = sessionStorage.connectedDevice
    //     let version = sessionStorage.deviceVersion
    //     if (
    //         programMode in JSON.parse(localStorage.getItem('recoveryData')) &&
    //         connectedDevice in
    //             JSON.parse(localStorage.getItem('recoveryData'))[programMode]
    //     ) {
    //         let updatelocalStorage = JSON.parse(
    //             localStorage.getItem('recoveryData')
    //         )
    //         updatelocalStorage[programMode][connectedDevice][version] =
    //             sessionStorage
    //         localStorage.setItem(
    //             'recoveryData',
    //             JSON.stringify(updatelocalStorage)
    //         )
    //     }

    //     if (this.state.isReading) {
    //         this.humanoidPARead()
    //         await new Promise((resolve) => setTimeout(resolve, 200))
    //     }

    //     this.setState({ readbytes: false })
    //     if (sessionStorage.getItem('deviceVersion') === '1.0.03') {
    //         if (
    //             sessionStorage.getItem('readSetIntervalId') !== null ||
    //             undefined
    //         ) {
    //             this.handleReadByte_v1()
    //         }
    //     }
    //     this.props.history.push('/logic')
    // }

    render() {
        var DD
        //  const { isDragging, connectDragSource } = this.props;
        if (sessionStorage.getItem('SelectedComp') != null) {
            DD = JSON.parse(sessionStorage.getItem('SelectedComp'))
        } else {
            DD = this.props.selectedComponents.concept.counter
        }
        // const { isDragging, connectDragSource } = this.props;

        const isComImgev1 =
            ['1', '2'].includes(sessionStorage.getItem('deviceVersion')[0]) ||
            ['Roboki', 'Atr', 'Toki', 'Moki'].includes(
                sessionStorage.getItem('connectedDevice')
            )

        return this.props.connectDropTarget(
            <div>
                <div>
                    {' '}
                    {DD.length !== 0 ? (
                        this.state.isExpand ? (
                            <>
                                <div
                                    style={{
                                        width: '20.5vw',
                                        height: '100%',
                                        // backgroundColor: 'rgb(246, 246, 246)',
                                        position: 'absolute',
                                        left: '-0.5%',
                                    }}
                                >
                                    <div
                                        className="user-select assemblySidebar"
                                        style={{
                                            height: Sizes.mainHeight - 14,
                                            overflowY: 'scroll',
                                            width: '20vw',
                                            height: '90vh',
                                            backgroundImage: `url("${renderPrgImage(
                                                'leftComponentBar'
                                            )}")`,
                                            overflowX: 'clip',
                                            backgroundRepeat: 'no-repeat',
                                            animation: '3s linear .5s colorIt',
                                            // marginTop: "-5px",
                                            position: 'fixed',
                                        }}
                                    >
                                        {/* <img style={{ height: "10%", width: "5%", margin: "2% 0 0 3%", float: "left" }} src="images/Learn/login_button_back.png" /> */}
                                        {/* <Link to="/concept">
                <div className="back_btn_assembly">BACK</div>
                </Link> */}

                                        {/* <img style={{height:"200px",width:"150px"}} src={`images/oldImages/component_`+this.props.data.type+'.png'}/> */}
                                        {/* sidebar images ,css changed by laxmi */}

                                        {DD.map((indComp, index) => {
                                            let divHeight = '25vh'
                                            if (
                                                indComp.type == 'RGB' ||
                                                indComp.type == 'OLED'
                                            ) {
                                                divHeight = '19vh'
                                            } else if (
                                                indComp.type == 'dc_motor'
                                            ) {
                                                divHeight = '23vh'
                                            } else if (
                                                [
                                                    'servo_motor_360',
                                                    'servo_motor',
                                                ].includes(indComp.type) &&
                                                !isComImgev1
                                            ) {
                                                divHeight = '23vh'
                                            } else if (
                                                [
                                                    'dual_splitter',
                                                    'joystick',
                                                ].includes(indComp.type)
                                            ) {
                                                divHeight = '23vh'
                                            } else if (
                                                [
                                                    'ultrasonic_sensor',
                                                    'speaker',
                                                ].includes(indComp.type)
                                            ) {
                                                divHeight = '19vh'
                                            } else if (
                                                ['peeCee_ATR'].includes(
                                                    indComp.type
                                                ) &&
                                                !isComImgev1
                                            ) {
                                                divHeight = '22vh'
                                            } else if (
                                                indComp.type == 'play_shield_v1'
                                            ) {
                                                divHeight = '22vh'
                                            } else if (
                                                indComp.type == 'pot' &&
                                                isComImgev1
                                            ) {
                                                divHeight = '26.5vh'
                                            } else if (
                                                indComp.type == 'linear_pot' &&
                                                !isComImgev1
                                            ) {
                                                divHeight = '17vh'
                                            } else if (
                                                indComp.type == 'linear_pot' &&
                                                isComImgev1
                                            ) {
                                                divHeight = '19vh'
                                            } else if (
                                                indComp.type == 'gas' &&
                                                !isComImgev1
                                            ) {
                                                divHeight = '28vh'
                                            } else if (
                                                indComp.type == 'dynamex_motor'
                                            ) {
                                                divHeight = '22vh'
                                            } else if (
                                                [
                                                    'Analog_3_Pin_Input',
                                                    'Analog_2_Pin_Input',
                                                    'PWM_3_Pin_Output',
                                                    'PWM_2_Pin_Output',
                                                    'Digital_3_Pin_Input',
                                                    'Digital_2_Pin_Input',
                                                    'Digital_3_Pin_Output',
                                                    'Digital_2_Pin_Output',
                                                ].includes(indComp.type)
                                            ) {
                                                divHeight = '27vh'
                                            }
                                            return (
                                                <div
                                                    key={index}
                                                    className="items"
                                                    style={{
                                                        position: 'relative',
                                                        marginBottom: '15px',
                                                        paddingBottom: '4vh',
                                                        // left: "24px",
                                                        // border: 'red 1px solid',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position:
                                                                'relative',
                                                            background:
                                                                '#F5F5F5',
                                                            height: divHeight,
                                                            width: '110%',
                                                            borderRadius:
                                                                '12px',
                                                            margin: '0 auto',
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            // border: 'green 1px solid',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                // height: "21.4vh",
                                                                width: '9vw',
                                                                // backgroundColor:
                                                                //     'rgba(245, 244, 244, 0.98)',
                                                                position:
                                                                    'absolute',
                                                                top: '10%',
                                                                left: '45%',
                                                                transform:
                                                                    'translate(-50%, -50%)',
                                                                borderRadius:
                                                                    '16px 16px 16px 16px',
                                                                // border: 'blue 1px solid',
                                                            }}
                                                        >
                                                            <Card
                                                                height="150"
                                                                key={index}
                                                                type={
                                                                    indComp.type
                                                                }
                                                                shield={
                                                                    this.props
                                                                        .shield
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <Card /> */}
                                    </div>
                                </div>
                                {/* sidebar, css changed by laxmi */}
                                <div
                                    id="expandSidebar"
                                    style={{
                                        position: 'absolute',
                                        height: '100px',
                                        width: '50px',
                                        top: '50%',
                                        left: '21.5%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundImage: `url("${renderPrgImage(
                                            'CloseleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        // zIndex: '150',
                                        zIndex: '2000',
                                    }}
                                    onClick={() => {
                                        this.handleExpand('close')
                                    }}
                                ></div>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        height: Sizes.mainHeight - 14,
                                        overflowY: 'scroll',
                                        width: '28px',
                                        height: '100vh',
                                        // backgroundColor: "#dfe4eb",
                                        backgroundImage: `url("${renderPrgImage(
                                            'smallleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                ></div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '100px',
                                        width: '50px',
                                        top: '50%',
                                        left: '28px',
                                        transform: 'translateY(-50%)',
                                        backgroundImage: `url("${renderPrgImage(
                                            'OpenleftComponentBar'
                                        )}")`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        zIndex: '2000',
                                    }}
                                    onClick={() => {
                                        this.handleExpand('open')
                                    }}
                                ></div>
                            </>
                        )
                    ) : null}
                </div>
                <div className="slide-animation">
                    <>
                        <img
                            className="no-animation"
                            style={{
                                width: '60px',
                                position: 'absolute',
                                top: '95%',
                                left: '5%',
                                transform: 'translate(-50%,-50%)',
                                zIndex: '100',
                                animation: 'none',
                                cursor: 'pointer',
                            }}
                            src={renderPrgImage('backBtn')}
                            draggable="false"
                            onClick={this.backBtn}
                            onMouseDown={() => {
                                // if (
                                //     sessionStorage.getItem(
                                //         'connectedDevice'
                                //     ) === 'Ace'
                                // )
                                this.props.stopPA()
                            }}
                        />

                        <img
                            className="no-animation"
                            style={{
                                width: '60px',
                                position: 'absolute',
                                top: '95%',
                                left: '95%',
                                transform: 'translate(-50%,-50%)',
                                zIndex: '100',
                                cursor: 'pointer',

                                // border: '2px solid red',
                            }}
                            src={renderPrgImage('nextBtn')}
                            draggable="false"
                            onClick={() => {
                                this.nextBtn()
                            }}
                            onMouseDown={() => {
                                // this.props.handleMouseDown()
                                this.props.stopPA()
                            }}
                        />
                    </>
                </div>

                {/* <img
                    // className="iconBtnSize imgBackBtn"
                    src={renderPrgImage('backBtn')}
                    onMouseDown={() => {
                        if (sessionStorage.getItem('connectedDevice') === 'Ace')
                            this.props.stopPA()
                    }}
                    onClick={this.backBtn}
                    draggable="false"
                    style={{
                        zIndex: '80',
                        // position: 'absolute',
                        bottom: '-1.5%',
                        left: '5%',
                        cursor: 'pointer',
                        transform: 'translate(-50%,-50%)',
                        width: '60px',
                        height: '60px',
                    }}
                /> */}

                {}
            </div>
        )
    }
}

Sidebar = DropTarget(ItemTypes.COMPONENT, sidebarTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
}))(Sidebar)

const mapStateToProps = (state) => {
    return {
        selectedComponents: state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAssemblyPageAi: (data) => {
            dispatch({ type: 'ASSEMBLY_PAGE_AI', payload: data })
        },
    }
}

Sidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar))
export default Sidebar
