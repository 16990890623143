const zingFreeFallSendBytes = ({ worker, value }) => {
    try {
        let bytes = [
            'F'.charCodeAt(0),
            'F'.charCodeAt(0),
            'E'.charCodeAt(0),
            'N'.charCodeAt(0),
        ]
        bytes.push(value)
        worker.postMessage({
            type: 'writeArray',
            value: bytes,
        })
    } catch (e) {
        console.error(e)
    }
}

export { zingFreeFallSendBytes }
