module.exports = {
    ACE: {
        A: { color: 'red', ports: ['A'] },
        B: { color: 'red', ports: ['B'] },
        C: { color: 'red', ports: ['C'] },
        D: { color: 'red', ports: ['D'] },
        E: { color: 'red', ports: ['E'] },
        F: { color: 'red', ports: ['F'] },
        G: { color: 'red', ports: ['G'] },
        H: { color: 'red', ports: ['H'] },
        A1: { color: 'red', ports: ['A1'] },
        B1: { color: 'red', ports: ['B1'] },
        C1: { color: 'red', ports: ['C1'] },
        D1: { color: 'red', ports: ['D1'] },
        E1: { color: 'red', ports: ['E1'] },
        F1: { color: 'red', ports: ['F1'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        servo_motor1: { color: 'red', ports: ['servo_motor1'] },
        A2: { color: 'red', ports: ['A2'] },
        B2: { color: 'red', ports: ['B2'] },
        C2: { color: 'red', ports: ['C2'] },
        D2: { color: 'red', ports: ['D2'] },
        E2: { color: 'red', ports: ['E2'] },
        F2: { color: 'red', ports: ['F2'] },
        servo_motor2: { color: 'red', ports: ['servo_motor2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },

        P0: { color: 'red', ports: ['P0'] },
        P1: { color: 'red', ports: ['P1'] },
        P2: { color: 'red', ports: ['P2'] },
        S1: { color: 'red', ports: ['S1'] },
        S2: { color: 'red', ports: ['S2'] },
        SS: { color: 'red', ports: ['SS'] },
        SPK: { color: 'red', ports: ['SPK'] },

        MOTOR1: { color: 'red', ports: ['MOTOR1'] },

        //STPM: { color: "yellow", ports: ["STPM"] },
    },
    HUMANOID: {
        A: { color: 'RED', ports: ['A'] },
        B: { color: 'RED', ports: ['B'] },
        C: { color: 'RED', ports: ['C'] },
        D: { color: 'RED', ports: ['D'] },
        A1: { color: 'RED', ports: ['A1'] },
        B1: { color: 'RED', ports: ['B1'] },
        C1: { color: 'RED', ports: ['C1'] },
        D1: { color: 'RED', ports: ['D1'] },
        A2: { color: 'RED', ports: ['A2'] },
        B2: { color: 'RED', ports: ['B2'] },
        C2: { color: 'RED', ports: ['C2'] },
        D2: { color: 'RED', ports: ['D2'] },
    },
    HEXAPOD: {
        A: { color: 'RED', ports: ['A'] },
        B: { color: 'RED', ports: ['B'] },
        C: { color: 'RED', ports: ['C'] },
        D: { color: 'RED', ports: ['D'] },
        A1: { color: 'RED', ports: ['A1'] },
        B1: { color: 'RED', ports: ['B1'] },
        C1: { color: 'RED', ports: ['C1'] },
        D1: { color: 'RED', ports: ['D1'] },
        A2: { color: 'RED', ports: ['A2'] },
        B2: { color: 'RED', ports: ['B2'] },
        C2: { color: 'RED', ports: ['C2'] },
        D2: { color: 'RED', ports: ['D2'] },
    },
    ROBOKI: {
        A: { color: 'red', ports: ['A'] },
        B: { color: 'red', ports: ['B'] },
        C: { color: 'red', ports: ['C'] },
        D: { color: 'red', ports: ['D'] },
        E: { color: 'red', ports: ['E'] },
        F: { color: 'red', ports: ['F'] },
        G: { color: 'red', ports: ['G'] },
        H: { color: 'red', ports: ['H'] },
        A1: { color: 'red', ports: ['A1'] },
        B1: { color: 'red', ports: ['B1'] },
        C1: { color: 'red', ports: ['C1'] },
        D1: { color: 'red', ports: ['D1'] },
        E1: { color: 'red', ports: ['E1'] },
        F1: { color: 'red', ports: ['F1'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        servo_motor1: { color: 'red', ports: ['servo_motor1'] },
        A2: { color: 'red', ports: ['A2'] },
        B2: { color: 'red', ports: ['B2'] },
        C2: { color: 'red', ports: ['C2'] },
        D2: { color: 'red', ports: ['D2'] },
        E2: { color: 'red', ports: ['E2'] },
        F2: { color: 'red', ports: ['F2'] },
        servo_motor2: { color: 'red', ports: ['servo_motor2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },

        P0: { color: 'red', ports: ['P0'] },
        P1: { color: 'red', ports: ['P1'] },
        P2: { color: 'red', ports: ['P2'] },
        S1: { color: 'red', ports: ['S1'] },
        S2: { color: 'red', ports: ['S2'] },
        SS: { color: 'red', ports: ['SS'] },
        SPK: { color: 'red', ports: ['SPK'] },

        MOTOR1: { color: 'red', ports: ['MOTOR1'] },

        //STPM: { color: "yellow", ports: ["STPM"] },
    },
    KLAW: {
        A: { color: 'RED', ports: ['A'] },
        B: { color: 'RED', ports: ['B'] },
        C: { color: 'RED', ports: ['C'] },
        D: { color: 'RED', ports: ['D'] },
        A1: { color: 'RED', ports: ['A1'] },
        B1: { color: 'RED', ports: ['B1'] },
        O1: { color: 'RED', ports: ['O1'] },
        A2: { color: 'RED', ports: ['A2'] },
        B2: { color: 'RED', ports: ['B2'] },
        O2: { color: 'RED', ports: ['O2'] },
        F1: { color: 'red', ports: ['F1'] },
        F2: { color: 'red', ports: ['F2'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },
        serial_servo1: { color: 'RED', ports: ['serial_servo1'] },
        serial_servo2: { color: 'RED', ports: ['serial_servo2'] },
        PW1: { color: 'RED', ports: ['PW1'] },
        PW2: { color: 'RED', ports: ['PW2'] },
        PW3: { color: 'RED', ports: ['PW3'] },
        PW4: { color: 'RED', ports: ['PW4'] },
        PW5: { color: 'RED', ports: ['PW5'] },
        PW6: { color: 'RED', ports: ['PW6'] },
    },
    ATR: {
        A: { color: 'red', ports: ['A'] },
        B: { color: 'red', ports: ['B'] },
        C: { color: 'red', ports: ['C'] },
        D: { color: 'red', ports: ['D'] },
        E: { color: 'red', ports: ['E'] },
        F: { color: 'red', ports: ['F'] },
        G: { color: 'red', ports: ['G'] },
        H: { color: 'red', ports: ['H'] },
        A1: { color: 'red', ports: ['A1'] },
        B1: { color: 'red', ports: ['B1'] },
        C1: { color: 'red', ports: ['C1'] },
        D1: { color: 'red', ports: ['D1'] },
        E1: { color: 'red', ports: ['E1'] },
        F1: { color: 'red', ports: ['F1'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        servo_motor1: { color: 'red', ports: ['servo_motor1'] },
        A2: { color: 'red', ports: ['A2'] },
        B2: { color: 'red', ports: ['B2'] },
        C2: { color: 'red', ports: ['C2'] },
        D2: { color: 'red', ports: ['D2'] },
        E2: { color: 'red', ports: ['E2'] },
        F2: { color: 'red', ports: ['F2'] },
        servo_motor2: { color: 'red', ports: ['servo_motor2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },

        P0: { color: 'red', ports: ['P0'] },
        P1: { color: 'red', ports: ['P1'] },
        P2: { color: 'red', ports: ['P2'] },
        S1: { color: 'red', ports: ['S1'] },
        S2: { color: 'red', ports: ['S2'] },
        SS: { color: 'red', ports: ['SS'] },
        SPK: { color: 'red', ports: ['SPK'] },

        MOTOR1: { color: 'red', ports: ['MOTOR1'] },

        //STPM: { color: "yellow", ports: ["STPM"] },
    },

    TOKI: {
        A: { color: 'red', ports: ['A'] },
        B: { color: 'red', ports: ['B'] },
        C: { color: 'red', ports: ['C'] },
        D: { color: 'red', ports: ['D'] },
        E: { color: 'red', ports: ['E'] },
        F: { color: 'red', ports: ['F'] },
        G: { color: 'red', ports: ['G'] },
        H: { color: 'red', ports: ['H'] },
        A1: { color: 'red', ports: ['A1'] },
        B1: { color: 'red', ports: ['B1'] },
        C1: { color: 'red', ports: ['C1'] },
        D1: { color: 'red', ports: ['D1'] },
        E1: { color: 'red', ports: ['E1'] },
        F1: { color: 'red', ports: ['F1'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        servo_motor1: { color: 'red', ports: ['servo_motor1'] },
        A2: { color: 'red', ports: ['A2'] },
        B2: { color: 'red', ports: ['B2'] },
        C2: { color: 'red', ports: ['C2'] },
        D2: { color: 'red', ports: ['D2'] },
        E2: { color: 'red', ports: ['E2'] },
        F2: { color: 'red', ports: ['F2'] },
        servo_motor2: { color: 'red', ports: ['servo_motor2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },
        P0: { color: 'red', ports: ['P0'] },
        P1: { color: 'red', ports: ['P1'] },
        P2: { color: 'red', ports: ['P2'] },
        S1: { color: 'red', ports: ['S1'] },
        S2: { color: 'red', ports: ['S2'] },
        SS: { color: 'red', ports: ['SS'] },
        SPK: { color: 'red', ports: ['SPK'] },
        MOTOR1: { color: 'red', ports: ['MOTOR1'] },
        //STPM: { color: "yellow", ports: ["STPM"] },
    },
    MOKI: {
        A: { color: 'red', ports: ['A'] },
        B: { color: 'red', ports: ['B'] },
        C: { color: 'red', ports: ['C'] },
        D: { color: 'red', ports: ['D'] },
        E: { color: 'red', ports: ['E'] },
        F: { color: 'red', ports: ['F'] },
        G: { color: 'red', ports: ['G'] },
        H: { color: 'red', ports: ['H'] },
        A1: { color: 'red', ports: ['A1'] },
        B1: { color: 'red', ports: ['B1'] },
        C1: { color: 'red', ports: ['C1'] },
        D1: { color: 'red', ports: ['D1'] },
        E1: { color: 'red', ports: ['E1'] },
        F1: { color: 'red', ports: ['F1'] },
        M1: { color: 'red', ports: ['M1'] },
        M2: { color: 'red', ports: ['M2'] },
        servo_motor1: { color: 'red', ports: ['servo_motor1'] },
        A2: { color: 'red', ports: ['A2'] },
        B2: { color: 'red', ports: ['B2'] },
        C2: { color: 'red', ports: ['C2'] },
        D2: { color: 'red', ports: ['D2'] },
        E2: { color: 'red', ports: ['E2'] },
        F2: { color: 'red', ports: ['F2'] },
        servo_motor2: { color: 'red', ports: ['servo_motor2'] },
        M3: { color: 'red', ports: ['M3'] },
        M4: { color: 'red', ports: ['M4'] },
        P0: { color: 'red', ports: ['P0'] },
        P1: { color: 'red', ports: ['P1'] },
        P2: { color: 'red', ports: ['P2'] },
        S1: { color: 'red', ports: ['S1'] },
        S2: { color: 'red', ports: ['S2'] },
        SS: { color: 'red', ports: ['SS'] },
        SPK: { color: 'red', ports: ['SPK'] },
        MOTOR1: { color: 'red', ports: ['MOTOR1'] },
        //STPM: { color: "yellow", ports: ["STPM"] },

        H1: { color: 'red', ports: ['H1'] },
        H2: { color: 'red', ports: ['H2'] },
        I1: { color: 'red', ports: ['I1'] },
        I2: { color: 'red', ports: ['I2'] },
        G1: { color: 'red', ports: ['G1'] },
        G2: { color: 'red', ports: ['G2'] },
    },
}
