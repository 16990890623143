import MainHeader from '../ReusableComponents/Header/MainHeader'
import PrgmSelection from '../ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'

import appMenu from '../../Assets/Bisoft_UI/AppMode/appMenu@2x.png'
import renderPrgImage from '../../source/programImg'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    getDeviceName,
    isAi1ShowCondition,
    stopByteCondition,
} from '../../utils/utils'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import { useState } from 'react'

function SelectCategory(props) {
    const history = useHistory()
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    // const [isShowLowerPcAiToolPopup, setIsShowLowerPcAiToolPopup] =
    //     useState(false)

    const item1Styl = {
        backgroundImage: `url("${renderPrgImage('softwareMode')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'all',
        // cursor: 'pointer',

        height: '330px',
        width: '330px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
    }
    const item2Styl = {
        backgroundImage: `url("${renderPrgImage('hardwareMode')}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        // cursor: 'pointer',

        height: '330px',
        width: '330px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        position: 'relative',
    }

    const emptyDivStyle = {
        // backgroundColor: 'rgb(245, 211, 208, 0.5)',
        cursor: 'pointer',
        height: '174px',
        width: '193px',
        position: 'absolute',
        top: '54px',
        left: '50px',
    }

    const enterHardWearAi = () => {
        if (props.webSerial.isConnected) {
            let sendStopBytes = stopByteCondition({
                connectedDevice,
                deviceVersion,
            })
            props.worker.postMessage({
                type: 'writeArray',
                value: sendStopBytes,
            })
        }
        history.push('/appMode/selectHardware')
    }
    const hardwareBtnClick = () => {
        // if (!isAi1ShowCondition({ connectedDevice, deviceVersion })) {
        //     setIsShowLowerPcAiToolPopup(true)
        // } else {
        enterHardWearAi()
        // }
    }

    return (
        <div>
            {/* Header */}
            <MainHeader
                help_btnPosition="right"
                helper={
                    <div
                        className="PS-S_slide"
                        style={{
                            left: '70%',
                            transform: 'translate(-50%)',
                        }}
                    >
                        <PrgmSelection />
                    </div>
                }
                goBack={() => {
                    // setSlideDirection(true)
                    // sessionStorage.setItem('slideDirection', true)
                    history.push('/Selection')
                }}
                isGoBack={true}
            />
            <img
                src={appMenu}
                style={{
                    position: 'absolute',
                    top: '8.5%',
                    left: '12%',
                    transform: 'translate(-50%,-50%)',
                    height: '22%',
                }}
            />

            {/* <DialogModal
                show={isShowLowerPcAiToolPopup}
                text={
                    <p>
                        {`AI-1 accessory is not compatible with the current firmware version. Please update the ${getDeviceName(
                            connectedDevice
                        )} using the firmware updation tool `}
                        <a href="https://firmware.plode.org" target="_blank">
                            Here.
                        </a>
                    </p>
                }
                showCloseBtn={true}
                handleDialogModalClose={() =>
                    setIsShowLowerPcAiToolPopup(false)
                }
            /> */}

            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div style={item1Styl}>
                    <p
                        style={{
                            fontSize: '1.3rem',
                            paddingBottom: '120px',
                            paddingRight: '30px',
                        }}
                    >
                        Software
                    </p>
                    <div
                        style={emptyDivStyle}
                        onClick={() => {
                            history.push('/appMode/selection')
                        }}
                    ></div>
                </div>
                <div style={item2Styl}>
                    <p
                        style={{
                            fontSize: '1.3rem',
                            paddingBottom: '120px',
                            paddingRight: '30px',
                        }}
                    >
                        Hardware
                    </p>
                    <div style={emptyDivStyle} onClick={hardwareBtnClick}></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        webSerial: state.webSerial,
        worker: state.worker,
        isConnecting: state.isConnecting,
        languageName: state.device.language,
    }
}

export default connect(mapStateToProps)(SelectCategory)
