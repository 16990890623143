import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { webSerialConnect } from '../../../../redux/actions'
import useLocalStorage from '../../../LocalStorage/LocalStorage'
import renderPrgImage from '../../../../source/programImg'
import inputInactiveURAT from '../../../../Assets/img/inputInactiveURAT.png'
import closeBtn from '../../../../Assets/img/close.png'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import cacheAssets from '../../../../utils/cacheAssets'
import HelpScreen from './components/HelpScreen'
import LeftToggleSection from './components/LeftToggleSection'
import RightToggleSection from './components/RightToggleSection'
import styles from './style.module.css'
import { getHederTextArr } from '../../../../utils/langaugeSupport'

const isPCv1 = sessionStorage.getItem('deviceVersion')?.startsWith('1')
    ? true
    : false

let bttnColor = []
let bttnColor2 = []
let bttType = [
    'a1-I/O',
    'a2-I/O',
    'b1-I/O',
    'b2-I/O',
    'c1-I/O',
    'c2-I/O',
    'd1-I/O',
    'd2-I/O',
    'e1-I/O',
    'e2-I/O',
    'f1-I/O',
    'f2-I/O',
    'm1-I/O',
    'm2-I/O',
    'm3-I/O',
    'm4-I/O',
    's1-I/O',
    's2-I/O',
]

function InputOutput(props) {
    const history = useHistory()
    const [isAnimating, setIsAnimating] = useState(false)
    const languageName = props.device.language

    useEffect(() => {
        setIsAnimating(false)
    }, [])
    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const backBtnAction = () => {
        saveDataToLocalStorage()

        history.push('/flow/selectports')
    }

    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    const next = () => {
        saveDataToLocalStorage()

        history.push('/flow/digital-analog')
    }
    for (let i = 0; i < 16; i++) {
        bttnColor[i] = '#717171'
        bttnColor2[i] = '#fcfcfc'
        if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
            bttnColor[i] = '#fcfcfc'
            bttnColor2[i] = '#717171'
        }
    }
    useLayoutEffect(() => {
        return () => {
            for (let i = 0; i < 16; i++) {
                if (JSON.parse(sessionStorage.getItem(bttType[i]))) {
                    bttnColor[i] = '#fcfcfc'
                    bttnColor2[i] = '#717171'
                } else {
                    bttnColor[i] = '#717171'
                    bttnColor2[i] = '#fcfcfc'
                }
            }
        }
    })

    const flowElements = JSON.parse(
        sessionStorage.getItem('flowchart-elements')
    )
    const A1 = JSON.parse(sessionStorage.getItem('A1'))
    const A2 = JSON.parse(sessionStorage.getItem('A2'))
    const B1 = JSON.parse(sessionStorage.getItem('B1'))
    const B2 = JSON.parse(sessionStorage.getItem('B2'))
    const C1 = JSON.parse(sessionStorage.getItem('C1'))
    const C2 = JSON.parse(sessionStorage.getItem('C2'))
    const D1 = JSON.parse(sessionStorage.getItem('D1'))
    const D2 = JSON.parse(sessionStorage.getItem('D2'))
    const E1 = JSON.parse(sessionStorage.getItem('E1'))
    const E2 = JSON.parse(sessionStorage.getItem('E2'))
    const F1 = JSON.parse(sessionStorage.getItem('F1'))
    const F2 = JSON.parse(sessionStorage.getItem('F2'))
    const M1 = JSON.parse(sessionStorage.getItem('M1'))
    const M2 = JSON.parse(sessionStorage.getItem('M2'))
    const M3 = JSON.parse(sessionStorage.getItem('M3'))
    const M4 = JSON.parse(sessionStorage.getItem('M4'))
    const S1 = JSON.parse(sessionStorage.getItem('S1'))
    const S2 = JSON.parse(sessionStorage.getItem('S2'))

    const isDistanceSensors = JSON.parse(
        sessionStorage.getItem('isDistanceSensors')
    )
    const isGestureSensor = JSON.parse(
        sessionStorage.getItem('isGestureSensor')
    )
    const isLightSensor = JSON.parse(sessionStorage.getItem('isLightSensor'))
    const isColorSensor = JSON.parse(sessionStorage.getItem('isColorSensor'))
    const isAccelerometer = JSON.parse(
        sessionStorage.getItem('isAccelerometer')
    )
    const isTemperature = JSON.parse(sessionStorage.getItem('isTemperature'))
    const isMic = JSON.parse(sessionStorage.getItem('isMic'))
    const isTouchZero = JSON.parse(sessionStorage.getItem('isTouchZero'))
    const isTouchOne = JSON.parse(sessionStorage.getItem('isTouchOne'))
    const isTouchTwo = JSON.parse(sessionStorage.getItem('isTouchTwo'))
    const isTouchThree = JSON.parse(sessionStorage.getItem('isTouchThree'))
    const isInternalEye = JSON.parse(sessionStorage.getItem('isInternalEye'))

    const isTouchZeroOutput = JSON.parse(
        sessionStorage.getItem('isTouchZeroOutput')
    )
    const isTouchOneOutput = JSON.parse(
        sessionStorage.getItem('isTouchOneOutput')
    )
    const isTouchTwoOutput = JSON.parse(
        sessionStorage.getItem('isTouchTwoOutput')
    )
    const isTouchThreeOutput = JSON.parse(
        sessionStorage.getItem('isTouchThreeOutput')
    )
    const isEyeLeft = JSON.parse(sessionStorage.getItem('isEyeLeft'))
    const isEyeRight = JSON.parse(sessionStorage.getItem('isEyeRight'))
    const isbuzzer = JSON.parse(sessionStorage.getItem('isBuzzer'))
    const isSimeleOne = JSON.parse(sessionStorage.getItem('isSmileOne'))
    const isSimeleTwo = JSON.parse(sessionStorage.getItem('isSmileTwo'))
    const isSimeleThree = JSON.parse(sessionStorage.getItem('isSmileThree'))
    const isSimeleFour = JSON.parse(sessionStorage.getItem('isSmileFour'))

    const [a1Checked, setA1Checked] = useLocalStorage('a1-I/O', false)
    const [a2Checked, setA2Checked] = useLocalStorage('a2-I/O', false)
    const [b1Checked, setB1Checked] = useLocalStorage('b1-I/O', false)
    const [b2Checked, setB2Checked] = useLocalStorage('b2-I/O', false)
    const [c1Checked, setC1Checked] = useLocalStorage('c1-I/O', false)
    const [c2Checked, setC2Checked] = useLocalStorage('c2-I/O', false)
    const [d1Checked, setD1Checked] = useLocalStorage(
        'd1-I/O',
        isPCv1 ? false : true
    )
    const [d2Checked, setD2Checked] = useLocalStorage(
        'd2-I/O',
        isPCv1 ? false : true
    )
    const [f1Checked, setF1Checked] = useLocalStorage('f1-I/O', false)
    const [f2Checked, setF2Checked] = useLocalStorage('f2-I/O', false)
    const [e1Checked, setE1Checked] = useLocalStorage('e1-I/O', false)
    const [e2Checked, setE2Checked] = useLocalStorage('e2-I/O', false)
    const [m1Checked, setM1Checked] = useLocalStorage('m1-I/O', false)
    const [m2Checked, setM2Checked] = useLocalStorage('m2-I/O', false)
    const [m3Checked, setM3Checked] = useLocalStorage('m3-I/O', false)
    const [m4Checked, setM4Checked] = useLocalStorage('m4-I/O', false)
    const [s1Checked, setS1Checked] = useLocalStorage('s1-I/O', false)
    const [s2Checked, setS2Checked] = useLocalStorage('s2-I/O', false)

    const [aUltra, setAUltra] = useLocalStorage('AUltra', false)
    const [cUltra, setCUltra] = useLocalStorage('CUltra', false)
    const [bRGB, setBRGB] = useLocalStorage('BRGB', false)
    const [bMP3, setBMP3] = useLocalStorage('BMP3', false)
    const [dOLED, setDOLED] = useLocalStorage('DOLED', false)
    const [uart, setUart] = useLocalStorage('uart', false)
    const [spi, setSpi] = useLocalStorage('spi', false)
    const [i2c, setI2c] = useLocalStorage('i2c', false)

    const [showPopupUart, setShowPopupUart] = useState(false)
    const [showPopupSpi, setShowPopupSpi] = useState(false)
    const [showPopupI2c, setShowPopupI2c] = useState(false)
    useEffect(() => {
        if (A1 && sessionStorage.getItem('a1-I/O') === null)
            sessionStorage.setItem('a1-I/O', false)
        if (A2 && sessionStorage.getItem('a2-I/O') === null)
            sessionStorage.setItem('a2-I/O', false)
        if (B1 && sessionStorage.getItem('b1-I/O') === null)
            sessionStorage.setItem('b1-I/O', false)
        if (B2 && sessionStorage.getItem('b2-I/O') === null)
            sessionStorage.setItem('b2-I/O', false)
        if (C1 && sessionStorage.getItem('c1-I/O') === null)
            sessionStorage.setItem('c1-I/O', false)
        if (C2 && sessionStorage.getItem('c2-I/O') === null)
            sessionStorage.setItem('c2-I/O', false)
        if (D1 && sessionStorage.getItem('d1-I/O') === null)
            sessionStorage.setItem('d1-I/O', isPCv1 ? false : true)
        if (D2 && sessionStorage.getItem('d2-I/O') === null)
            sessionStorage.setItem('d2-I/O', isPCv1 ? false : true)
        if (E1 && sessionStorage.getItem('e1-I/O') === null)
            sessionStorage.setItem('e1-I/O', false)
        if (E2 && sessionStorage.getItem('e2-I/O') === null)
            sessionStorage.setItem('e2-I/O', false)
        if (F1 && sessionStorage.getItem('f1-I/O') === null)
            sessionStorage.setItem('f1-I/O', false)
        if (F2 && sessionStorage.getItem('f2-I/O') === null)
            sessionStorage.setItem('f2-I/O', false)
        if (M1 && sessionStorage.getItem('m1-I/O') === null)
            sessionStorage.setItem('m1-I/O', false)
        if (M2 && sessionStorage.getItem('m2-I/O') === null)
            sessionStorage.setItem('m2-I/O', false)
        if (M3 && sessionStorage.getItem('m3-I/O') === null)
            sessionStorage.setItem('m3-I/O', false)
        if (M4 && sessionStorage.getItem('m4-I/O') === null)
            sessionStorage.setItem('m4-I/O', false)
        if (S1 && sessionStorage.getItem('s1-I/O') === null)
            sessionStorage.setItem('s1-I/O', true)
        if (S2 && sessionStorage.getItem('s2-I/O') === null)
            sessionStorage.setItem('s2-I/O', true)
    }, [])
    useEffect(() => {
        if (
            isPCv1 &&
            (isAccelerometer ||
                isColorSensor ||
                isDistanceSensors ||
                isLightSensor ||
                isGestureSensor)
        ) {
            sessionStorage.setItem('d1-I/O', true)
            sessionStorage.setItem('d2-I/O', true)
            setD1Checked(true)
            setD2Checked(true)
        }
    }, [])

    const closeModalUart = () => {
        setShowPopupUart(false)
    }

    const closeModalSp1 = () => {
        setShowPopupSpi(false)
    }

    const closeModalI2c = () => {
        setShowPopupI2c(false)
    }

    const a1CheckedState = async () => {
        console.log('qqqqqqqqq')
        if (!A1 || aUltra) {
            setToastMsg('Enable A1 To Enable A1 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (aUltra) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!A1 || aUltra) return
        setA1Checked(!a1Checked)
        await JSON.parse(sessionStorage.getItem('a1-I/O'))
        sessionStorage.setItem('A1Servo', false)
        if (!(await JSON.parse(sessionStorage.getItem('a1-I/O')))) {
            document.getElementById('in1').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s1').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in1').style.cssText = 'color: #717171; '
            document.getElementById('s1').style.cssText = '  color: #fcfcfc;'
        }
    }
    const a2CheckedState = async () => {
        if (!A2 || aUltra) {
            setToastMsg('Enable A2 To Enable A2 Input or Output ')
            setToastActive(true)
            disableToast()
        }
        if (aUltra) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!A2 || aUltra) return
        setA2Checked(!a2Checked)
        await JSON.parse(sessionStorage.getItem('a2-I/O'))
        sessionStorage.setItem('A2Servo', false)

        if (!(await JSON.parse(sessionStorage.getItem('a2-I/O')))) {
            document.getElementById('in2').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s2').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in2').style.cssText = 'color: #717171; '
            document.getElementById('s2').style.cssText = '  color: #fcfcfc;'
        }
    }

    const b1CheckedState = async () => {
        if (!B1 || bRGB || bMP3) {
            setToastMsg('Enable B1 To Enable B1 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (bRGB || bMP3) {
            console.log('WORKING 1')
            setToastMsg('Disable  RGB or MP3')
            setToastActive(true)
            disableToast()
        }

        if (!B1 || bRGB || bMP3) return
        setB1Checked(!b1Checked)
        await JSON.parse(sessionStorage.getItem('b1-I/O'))
        sessionStorage.setItem('B1Servo', false)

        if (!(await JSON.parse(sessionStorage.getItem('b1-I/O')))) {
            document.getElementById('in3').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s3').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in3').style.cssText = 'color: #717171; '
            document.getElementById('s3').style.cssText = '  color: #fcfcfc;'
        }
    }

    const b2CheckedState = async () => {
        if (!B2 || bRGB || bMP3) {
            setToastMsg('Enable B2 To Enable B2 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (bRGB || bMP3) {
            console.log('WORKING 1')
            setToastMsg('Disable  RGB or MP3')
            setToastActive(true)
            disableToast()
        }

        if (!B2 || bRGB || bMP3) return
        setB2Checked(!b2Checked)
        await JSON.parse(sessionStorage.getItem('b2-I/O'))
        sessionStorage.setItem('B2Servo', false)

        if (!(await JSON.parse(sessionStorage.getItem('b2-I/O')))) {
            document.getElementById('in4').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s4').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in4').style.cssText = 'color: #717171; '
            document.getElementById('s4').style.cssText = '  color: #fcfcfc;'
        }
    }
    const c1CheckedState = async () => {
        if (!C1 || cUltra) {
            setToastMsg('Enable C1 To Enable C1 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (cUltra) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }
        if (!C1 || cUltra) return
        setC1Checked(!c1Checked)
        await JSON.parse(sessionStorage.getItem('c1-I/O'))
        sessionStorage.setItem('C1Servo', false)

        if (!(await JSON.parse(sessionStorage.getItem('c1-I/O')))) {
            document.getElementById('in5').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s5').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in5').style.cssText = 'color: #717171; '
            document.getElementById('s5').style.cssText = '  color: #fcfcfc;'
        }
    }
    const c2CheckedState = async () => {
        if (!C2 || cUltra) {
            setToastMsg('Enable C2 To Enable C2 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (cUltra) {
            console.log('WORKING 1')
            setToastMsg('Disable  Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!C2 || cUltra) return
        setC2Checked(!c2Checked)
        await JSON.parse(sessionStorage.getItem('c2-I/O'))
        sessionStorage.setItem('C2Servo', false)

        if (!(await JSON.parse(sessionStorage.getItem('c2-I/O')))) {
            document.getElementById('in6').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s6').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in6').style.cssText = 'color: #717171; '
            document.getElementById('s6').style.cssText = '  color: #fcfcfc;'
        }
    }
    const d1CheckedState = () => {
        if (!D1 || dOLED) {
            setToastMsg('Enabled D1 to Enabled D1 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }

        if (
            D1 &&
            (isColorSensor ||
                isDistanceSensors ||
                isLightSensor ||
                isAccelerometer ||
                isGestureSensor) &&
            isPCv1
        ) {
            setToastMsg(' Only OLED can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        if ((D1 || dOLED) && !isPCv1) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        if (!D1 || dOLED) return
        setD1Checked(!d1Checked)
        sessionStorage.setItem('D1Servo', false)
        sessionStorage.setItem('D1Servo360', false)

        if (!JSON.parse(sessionStorage.getItem('d1-I/O'))) {
            document.getElementById('inD1').style.cssText = '  color: #fcfcfc;'
            document.getElementById('sD1').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('inD1').style.cssText = 'color: #717171; '
            document.getElementById('sD1').style.cssText = '  color: #fcfcfc;'
        }
    }

    const d2CheckedState = () => {
        if (!D2 || dOLED) {
            setToastMsg('Enabled D2 to Enabled D2 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }

        if (
            D2 &&
            (isColorSensor ||
                isDistanceSensors ||
                isLightSensor ||
                isAccelerometer ||
                isGestureSensor) &&
            isPCv1
        ) {
            setToastMsg(' Only OLED can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        if ((D2 || dOLED) && !isPCv1) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        setD2Checked(!d2Checked)
        sessionStorage.setItem('D2Servo', false)
        sessionStorage.setItem('D2Servo360', false)

        if (!JSON.parse(sessionStorage.getItem('d2-I/O'))) {
            document.getElementById('inD2').style.cssText = '  color: #fcfcfc;'
            document.getElementById('sD2').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('inD2').style.cssText = 'color: #717171; '
            document.getElementById('sD2').style.cssText = '  color: #fcfcfc;'
        }
    }

    const f1CheckedState = async () => {
        if (!F1) {
            setToastMsg('Enable F1 To Enable F1 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (!F1) return
        setF1Checked(!f1Checked)
        await JSON.parse(sessionStorage.getItem('f1-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('f1-I/O')))) {
            document.getElementById('in11').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s11').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in11').style.cssText = 'color: #717171; '
            document.getElementById('s11').style.cssText = '  color: #fcfcfc;'
        }
    }
    const f2CheckedState = async () => {
        if (!F2) {
            setToastMsg('Enable F2 To Enable F2 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (!F2) return
        setF2Checked(!f2Checked)
        await JSON.parse(sessionStorage.getItem('f2-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('f2-I/O')))) {
            document.getElementById('in12').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s12').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in12').style.cssText = 'color: #717171; '
            document.getElementById('s12').style.cssText = '  color: #fcfcfc;'
        }
    }

    const e1CheckedState = async () => {
        if (!E1) {
            setToastMsg('Enable E1 To Enable E1 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (!E1) return
        setE1Checked(!e1Checked)
        await JSON.parse(sessionStorage.getItem('e1-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('e1-I/O')))) {
            document.getElementById('in9').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s9').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in9').style.cssText = 'color: #717171; '
            document.getElementById('s9').style.cssText = '  color: #fcfcfc;'
        }
    }

    const e2CheckedState = async () => {
        if (!E2) {
            setToastMsg('Enable E2 To Enable E2 Input or Output')
            setToastActive(true)
            disableToast()
        }

        if (!E2) return
        setE2Checked(!e2Checked)
        await JSON.parse(sessionStorage.getItem('e2-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('e2-I/O')))) {
            document.getElementById('in10').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s10').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in10').style.cssText = 'color: #717171; '
            document.getElementById('s10').style.cssText = '  color: #fcfcfc;'
        }
    }
    const m1CheckedState = async () => {
        if (!M1) {
            setToastMsg('Enabled L1 to Enabled L1 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (M1) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
        }

        if (!M1) return
        setM1Checked(!m1Checked)
        await JSON.parse(sessionStorage.getItem('m1-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('m1-I/O')))) {
            document.getElementById('in13').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s13').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in13').style.cssText = 'color: #717171; '
            document.getElementById('s13').style.cssText = '  color: #fcfcfc;'
        }
    }
    const m2CheckedState = async () => {
        if (!M2) {
            setToastMsg('Enabled L2 to Enabled L2 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (M2) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
        }

        if (!M2) return
        setM2Checked(!m2Checked)
        await JSON.parse(sessionStorage.getItem('m2-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('m2-I/O')))) {
            document.getElementById('in14').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s14').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in14').style.cssText = 'color: #717171; '
            document.getElementById('s14').style.cssText = '  color: #fcfcfc;'
        }
    }
    const m3CheckedState = async () => {
        if (!M3) {
            setToastMsg('Enabled L3 to Enabled L3 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (M3) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
        }

        if (!M3) return
        setM3Checked(!m3Checked)
    }

    const m4CheckedState = async () => {
        if (!M4) {
            setToastMsg('Enabled L4 to Enabled L4 Input or Output')
            setToastActive(true)
            disableToast()
        }
        if (M4) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
        }

        if (!M4) return
        setM4Checked(!m4Checked)
    }

    const s1CheckedState = async () => {
        if (!S1) {
            setToastMsg('Enabled S1 to Enabled S1 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }
        if (S1) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        setS1Checked(!s1Checked)
        await JSON.parse(sessionStorage.getItem('s1-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('s1-I/O')))) {
            document.getElementById('in17').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s17').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in17').style.cssText = 'color: #717171; '
            document.getElementById('s17').style.cssText = '  color: #fcfcfc;'
        }
    }

    const s2CheckedState = async () => {
        if (!S2) {
            setToastMsg('Enabled S2 to Enabled S2 Input or Output')
            setToastActive(true)
            disableToast()
            return
        }
        if (S2) {
            setToastMsg(' Only output can be enabled ')
            setToastActive(true)
            disableToast()
            return
        }

        setS2Checked(!s2Checked)
        await JSON.parse(sessionStorage.getItem('s2-I/O'))

        if (!(await JSON.parse(sessionStorage.getItem('s2-I/O')))) {
            document.getElementById('in18').style.cssText = '  color: #fcfcfc;'
            document.getElementById('s18').style.cssText = 'color: #717171;'
        } else {
            document.getElementById('in18').style.cssText = 'color: #717171; '
            document.getElementById('s18').style.cssText = '  color: #fcfcfc;'
        }
    }

    const UltraA = () => {
        if (!A1 || !A2) {
            console.log('hiii ')
            setToastMsg(' Enabled A1 and A2 to enabled Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!A1 || !A2) return

        setAUltra(!aUltra)
        setA1Checked(true)
        setA2Checked(false)
    }
    const Mp3B = () => {
        if (!B1 || !B2) {
            console.log('hiii ')
            setToastMsg(' Enabled B1 and B2 to enabled MP3')
            setToastActive(true)
            disableToast()
        }

        if (!B1 || !B2) return
        setBMP3(!bMP3)
        setBRGB(false)
        setB1Checked(true)
        setB2Checked(true)
    }
    const RgbB = () => {
        if (!B1 || !B2) {
            console.log('hiii ')
            setToastMsg(' Enabled B1 and B2 to enabled RGB')
            setToastActive(true)
            disableToast()
        }

        if (!B1 || !B2) return
        setBRGB(!bRGB)
        setBMP3(false)
        setB1Checked(true)
        setB2Checked(true)
    }
    const OledD = () => {
        if (!D1 || !D2) {
            setToastMsg(' Enabled D1 and D2 to enabled OLED')
            setToastActive(true)
            disableToast()
        }
        if (isPCv1) {
            sessionStorage.setItem('d1-I/O', true)
            sessionStorage.setItem('d2-I/O', true)
            setD1Checked(true)
            setD2Checked(true)
        }

        if (!D1 || !D2) return
        setDOLED(!dOLED)
        if (flowElements !== null) {
            flowElements.map((value) => {
                if (value.type === 'output') {
                    sessionStorage.setItem(`oledChk1${value.id}`, false)
                    sessionStorage.setItem(`oled1${value.id}`, '')
                    sessionStorage.setItem(`oledChk2${value.id}`, false)
                    sessionStorage.setItem(`oled2${value.id}`, '')
                    sessionStorage.setItem(`oledChk3${value.id}`, false)
                    sessionStorage.setItem(`oled3${value.id}`, '')
                    sessionStorage.setItem(`oledChk4${value.id}`, false)
                    sessionStorage.setItem(`oled4${value.id}`, '')
                }
            })
        }
    }
    const UltraC = () => {
        if (!C1 || !C2) {
            console.log('hiii ')
            setToastMsg(' Enabled C1 and C2 to enabled Ultrasonic')
            setToastActive(true)
            disableToast()
        }

        if (!C1 || !C2) return
        setCUltra(!cUltra)
        setC1Checked(true)
        setC2Checked(false)
    }

    //set mports to output and digital
    sessionStorage.setItem('m1-I/O', true)
    // sessionStorage.setItem('M1DIGI', false)
    sessionStorage.setItem('m2-I/O', true)
    // sessionStorage.setItem('M2DIGI', false)
    sessionStorage.setItem('m3-I/O', true)
    // sessionStorage.setItem('M3DIGI', false)
    sessionStorage.setItem('m4-I/O', true)
    // sessionStorage.setItem('M4DIGI', false)

    const disableToast = () => {
        setTimeout(() => {
            setToastActive(false)
        }, 3000)
    }

    useEffect(() => {
        let assetsToBeCached = [closeBtn, inputInactiveURAT]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <>
            <ProgramHeader
                data={{
                    options: getHederTextArr({
                        languageName,
                        keyArr: [
                            'SelectPorts',
                            'InputOutput',
                            'DigitalAnalog',
                            'Flowchart',
                        ],
                    }),
                    selected: 1,
                }}
                showSave={true}
                showHelp={true}
                handleHelp={handleClickhelpbtn}
            />
            <div className={styles.MainContainerInputs}>
                {!isPCv1 && (
                    <div className={styles.CenterImg}>
                        <img
                            className={styles.ImgPcv0}
                            src={renderPrgImage('pcImg')}
                        />
                        <div className={styles.deviceContainer}>
                            {isEyeLeft ? (
                                <img
                                    src={renderPrgImage('internalEYEActive')}
                                    className={styles.imgStyleEyeL}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalEYEInActive')}
                                    className={styles.imgStyleEyeL}
                                />
                            )}
                            {isEyeRight ? (
                                <img
                                    src={renderPrgImage('internalEYEActive')}
                                    className={styles.imgStyleEyeR}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalEYEInActive')}
                                    className={styles.imgStyleEyeR}
                                />
                            )}
                            {isSimeleOne ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth1}
                                />
                            )}

                            {isSimeleTwo ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth2}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth2}
                                />
                            )}

                            {isSimeleThree ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth3}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth3}
                                />
                            )}

                            {isSimeleFour ? (
                                <img
                                    src={renderPrgImage('internalTeethActive')}
                                    className={styles.imgStyleTeeth4}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTeethInActive'
                                    )}
                                    className={styles.imgStyleTeeth4}
                                />
                            )}

                            {isDistanceSensors ||
                            isColorSensor ||
                            isGestureSensor ||
                            isLightSensor ? (
                                <img
                                    src={renderPrgImage('internal4in1Active')}
                                    className={styles.imgStyle4in1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internal4in1InActive')}
                                    className={styles.imgStyle4in1}
                                />
                            )}

                            {isMic ? (
                                <img
                                    src={renderPrgImage('internalMicActive')}
                                    className={styles.imgStyleMic}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage('internalMicInActive')}
                                    className={styles.imgStyleMic}
                                />
                            )}
                            {isbuzzer ? (
                                <img
                                    src={renderPrgImage('internalBuzzerActive')}
                                    className={styles.imgStyleBuzzer}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalBuzzerInActive'
                                    )}
                                    className={styles.imgStyleBuzzer}
                                />
                            )}

                            {isTouchZero ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads1}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads1}
                                />
                            )}
                            {isTouchOne ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads2}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads2}
                                />
                            )}

                            {isTouchTwo ? (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsActive'
                                    )}
                                    className={styles.imgStyleTouchpads3}
                                />
                            ) : (
                                <img
                                    src={renderPrgImage(
                                        'internalTouchpadsInActive'
                                    )}
                                    className={styles.imgStyleTouchpads3}
                                />
                            )}
                        </div>
                    </div>
                )}

                {isPCv1 && (
                    <div className={styles.Pcv1CenterImg}>
                        <img
                            className={styles.ImgPcv1}
                            src={renderPrgImage('PCv1')}
                        />
                        <div className={styles.deviceContainer}>
                            <div>
                                {[26.8, 32.8, 38.8, 45, 50.5, 57].map(
                                    (value, index) => {
                                        return (
                                            <>
                                                <img
                                                    className={
                                                        styles.isPCv1LeftEye
                                                    }
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        left: `${value}%`,
                                                    }}
                                                    draggable="false"
                                                />
                                                <img
                                                    className={
                                                        styles.isPCv1RightEye
                                                    }
                                                    src={
                                                        isInternalEye
                                                            ? renderPrgImage(
                                                                  'PcinternalEYEActive_v1'
                                                              )
                                                            : renderPrgImage(
                                                                  'PcinternalEYEInActive_v1'
                                                              )
                                                    }
                                                    style={{
                                                        left: `${value}%`,
                                                    }}
                                                    draggable="false"
                                                />
                                            </>
                                        )
                                    }
                                )}
                            </div>

                            <img
                                className={styles.isPCv1Teeth1}
                                src={
                                    isSimeleOne
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />
                            <img
                                className={styles.isPCv1Teeth2}
                                src={
                                    isSimeleTwo
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth3}
                                src={
                                    isSimeleThree
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth4}
                                src={
                                    isSimeleFour
                                        ? renderPrgImage(
                                              'PcinternalTeethActive_v1'
                                          )
                                        : renderPrgImage(
                                              'PcinternalTeethInActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Teeth5}
                                src={
                                    isDistanceSensors ||
                                    isColorSensor ||
                                    isGestureSensor ||
                                    isLightSensor
                                        ? renderPrgImage(
                                              'Pcinternal4in1Active_v1'
                                          )
                                        : renderPrgImage(
                                              'Pcinternal4in1InActive_v1'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1Mic}
                                src={
                                    isMic
                                        ? renderPrgImage('internalMicActive')
                                        : renderPrgImage('internalMicInActive')
                                }
                            />
                            <img
                                className={styles.isPCv1Buzzer}
                                src={
                                    isbuzzer
                                        ? renderPrgImage('internalBuzzerActive')
                                        : renderPrgImage(
                                              'internalBuzzerInActive'
                                          )
                                }
                            />

                            <img
                                className={styles.isPCv1TouchZero}
                                style={{
                                    background:
                                        isTouchZero || isTouchZeroOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchOne}
                                style={{
                                    background:
                                        isTouchOne || isTouchOneOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchTwo}
                                style={{
                                    background:
                                        isTouchTwo || isTouchTwoOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />

                            <img
                                className={styles.isPCv1TouchThree}
                                style={{
                                    background:
                                        isTouchThree || isTouchThreeOutput
                                            ? 'rgb(255 153 64 / 94%)'
                                            : 'rgb(176 174 161 / 95%)',
                                }}
                                draggable="false"
                            />
                        </div>
                    </div>
                )}

                <div
                    className={`page2-content ${
                        isAnimating ? 'slide-out2' : ''
                    }`}
                    style={{ width: '100vw' }}
                >
                    {' '}
                    <div className={styles.Inputs_ports_Container}>
                        <LeftToggleSection
                            A1={A1}
                            A2={A2}
                            B1={B1}
                            B2={B2}
                            E1={E1}
                            E2={E2}
                            M1={M1}
                            M2={M2}
                            S1={S1}
                            S2={S2}
                            aUltra={aUltra}
                            bttnColor={bttnColor}
                            bttnColor2={bttnColor2}
                            a1Checked={a1Checked}
                            a1CheckedState={a1CheckedState}
                            a2Checked={a2Checked}
                            a2CheckedState={a2CheckedState}
                            b1Checked={b1Checked}
                            b1CheckedState={b1CheckedState}
                            b2Checked={b2Checked}
                            b2CheckedState={b2CheckedState}
                            e1Checked={e1Checked}
                            e1CheckedState={e1CheckedState}
                            e2Checked={e2Checked}
                            e2CheckedState={e2CheckedState}
                            m1CheckedState={m1CheckedState}
                            m2CheckedState={m2CheckedState}
                            s1CheckedState={s1CheckedState}
                            s2CheckedState={s2CheckedState}
                            UltraA={UltraA}
                            bRGB={bRGB}
                            bMP3={bMP3}
                            RgbB={RgbB}
                            Mp3B={Mp3B}
                        />
                        <RightToggleSection
                            bttnColor={bttnColor}
                            bttnColor2={bttnColor2}
                            C1={C1}
                            C2={C2}
                            D1={D1}
                            D2={D2}
                            F1={F1}
                            F2={F2}
                            M3={M3}
                            M4={M4}
                            c1Checked={c1Checked}
                            c2Checked={c2Checked}
                            d1Checked={d1Checked}
                            d2Checked={d2Checked}
                            f1Checked={f1Checked}
                            f2Checked={f2Checked}
                            m3CheckedState={m3CheckedState}
                            m4CheckedState={m4CheckedState}
                            c1CheckedState={c1CheckedState}
                            c2CheckedState={c2CheckedState}
                            d1CheckedState={d1CheckedState}
                            d2CheckedState={d2CheckedState}
                            f1CheckedState={f1CheckedState}
                            f2CheckedState={f2CheckedState}
                            cUltra={cUltra}
                            UltraC={UltraC}
                            OledD={OledD}
                            dOLED={dOLED}
                            isDistanceSensors={isDistanceSensors}
                            isGestureSensor={isGestureSensor}
                            isLightSensor={isLightSensor}
                            isColorSensor={isColorSensor}
                            isAccelerometer={isAccelerometer}
                        />
                    </div>
                </div>
            </div>
            <div className="SelectScreenBottom">
                <div className="bottom-child">
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('nextBtn')}
                        onClick={next}
                    />
                </div>
            </div>
            <HelpScreen ishelpButton={ishelpButton} closeModal={closeModal} />
            <div
                className={`${styles['toast-msg']} ${
                    isToastActive
                        ? styles['toast-msg-active']
                        : styles['toast-msg-inactive']
                }`}
                ref={toastRef}
            >
                {toastMsg}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            console.log('mapDispatchToProps', data)
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputOutput)
