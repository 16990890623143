import styles from './wifiSetting.module.css'

function PasswordInputBox({ selectedWifi, setSelectedWifi }) {
    return (
        <div>
            {/* <p> Wifi networkName is {selectedWifi.wifiName}.</p> */}
            <p>Send Wi-Fi Password</p>
            <input
                onChange={(e) =>
                    setSelectedWifi({
                        ...selectedWifi,
                        password: e.target.value,
                    })
                }
                className={styles.passwordInput}
                placeholder="Enter Password"
                value={selectedWifi.password}
            />
        </div>
    )
}
export default PasswordInputBox
