const stopByteCondition = ({ connectedDevice, deviceVersion }) => {
    const PLAY_BYTES = [
        'P'.charCodeAt(0),
        'L'.charCodeAt(0),
        'A'.charCodeAt(0),
        'Y'.charCodeAt(0),
    ]

    try {
        const STOP_BYTES = [
            'S'.charCodeAt(0),
            'T'.charCodeAt(0),
            'O'.charCodeAt(0),
            'P'.charCodeAt(0),
        ]
        let sendStopBytes = PLAY_BYTES

        const stopConditions = [
            { device: 'Humanoid', minVersion: 4, startsWith: '2' },
            { device: 'Hexapod', minVersion: 3, startsWith: '2' },
            { device: 'Ace', minVersion: 7, startsWith: '1' },
            { device: 'Roboki', minVersion: 3 },
        ]

        if (
            stopConditions.some(
                ({ device, minVersion, startsWith }) =>
                    connectedDevice === device &&
                    (!startsWith || deviceVersion?.startsWith(startsWith)) &&
                    parseInt(deviceVersion?.slice(-1)) >= minVersion
            ) ||
            ['Klaw', 'Logiki', 'Toki', 'Atr', 'Moki'].includes(connectedDevice)
        ) {
            sendStopBytes = STOP_BYTES
        }

        return sendStopBytes
    } catch (e) {
        console.error(e)
        return PLAY_BYTES
    }
}

//ai not pracent for the following device and version
const isAi1NotPresent = ({ connectedDevice, deviceVersion }) => {
    const deviceList = [
        { device: 'Humanoid', startsWith: '1' },
        { device: 'Hexapod', startsWith: '1' },
        { device: 'Hexapod', startsWith: '2' },
        { device: 'Ace', startsWith: '0' },
        { device: 'Roboki', startsWith: '1' },
        { device: 'Moki', startsWith: '1' },
        { device: 'Toki', startsWith: '1' },
    ]

    if (
        deviceList.some(
            ({ device, startsWith }) =>
                connectedDevice === device &&
                (!startsWith || deviceVersion?.startsWith(startsWith))
        )
    ) {
        return true
    }

    return false
}

//check the Ai-1 is show for which device and which version
const isAi1ShowCondition = ({ connectedDevice, deviceVersion }) => {
    try {
        // const deviceConditions = {
        //     Ace: (version) => version?.startsWith('1'),
        //     Humanoid: (version) => version?.startsWith('2'),
        //     Hexapod: (version) => version?.startsWith('2'),
        //     Atr: () => true,
        //     Klaw: () => true,
        //     Roboki: () => true,
        // }

        // return deviceConditions[connectedDevice]?.(deviceVersion) ?? false
        const stopConditions = [
            { device: 'Humanoid', minVersion: 5, startsWith: '2' },
            // { device: 'Hexapod', minVersion: 3, startsWith: '2' },
            { device: 'Ace', minVersion: 8, startsWith: '1' },
            // { device: 'Roboki', minVersion: 3 },
        ]

        if (
            stopConditions.some(
                ({ device, minVersion, startsWith }) =>
                    connectedDevice === device &&
                    (!startsWith || deviceVersion?.startsWith(startsWith)) &&
                    parseInt(deviceVersion?.slice(-1)) >= minVersion
            ) ||
            ['Klaw', 'Atr'].includes(connectedDevice)
        ) {
            return true
        }

        return false
    } catch (e) {
        console.error(e)
        return false
    }
}

//delete the aiTranlist data from the localstorage if connectDevice change.
const ifAiTranDataDelete = (device) => {
    console.log(device)
    try {
        const aiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        // const connectedDevice = sessionStorage.getItem('connectedDevice')
        if (device !== aiTrainList?.connectedDevice) {
            localStorage.removeItem('aiTrainList')
        }
    } catch (e) {
        console.error(e)
        localStorage.removeItem('aiTrainList')
    }
}

const getDeviceName = (connectedDevice) => {
    switch (connectedDevice) {
        case 'Ace':
            return 'PeeCee'
        case 'Humanoid':
            return 'Zing'
        case 'Hexapod':
            return 'Crawl-e'
        default:
            return connectedDevice
    }
}

export {
    stopByteCondition,
    isAi1ShowCondition,
    ifAiTranDataDelete,
    getDeviceName,
    isAi1NotPresent,
}
