import React from 'react'
import selectStyle from './ActionsSelect.module.css'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'

import humanoid from '../../../Assets/images/ZingImage.png'
import hexapod from '../../../Assets/images/Create Actions/Hexapod.png'
import inactiveBtnOne from '../../../Assets/images/Create Actions/Component 31 – 71.png'
import activeBtnOne from '../../../Assets/images/Create Actions/Component 31 – 88.png'
import inactivebtntwo from '../../../Assets/images/Create Actions/Component 31 – 72.png'
import activeBtnTwo from '../../../Assets/images/Create Actions/Component 31 – 89.png'
import inactiveBtnThree from '../../../Assets/images/Create Actions/Component 31 – 3.png'
import activeBtnthree from '../../../Assets/images/Create Actions/activecomponent 31 – 90.png'
import inactivebtnfour from '../../../Assets/images/Create Actions/Component 31 – 74.png'
import activeBtnfour from '../../../Assets/images/Create Actions/Component 31 – 91.png'
import inactiveBtnfive from '../../../Assets/images/Create Actions/Component 31 – 75.png'
import activeBtnfive from '../../../Assets/images/Create Actions/Component 31 – 92.png'
import inactiveBtnsix from '../../../Assets/images/Create Actions/Component 31 – 76.png'
import activeBtnsix from '../../../Assets/images/Create Actions/Component 31 – 93.png'
import inactiveBtnseven from '../../../Assets/images/Create Actions/Component 31 – 77.png'
import activeBtnseven from '../../../Assets/images/Create Actions/Component 31 – 94.png'
import inactivebtneight from '../../../Assets/images/Create Actions/Component 31 – 78.png'
import activeBtneight from '../../../Assets/images/Create Actions/Component 31 – 95.png'
import inactivebtnnine from '../../../Assets/images/Create Actions/Component 31 – 79.png'
import activeBtnnine from '../../../Assets/images/Create Actions/Component 31 – 96.png'
import inactivebtnten from '../../../Assets/images/Create Actions/Component 31 – 80.png'
import activeBtnten from '../../../Assets/images/Create Actions/Component 31 – 97.png'
import inactivebtneleven from '../../../Assets/images/Create Actions/Component 31 – 81.png'
import activeBtneleven from '../../../Assets/images/Create Actions/Component 31 – 98.png'
import inactivebtntweleve from '../../../Assets/images/Create Actions/Component 31 – 82.png'
import activeBtnTweleve from '../../../Assets/images/Create Actions/Component 31 – 99.png'
import inactiveBtnthirteen from '../../../Assets/images/Create Actions/Component 31 – 83.png'
import activeBtnThirteen from '../../../Assets/images/Create Actions/Component 31 – 100.png'
import inactiveBtnfourteen from '../../../Assets/images/Create Actions/Component 31 – 67.png'
import activeBtnfourteen from '../../../Assets/images/Create Actions/Component 31 – 84.png'
import inactiveBtnfifteen from '../../../Assets/images/Create Actions/Component 31 – 68.png'
import activeBtnfifteen from '../../../Assets/images/Create Actions/Component 31 – 85.png'
import inactiveBtnsixteen from '../../../Assets/images/Create Actions/Component 31 – 69.png'
import activeBtnsixteen from '../../../Assets/images/Create Actions/Component 31 – 86.png'
import inactiveBtnseventeen from '../../../Assets/images/Create Actions/Component 31 - 101.png'
import activeBtnseventeen from '../../../Assets/images/Create Actions/Component 31 - 103.png'
import inactiveBtneighteen from '../../../Assets/images/Create Actions/Component 31 - 102.png'
import activeBtneighteen from '../../../Assets/images/Create Actions/Component 31 - 104.png'
import inactiveBtnH from '../../../Assets/images/Create Actions/Component 31 – 70.png'
import activeBtnH from '../../../Assets/images/Create Actions/Component 31 – 87.png'
import popupcard from '../../../Assets/images/Create Actions/popupcard.png'
import inactiveMenuOne from '../../../Assets/images/Create Actions/a-1.png'
import activeMenuOne from '../../../Assets/images/Create Actions/b-1.png'
import inactiveMenuTwo from '../../../Assets/images/Create Actions/a-2.png'
import activeMenuTwo from '../../../Assets/images/Create Actions/b-2.png'
import inactiveMenuThree from '../../../Assets/images/Create Actions/a-3.png'
import activeMenuThree from '../../../Assets/images/Create Actions/b-3.png'
import inactiveMenuFour from '../../../Assets/images/Create Actions/a-4.png'
import activeMenuFour from '../../../Assets/images/Create Actions/b-4.png'
import inactiveMenuFive from '../../../Assets/images/Create Actions/a-5.png'
import activeMenuFive from '../../../Assets/images/Create Actions/b-5.png'
import inactiveMenuSix from '../../../Assets/images/Create Actions/a-6.png'
import activeMenuSix from '../../../Assets/images/Create Actions/b-6.png'
import inactiveMenuSeven from '../../../Assets/images/Create Actions/a-7.png'
import activeMenuSeven from '../../../Assets/images/Create Actions/b-7.png'
import inactiveMenuEight from '../../../Assets/images/Create Actions/a-8.png'
import activeMenuEight from '../../../Assets/images/Create Actions/b-8.png'
import inactiveMenuNine from '../../../Assets/images/Create Actions/a-9.png'
import activeMenuNine from '../../../Assets/images/Create Actions/b-9.png'
import inactiveMenuTen from '../../../Assets/images/Create Actions/a-10.png'
import activeMenuTen from '../../../Assets/images/Create Actions/b-10.png'
import inactiveMenuEleven from '../../../Assets/images/Create Actions/a-11.png'
import activeMenuEleven from '../../../Assets/images/Create Actions/b-11.png'
import inactiveMenuTweleve from '../../../Assets/images/Create Actions/a-12.png'
import activeMenuTweleve from '../../../Assets/images/Create Actions/b-12.png'
import inactiveMenuThirteen from '../../../Assets/images/Create Actions/a-13.png'
import activeMenuThirteen from '../../../Assets/images/Create Actions/b-13.png'
import inactiveMenuFourteen from '../../../Assets/images/Create Actions/a-14.png'
import activeMenuFourteen from '../../../Assets/images/Create Actions/b-14.png'
import inactiveMenuFifteen from '../../../Assets/images/Create Actions/a-15.png'
import activeMenuFifteen from '../../../Assets/images/Create Actions/b-15.png'
import inactiveMenuSixteen from '../../../Assets/images/Create Actions/a-16.png'
import activeMenuSixteen from '../../../Assets/images/Create Actions/b-16.png'
import inactiveMenuSeventeen from '../../../Assets/images/Create Actions/a-17.png'
import activeMenuSeventeen from '../../../Assets/images/Create Actions/b-17.png'
import inactiveMenuEighteen from '../../../Assets/images/Create Actions/a-18.png'
import activeMenuEighteen from '../../../Assets/images/Create Actions/b-18.png'
import inactiveMenuH from '../../../Assets/images/Create Actions/a-h.png'
import activeMenuH from '../../../Assets/images/Create Actions/b-H.png'
import backbutton from '../../../Assets/images/back@2x.png'
import nextbutton from '../../../Assets/images/next@2x.png'
// import { useNavigate } from "react-router-dom";
import cacheAssets from '../../../utils/cacheAssets'

import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import { CREATEACTIONSTATE } from './defaultData'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'
import Slider from 'react-slick'
import leftArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import renderPrgImage from '../../../source/programImg'
import closeBtn from '../../../Assets/img/close.png'

function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    zIndex: '1000',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
}
const connectedDevice = sessionStorage.getItem('connectedDevice') || 'Humanoid'
const ALLBUTTONS = {
    1: {
        img: [activeBtnOne, inactiveBtnOne, activeMenuOne, inactiveMenuOne],
        style: [
            `btn-${connectedDevice.toLowerCase()}-one`,
            'inactive-Card-One',
        ],
    },
    2: {
        img: [activeBtnTwo, inactivebtntwo, activeMenuTwo, inactiveMenuTwo],
        style: [
            `btn-${connectedDevice.toLowerCase()}-two`,
            'inactive-Card-Two',
        ],
    },
    3: {
        img: [
            activeBtnthree,
            inactiveBtnThree,
            activeMenuThree,
            inactiveMenuThree,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-three`,
            'inactive-Card-Three',
        ],
    },
    4: {
        img: [activeBtnfour, inactivebtnfour, activeMenuFour, inactiveMenuFour],
        style: [
            `btn-${connectedDevice.toLowerCase()}-four`,
            'inactive-Card-Four',
        ],
    },
    5: {
        img: [activeBtnfive, inactiveBtnfive, activeMenuFive, inactiveMenuFive],
        style: [
            `btn-${connectedDevice.toLowerCase()}-five`,
            'inactive-Card-Five',
        ],
    },
    6: {
        img: [activeBtnsix, inactiveBtnsix, activeMenuSix, inactiveMenuSix],
        style: [
            `btn-${connectedDevice.toLowerCase()}-six`,
            'inactive-Card-Six',
        ],
    },
    7: {
        img: [
            activeBtnseven,
            inactiveBtnseven,
            activeMenuSeven,
            inactiveMenuSeven,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-seven`,
            'inactive-Card-Seven',
        ],
    },
    8: {
        img: [
            activeBtneight,
            inactivebtneight,
            activeMenuEight,
            inactiveMenuEight,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-eight`,
            'inactive-Card-Eight',
        ],
    },
    9: {
        img: [activeBtnnine, inactivebtnnine, activeMenuNine, inactiveMenuNine],
        style: [
            `btn-${connectedDevice.toLowerCase()}-nine`,
            'inactive-Card-Nine',
        ],
    },
    10: {
        img: [activeBtnten, inactivebtnten, activeMenuTen, inactiveMenuTen],
        style: [
            `btn-${connectedDevice.toLowerCase()}-ten`,
            'inactive-Card-Ten',
        ],
    },
    11: {
        img: [
            activeBtneleven,
            inactivebtneleven,
            activeMenuEleven,
            inactiveMenuEleven,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-eleven`,
            'inactive-Card-Eleven',
        ],
    },
    12: {
        img: [
            activeBtnTweleve,
            inactivebtntweleve,
            activeMenuTweleve,
            inactiveMenuTweleve,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-tweleve`,
            'inactive-Card-Tweleve',
        ],
    },
    13: {
        img: [
            activeBtnThirteen,
            inactiveBtnthirteen,
            activeMenuThirteen,
            inactiveMenuThirteen,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-thirteen`,
            'inactive-Card-Thirteen',
        ],
    },
    14: {
        img: [
            activeBtnfourteen,
            inactiveBtnfourteen,
            activeMenuFourteen,
            inactiveMenuFourteen,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-fourteen`,
            'inactive-Card-Fourteen',
        ],
    },
    15: {
        img: [
            activeBtnfifteen,
            inactiveBtnfifteen,
            activeMenuFifteen,
            inactiveMenuFifteen,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-fifteen`,
            'inactive-Card-Fifteen',
        ],
    },
    16: {
        img: [
            activeBtnsixteen,
            inactiveBtnsixteen,
            activeMenuSixteen,
            inactiveMenuSixteen,
        ],
        style: [
            `btn-${connectedDevice.toLowerCase()}-sixteen`,
            'inactive-Card-Sixteen',
        ],
    },
    //for all zing version
    ...(['1', '2'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    ) &&
        sessionStorage.getItem('connectedDevice') === 'Humanoid' && {
            H: {
                img: [activeBtnH, inactiveBtnH, activeMenuH, inactiveMenuH],
                style: ['btn-H', 'inactive-Card-H'],
            },
        }),
    ...(connectedDevice == 'Hexapod' && {
        17: {
            img: [
                activeBtnseventeen,
                inactiveBtnseventeen,
                activeMenuSeventeen,
                inactiveMenuSeventeen,
            ],
            style: [
                `btn-${connectedDevice.toLowerCase()}-seventeen`,
                `inactive-Card-${connectedDevice.toLowerCase()}-Seventeen`,
            ],
        },
        18: {
            img: [
                activeBtneighteen,
                inactiveBtneighteen,
                activeMenuEighteen,
                inactiveMenuEighteen,
            ],
            style: [
                `btn-${connectedDevice.toLowerCase()}-eighteen`,
                `inactive-Card-${connectedDevice.toLowerCase()}-Eighteen`,
            ],
        },
    }),
}
const IMAGE = {
    HEXAPOD: hexapod,
    HUMANOID: humanoid,
}
function Select(props) {
    const history = useHistory()
    // const zingVersion = sessionStorage.getItem('zingVersion') === '1.0.01'
    const isZingVersion12 =
        ['1', '2'].includes(
            (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        ) && ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))
    const [createActionState, setCreateActionState] =
        useState(CREATEACTIONSTATE)

    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')

    // loading existing createActionState or loading a new one
    useEffect(() => {
        const existingState = JSON.parse(
            sessionStorage.getItem('createActionState')
        )
        if (existingState) setCreateActionState(existingState)
    }, [])

    // Saving each change directly to the sessionStorage
    useEffect(() => {
        sessionStorage.setItem(
            'createActionState',
            JSON.stringify(createActionState)
        )
    })

    // Updating createActionState
    const handleButtonClick = (id) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        const newCreateActionState = { ...createActionState }
        newCreateActionState.commonActionState[id].isSelected =
            !createActionState.commonActionState[id].isSelected
        setCreateActionState(newCreateActionState)
    }

    const handleNextButton = () => {
        setSlideDirection(false)
        saverecoveryData()
        sessionStorage.setItem('slideDirection', false)
        history.push('/code/project/action/create')
    }

    const handleBackButton = () => {
        sessionStorage.removeItem('slideDirection')
        let saved = JSON.parse(sessionStorage.getItem('isSave'))
        if (!saved) setShowModal(true)
        else {
            clearActionFromSession()
            const currMode = sessionStorage.getItem('programMode')
            if (currMode === 'flowchartBased') history.push('/flow')
            else history.push('/code/project/')
        }
    }

    // Caching assets for the next page.

    useEffect(() => {
        let assetsToBeCached = [
            activeBtnH,
            activeBtnOne,
            activeBtnTwo,
            activeBtnthree,
            activeBtnfour,
            activeBtnfive,
            activeBtnsix,
            activeBtnseven,
            activeBtneight,
            activeBtnnine,
            activeBtnten,
            activeBtneleven,
            activeBtnTweleve,
            activeBtnThirteen,
            activeBtnfourteen,
            activeBtnfifteen,
            activeBtnsixteen,
            activeBtnseventeen,
            activeBtneighteen,
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const [showModal, setShowModal] = useState(false)

    const clearActionFromSession = () => {
        const newCreateActionState = { ...createActionState }
        Object.keys(newCreateActionState.commonActionState).forEach((id) => {
            newCreateActionState.commonActionState[id].isSelected = false
            CREATEACTIONSTATE.commonActionState[id].isSelected = false
        })
        setCreateActionState(newCreateActionState)
        sessionStorage.removeItem('createActionState')
    }

    const [ishelpButton, sethelpButton] = useState(false)
    const closeModal = () => {
        sethelpButton(false)
    }
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    // its save the createActionState data in local storage
    const saverecoveryData = () => {
        let newRecoveryData =
            JSON.parse(
                localStorage.getItem('recoveryData') || JSON.stringify({})
            ) || {}
        let newCreateActionState = JSON.parse(
            sessionStorage.getItem('createActionState')
        )

        //create the recoveryData object
        function addData(baseType, connectedDevice, deviceVersion, data) {
            if (!newRecoveryData[baseType]) {
                newRecoveryData[baseType] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice]) {
                newRecoveryData[baseType][connectedDevice] = {}
            }
            if (!newRecoveryData[baseType][connectedDevice][deviceVersion]) {
                newRecoveryData[baseType][connectedDevice][deviceVersion] = {}
            }
            newRecoveryData[baseType][connectedDevice][
                deviceVersion
            ].createActionState = data
        }

        addData(
            'actionBase',
            connectedDevice,
            deviceVersion,
            newCreateActionState
        )

        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    //delete the data from recovery object
    function deleteRecoveryData(newRecoveryData, baseType, device, version) {
        if (newRecoveryData[baseType] && newRecoveryData[baseType][device]) {
            delete newRecoveryData[baseType][device][version]

            // If the device has no more versions, delete the device
            if (Object.keys(newRecoveryData[baseType][device]).length === 0) {
                delete newRecoveryData[baseType][device]
            }

            // If the baseType has no more devices, delete the baseType
            if (Object.keys(newRecoveryData[baseType]).length === 0) {
                delete newRecoveryData[baseType]
            }
        }
        localStorage.setItem('recoveryData', JSON.stringify(newRecoveryData))
    }

    useEffect(() => {
        //if user close the window its save the createActionState data in local storage
        const handleBeforeUnload = (e) => {
            saverecoveryData()
        }
        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
    return (
        <>
            <DialogModal
                show={showModal}
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => {
                            let newRecoveryData =
                                JSON.parse(
                                    localStorage.getItem('recoveryData') ||
                                        JSON.stringify({})
                                ) || {}
                            deleteRecoveryData(
                                newRecoveryData,
                                'actionBase',
                                connectedDevice,
                                deviceVersion
                            )

                            clearActionFromSession()

                            //when leave from create action panel send 'Ra' to come attention mood
                            props.worker.postMessage({
                                type: 'writeArray',
                                value: ['R'.charCodeAt(0), 'a'.charCodeAt(0)],
                            })

                            const currMode =
                                sessionStorage.getItem('programMode')
                            if (currMode === 'flowchartBased')
                                history.push('/flow')
                            else history.push('/code/project/')
                            // window.location.reload()
                        },
                    },
                    {
                        text: 'NO',
                        do: () => {
                            setShowModal(false)
                        },
                    },
                ]}
            />
            <div className={selectStyle['select-container']}>
                <ProgramHeader
                    data={{
                        options: [
                            'Select',
                            'Create',
                            ...(!isZingVersion12 ? ['Save'] : ''),
                            '',
                        ],
                        selected: 0,
                    }}
                    showHelp={true}
                    handleHelp={handleClickhelpbtn}
                />

                <div
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    }`}
                    style={{ height: '100%' }}
                >
                    {' '}
                    <div className={selectStyle['body-container']}>
                        <div
                            className={
                                selectStyle[
                                    `select-${connectedDevice.toLowerCase()}`
                                ]
                            }
                            style={{
                                backgroundImage: `url(${
                                    IMAGE[connectedDevice.toUpperCase()]
                                })`,
                            }}
                        >
                            {Object.entries(ALLBUTTONS).map((button) => (
                                <>
                                    <img
                                        key={button[0]}
                                        className={`${
                                            selectStyle['sm-toggle-button']
                                        } ${selectStyle[button[1].style[0]]}`}
                                        src={
                                            createActionState.commonActionState[
                                                button[0]
                                            ].isSelected
                                                ? button[1].img[0]
                                                : button[1].img[1]
                                        }
                                        onClick={() =>
                                            handleButtonClick(button[0])
                                        }
                                    />
                                </>
                            ))}
                        </div>
                        <div
                            className={selectStyle['popupcard']}
                            style={{
                                backgroundImage: `url(${popupcard})`,
                                backgroundSize: isZingVersion12
                                    ? '75% 100%'
                                    : null,
                            }}
                        >
                            <div
                                className={
                                    selectStyle[
                                        `btnGroup-${connectedDevice.toLowerCase()}`
                                    ]
                                }
                                style={{
                                    left: isZingVersion12 ? '45%' : null,
                                }}
                            >
                                {Object.entries(ALLBUTTONS)
                                    .filter(([key, value]) => key !== 'H')
                                    .map((button) => (
                                        <button
                                            className={
                                                createActionState
                                                    .commonActionState[
                                                    button[0]
                                                ].isSelected
                                                    ? selectStyle.onBtn
                                                    : selectStyle.offBtn
                                            }
                                            onClick={() =>
                                                handleButtonClick(button[0])
                                            }
                                        >
                                            {button[0]}
                                        </button>
                                    ))}
                            </div>
                            {isZingVersion12 && (
                                <div className={selectStyle.btnGroup_H}>
                                    <button
                                        className={
                                            createActionState.commonActionState[
                                                'H'
                                            ].isSelected
                                                ? selectStyle.onBtn
                                                : selectStyle.offBtn
                                        }
                                        onClick={() => handleButtonClick('H')}
                                    >
                                        H
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <img
                        className={selectStyle['select-backbutton']}
                        src={backbutton}
                        onClick={handleBackButton}
                    />

                    <img
                        className={selectStyle['select-nextbutton']}
                        src={nextbutton}
                        onClick={handleNextButton}
                    />
                </div>

                {ishelpButton == true ? (
                    <div
                        style={{
                            height: '100vh',
                            width: '100vw',
                            background: '#fff',
                            position: 'relative',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: '1000',
                            position: 'absolute',
                        }}
                    >
                        <div
                            onClick={closeModal}
                            style={{
                                borderRadius: '50%',
                                zIndex: '2000',
                                position: 'absolute',
                                top: '15%',
                                right: '18%',
                            }}
                        >
                            <img
                                src={closeBtn}
                                style={{ width: '7vh', height: 'auto' }}
                            />
                        </div>
                        <Slider
                            {...settings}
                            style={{
                                transform: 'translate(-50%,-50%)',
                                top: '46%',
                                left: '50%',
                                zIndex: '10',
                                position: 'absolute',
                            }}
                        >
                            {/* First screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('ZingCreateActionH2')}
                                    style={{ width: '100%' }}
                                />
                                <div
                                    style={{
                                        // height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '54.5%',
                                        left: '41.7%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '7px',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        Shows the motor numbers and position on
                                        Zing/Crawl-e
                                    </p>
                                </div>
                            </div>
                            {/* second screen */}
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    background: '#fff',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    zIndex: '1000',
                                }}
                            >
                                <img
                                    src={renderPrgImage('ZingCreateActionH3')}
                                    style={{ width: '100%' }}
                                />

                                <div
                                    style={{
                                        // height: '6%',
                                        width: '3%',
                                        zIndex: '2000',
                                        position: 'absolute',
                                        top: '57.5%',
                                        left: '54.3%',
                                        background: '#fff',
                                        fontSize: '22px',
                                        borderRadius: '7px',
                                        transform: 'translate(-50%,-50%)',
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '2px',
                                            fontSize: '1.8vh',
                                            textAlign: 'left',
                                            color: '#707070',
                                            fontFamily: 'Halcyon_Regular',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        Buttons to enable/disable the motors and
                                        control in the actions programmed in the
                                        next screen.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>
                ) : null}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, null)(Select)
