import { useEffect, useRef, useState } from 'react'
import selectbarY from '../../Assets/Bisoft_UI/AppMode/selectbar.png'
import renderImage from '../../source/importImg'
import NavBar from '../AppMode/NavBar'
import styles from './wifiSetting.module.css'
import { connect } from 'react-redux'
import { appAiBytesGeneration } from '../AppMode/AppModeAI/AppModeAiBytesGeneration'
import PasswordInputBox from './PasswordInputBox'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import sign from 'jwt-encode'
import Processbar from './Processbar'
import Footer from '../ReusableComponents/FooterComponent'
import { useHistory } from 'react-router'

function WifiSetting(props) {
    // const [isShowConnectWifiIcon, setIsShowConnectWifiIcon] = useState(true)
    const [wifiList, setWifiList] = useState([])
    const [messageDialogModel, setMessageDialogModel] = useState({
        isShowDialogModel: false,
        message: '',
        modelType: '',
    })
    const [selectedWifi, setSelectedWifi] = useState({
        isShowPasswordModel: false,
        wifiName: '',
        password: '',
    })
    const [processBar, setProcessBar] = useState({
        isShowProcessBar: false,
        isProcessCompleted: true,
        processStep: 0,
    })

    const isStopScan = useRef(true)
    const sendDataAsPacket = useRef({ byteSend: false, responseMessage: '' })
    let history = useHistory()

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: '',
                }
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }
    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!sendDataAsPacket.current.byteSend) {
                    return resolve(sendDataAsPacket.current.responseMessage)
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    //byte sending function
    const sendByte = (bytes) => {
        props.worker.postMessage({
            type: 'WriteArrayFormat1310',
            value: bytes,
        })
        sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
    }

    //fetch the list of wifi network present
    const scanWifi = async (e) => {
        try {
            let scanByte = appAiBytesGeneration('scanWifiByte')
            sendByte(scanByte)

            let replyOK = await Promise.race([
                checkOk(),
                timeoutPromise(60000), //
            ])
            // isStopScan.current = true
        } catch (e) {
            isStopScan.current = true
            console.error(e)
        }
    }

    useEffect(() => {
        let interVal = null
        if (props.webSerial.isConnected) {
            isStopScan.current = false
            scanWifi()
            interVal = setInterval(() => {
                if (interVal && isStopScan.current) {
                    clearInterval(interVal)
                    interVal = null
                }
                if (!isStopScan.current) {
                    scanWifi()
                }
            }, 6100)
        }
        return () => {
            clearInterval(interVal)
        }
    }, [props.webSerial.isConnected])

    //open the password model
    const handelWifiNameClick = (e, wifiName) => {
        setSelectedWifi({
            ...selectedWifi,
            isShowPasswordModel: true,
            wifiName,
        })
    }

    //wifi connection byte setup
    //------------------------------------------------------------------------------------------------------------------------------------------
    //connection message show in  popup model function
    const showMessage = (message) => {
        setMessageDialogModel({ isShowDialogModel: true, message })
    }

    //execute the step by step byte sending or catching the error function
    const executeStep = async ({ byteType, value, expectedResponse }) => {
        try {
            sendByte(appAiBytesGeneration(byteType, value))
            let response = await Promise.race([checkOk(), timeoutPromise(5000)])
            return response[3] == expectedResponse[0] && response[4] == 1
        } catch (e) {
            console.error(e)
            throw new Error(e.message)
        }
    }

    //connect the wifi and byte sending
    const connectWifi = async () => {
        try {
            // showMessage('Connecting Wifi ......')
            setProcessBar({
                isShowProcessBar: true,
                processStep: 0,
                isProcessCompleted: false,
            })
            setSelectedWifi({ ...selectedWifi, isShowPasswordModel: false })

            const steps = [
                {
                    byteType: 'setWifiSSIDByte',
                    value: selectedWifi.wifiName,
                    expectedResponse: [0x3e, 1],
                },
                {
                    byteType: 'setWifiPasswordByte',
                    value: selectedWifi.password,
                    expectedResponse: [0x4e, 1],
                },
                {
                    byteType: 'wifiConnectByte',
                    value: null,
                    expectedResponse: [0x5e, 1],
                },

                {
                    byteType: 'httpJWTByte',
                    value: localStorage.getItem('paAiJWTToken'),
                    expectedResponse: [0x84, 1],
                },
                {
                    byteType: 'httpHostByte',
                    value: null,
                    expectedResponse: [0x82, 1],
                },
                {
                    byteType: 'httpPathByte',
                    value: null,
                    expectedResponse: [0x83, 1],
                },
                {
                    byteType: 'startChatByte',
                    value: null,
                    expectedResponse: [0xcb, 1],
                },
                {
                    byteType: 'checkWifiConnectByte',
                    value: null,
                    expectedResponse: [0x7e, 1],
                },
            ]

            for (const [index, step] of steps.entries()) {
                if (!(await executeStep(step))) {
                    await new Promise((reslove) => setTimeout(reslove, 2000))
                    setProcessBar({
                        isShowProcessBar: false,
                        isProcessCompleted: true,
                        processStep: 0,
                    })
                    showMessage('Wifi Connection Fail!')
                    await new Promise((reslove) => setTimeout(reslove, 2000))
                    setMessageDialogModel({
                        isShowDialogModel: false,
                        message: '',
                    })

                    return
                }

                setProcessBar({
                    isShowProcessBar: true,
                    isProcessCompleted: false,
                    processStep: index + 1,
                })
            }

            setProcessBar({
                isShowProcessBar: true,
                isProcessCompleted: true,
                processStep: 8,
            })
        } catch (e) {
            console.error(e)
            setProcessBar({
                isShowProcessBar: false,
                isProcessCompleted: true,
                processStep: 0,
            })
            showMessage('An error occurred while connecting to Wifi!')
            await new Promise((reslove) => setTimeout(reslove, 1500))
            setMessageDialogModel({ isShowDialogModel: false, message: '' })
        }
    }

    //-----------------------------------------------------------------------------------------------
    const cookiePostRequest = async ({ token, url }) => {
        try {
            let data = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' },
                body: token,
            })

            return data
        } catch (e) {
            console.log('ERROR', e)
            return new Error('ERROR')
        }
    }
    const getCookies = async ({ token, url }) => {
        try {
            let data = await Promise.race([
                cookiePostRequest({ token, url }),
                timeoutPromise(5000), //
            ])
            console.log('VVVVVVVVV', data)
            if (data.ok) {
                let jsonData = await data.json()
                if (jsonData?.value?.Jwt) {
                    console.log(jsonData)
                    localStorage.setItem('paAiJWTToken', jsonData?.value?.Jwt)
                }
            } else {
                showMessage('Server error!')
                setTimeout(() => {
                    setMessageDialogModel({
                        isShowDialogModel: false,
                        message: '',
                    })
                }, 1000)
            }
        } catch (e) {
            console.log('ERROR', e)
            showMessage('Server error!')
            setTimeout(() => {
                setMessageDialogModel({ isShowDialogModel: false, message: '' })
            }, 1000)
        }
    }

    //get the JWT token
    const getAiJwtToken = async () => {
        try {
            const userData = JSON.parse(localStorage.getItem('atatus-user'))
            const SECRET_KEY = 'hsgduwewooi&'
            const data = {
                email: userData.email,
                ref_id: userData.id,
            }
            const aiUserDetailsToken = sign(data, SECRET_KEY)
            // const aiUserDetailsToken =
            //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6InRlc3QiLCJyZWZfaWQiOiJyZWZfaWQiLCJlbWFpbCI6InNvbWV0aGluZyJ9.N12uOol-Sg2vERSNuReSALAVrou_v7DkvGsNdDxSoCQ' //sign(data, secretString)
            if (
                await getCookies({
                    token: aiUserDetailsToken,
                    url: 'https://iot.plode.org/api/v1/users/auth',
                })
            ) {
                alert('Login Failed to get token')
                return
            }
            console.log('HHHHHHHH', data)
        } catch (e) {
            console.error(e)
            showMessage('Server error!')
            setTimeout(() => {
                setMessageDialogModel({ isShowDialogModel: false, message: '' })
            }, 1000)
        }
    }

    useEffect(() => {
        getAiJwtToken()

        //read data from serial worker
        const messageHandler = (e) => {
            console.log('NNNNNNNNNNNNNN', e.data)

            //scan the wifi and get all list of present wifi
            if (e.data.type === 'actionRead') {
                try {
                    let { arrayData, stringData } = e.data.value

                    //scan wifiList
                    const regexEqu = /^65,76,(?:\d+,){3}((?:\d+,)*?)255,245$/
                    let match = arrayData.join(',').match(regexEqu)
                    if (match) {
                        console.log('match[1]', match[1])
                        const matchArr = match[1].split(',')
                        const stringData = String.fromCharCode(...matchArr)
                        let stringArr = stringData.split('\n')

                        //delete the last garbage element
                        stringArr.pop()
                        console.log(stringArr)

                        setWifiList(stringArr)
                        isStopScan.current = true
                        sendDataAsPacket.current = {
                            byteSend: false,
                            responseMessage: '',
                        }
                    }

                    //
                    const pattern = /^65,73,.*255,245$/
                    const isPatternMatch = pattern.test(arrayData.join(','))
                    if (isPatternMatch) {
                        sendDataAsPacket.current = {
                            byteSend: false,
                            responseMessage: arrayData,
                        }
                    }
                } catch (e) {
                    console.log('ERROR', e)
                }
            }
        }

        props.worker.addEventListener('message', messageHandler)

        return () => {
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [])

    return (
        <div>
            {/* -------password dilalog model -------- */}
            <DialogModal
                show={selectedWifi.isShowPasswordModel}
                text={
                    <PasswordInputBox
                        selectedWifi={selectedWifi}
                        setSelectedWifi={setSelectedWifi}
                    />
                }
                showCloseBtn={true}
                handleDialogModalClose={() =>
                    setSelectedWifi({
                        ...selectedWifi,
                        isShowPasswordModel: false,
                    })
                }
                optionsToSelect={[
                    {
                        text: 'Conform',
                        do: () => connectWifi(),
                    },
                ]}
            />

            {/* -----error, successful, process dilalog model -------*/}
            <DialogModal
                show={messageDialogModel.isShowDialogModel}
                text={messageDialogModel.message}
                showCloseBtn={false}
            />

            {/* NavBar */}
            <div>
                <NavBar
                    selectbar={selectbarY}
                    selectbarwidth="20%"
                    headers={[
                        { name: 'Wifi Setting', color: 'white' },
                        { name: '', color: 'black' },
                        { name: '', color: 'black' },
                    ]}
                    showHelp={false}
                    // handleHelp={handleClickhelpbtn}
                />
            </div>
            {/* processBar.isShowProcessBar  */}
            {processBar.isShowProcessBar && (
                <Processbar
                    processStep={processBar.processStep}
                    isProcessCompleted={processBar.isProcessCompleted}
                />
            )}

            {/* -------- body ------------------- */}
            {!processBar.isShowProcessBar && (
                <div className={styles.pageBody}>
                    <div className={styles.wifiScanDiv}>
                        <p className={styles.initiatingText}>
                            Initiating Process...
                        </p>

                        <div>
                            <img
                                className={styles.wifiImage}
                                src={renderImage('wifiIcon')}
                                alt="wifi icon"
                            />
                        </div>
                    </div>
                    <div className={styles.wifiDiv}>
                        {/* <div className={styles.wifiListDiv}> */}
                        {wifiList.map((ele, ind) => {
                            return (
                                <p
                                    onClick={(e) => handelWifiNameClick(e, ele)}
                                    key={ind}
                                    className={styles.wifiName}
                                >
                                    {ele}
                                </p>
                            )
                        })}
                        {/* </div> */}
                    </div>
                </div>
            )}

            <div
                style={{
                    width: '100%',
                    position: 'relative',
                    top: '5%',
                    transform: 'translateY(-50%)',
                }}
            >
                <Footer
                    goBack={() => {
                        if (processBar.isProcessCompleted) {
                            setProcessBar({
                                isShowProcessBar: false,
                                isProcessCompleted: true,
                                processStep: 0,
                            })
                            history.push('./selection')
                        }
                    }}
                    isGoNext={false}
                    // goNext={this.check}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        worker: state.worker,
        webSerial: state.webSerial,
    }
}

export default connect(mapStateToProps)(WifiSetting)
