import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import renderImage from '../../source/importImg'
import AdvanceSettingStyle from './AdvanceSetting.module.css'
import KitSelectStyle from '../Login/KitSelect.module.css'
import cacheAssets from '../../utils/cacheAssets'
import AdvanceSlider from '../ReusableComponents/AdvanceSetSlider/AdvanceSlider'
import { decrypt, generateBrowserFingerprint } from '../Login/JWTverify'
import { languageMenu } from '../../utils/langaugeSupport'
import { connect } from 'react-redux'
import { setLanguageSelect } from '../../redux/actions'

function UserAccount(props) {
    let history = useHistory()
    const [languageDivSelect, setLanguageDivSelect] = useState(false)
    const [languageValue, setLanguageValue] = useState(props.device.language)
    const gobackUrl = () => {
        history.goBack()
    }

    const [isHelp, setHelp] = useState(false)
    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const [googleUserData, setGoogleUserData] = useState({})

    const getDataFromLocalStorage = () => {
        return new Promise((resolve, reject) => {
            let userAccount = localStorage.getItem('userJWT')

            if (userAccount) {
                resolve(userAccount ?? null)
            }
        })
    }

    useEffect(async () => {
        const visitorId = await generateBrowserFingerprint()
        const userJWT = await getDataFromLocalStorage()
        if (!userJWT) return
        const verify = await decrypt(userJWT, visitorId)
        if (verify) {
            const userData = {
                userAccount_email: verify.email,
                userAccount_name: verify.name,
                userAccount_Img: verify.image,
            }
            setGoogleUserData(userData)
        }
    }, [])

    let userAccount = localStorage.getItem('userJWT')

    const userLogout = () => {
        localStorage.removeItem('userJWT')
        localStorage.removeItem('atatus-user')
        history.push('/login')
    }

    useEffect(
        () => () => {
            if (!localStorage.getItem('userJWT')) window.location.reload()
        },
        []
    )

    useEffect(() => {
        let assetsToBeCached = [
            renderImage('backBtn'),
            renderImage('deleteAcBtn'),
            renderImage('helpBtnInActive'),
            googleUserData.userAccount_Img,
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const [imageError, setImageError] = useState(false)

    const handleImageError = () => {
        setImageError(true)
    }

    const languageActionHandel = () => {
        props.setLanguageSelect({ language: languageValue })
        setLanguageDivSelect(false)
    }
    return (
        <>
            <div className={AdvanceSettingStyle.Main_Select}>
                <div className={AdvanceSettingStyle.Select_Header}>
                    <div>
                        <img
                            className={AdvanceSettingStyle.Back_Btn}
                            src={renderImage('backBtn')}
                            onClick={gobackUrl}
                        ></img>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <p className={AdvanceSettingStyle.text}>
                            Advance Setting
                        </p>
                    </div>
                    <div id={AdvanceSettingStyle.Help_Bttn}>
                        <div className="header_button">
                            <img
                                className={AdvanceSettingStyle.Help_Bttn_Img}
                                src={renderImage('helpBtnInActive')}
                                onClick={handleHelpBtn}
                            ></img>
                            <span
                                className="tooltiptext"
                                style={{ left: '90.3%' }}
                            >
                                {' '}
                                <p>Help</p>
                            </span>
                        </div>

                        {/* {isHelp == false ? (
                            <img
                                className={AdvanceSettingStyle.Help_Bttn_Img}
                                src={renderImage('helpBtnInActive')}
                                onClick={handleHelpBtn}
                            ></img>
                        ) : (
                            <div style={{ position: 'relative' }}>
                                {' '}
                                <div className={AdvanceSettingStyle.S_slide}>
                                    <AdvanceSlider />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '23vh',
                                        left: '40vw',
                                        transform: 'translate(-50%,-50%)',
                                        zIndex: '100000',
                                    }}
                                >
                                    <img
                                        className={
                                            AdvanceSettingStyle.helpClose
                                        }
                                        src={renderImage('clos')}
                                        onClick={handleHelpBtn}
                                    ></img>
                                </div>
                            </div>
                        )} */}
                    </div>
                </div>
                <div className={AdvanceSettingStyle.setting_Body}>
                    {/* <div>
                        {' '}
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv2}
                            src={renderImage('deleteAcBtn')}
                        ></img>
                        <div
                            className={AdvanceSettingStyle.Body_Text2}
                            onClick={userAccount && userLogout}
                        >
                            {' '}
                            <p className={AdvanceSettingStyle.P_Text2}>
                                Log out Your Account
                            </p>
                            {Object.keys(googleUserData).length ? (
                                <>
                                    {' '}
                                    <img
                                        style={{
                                            width: '5vh',
                                            borderRadius: '51%',
                                        }}
                                        src={googleUserData.userAccount_Img}
                                    />
                                    {userAccount && (
                                        <span
                                            className={
                                                AdvanceSettingStyle.tooltiptext
                                            }
                                        >
                                            <p style={{ color: ' #E4E5E4' }}>
                                                Google Account
                                            </p>
                                            <p>
                                                {
                                                    googleUserData.userAccount_name
                                                }
                                            </p>
                                            <p>
                                                {' '}
                                                {
                                                    googleUserData.userAccount_email
                                                }
                                            </p>
                                        </span>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div> */}
                    <div className={AdvanceSettingStyle.setting_Body_left}>
                        <div
                            className={
                                AdvanceSettingStyle.account_setting_left_inner
                            }
                        >
                            <div
                                className={`${
                                    AdvanceSettingStyle.language_setting_div
                                } ${
                                    languageDivSelect
                                        ? AdvanceSettingStyle[
                                              'language_setting_div_select'
                                          ]
                                        : ''
                                }`}
                                onClick={() =>
                                    setLanguageDivSelect(!languageDivSelect)
                                }
                            >
                                Language
                            </div>

                            <div
                                className={
                                    AdvanceSettingStyle.account_setting_div
                                }
                                onClick={userAccount && userLogout}
                            >
                                <p style={{ marginBottom: '0' }}>
                                    Log out Your Account
                                </p>
                                {Object.keys(googleUserData).length ? (
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            style={{
                                                width: '5vh',
                                                borderRadius: '51%',
                                                marginLeft: '15px',
                                            }}
                                            src={googleUserData.userAccount_Img}
                                        />

                                        {userAccount && (
                                            <div
                                                className={
                                                    AdvanceSettingStyle.tooltiptext
                                                }
                                            >
                                                <p
                                                    style={{
                                                        color: ' #E4E5E4',
                                                    }}
                                                >
                                                    Google Account
                                                </p>
                                                <p>
                                                    {
                                                        googleUserData.userAccount_name
                                                    }
                                                </p>
                                                <p>
                                                    {' '}
                                                    {
                                                        googleUserData.userAccount_email
                                                    }
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={AdvanceSettingStyle.setting_Body_right}>
                        <div
                            className={
                                AdvanceSettingStyle.setting_Body_right_inner
                            }
                        >
                            {languageDivSelect && (
                                <div>
                                    <p style={{ textAlign: 'center' }}>
                                        Select Your language
                                    </p>
                                    <div>
                                        <select
                                            className={
                                                KitSelectStyle.language_select
                                            }
                                            value={languageValue}
                                            onChange={(e) =>
                                                setLanguageValue(e.target.value)
                                            }
                                        >
                                            {languageMenu.map((ele) => {
                                                return (
                                                    <option value={ele}>
                                                        {ele}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    <div
                                        className={
                                            AdvanceSettingStyle.setting_Body_confirmBtn_div
                                        }
                                    >
                                        <div
                                            className={
                                                AdvanceSettingStyle.setting_Body_confirmBtn
                                            }
                                            onClick={languageActionHandel}
                                        >
                                            CONFIRM
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        setLanguageSelect: (data) => {
            dispatch(setLanguageSelect(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAccount)
