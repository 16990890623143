const IOComponents = {
    led: { output: true, input: false },
    led_1c: { output: true, input: false },
    laser: { output: true, input: false },
    beeper: { output: true, input: false },
    play_shield: { output: false, input: false },
    play_shield_v1: { output: false, input: false },
    arm_shield: { output: false, input: false },
    peeCee_ATR: { output: false, input: false },
    temp_dew: { output: false, input: true },
    temp_gas: { output: false, input: true },

    geared_motor: { output: true, input: false },
    mini_geared_motor: { output: true, input: false },
    motor_driver: { output: false, input: false },
    servo_motor: { output: true, input: false },
    servo_motor_270: { output: true, input: false },
    serial_servo_motor: { output: true, input: false },
    speaker: { output: true, input: false },

    servo_motor_360: { output: true, input: false },
    dc_motor: { output: true, input: false },
    dynamex_motor: { output: true, input: false },
    servo_extender: { output: true, input: false },
    mp3: { output: true, input: false },
    '7segment_display': { output: true, input: false },
    '4_CH_relay': { output: true, input: false },
    stepper_motor: { output: true, input: false },
    //'relay' : { output: true, input: false },
    electromagnet: { output: true, input: false },
    led_strip: { output: true, input: false },
    dot_matrix: { output: true, input: false },
    dual_splitter: { output: true, input: false },

    pc_motor_driver: { output: true, input: false },
    octa_splitter: { output: true, input: false },
    light_sensor: { output: false, input: true },
    bend_sensor: { output: false, input: true },
    gas_sensor: { output: false, input: true },
    distance_sensor: { output: false, input: true },
    sound_sensor: { output: false, input: true },
    temperature_sensor: { output: false, input: true },
    humidity_sensor: { output: false, input: true },
    hall_sensor: { output: false, input: true },
    metal_detector: { output: false, input: true },
    ultrasonic_sensor: { output: false, input: true },
    heartbeat_sensor: { output: false, input: true },
    rfid: { output: false, input: true },
    color_sensor: { output: false, input: true },
    rain_sensor: { output: false, input: true },
    tact_switch: { output: false, input: true },
    tact_switch_2c: { output: false, input: true },
    dip_switch: { output: false, input: true },
    metal_detector: { output: false, input: true },
    dual_switch: { output: false, input: true },
    rotatory: { output: false, input: true },
    pot: { output: false, input: true },
    linear_pot: { output: false, input: true },

    humidity: { output: false, input: true },
    temp: { output: false, input: true },
    gas: { output: false, input: true },

    joystick: { output: false, input: true },
    extender: { output: false, input: true },
    touch_sensor: { output: false, input: true },
    pir_sensor: { output: false, input: true },
    gesture_sensor: { output: false, input: true },
    '4_in_1_sensor': { output: false, input: true },
    gyro_sensor: { output: false, input: true },
    rotational_sensor: { output: false, input: true },
    accelerometer: { output: false, input: true },
    solar_panel: { output: false, input: true },
    battery: { output: false, input: false },
    compass: { output: false, input: true },
    OLED: { output: true, input: false },
    RGB: { output: true, input: false },
    //klaw
    PWM: { output: true, input: false },

    //toki external components
    // LED_blue: { output: true, input: false },
    // LED_green: { output: true, input: false },
    // LED_red: { output: true, input: false },
    // LED_white: { output: true, input: false },
    // laser_diode: { output: true, input: false },
    // L293D_motor_driver: { output: true, input: false },
    // LDR_sensor: { output: false, input: true },
    // PIR_sensor: { output: false, input: true },
    // DHT11: { output: false, input: true },
    // capacitative_touch_sensor: { output: false, input: true },
    // IR_proximity: { output: false, input: true },
    // rain_sensor_module_analog: { output: false, input: true },
    // rain_sensor_module_digital: { output: false, input: true },

    // Photo_diode: { output: false, input: true },
    // Analog_sensor_module: { output: false, input: true },
    // Digital_sensor_module: { output: false, input: true },
    // PWM_output_module: { output: true, input: false },
    // Digital_Output_module: { output: true, input: false },
    // Analog_sensor_component: { output: false, input: true },

    Analog_3_Pin_Input: { output: false, input: true },
    Analog_2_Pin_Input: { output: false, input: true },
    PWM_3_Pin_Output: { output: true, input: false },
    PWM_2_Pin_Output: { output: true, input: false },
    Digital_3_Pin_Input: { output: false, input: true },
    Digital_2_Pin_Input: { output: false, input: true },
    Digital_3_Pin_Output: { output: true, input: false },
    Digital_2_Pin_Output: { output: true, input: false },
}
export default IOComponents
