// import { defaultAssemblyPageAi } from '../../source/assemblyPageAiData'
let default_colorTranEachObject = {
    title: 'Color',
    value1: 2,
    value2: 2,
    isUpload: false,
    isDisableSlider1: false,
    isDisableSlider2: true,
    // redMin: 0,
    // redMax: 100,
    // greenMin: 0,
    // greenMax: 100,
    // blueMin: 0,
    // blueMax: 100,
}

var defaultAppAiModeStorage = {
    chooseTrainingDevice: {
        tabFocus: {
            camera: true,
            speech: false,
        },
        detectionModeFocus: {
            motion: false,
            color: false,
            face: false,
            object: false,
            speech: false,
        },
    },
    speechTrain: {
        speechQueryArr: [{ value: '' }], //arr.push({ value: '', isUpload:false })
        wakeWord: 'Hi PeeCee',
    },
    colorTrain: {
        tranList: [
            { ...default_colorTranEachObject },
            // { ...default_colorTranEachObject },
            // { ...default_colorTranEachObject },
        ],
        focusInd: 0,
        isCleanTrain: false,
    },
    faceTrain: {
        tranList: [
            {
                title: 'Face',
                faceName: '',
                isUpload: false,
                id: 0,
            },
        ],
        focusInd: 0,
        isCleanTrain: false,
    },
}

if (sessionStorage.getItem('appAiModeStorage') != null) {
    var appAiModeStorage = sessionStorage.getItem('appAiModeStorage')
    appAiModeStorage = JSON.parse(appAiModeStorage)
} else {
    var appAiModeStorage = structuredClone(defaultAppAiModeStorage)
}
//assemblyPageAi: assemblyPageAi,
// if (sessionStorage.getItem('assemblyPageAi') != null) {
//     var assemblyPageAi = sessionStorage.getItem('assemblyPageAi')
//     assemblyPageAi = JSON.parse(assemblyPageAi)
// } else {
//     var assemblyPageAi = defaultAssemblyPageAi
// }
const appAiModeStorage_Reducer = (state = appAiModeStorage, action) => {
    switch (action.type) {
        case 'APP_AI_MODE_STORAGE': {
            console.log(state, 'HHHHHHH', action)

            switch (action.payload.type) {
                case 'tabFocus':
                    state.chooseTrainingDevice.tabFocus =
                        action.payload.tabFocus
                    break

                case 'detectionModeFocus':
                    state.chooseTrainingDevice.detectionModeFocus =
                        action.payload.detectionModeFocus
                    break
                case 'speechQueryArr':
                    state.speechTrain.speechQueryArr =
                        action.payload.speechQueryArr
                    break
                case 'wakeWord':
                    state.speechTrain.wakeWord = action.payload.wakeWord
                    break
                case 'colorTrain':
                    state.colorTrain = action.payload.colorTrain
                    break
                case 'faceTrain':
                    state.faceTrain = action.payload.faceTrain
                    break

                case 'setAppAiModeStorage': {
                    state = action.payload.setAppAiModeStorage
                    break
                }

                case 'RemoveAppAiModeStorage':
                    state = structuredClone(defaultAppAiModeStorage)
                    break
            }
            var data = { ...state }
            sessionStorage.setItem('appAiModeStorage', JSON.stringify(data))
            return data
        }
        default:
            sessionStorage.setItem('appAiModeStorage', JSON.stringify(state))
            return state
    }
}

// export const assemblyPageAi_Reducer = (state = assemblyPageAi, action) => {
// switch (action.type) {
// case 'ASSEMBLY_PAGE_AI1': {
// console.log('ASSEMBLY_PAGE_AI1', action.payload)
// var assemblyPageAi = action.payload
// var data = { ...state, assemblyPageAi }
// state = { ...assemblyPageAi }
// sessionStorage.setItem('assembly', JSON.stringify(data))
// return data
// switch (action.payload.type) {
//     case 'tabFocus':
//         state.chooseTrainingDevice.tabFocus =
//             action.payload.tabFocus
//         break

//     case 'detectionModeFocus':
//         state.chooseTrainingDevice.detectionModeFocus =
//             action.payload.detectionModeFocus
//         break
//     case 'speechQueryArr':
//         state.speechTrain.speechQueryArr =
//             action.payload.speechQueryArr
//         break
//     case 'wakeWord':
//         state.speechTrain.wakeWord = action.payload.wakeWord
//         break
//     case 'colorTrain':
//         state.colorTrain = action.payload.colorTrain
//         break
//     case 'faceTrain':
//         state.faceTrain = action.payload.faceTrain
//         break

//     // case 'setAppAiModeStorage':
//     //     state = defaultAppAiModeStorage
//     //     break

//     case 'RemoveAppAiModeStorage':
//         state = defaultAppAiModeStorage
//         break
// }
//     var data = { ...state }
//     sessionStorage.setItem('appAiModeStorage', JSON.stringify(data))
//     return data
// }
//     default:
//         sessionStorage.setItem('appAiModeStorage', JSON.stringify(state))
//         return state
// }
// }

export default appAiModeStorage_Reducer
