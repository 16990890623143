import React, { Component } from 'react'

import Colors from '../../Colors'
import { connect } from 'react-redux'

let isPeeCeeATR = sessionStorage.getItem('peeCeeATR') == 'true' || false
let isRboki = ['Roboki', 'Atr', 'Toki', 'Moki'].includes(
    sessionStorage.getItem('connectedDevice')
)

// in toki has frontend show port and backend program port are different, so the ports are map here.
const uiPortNameMap = {
    Toki: {
        A1: 'AIN1',
        A2: 'AIN2',
        B1: 'AIN3',
        C1: 'DRV1',
        C2: 'DRV2',
        servo_motor1: 'S1',
        servo_motor2: 'S2',
        M1: 'M1',
        M2: 'M2',
    },
    Moki: {
        G1: 'I1',
        G2: 'I2',
    },
}

class Checkbox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isClickChecked: this.props.checked,
        }
    }

    handleClickChecked = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        this.props.onChange(!this.state.isClickChecked)
        this.setState({ isClickChecked: !this.state.isClickChecked })
    }

    getButtonLabel = ({ activePort, label, connectedDevice }) => {
        if (
            ['Toki', 'Moki'].includes(connectedDevice) &&
            uiPortNameMap?.[connectedDevice]?.[activePort]
        ) {
            // return `Port ${tokiPortNameMap[activePort]} : ${label}`
            return `Port ${uiPortNameMap?.[connectedDevice]?.[activePort]} : ${label}`
        } else if (activePort === 'servo_motor1') {
            return `Port S1 : ${label}`
        } else if (activePort === 'servo_motor2') {
            return `Port S2 : ${label}`
        } else if (
            !isPeeCeeATR &&
            !isRboki &&
            ['S1', 'S2', 'S3', 'S4'].includes(activePort)
        ) {
            return 'PC  Smile LED ' + activePort[1]
        } else if (['AI LED', 'AI Audio'].includes(label)) {
            return label
        } else {
            return `Port ${activePort} : ${label}`
        }
    }
    //get the button label for servo360 serial servo, and minigrad motor
    getButtonLabel_MINIGEARED_SERVO360_SERIALSERVO = ({
        activePort,
        connectedDevice,
    }) => {
        if (
            connectedDevice == 'Toki' &&
            uiPortNameMap?.[connectedDevice]?.[activePort]
        ) {
            // return `Port ${tokiPortNameMap[activePort]}`
            return `Port ${uiPortNameMap?.[connectedDevice]?.[activePort]}`
        } else if (activePort === 'servo_motor1') {
            return `Port S1`
        } else if (activePort === 'servo_motor2') {
            return `Port S2`
        } else if (activePort == 'SSTime') {
            return 'Time'
        } else {
            return `Port ${activePort}`
        }
    }

    //if live button show then give the style for dc motor checkbox
    getLiveButtonShowDCMotor = (connectedDevice, deviceVersion, activePort) => {
        if (connectedDevice == 'Ace' && deviceVersion?.startsWith('1')) {
            return true
        } else if (
            ['Atr'].includes(connectedDevice) &&
            ['C', 'C1', 'C2', 'D1'].includes(activePort)
        ) {
            return false
        } else if (
            ['Toki'].includes(connectedDevice) &&
            ['C1', 'C2'].includes(activePort)
        ) {
            return false
        } else if (
            ['Roboki', 'Atr', 'Toki', 'Moki'].includes(connectedDevice)
        ) {
            return true
        } else return false
    }

    liveButtonShowCondition = ({
        connectedDevice,
        deviceVersion,
        activePort,
    }) => {
        if (
            connectedDevice === 'Atr' &&
            ['C1', 'C2'].includes(activePort) &&
            ['servo_motor', 'servo_motor_270', 'servo_motor_360'].includes(
                this.props.componentType
            )
        ) {
            return true
        }
        if (
            activePort &&
            ['C1', 'C2'].includes(activePort) &&
            ['Atr'].includes(connectedDevice)
        ) {
            return false
        }
        if (
            ['PWM_3_Pin_Output'].includes(this.props.componentType) &&
            ['Toki'].includes(connectedDevice)
        ) {
            return false
        }
        if (
            [
                'led_1c',
                'led',
                'servo_motor',
                'servo_motor_270',
                'PWM_3_Pin_Output',
            ].includes(this.props.componentType)
        ) {
            return (
                (connectedDevice == 'Ace' && deviceVersion?.startsWith('1')) ||
                ['Roboki', 'Toki', 'Atr', 'Moki'].includes(connectedDevice)
            )
        }
    }

    render() {
        const { checked, onChange, label, disabled, activePort } = this.props
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const deviceVersion = sessionStorage.getItem('deviceVersion')

        console.log(checked, this.state.isClickChecked, 'gkaay checkbox')

        var style = {
            width: '1.5em',
            height: '1.5em',
            display: 'inline-block',
            borderRadius: '20px',
            marginRight: '1em',
            backgroundColor: 'white',
        }
        if (checked) {
            style.boxShadow = 'inset 0 0 0 0em #FFF'
            style.backgroundColor = 'green'
        }

        if (this.props.hexComponentType === 'start') {
            return (
                <div
                    className={`start-checkBox-conatiner start-renderClick${this.state.isClickChecked}`}
                    onClick={() => {
                        this.handleClickChecked()
                        // this.reduxDeletPogram()
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {/* <p className={`tick-Active${this.state.isClickChecked}`}>✔</p> */}
                    {activePort !== undefined ? (
                        <>
                            <p
                                style={{
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '30%',
                                    left: '10%',
                                    fontSize: '15px',
                                }}
                            >
                                {activePort == 'PC'
                                    ? activePort
                                    : `Port ${activePort} :`}{' '}
                            </p>
                            <p
                                style={{
                                    textAlign: 'center',
                                    position: 'absolute',
                                    top: '60%',
                                    left: '10%',
                                    fontSize: '15px',
                                }}
                            >
                                {label}
                            </p>
                        </>
                    ) : (
                        <p
                            style={{
                                position: 'absolute',
                                top: '54%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                fontSize: '15px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {label}
                        </p>
                    )}
                </div>
            )
        } else if (
            label == 'MINI GEARED MOTOR' ||
            label == 'SERVO MOTOR 360' ||
            label == 'SERIAL SERVO MOTOR' ||
            label == 'DYNAMEX MOTOR' ||
            label == 'DC MOTOR' ||
            (label?.includes('Motor') &&
                parseInt(label[6] || '0') < 4 &&
                parseInt(label[6] || '0') > 0)
        ) {
            return (
                <div
                    className={`checkBox-conatiner renderClick${checked} ${
                        this.getLiveButtonShowDCMotor(
                            connectedDevice,
                            deviceVersion,
                            activePort
                        ) && 'checkBox-conatiner_1'
                        // ((connectedDevice == 'Ace' &&
                        //     deviceVersion?.startsWith('1')) ||
                        //     ['Roboki', 'Atr'].includes(connectedDevice)) &&
                    }`}
                    onClick={this.handleClickChecked}
                >
                    {activePort !== undefined ? (
                        <>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                    wordWrap: 'break-word',
                                    maxWidth: '40vw',
                                    maxHeight: '4vh',
                                    marginBottom: '0px',
                                }}
                            >
                                {this.getButtonLabel_MINIGEARED_SERVO360_SERIALSERVO(
                                    { activePort, connectedDevice }
                                )}
                                {/* {activePort == 'SSTime'
                                    ? 'Time'
                                    : `Port ${activePort}`} */}
                                :
                            </p>

                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                    wordWrap: 'break-word',
                                    maxWidth: '40vw',
                                    // maxHeight: '4vh',
                                    marginBottom: '0px',
                                }}
                            >
                                {label}{' '}
                            </p>
                        </>
                    ) : (
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                whiteSpace: 'nowrap',
                                fontSize: '15px',
                            }}
                        >
                            {label}{' '}
                        </p>
                    )}
                </div>
            )
        } else if (/^Bi[cfd][12]|Usbtx|BtTx[1-5]|Asgn[1-5]$/.test(activePort)) {
            return (
                <div
                    className={`checkBox-conatiner renderClick${checked}`}
                    onClick={this.handleClickChecked}
                >
                    <p
                        style={{
                            textAlign: 'center',
                            paddingTop: '1.5rem',
                            fontSize: '15px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {label}
                    </p>
                </div>
            )
        } else {
            return (
                <button
                    className={`checkBox-conatiner renderClick${checked} ${
                        // [
                        //     'led_1c',
                        //     'led',
                        //     'servo_motor',
                        //     'servo_motor_270',
                        // ].includes(this.props.componentType) &&
                        // ((connectedDevice == 'Ace' &&
                        //     deviceVersion?.startsWith('1')) ||
                        //     ['Roboki', 'Atr'].includes(connectedDevice))
                        this.liveButtonShowCondition({
                            connectedDevice,
                            deviceVersion,
                            activePort,
                        })
                            ? 'checkBox-conatiner_1'
                            : 'checkBox-conatiner-liveButtonEmpty'
                    }`}
                    onClick={this.handleClickChecked}
                >
                    {activePort !== undefined ? (
                        <>
                            <p
                                style={{
                                    textAlign: 'center',
                                    marginBottom: '0rem',
                                    fontSize: '15px',
                                }}
                            >
                                {/* this.getLabelText(activePort, label) */}
                                {/* {!isPeeCeeATR &&
                                !isRboki &&
                                (activePort == 'S1' ||
                                    activePort == 'S2' ||
                                    activePort == 'S3' ||
                                    activePort == 'S4')
                                    ? 'PC  Smile LED ' + activePort[1]
                                    : `Port ${activePort} : ${label}`} */}
                                {this.getButtonLabel({
                                    activePort,
                                    label,
                                    connectedDevice,
                                })}
                            </p>
                        </>
                    ) : (
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: `translate(-50%,-50%)`,
                                whiteSpace: 'nowrap',
                                fontSize: '15px',
                            }}
                        >
                            {label}{' '}
                        </p>
                    )}
                </button>
            )
        }
    }
}
const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateLogic: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkbox)

// export default Checkbox
