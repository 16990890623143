import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import renderPrgImage from '../../source/programImg'
import DialogModal from '../../Components/ReusableComponents/DialogModal/DialogModal'
import MainHeader from '../../Components/ReusableComponents/Header/MainHeader'
import PrgmSelection from '../../Components/ReusableComponents/PrgmSlider/PrgmSelection/PrgmSelection'
import ZingPrgmSelection from '../../Components/ReusableComponents/PrgmSlider/ZingPrgmSelection/ZingPrgmSelection'
import {
    retrieveAssemblyData,
    retrieveFlowDataExcept,
} from '../../utils/retrieveData'
import cacheAssets from '../../utils/cacheAssets'
import ViewActions from '../Code/CreateAction/ViewActions'
import { languageWords } from '../../utils/langaugeSupport'
import { stopByteCondition } from '../../utils/utils'

// const getUrl = () => {
//     const version = sessionStorage.getItem('deviceVersion')
//     if (version?.startsWith('1')) {
//         return '/v1/selectScreen/InternalAccessories'
//     } else {
//         return '/flow/InternalAccessories'
//     }
// }
const deviceVersion = sessionStorage.getItem('deviceVersion')
const connectedDevice = sessionStorage.getItem('connectedDevice')
const getUrl = {
    Ace: deviceVersion?.startsWith('1')
        ? '/v1/selectScreen/InternalAccessories'
        : '/flow/InternalAccessories',
    Hexapod: '/code/InternalAccessories',
    Humanoid: '/code/InternalAccessories',
    Klaw: '/code/InternalAccessories',
}
const DEVICE_MENU_OPTIONS = {
    ACE: [
        {
            name: 'NewProject',
            imgSrc: 'newfilegroupbutton',
            link: getUrl[connectedDevice],
            imgStyle: { width: '19rem' },
        },
        {
            name: 'OpenProject',
            imgSrc: 'yourprojectsgroupbutton',
            link: '/comingSoon',
            imgStyle: { width: '19rem', cursor: 'pointer' },
        },
    ],

    HUMANOID: [
        {
            name: 'NewProject',
            imgSrc: 'newfilegroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem' },
        },
        {
            name: 'OpenProject',
            imgSrc: 'yourprojectsgroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem', cursor: 'pointer' },
        },
        {
            name: 'NewAction',
            imgSrc: 'newactiongroupbutton',
            link: '/code/project/action/select',
            imgStyle: {
                width: '19rem',

                marginTop: '20px',
                scale: '1.05',
            },
        },
        {
            name: 'OpenAction',
            imgSrc: 'youractiongroupbutton',
            link: '/code/project/actions',
            imgStyle: {
                width: '19rem',
                cursor: 'pointer',
                marginTop: '20px',
                scale: '1.05',
            },
        },
    ],
    HEXAPOD: [
        {
            name: 'NewProject',
            imgSrc: 'newfilegroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem' },
        },
        {
            name: 'OpenProject',
            imgSrc: 'yourprojectsgroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem', cursor: 'pointer' },
        },
        {
            name: 'NewAction',
            imgSrc: 'newactiongroupbutton',
            link: '/code/project/action/select',
            imgStyle: {
                width: '19rem',

                marginTop: '20px',
                scale: '1.05',
            },
        },
        {
            name: 'OpenAction',
            imgSrc: 'youractiongroupbutton',
            link: '/code/project/actions',
            imgStyle: {
                width: '19rem',
                cursor: 'pointer',
                marginTop: '20px',
                scale: '1.05',
            },
        },
    ],
    KLAW: [
        {
            name: 'NewProject',
            imgSrc: 'newfilegroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem' },
        },
        {
            name: 'OpenProject',
            imgSrc: 'yourprojectsgroupbutton',
            link: '/code/InternalAccessories',
            imgStyle: { width: '19rem', cursor: 'pointer' },
        },
        {
            name: 'NewAction',
            imgSrc: 'newactiongroupbutton',
            link: '/code/project/action/select',
            imgStyle: {
                width: '19rem',

                marginTop: '20px',
                scale: '1.05',
            },
        },
        {
            name: 'OpenAction',
            imgSrc: 'youractiongroupbutton',
            link: '/code/project/actions',
            imgStyle: {
                width: '19rem',
                cursor: 'pointer',
                marginTop: '20px',
                scale: '1.05',
            },
        },
    ],
}
// let ACE_MENU_OPTIONS = [
//     {
//         name: 'New Project',
//         imgSrc: 'newfilegroupbutton',
//         link: getUrl(),
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'Open Project',
//         imgSrc: 'yourprojectsgroupbutton',
//         link: '/comingSoon',
//         imgStyle: { width: '19rem' },
//     },
// ]

// const HUMANOID_MENU_OPTIONS = [
//     {
//         name: 'New Project',
//         imgSrc: 'newfilegroupbutton',
//         link: '/code/InternalAccessories',
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'Open Project',
//         imgSrc: 'yourprojectsgroupbutton',
//         link: '/code/InternalAccessories',
//         imgStyle: { width: '19rem' },
//     },
//     {
//         name: 'New Action',
//         imgSrc: 'newactiongroupbutton',
//         link: '/code/project/action/select',
//         imgStyle: {
//             width: '19rem',
//             marginTop: '20px',
//             scale: '1.05',
//         },
//     },
//     {
//         name: 'Open Action',
//         imgSrc: 'youractiongroupbutton',
//         link: '/code/project/actions',
//         imgStyle: {
//             width: '19rem',
//             cursor: 'pointer',
//             marginTop: '20px',
//             scale: '1.05',
//         },
//     },
// ]

const container = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '22rem',
    width: '100vw',
    paddingTop: '180px',
    position: 'absolute',
    top: '15%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
}

const menuText = {
    position: 'relative',
    top: '-8.5rem',
    left: '6rem',
    fontSize: '1.3rem',
    cursor: 'pointer',
}

function ProgramSelection(props) {
    const history = useHistory()
    let programMode = sessionStorage.getItem('programMode')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const version = sessionStorage.getItem('deviceVersion')
    const [isRecoverData, setIsRecoverData] = useState(false)
    const [isRetrieveData, setRetrieveData] = useState(false)
    const [isCondProps, setCondProps] = useState({})
    const [isText, setText] = useState('')
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    const [showActionModal, setShowActionModal] = useState(false)
    const [retrieveActions, setRetrieveActions] = useState(false)

    let MENU_OPTIONS = false
    MENU_OPTIONS = DEVICE_MENU_OPTIONS[connectedDevice.toUpperCase()]

    useEffect(() => {
        // DEVICE_MENU_OPTIONS[connectedDevice.toUpperCase()] = [
        //     {
        //         name: 'NewProject',
        //         imgSrc: 'newfilegroupbutton',
        //         link: getUrl[connectedDevice],
        //         imgStyle: { width: '19rem' },
        //     },
        //     {
        //         name: 'OpenProject',
        //         imgSrc: 'yourprojectsgroupbutton',
        //         link: '/comingSoon',
        //         imgStyle: { width: '19rem', cursor: 'pointer' },
        //     },
        // ]
        MENU_OPTIONS = DEVICE_MENU_OPTIONS[connectedDevice.toUpperCase()]
    }, [props.device])

    useEffect(() => {
        if (isRecoverData.recoveryDataPresent === true) {
            let condProps = {
                show: isRecoverData.recoveryDataPresent,
                text: 'Would you like to recover your previous program?',
                optionsToSelect: [
                    {
                        text: 'YES',
                        do: async () => {
                            retriveRecoveryData(isRecoverData.link)
                            // history.push(getUrl[connectedDevice])isRecoverData.link
                            history.push(isRecoverData.link)
                        },
                    },
                    {
                        text: 'NO',
                        do: () => {
                            history.push(isRecoverData.link)
                        },
                    },
                ],
            }
            console.log(condProps)
            setCondProps(condProps)
        }
        if (isRetrieveData === true) {
            let condProps = {
                show: isRetrieveData,
                text: isText,
                handleDialogModalClose: closeModal,
                showCloseBtn: true,
            }
            setCondProps(condProps)
        }
        if (
            isRecoverData.recoveryDataPresent === false &&
            isRetrieveData === false
        ) {
            let condProps = {
                show: false,
            }
            setCondProps(condProps)
        }
    }, [isRecoverData, isRetrieveData])

    //send stop bytes to stop the running programming
    const sendStopBytesDevice = (name) => {
        if (
            props.webSerial.isConnected &&
            ['NewAction', 'OpenAction'].includes(name)
        ) {
            let sendStopBytes = stopByteCondition({
                connectedDevice,
                deviceVersion,
            })
            props.worker.postMessage({
                type: 'writeArray',
                value: sendStopBytes,
            })
        }
    }

    const handleNewProject = (option) => {
        let link = option.link
        sessionStorage.setItem('isSave', JSON.stringify(true))
        let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
        if (link === '/code/project/action/select') {
            programMode = 'actionBase'
        }
        if (
            localStorage.getItem('recoveryData') &&
            programMode in recoveryData &&
            connectedDevice in recoveryData[programMode] &&
            version in recoveryData[programMode][connectedDevice]
        ) {
            setIsRecoverData({ recoveryDataPresent: true, link: link })
        } else {
            history.push(link)
        }

        sendStopBytesDevice(option.name)
    }

    const retriveRecoveryData = (link) => {
        try {
            let recoveryData = JSON.parse(
                localStorage.getItem('recoveryData') || JSON.stringify({})
            )
            // let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))[
            //     programMode
            // ][connectedDevice][version]
            if (link === '/code/project/action/select') {
                let createActionState =
                    recoveryData['actionBase'][connectedDevice][version]
                        .createActionState
                sessionStorage.setItem(
                    'createActionState',
                    JSON.stringify(createActionState)
                )
            } else {
                recoveryData =
                    recoveryData[programMode][connectedDevice][version]

                for (let [key, value] of Object.entries(recoveryData)) {
                    sessionStorage.setItem(key, value)
                }
                props.selecteInternalAcessories(
                    JSON.parse(recoveryData.concept).internalaccessories
                )
                props.setZingMp3List(
                    JSON.parse(recoveryData?.concept)?.zingMp3List || []
                )
                props.selecteComponent(JSON.parse(recoveryData.concept))
                props.assemblyComponent(JSON.parse(recoveryData.assembly))
                props.logicComponent(JSON.parse(recoveryData.logic))
            }
        } catch (e) {
            localStorage.removeItem('recoveryData')
        }
    }

    //get the device name
    const getDeviceName = (device, version) => {
        switch (device) {
            case 'Hexapod':
                return 'Crawl-e'
            case 'Humanoid':
                if (version?.[0] == '0') return 'Zing Alpha'
                else return 'Zing Beta'
            // case 'RB':
            //     return 'Roboki'
            case 'Klaw':
                return 'Klaw-b'
            case 'Ace':
                if (version?.[0] == '0') return 'PeeCee Alpha'
                else return 'PeeCee Beta'
            default:
                return device
        }
    }

    const openProject = (event, loadContentType) => {
        sessionStorage.setItem('isSave', JSON.stringify(true))
        const currVersion = sessionStorage.getItem('deviceVersion')
        const currDevice = sessionStorage.getItem('connectedDevice')
        const currMode = sessionStorage.getItem('programMode')
        const version = sessionStorage.getItem('deviceVersion') || null
        let file = event.target.files[0]
        const reader = new FileReader()
        reader.onload = (evt) => {
            const sessionData = JSON.parse(evt.target.result)
            console.log(sessionData)
            let sessionDataVersion = ''
            if ('deviceVersion' in sessionData) {
                sessionDataVersion = sessionData.deviceVersion
            } else if ('PCVersion' in sessionData) {
                if (sessionData.PCVersion !== '0.0.0') {
                    sessionDataVersion = sessionData.PCVersion
                } else {
                    sessionDataVersion = sessionData.zingVersion
                }
            } else {
                sessionDataVersion = currVersion
            }
            if (loadContentType === 'OpenAction') {
                if (sessionData.connectedDevice != currDevice) {
                    setText(
                        `The Selected file is for ${getDeviceName(
                            sessionData.connectedDevice,
                            sessionDataVersion
                        )}, please select the correct file for ${getDeviceName(
                            currDevice,
                            currVersion
                        )} `
                    )
                    setRetrieveData(true)
                }
                //  else if (
                //     currVersion.slice(0, 1) !== sessionDataVersion.slice(0, 1)
                // ) {
                //     setText(
                //         `The Selected file is for a different version of ${getDeviceName(
                //             currDevice,
                //             currVersion
                //         )}, please select the correct file for ${getDeviceName(
                //             currDevice,
                //             currVersion
                //         )} `
                //     )
                //     setRetrieveData(true)
                // }
                else if (
                    (currVersion?.startsWith('0') ||
                        sessionDataVersion?.startsWith('0')) &&
                    currVersion?.[0] !== sessionDataVersion?.[0]
                ) {
                    setText(
                        `The Selected file is for ${getDeviceName(
                            connectedDevice,
                            sessionDataVersion
                        )}, please select the correct file for ${getDeviceName(
                            connectedDevice,
                            currVersion
                        )} `
                    )
                    setRetrieveData(true)
                } else {
                    Object.entries(sessionData).map((value) => {
                        if (value[0] === 'programMode') {
                            sessionStorage.setItem(value[0], 'flowchartBased')
                        } else {
                            sessionStorage.setItem(value[0], value[1])
                        }
                    })
                    history.push('/code/project/action/select')
                }
            } else if (loadContentType === 'OpenProject') {
                let isVersionMatch = false
                if (['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice)) {
                    isVersionMatch =
                        currVersion.slice(0, 3) ===
                            sessionDataVersion.slice(0, 3) &&
                        currMode === sessionData.programMode
                } else {
                    isVersionMatch =
                        currVersion.slice(0, 1) ===
                            sessionDataVersion.slice(0, 1) &&
                        currMode === sessionData.programMode
                }

                if (isVersionMatch) {
                    sessionStorage.setItem('deviceVersion', sessionDataVersion)
                    Object.entries(sessionData).map((value) => {
                        if (!retrieveFlowDataExcept.includes(value[0])) {
                            sessionStorage.setItem(value[0], value[1])
                        }
                        if (value[0] === 'concept') {
                            props.selecteInternalAcessories(
                                JSON.parse(value[1]).internalaccessories
                            )
                            props.setZingMp3List(
                                JSON.parse(value[1])?.zingMp3List || []
                            )
                        }
                        if (value[0] === ('deviceVersion' || 'connectedDevice'))
                            sessionStorage.setItem(value[0], value[1])
                    })
                    if (version !== null && version?.startsWith('1'))
                        props.history.push(
                            '/v1/selectScreen/InternalAccessories'
                        )
                    else props.history.push('/flow/InternalAccessories')
                } else {
                    setText(
                        `The Selected file is for a different version of ${getDeviceName(
                            currDevice,
                            currVersion
                        )}, please select the correct file for ${currMode} for your ${getDeviceName(
                            currDevice,
                            currVersion
                        )}`
                    )
                    setRetrieveData(true)
                }
            }
        }
        reader.readAsText(file)
    }

    const closeModal = () => {
        setRetrieveData(false)
        let condProps = {
            show: false,
        }
        setCondProps(condProps)
    }

    //in open the file, dialog model shows option generat
    const dialogModelOption = () => {
        let optionArr = []
        if (props.webSerial.isConnected) {
            optionArr.push({
                text: connectedDevice === 'Hexapod' ? 'crawl-e' : 'Zing',
                do: () => {
                    sendStopBytesDevice('OpenAction')
                    setShowActionModal(false)
                    setRetrieveActions(true)
                },
            })
        }

        optionArr.push({
            text: 'Local',
            do: () => {
                sendStopBytesDevice('OpenAction')
                document.getElementById(`file-input-3`).click()
                setShowActionModal(false)
            },
        })
        return optionArr
    }

    useEffect(() => {
        let assetsToBeCached = [
            // helpScreen
            renderPrgImage('zingProjectH'),
            renderPrgImage('zingProjectH1'),
            renderPrgImage('zingProjectH2'),
            renderPrgImage('zingProjectH3'),
            renderPrgImage('zingProjectH4'),
            renderPrgImage('SelectionPrgf1'),
            renderPrgImage('SelectionPrgf2'),
            renderPrgImage('SelectionPrgf3'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <>
            <DialogModal {...isCondProps} />
            <DialogModal
                show={showActionModal}
                text="Open files from"
                showCloseBtn={true}
                handleDialogModalClose={() => setShowActionModal(false)}
                optionsToSelect={dialogModelOption()}
                // {
                //     text: 'Zing',
                //     do: () => {
                //         setShowActionModal(false)
                //         setRetrieveActions(true)
                //     },
                // },

                // {
                //     text: 'Local',
                //     do: () => {
                //         document.getElementById(`file-input-3`).click()
                //         setShowActionModal(false)
                //     },
                // },
                // }
            />
            <ViewActions
                viewPopUp={retrieveActions}
                setViewPopUp={setRetrieveActions}
            />
            <MainHeader
                help_btnPosition="right"
                helper={
                    sessionStorage.getItem('connectedDevice') === 'Humanoid' ? (
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <ZingPrgmSelection />
                        </div>
                    ) : (
                        <div
                            className="PS-S_slide"
                            style={{
                                left: '70%',
                                transform: 'translate(-50%)',
                            }}
                        >
                            <PrgmSelection />
                        </div>
                    )
                }
                goBack={() => {
                    setSlideDirection(true)
                    sessionStorage.setItem('slideDirection', true)
                    history.push('/code')
                }}
                isGoBack={true}
            />
            <img
                src={renderPrgImage('programmenucard')}
                style={{
                    position: 'absolute',
                    top: '8.5%',
                    left: '12%',
                    transform: 'translate(-50%,-50%)',
                    height: '22%',
                }}
            />
            <p
                style={{
                    position: 'absolute',
                    top: '7%',
                    left: '11.5%',
                    transform: 'translate(-50%,-50%)',
                    color: 'white',
                    fontSize: '18px',
                }}
            >
                {languageWords[props.languageName]?.['Program'] || 'Program'}
            </p>

            <div style={{ height: '80%', position: 'relative' }}>
                {' '}
                <div style={container}>
                    {MENU_OPTIONS &&
                        MENU_OPTIONS.map((option, index) => (
                            <div
                                key={index}
                                style={{ width: '19rem', height: '15rem' }}
                            >
                                {option.name === 'OpenProject' ||
                                option.name === 'OpenAction' ? (
                                    <>
                                        {/* <label htmlFor={`file-input-${index}`}> */}
                                        <img
                                            src={renderPrgImage(option.imgSrc)}
                                            style={{
                                                ...option.imgStyle,
                                                opacity: '1',
                                                // option.name ===
                                                //     'OpenAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? '0.5'
                                                //     : '1',
                                                cursor: 'pointer',
                                                // option.name ===
                                                //     'OpenAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? 'default'
                                                //     : 'pointer',
                                            }}
                                            onClick={() => {
                                                if (
                                                    [
                                                        'Humanoid',
                                                        'Hexapod',
                                                    ].includes(
                                                        connectedDevice
                                                    ) &&
                                                    option.name == 'OpenAction'
                                                ) {
                                                    if (
                                                        ['2', '1'].includes(
                                                            deviceVersion[0]
                                                        )
                                                    )
                                                        setShowActionModal(true)
                                                    return
                                                }
                                                document
                                                    .getElementById(
                                                        `file-input-${index}`
                                                    )
                                                    .click()
                                            }}
                                        />
                                        <p
                                            style={{
                                                ...menuText,
                                                opacity: '1',
                                                // option.name ===
                                                //     'OpenAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                        >
                                            {
                                                languageWords[
                                                    props.languageName
                                                ]?.[option.name]
                                            }
                                        </p>
                                        <div
                                            style={{
                                                width: '50%',
                                                height: '54%',
                                                position: 'relative',
                                                top: '-16rem',
                                                left: '5rem',
                                                cursor: 'pointer',
                                                // option.name ===
                                                //     'OpenAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? 'default'
                                                //     : 'pointer',
                                            }}
                                            onClick={() => {
                                                if (
                                                    [
                                                        'Humanoid',
                                                        'Hexapod',
                                                    ].includes(
                                                        connectedDevice
                                                    ) &&
                                                    option.name == 'OpenAction'
                                                ) {
                                                    if (
                                                        ['2', '1'].includes(
                                                            deviceVersion[0]
                                                        )
                                                    )
                                                        setShowActionModal(true)
                                                    return
                                                }
                                                document
                                                    .getElementById(
                                                        `file-input-${index}`
                                                    )
                                                    .click()
                                            }}
                                        ></div>
                                        {/* </label> */}
                                        {((['Hexapod'].includes(
                                            connectedDevice
                                        ) &&
                                            option.name !== 'OpenAction') ||
                                            connectedDevice !== 'Hexapod') && (
                                            <input
                                                id={`file-input-${index}`}
                                                type="file"
                                                accept=".pld"
                                                hidden
                                                onInput={(event) =>
                                                    openProject(
                                                        event,
                                                        option.name,
                                                        option.link
                                                    )
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={renderPrgImage(option.imgSrc)}
                                            style={{
                                                ...option.imgStyle,
                                                opacity: '1',
                                                // option.name ===
                                                //     'NewAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? '0.5'
                                                //     : '1',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     ((['Hexapod'].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //         ['1', '2'].includes(
                                                //             deviceVersion[0]
                                                //         )) ||
                                                //         deviceVersion[0] ==
                                                //             '1') &&
                                                //     option.name == 'NewAction'
                                                // ) {
                                                //     return
                                                // }
                                                sessionStorage.removeItem(
                                                    'slideDirection'
                                                )
                                                if (
                                                    [
                                                        'NewProject',
                                                        'NewAction',
                                                    ].includes(option.name)
                                                )
                                                    handleNewProject(option)
                                                else {
                                                    sessionStorage.setItem(
                                                        'isSave',
                                                        JSON.stringify(true)
                                                    )
                                                    history.push(option.link)
                                                }
                                            }}
                                        />
                                        <p
                                            style={{
                                                ...menuText,
                                                opacity: '1',
                                                // option.name ===
                                                //     'NewAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? '0.5'
                                                //     : '1',
                                                // cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     ((['Hexapod'].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //         ['1', '2'].includes(
                                                //             deviceVersion[0]
                                                //         )) ||
                                                //         deviceVersion[0] ==
                                                //             '1') &&
                                                //     option.name == 'NewAction'
                                                // ) {
                                                //     return
                                                // }
                                                sessionStorage.removeItem(
                                                    'slideDirection'
                                                )
                                                if (
                                                    [
                                                        'NewProject',
                                                        'NewAction',
                                                    ].includes(option.name)
                                                )
                                                    handleNewProject(option)
                                                else history.push(option.link)
                                            }}
                                        >
                                            {
                                                languageWords[
                                                    props.languageName
                                                ]?.[option.name]
                                            }
                                        </p>

                                        <div
                                            style={{
                                                width: '50%',
                                                height: '54%',
                                                position: 'relative',
                                                top: '-16rem',
                                                left: '5rem',
                                                cursor: 'pointer',
                                                // option.name ===
                                                //     'NewAction' &&
                                                // (connectedDevice ===
                                                //     'Hexapod' ||
                                                //     deviceVersion[0] == '1')
                                                //     ? 'default'
                                                //     : 'pointer',
                                            }}
                                            onClick={() => {
                                                // if (
                                                //     ((['Hexapod'].includes(
                                                //         connectedDevice
                                                //     ) &&
                                                //         ['1', '2'].includes(
                                                //             deviceVersion[0]
                                                //         )) ||
                                                //         deviceVersion[0] ==
                                                //             '1') &&
                                                //     option.name == 'NewAction'
                                                // ) {
                                                //     return
                                                // }
                                                sessionStorage.removeItem(
                                                    'slideDirection'
                                                )
                                                if (
                                                    [
                                                        'NewProject',
                                                        'NewAction',
                                                    ].includes(option.name)
                                                )
                                                    handleNewProject(option)
                                                else {
                                                    sessionStorage.setItem(
                                                        'isSave',
                                                        JSON.stringify(true)
                                                    )
                                                    history.push(option.link)
                                                }
                                            }}
                                        ></div>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    // return state;
    return {
        device: state.device,
        webSerial: state.webSerial,
        languageName: state.device.language,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selecteInternalAcessories: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        setZingMp3List: (data) => {
            dispatch({ type: 'ZING_MP3_LIST', payload: data })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramSelection)
