export const outPutData = {
    ATR1: {
        row1: {
            rowClassName: '',
            rowStyle: {},

            internalComponents: {
                atrMotor1: {
                    ImgClassName: 'imgStyle_v1',
                    altText: 'atrMotor1',
                    activeImgSrc: 'activeIndividual',
                    inActiveImgSrc: 'inactiveIndividual',
                    imgTagStyle: { height: 'auto' },
                    reduxName: 'isAtrMotor1',
                },
                atrMotor2: {
                    ImgClassName: 'imgStyle_v1',
                    altText: 'atrMotor2',
                    activeImgSrc: 'activeIndividual',
                    inActiveImgSrc: 'inactiveIndividual',
                    imgTagStyle: { height: 'auto' },
                    reduxName: 'isAtrMotor2',
                },
                atrMotor3: {
                    ImgClassName: 'imgStyle_v1',
                    altText: 'atrMotor3',
                    activeImgSrc: 'activeIndividual',
                    inActiveImgSrc: 'inactiveIndividual',
                    imgTagStyle: { height: 'auto' },
                    reduxName: 'isAtrMotor3',
                },
            },
        },
    },
}

export const tostMessages = {
    atrMotor1: {
        tostDivId: 'snackbar_atrMotor1',
        tostDivClass: 'bottom0',
        activeMessage: ' - Motor 1 selected',
        InactiveMessage: ' - Motor 1 deselected',
    },
    atrMotor2: {
        tostDivId: 'snackbar_atrMotor2',
        tostDivClass: 'bottom0',
        activeMessage: ' - Motor 2 selected',
        InactiveMessage: ' - Motor 2 deselected',
    },
    atrMotor3: {
        tostDivId: 'snackbar_atrMotor3',
        tostDivClass: 'bottom0',
        activeMessage: ' - Motor 3 selected',
        InactiveMessage: ' - Motor 3 deselected',
    },
}
