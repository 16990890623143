import { connect } from 'react-redux'
import SliderRow from './SliderRow'
import { appAiBytesGeneration } from '../../../AppMode/AppModeAI/AppModeAiBytesGeneration'
import { useEffect, useRef, useState } from 'react'

function AiMode_output(props) {
    const {
        name,
        port,
        key,
        onChange,
        currentPanelState,
        type,
        aiItems,
        worker,
        aiAudioList,
    } = props

    const [isShowAiAudioLoader, setIsShowAiAudioLoader] = useState(false)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    // const [audioNameList, setAudioNameList] = useState(['Select Sound'])

    const aiLEDOnchange = (key, value) => {
        if (key.includes('assign')) {
            onChange('assignai_LED' + port, value)
        } else if (key.includes('value')) {
            onChange('valueai_LED' + port, value)
        } else {
            onChange(key, value)
        }
    }

    return (
        <>
            {Object.keys(aiItems).map((eleKey, ind) => {
                if (!aiItems?.[eleKey]) {
                    return
                }
                if (eleKey === 'ai_LED') {
                    return (
                        <>
                            <SliderRow
                                name={'AI LED'}
                                port={port}
                                value={currentPanelState['valueai_LED' + port]}
                                key={key}
                                assign={
                                    currentPanelState['assignai_LED' + port]
                                }
                                onChange={aiLEDOnchange}
                                // min={min}
                                // max={max}
                                min={0}
                                max={100}
                            />
                        </>
                    )
                } else if (eleKey === 'ai_audio') {
                    return (
                        <>
                            <SliderRow
                                name={'AI Audio'}
                                port={port}
                                value={
                                    currentPanelState['valueai_audio' + port]
                                }
                                key={key}
                                assign={
                                    currentPanelState['assignai_audio' + port]
                                }
                                onChange={(key, value) => {
                                    onChange(key, value)
                                    // fetchAudioList(key, value)
                                }}
                                audioNameList={aiAudioList}
                                isShowAiAudioLoader={isShowAiAudioLoader}
                                sliderType="ai_audio"
                            />
                        </>
                    )
                }
            })}
            {/* {type === 'ai_LED' && (
                <SliderRow
                    name={'AI LED'}
                    port={port}
                    value={value}
                    key={key}
                    assign={assign}
                    onChange={onChange}
                    // min={min}
                    // max={max}
                    min={0}
                    max={100}
                />
            )}

            {type === 'ai_speaker' && (
                <SliderRow
                    name={'AI Speaker'}
                    port={port}
                    value={value}
                    key={key}
                    assign={assign}
                    onChange={onChange}
                    // min={min}
                    // max={max}
                    min={0}
                    max={100}
                    sliderType={type}
                />
            )} */}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        state: state,
        aiItems: state.assembly.assemblyPageAi.aiItems,
        aiAudioList: state.assembly.assemblyPageAi.aiAudioList,
        worker: state.worker,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAssemblyPageAi: (data) => {
            dispatch({ type: 'ASSEMBLY_PAGE_AI', payload: data })
        },
    }
}
// export default DragSource("data", cardSource, collect1)(Sidebar);

export default connect(mapStateToProps, mapDispatchToProps)(AiMode_output)
