//in  get the hader all text array function
const getHederTextArr = ({ languageName, keyArr = [] }) => {
    let arr = []

    keyArr.map((key) => {
        try {
            arr.push(languageWords[languageName][key])
        } catch (e) {
            console.log('ERROR', e)
            arr.push(key)
        }
    })

    return arr
}

//drop down language list
const languageMenu = ['English', 'Arabic', 'Hindi']

//all language words
const languageWords = {
    English: {
        OpenPlodeWeb: 'Open Plode Web',
        TryNow: 'Try Now',
        SelectDevice: 'Select your Kit',
        Device: 'Kits',
        CodableKits: 'Codable Kits',
        Animatronics: 'Animatronics',
        SimulationKits: 'Simulation Kits',
        RoboticKits: 'Robotic Kits',
        ScanDevice: 'Scan Device',
        Play: 'Play',
        Learn: 'Learn',
        Data: 'Data',
        Code: 'Code',
        AppMode: 'App Mode',
        Joystick: 'Joystick',
        Music: 'Music',
        Speech: 'Speech',
        Camera: 'Camera',
        SelectSensors: 'Select Sensors',
        SelectPorts: 'Select Ports',
        SetTime: 'Set Time',
        SetGraph: 'Set Graph',
        Plot: 'Plot',
        VisualProgramming: 'Visual Programming',
        ScriptProgramming: 'Script Programming',
        ProjectBased: `Project \nBased`,
        FlowchartBased: 'Flowchart \nBased',
        BlockBased: 'Block \nBased',
        CCoding: 'C \nCoding',
        PythonCoding: 'Python \nCoding',
        NewProject: 'New Project',
        OpenProject: 'Open Project',
        NewAction: 'New Action',
        OpenAction: 'Open Action',
        Select: 'Select',
        Assemble: 'Assemble',
        Code: 'Code',
        Simulate: 'Simulate',
        SelectPorts: 'Select Ports',
        InputOutput: 'Input/Output',
        DigitalAnalog: 'Digital/Analog',
        Flowchart: 'Flowchart',
    },
    Arabic: {
        OpenPlodeWeb: 'Plode  افتح موقع الويب الخاص بـ',
        TryNow: 'جرب الآن',
        SelectDevice: 'حدد مجموعتك',
        Device: 'مجموعات',
        CodableKits: 'مجموعات قابلة للترميز',
        Animatronics: 'الروبوتات المتحركة',
        SimulationKits: 'أدوات المحاكاة',
        RoboticKits: 'مجموعات الروبوتات',
        ScanDevice: 'جهاز المسح الضوئي',
        Play: 'يلعب',
        Learn: 'يتعلم',
        Data: 'بيانات',
        Code: 'شفرة',
        AppMode: 'وضع التطبيق',
        Joystick: 'عصا التحكم',
        Music: 'موسيقى',
        Speech: 'خطبة لغة',
        Camera: 'آلة تصوير',
        SelectSensors: 'حدد أجهزة الاستشعار',
        SelectPorts: 'حدد المنفذ',
        SetTime: 'ضبط الوقت',
        SetGraph: 'تعيين الرسم البياني',
        Plot: 'رسم بياني',
        VisualProgramming: 'البرمجة المرئية',
        ScriptProgramming: 'برمجة النصوص البرمجية',
        ProjectBased: 'تصور',
        FlowchartBased: 'مخطط  انسيابي',
        BlockBased: 'مجموعة',
        CCoding: 'C الترميز',
        PythonCoding: 'Python  الترميز',
        NewProject: 'مشروع جديد',
        OpenProject: 'مشروع مفتوح',
        NewAction: 'عمل جديد',
        OpenAction: 'عمل مفتوح',
        Select: 'يختار',
        Assemble: 'تجمع',
        Code: 'شفرة',
        Simulate: 'محاكاة',
        SelectPorts: 'حدد المنفذ',
        InputOutput: 'الإدخال/الإخراج',
        DigitalAnalog: 'رقمي/تناظري',
        Flowchart: 'مخطط انسيابي',
    },
    Hindi: {
        OpenPlodeWeb: 'प्लोड वेब खोलें',
        TryNow: 'अभी प्रयास करें',
        SelectDevice: 'अपना किट चुनें',
        Device: 'किट',
        CodableKits: 'कोडेबल किट्स',
        Animatronics: 'एनिमेट्रॉनिक्स',
        SimulationKits: 'सिम्युलेशन किट्स',
        RoboticKits: 'रोबोटिक किट्स',
        ScanDevice: 'डिवाइस स्कैन करें',
        Play: 'चलाएं',
        Learn: 'सीखें',
        Data: 'डेटा',
        Code: 'कोड',
        AppMode: 'ऐप मोड',
        Joystick: 'जॉयस्टिक',
        Music: 'संगीत',
        Speech: 'भाषण',
        Camera: 'कैमरा',
        SelectSensors: 'सेंसर चुनें',
        SelectPorts: 'पोर्ट चुनें',
        SetTime: 'समय सेट करें',
        SetGraph: 'ग्राफ सेट करें',
        Plot: 'प्लॉट',
        VisualProgramming: 'विजुअल प्रोग्रामिंग',
        ScriptProgramming: 'स्क्रिप्ट प्रोग्रामिंग',
        ProjectBased: `परियोजना \nआधारित`,
        FlowchartBased: 'फ्लोचार्ट \nआधारित',
        BlockBased: 'ब्लॉक \nआधारित',
        CCoding: 'C \nकोडिंग',
        PythonCoding: 'Python \nकोडिंग',
        NewProject: 'नई परियोजना',
        OpenProject: 'परियोजना खोलें',
        NewAction: 'नया कार्य',
        OpenAction: 'कार्य खोलें',
        Select: 'चयन करें',
        Assemble: 'असेंबल करें',
        Code: 'कोड',
        Simulate: 'सिम्युलेट करें',
        SelectPorts: 'पोर्ट चुनें',
        InputOutput: 'इनपुट/आउटपुट',
        DigitalAnalog: 'डिजिटल/एनालॉग',
        Flowchart: 'फ्लोचार्ट',
    },
}

export { languageMenu, languageWords, getHederTextArr }
