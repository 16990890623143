import { React, useEffect } from 'react'
import { webSerialConnect } from '../../../redux/actions/index'
import './ExternalAccessories.css'

import { connect } from 'react-redux'

import CenterMode from '../../concept/index'

import { useState } from 'react'
import EXternalScPrgm from '../../ReusableComponents/PrgmSlider/ExternalPrgm/ExternalScPrgm'
import ProgramHeader from '../../ReusableComponents/Header/ProgramHeader'
import renderPrgImage from '../../../source/programImg'
import { getHederTextArr } from '../../../utils/langaugeSupport'
import DialogModal from '../../ReusableComponents/DialogModal/DialogModal'

function ExternalAccessoriesScreen(props) {
    console.log('props history', props)

    const [isHelp, setHelp] = useState(false)
    const languageName = props.device.language
    const [erasedProgram, setErasedProgram] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const shouldErase = (info) => {
        if (info == 'Yes') {
            let programMode = sessionStorage.programMode
            let connectedDevice = sessionStorage.connectedDevice
            let version = sessionStorage.deviceVersion
            let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
            sessionStorage.setItem('isSave', JSON.stringify(false))
            if (
                localStorage.getItem('recoveryData') !== null &&
                programMode in recoveryData &&
                connectedDevice in recoveryData[programMode] &&
                version in recoveryData[programMode][connectedDevice]
            ) {
                if (Object.keys(recoveryData).length === 1) {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            localStorage.removeItem('recoveryData')
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                            localStorage.setItem(
                                'recoveryData',
                                JSON.stringify(recoveryData)
                            )
                        }
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (Object.keys(recoveryData[programMode]).length === 1) {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    } else {
                        if (
                            Object.keys(
                                recoveryData[programMode][connectedDevice]
                            ).length === 1
                        ) {
                            delete recoveryData[programMode][connectedDevice]
                        } else {
                            delete recoveryData[programMode][connectedDevice][
                                version
                            ]
                        }
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                }
            }

            console.log(props)
            sessionStorage.removeItem('programEnd')
            sessionStorage.removeItem('PET')
            sessionStorage.removeItem('EndSwitch')
            sessionStorage.setItem('pip', false)
            sessionStorage.setItem('name', 'null')
            sessionStorage.removeItem('Index')
            props.concept.Index = []
            props.history.push('/code/project')
            // window.location.reload()

            sessionStorage.removeItem('flowchart-elements')
        } else {
            setErasedProgram(false)
        }
    }

    return (
        <div className="ExternalAccessories-Main">
            <DialogModal
                show={erasedProgram} //this.state.erasedProgram
                text="All Unsaved program will be Erased. Are you sure want to continue ?"
                showCloseBtn={false}
                // handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => shouldErase('Yes'),
                    },
                    {
                        text: 'NO',
                        do: () => shouldErase('No'),
                    },
                ]}
            />
            <div>
                <div>
                    {sessionStorage.getItem('connectedDevice') === 'Ace' ? (
                        <ProgramHeader
                            data={{
                                options: getHederTextArr({
                                    languageName,
                                    keyArr: [
                                        'Select',
                                        'Assemble',
                                        'Code',
                                        'Simulate',
                                    ],
                                }),
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleHelpBtn}
                        />
                    ) : (
                        <ProgramHeader
                            data={{
                                options: getHederTextArr({
                                    languageName,
                                    keyArr: ['Select', 'Assemble', 'Code', ''],
                                }),
                                selected: 0,
                            }}
                            showSave={true}
                            showHelp={true}
                            handleHelp={handleHelpBtn}
                        />
                    )}
                </div>
            </div>

            <div style={{ width: '100%', height: '90%' }}>
                <CenterMode
                    history={props.history}
                    setErasedProgram={setErasedProgram}
                    shouldErase={shouldErase}
                />
            </div>

            {isHelp && (
                <div
                    className="Ss_slide"
                    style={{ height: '100vh', width: '105vw' }}
                >
                    {' '}
                    <EXternalScPrgm />{' '}
                    <img
                        className="helpClo"
                        src={renderPrgImage('closBtn')}
                        onClick={handleHelpBtn}
                    ></img>
                </div>
            )}

            {/* <CenterMode history={props.history} /> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}
// const mapStateToProps = (state) => {
//     // return state;
//     return {
//         indexData: state,
//     }
// }

// export default connect(mapStateToProps)(ExternalAccessoriesScreen)
const mapDispatchToProps = (dispatch) => {
    return {
        selecteComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        DeselectedComponent: (data) => {
            dispatch({ type: 'INTERNAL_ACCESSORIES', payload: data })
        },
        logicComponent: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalAccessoriesScreen)
