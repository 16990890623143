import React, { useState } from 'react'
import { useIndexedDB } from 'react-indexed-db'
import imageCompression from 'browser-image-compression'
import maskGroup from '../../Assets/Bisoft_UI/AppMode/maskGroup@1.png'
import Slider from './Slider.js'
import styles from './Appmode.module.css'

const Action = (props) => {
    const { update } = useIndexedDB('ProgramData')
    let enable = JSON.parse(sessionStorage.getItem('enable'))
    const connectedDevice = sessionStorage.getItem('connectedDevice') || 'Ace'
    let allDetails = {}

    const [image, setImage] = useState(() => {
        if (
            sessionStorage.getItem('allDetails') !== null &&
            `${props.value}` in
                JSON.parse(sessionStorage.getItem('allDetails')) &&
            JSON.parse(sessionStorage.getItem('allDetails'))[`${props.value}`]
                .image !== null
        ) {
            return JSON.parse(sessionStorage.getItem('allDetails'))[
                `${props.value}`
            ].image
        } else {
            return 'Upload Image'
        }
    })

    const [input, setInput] = useState(() => {
        if (
            sessionStorage.getItem('allDetails') !== null &&
            `${props.value}` in
                JSON.parse(sessionStorage.getItem('allDetails')) &&
            JSON.parse(sessionStorage.getItem('allDetails'))[`${props.value}`]
                .displayText !== null
        ) {
            return JSON.parse(sessionStorage.getItem('allDetails'))[
                `${props.value}`
            ].displayText
        } else {
            return null
        }
    })

    const [select, setSelect] = useState(() => {
        if (
            sessionStorage.getItem('allDetails') !== null &&
            `${props.value}` in
                JSON.parse(sessionStorage.getItem('allDetails')) &&
            JSON.parse(sessionStorage.getItem('allDetails'))[`${props.value}`]
                .selectedSize !== null
        ) {
            return JSON.parse(sessionStorage.getItem('allDetails'))[
                `${props.value}`
            ].selectedSize
        } else {
            return 'Select Size'
        }
    })

    const [sliderOption, setSliderOption] = useState(() => {
        if (
            sessionStorage.getItem('allDetails') !== null &&
            `${props.value}` in
                JSON.parse(sessionStorage.getItem('allDetails')) &&
            JSON.parse(sessionStorage.getItem('allDetails'))[`${props.value}`]
                .sliderOption !== null
        ) {
            return JSON.parse(sessionStorage.getItem('allDetails'))[
                `${props.value}`
            ].sliderOption
        } else {
            return 'Select an Option'
        }
    })

    const [slider, setSlider] = useState(() => {
        if (
            sessionStorage.getItem('allDetails') !== null &&
            `${props.value}` in
                JSON.parse(sessionStorage.getItem('allDetails')) &&
            JSON.parse(sessionStorage.getItem('allDetails'))[`${props.value}`]
                .sliderValue !== null
        ) {
            return JSON.parse(sessionStorage.getItem('allDetails'))[
                `${props.value}`
            ].sliderValue
        } else {
            return 0
        }
    })

    const save = (object) => {
        allDetails = JSON.parse(sessionStorage.getItem('allDetails')) || {}
        if (object.id in allDetails) {
            if (
                object.displayText !== null &&
                allDetails[object.id]['displayText'] !== object.displayText
            ) {
                allDetails[object.id]['displayText'] = object.displayText
            }
            if (
                object.image !== null &&
                allDetails[object.id]['image'] !== object.image
            ) {
                allDetails[object.id]['image'] = object.image
            }
            if (
                object.imageURL !== null &&
                allDetails[object.id]['imageURL'] !== object.imageURL
            ) {
                allDetails[object.id]['imageURL'] = object.imageURL
            }
            if (
                object.selectedSize !== null &&
                allDetails[object.id]['selectedSize'] !== object.selectedSize
            ) {
                allDetails[object.id]['selectedSize'] = object.selectedSize
            }
            if (
                object.sliderOption !== null &&
                allDetails[object.id]['sliderOption'] !== object.sliderOption
            ) {
                allDetails[object.id]['sliderOption'] = object.sliderOption
            }
            if (
                object.sliderValue !== null &&
                allDetails[object.id]['sliderValue'] !== object.sliderValue
            ) {
                allDetails[object.id]['sliderValue'] = object.sliderValue
            }
        } else {
            allDetails[`${object.id}`] = object
        }
        sessionStorage.setItem('allDetails', JSON.stringify(allDetails))
    }

    const data = (event) => {
        const compressImage = async (image) => {
            const imageFile = image
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(
                    imageFile,
                    options
                )
                var reader = new FileReader()
                reader.onloadend = function () {
                    update({ id: props.value, imageURL: `${reader.result}` })
                }
                reader.readAsDataURL(compressedFile)
            } catch (error) {
                console.log(error)
            }
        }
        if (event.target.type === 'file') {
            props.object['image'] = event.target.files[0]['name']
            compressImage(event.target.files[0])
            setImage(event.target.files[0]['name'])
        } else if (event.target.type === 'select-one') {
            if (event.target.value.length === 1) {
                props.object['selectedSize'] = event.target.value
                setSelect(event.target.value)
            } else {
                props.object['sliderOption'] = event.target.value
                setSliderOption(event.target.value)
            }
        } else if (
            event.target.type === 'range' ||
            event.target.type === 'number'
        ) {
            if (event.target.value > 255) {
                props.object['sliderValue'] = Number(255)
            } else {
                props.object['sliderValue'] = event.target.value
            }
        } else {
            props.object['displayText'] = event.target.value
            setInput(event.target.value)
        }
        save(props.object)
    }

    //get device name that show in ui
    const getDeviceName = () => {
        switch (connectedDevice) {
            case 'Ace':
                return 'PC'
            case 'Humanoid':
                return 'Zing'
            case 'Hexapod':
                return 'Crawl-e'
            case 'Klaw':
                return 'Klaw-b'
            case 'Roboki':
                return 'Roboki'
            case 'Klaw':
                return 'Klaw-b'
            default:
                return connectedDevice
        }
    }

    return (
        <div style={{ margin: '0px' }}>
            <div>
                <img
                    src={maskGroup}
                    draggable="false"
                    alt="maskGroup"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '37.6vh',
                        left: '48vw',
                        width: '80vw',
                        height: '10vh',
                        //-ms-transform: "translate(-50%, -50%)",
                        transform: 'translate(-50%, -50%)',
                    }}
                />
                {/* Element to Move Dynamically */}
                <h2
                    style={{
                        position: 'absolute',
                        top: '36vh',
                        left: '14vw',
                        transform: 'translate(-50%,-50%)',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '12px',
                        color: 'black',
                    }}
                >
                    <strong>ACTION</strong>
                </h2>
                <h2
                    style={{
                        position: 'absolute',
                        top: '36vh',
                        left: '25vw',
                        transform: 'translate(-50%,-50%)',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '12px',
                        color: 'grey',
                    }}
                >
                    <strong>Display Image</strong>
                </h2>
                <div
                    style={{
                        position: 'absolute',
                        top: '36vh',
                        left: '57.5vw',
                        transform: 'translate(-50%,-50%)',
                    }}
                >
                    <input
                        key={props.id}
                        type="file"
                        id={`${props.value * 2}`}
                        hidden
                        accept="image/png, image/jpeg"
                        onInput={data}
                    />
                    <label
                        style={{
                            backgroundColor: 'white',
                            color: 'black',
                            padding: '0.6vw 10vw',
                            fontFamily: 'Halcyon Regular',
                            borderRadius: '0.3rem',
                            cursor: 'pointer',
                            marginTop: '1rem',
                        }}
                        htmlFor={`${props.value * 2}`}
                    >
                        {image}
                    </label>
                </div>
            </div>
            <div>
                <img
                    src={maskGroup}
                    alt="maskGroup"
                    draggable="false"
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '45.6vh',
                        left: '48vw',
                        width: '80vw',
                        height: '10vh',
                        //-ms-transform: "translate(-50%, -50%)",
                        transform: 'translate(-50%, -50%)',
                    }}
                />
                <h2
                    style={{
                        position: 'absolute',
                        top: '44vh',
                        left: '14vw',
                        transform: 'translate(-50%,-50%)',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '12px',
                        color: 'black',
                    }}
                >
                    <strong>ACTION</strong>
                </h2>
                <h2
                    style={{
                        position: 'absolute',
                        top: '44vh',
                        left: '25vw',
                        transform: 'translate(-50%,-50%)',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '12px',
                        color: 'grey',
                    }}
                >
                    <strong>Display Text</strong>
                </h2>
                <input
                    style={{
                        margin: 0,
                        position: 'absolute',
                        top: '41.4vh',
                        left: '36vw',
                        width: '20vw',
                        height: '5vh',
                        textAlign: 'center',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '15px',
                    }}
                    key={props.id}
                    id={props.value}
                    className={styles.inputWrapper}
                    type="text"
                    placeholder="Enter Text"
                    onChange={data}
                    value={input || ''}
                />
                <h2
                    style={{
                        position: 'absolute',
                        top: '44vh',
                        left: '63.5vw',
                        transform: 'translate(-50%,-50%)',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '12px',
                        color: 'grey',
                    }}
                >
                    <strong>Select Size</strong>
                </h2>
                <div
                    style={{
                        position: 'absolute',
                        top: '41.5vh',
                        right: '17vw',
                        font: 'Halcyon Regular',
                        fontFamily: 'Halcyon Regular',
                        fontSize: '15px',
                    }}
                >
                    <select
                        className={styles.selectBox}
                        style={{
                            width: '20vh',
                            height: '5vh',
                            textAlign: 'center',
                        }}
                        id={props.value}
                        value={select}
                        onChange={data}
                    >
                        <option value="0">Select Size</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
            </div>
            {/* MaskGroup3 */}
            {enable.s1.pc === true ? (
                <div>
                    <img
                        src={maskGroup}
                        alt="maskGroup"
                        draggable="false"
                        style={{
                            margin: 0,
                            position: 'absolute',
                            top: '53.6vh',
                            left: '48vw',
                            width: '80vw',
                            height: '10vh',
                            //-ms-transform: "translate(-50%, -50%)",
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                    <h2
                        style={{
                            position: 'absolute',
                            top: '51vh',
                            left: '14vw',
                            font: 'Halcyon Regular',
                            fontFamily: 'Halcyon Regular',
                            fontSize: '12px',
                            color: 'black',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <strong>ACTION</strong>
                    </h2>

                    <div
                        style={{
                            position: 'absolute',
                            top: '49.7vh',
                            right: '-43vw',

                            margin: 0,
                            left: '36vw',
                        }}
                    >
                        <select
                            className={styles.selectBox}
                            style={{
                                height: '5vh',
                                width: '15vw',
                                font: 'Halcyon Regular',
                                fontFamily: 'Halcyon Regular',
                                fontSize: '15px',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                            id={props.value}
                            value={sliderOption}
                            onChange={data}
                        >
                            <option value="none">Select an Option</option>
                            <option value="TX1">{`Send to ${getDeviceName()} (USB RX)`}</option>
                        </select>
                    </div>
                    {sliderOption === 'TX1' ? (
                        <Slider
                            save={data}
                            setslider={setSlider}
                            slider={slider}
                            value={props.value}
                            top="50.5vh"
                            right="23vw"
                            usbRx={true}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}
export default Action
