import activeSmart from '../../../../../../../Assets/humanoid/button talkback-11.png'
import inActiveKick from '../../../../../../../Assets/humanoid/kick.png'
import activeKick from '../../../../../../../Assets/humanoid/button talkback-3.png'

import inActiveFight from '../../../../../../../Assets/humanoid/fight.png'
import activeFight from '../../../../../../../Assets/humanoid/button talkback-10.png'
import inActiveAttention from '../../../../../../../Assets/humanoid/attention.png'
import activeAttention from '../../../../../../../Assets/humanoid/button talkback-9.png'

import inActiveSideKickLeft from '../../../../../../../Assets/humanoid/side left.png'
import activeSideKickLeft from '../../../../../../../Assets/humanoid/button talkback-8.png'
import inActiveSideKickRight from '../../../../../../../Assets/humanoid/side right.png'
import activeSideKickRight from '../../../../../../../Assets/humanoid/button talkback-7.png'

import inActiveFrontKickLeft from '../../../../../../../Assets/humanoid/side left.png'
import activeFrontKickLeft from '../../../../../../../Assets/humanoid/button talkback-8.png'
import inActiveFrontKickRight from '../../../../../../../Assets/humanoid/side right.png'
import activeFrontKickRight from '../../../../../../../Assets/humanoid/button talkback-7.png'

import inActiveSalute from '../../../../../../../Assets/humanoid/salute.png'
import activeSalute from '../../../../../../../Assets/humanoid/button talkback-6.png'
import inActiveDance from '../../../../../../../Assets/humanoid/dance.png'
import activeDance from '../../../../../../../Assets/humanoid/button talkback-5.png'
import inActiveSquat from '../../../../../../../Assets/humanoid/squate.png'
import activeSquat from '../../../../../../../Assets/humanoid/button talkback-4.png'
import inActiveWave from '../../../../../../../Assets/humanoid/wave.png'
import activeWave from '../../../../../../../Assets/humanoid/button talkback-2.png'
import inActiveBow from '../../../../../../../Assets/humanoid/bow.png'
import activeBow from '../../../../../../../Assets/humanoid/button talkback-1.png'
import inActivePushUp from '../../../../../../../Assets/humanoid/pushup.png'
import activePushUp from '../../../../../../../Assets/humanoid/button talkback.png'

/////////////////////////////HexaPod////////////////////////////////////
import inActiveHexaAttack from '../../../../../../../Assets/hexapod/InActiveAttack@2x.png'
import activeHexaAttack from '../../../../../../../Assets/hexapod/activeAttack@2x.png'
import inActiveHexaAttention from '../../../../../../../Assets/hexapod/InActiveAttention@2x.png'
import activeHexaAttention from '../../../../../../../Assets/hexapod/activeAttention@2x.png'
import inActiveHexaDance from '../../../../../../../Assets/hexapod/InActiveDance@2x.png'
import activeHexaDance from '../../../../../../../Assets/hexapod/activeDance@2x.png'
import inActiveHexaDefence from '../../../../../../../Assets/hexapod/InActiveDefence@2x.png'
import activeHexaDefence from '../../../../../../../Assets/hexapod/activeDefence@2x.png'
import inActiveHexaGreeting from '../../../../../../../Assets/hexapod/InActiveWave@2x.png'
import activeHexaGreeting from '../../../../../../../Assets/hexapod/activeWave@2x.png'
import inActiveHexaSlideRight from '../../../../../../../Assets/hexapod/InActiveRight@2x.png'
import activeHexaSlideRight from '../../../../../../../Assets/hexapod/activeRight@2x.png'
import inActiveHexaSlideLeft from '../../../../../../../Assets/hexapod/InActiveLeft@2x.png'
import inActiveHexaTurnAround from '../../../../../../../Assets/hexapod/InActiveTurn@2x.png'
import inActiveHexaFight from '../../../../../../../Assets/hexapod/InActiveFight@2x.png'
import activeHexaSlideLeft from '../../../../../../../Assets/hexapod/activeLeft@2x.png'
import activeHexaTurnAround from '../../../../../../../Assets/hexapod/activeTurn@2x.png'
import activeHexaFight from '../../../../../../../Assets/hexapod/activeFight@2x.png'
import inActiveHexaBooting from '../../../../../../../Assets/hexapod/InActiveFight@2x.png'
import activeHexaBooting from '../../../../../../../Assets/hexapod/activeLeft@2x.png'

import actionCardStyle from './actionCard.module.css'

const IMAGES = {
    activeAttention,
    inActiveAttention,
    activeSideKickLeft,
    inActiveSideKickLeft,
    activeSideKickRight,
    inActiveSideKickRight,
    activeFrontKickLeft,
    inActiveFrontKickLeft,
    activeFrontKickRight,
    inActiveFrontKickRight,

    activeSalute,
    inActiveSalute,
    activeDance,
    inActiveDance,
    activeSquat,
    inActiveSquat,
    activeWave,
    inActiveWave,
    activeBow,
    inActiveBow,
    activeFight,
    inActiveFight,
    activePushUp,
    inActivePushUp,

    //dance images
    activeDance1: activeDance,
    inActiveDance1: inActiveDance,
    activeDance2: activeDance,
    inActiveDance2: inActiveDance,
    activeDance3: activeDance,
    inActiveDance3: inActiveDance,
    activeDance4: activeDance,
    inActiveDance4: inActiveDance,
    activeDance5: activeDance,
    inActiveDance5: inActiveDance,

    /////// Hexapod ////////
    inActiveHexaAttack,
    activeHexaAttack,
    inActiveHexaAttention,
    activeHexaAttention,
    inActiveHexaDance,
    activeHexaDance,
    inActiveHexaDefence,
    activeHexaDefence,
    inActiveHexaGreeting,
    activeHexaGreeting,
    inActiveHexaSlideRight,
    activeHexaSlideRight,
    inActiveHexaSlideLeft,
    activeHexaSlideLeft,
    inActiveHexaTurnAround,
    activeHexaTurnAround,
    inActiveHexaFight,
    activeHexaFight,
    inActiveHexaBooting,
    activeHexaBooting,
}

const disabledCard = {
    pointerEvents: 'None',
    opacity: '0.5',
}

const ActionCard = ({ name, isOn, action, handleAction, isEnabled }) => {
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    return (
        <div
            className={
                actionCardStyle[
                    `actionCard-container-${connectedDevice.toLowerCase()}`
                ]
            }
            style={{ userSelect: 'none' }}
        >
            <img
                className={actionCardStyle['actionCard-img']}
                // style={
                //     isEnabled || (action == 'Dance' && isOn)
                //         ? null
                //         : disabledCard
                // }
                style={isEnabled ? {} : disabledCard}
                src={
                    isOn
                        ? IMAGES[
                              `active${
                                  connectedDevice === 'Hexapod' ? 'Hexa' : ''
                              }${action}`
                          ]
                        : IMAGES[
                              `inActive${
                                  connectedDevice === 'Hexapod' ? 'Hexa' : ''
                              }${action}`
                          ]
                }
                onClick={() => handleAction(action)}
            />
            <p className={actionCardStyle['actionCard-title']}>{name}</p>
        </div>
    )
}
export default ActionCard
