import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    PORTDATA,
    EXTERNALDEVICES,
    // allPortDefaultData,
    // generateDeviceDataKey,
} from '../defaultData'
import { generateDeviceDataKey } from '../../ReusableComponents/ByteTransfer/utils'

const availableExternalPorts = [31, 32, 33, 34, 35, 36, 37, 38]
const availableExternalPortsKlaw = [31, 32, 33, 34]
const availableAiInputPorts = Array.from(
    { length: 103 - 82 + 1 },
    (_, i) => i + 82
)
//for start panel assigned port
const startPanelPortObj = {
    Bicounter1: {
        port: 52,
    },
    Bicounter2: {
        port: 53,
    },
    BiData1: {
        port: 54,
    },
    BiData2: {
        port: 63,
    },
    Biflag1: {
        port: 55,
    },
    Biflag2: {
        port: 56,
    },
    'BT RX1': {
        port: 57,
    },
    'BT RX2': {
        port: 58,
    },
    'BT RX3': {
        port: 59,
    },
    'BT RX4': {
        port: 60,
    },
    'BT RX5': {
        port: 61,
    },
    'USB RX': {
        port: 62,
    },
    'BT Remote': {
        port: 64,
    },
}

class Select extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // none
        }
        this.aiInputOptions = []
    }

    componentDidMount = () => {
        if (this.props.selected)
            this.props.onChange({ target: { value: this.props.selected } })
        this.getAiInputOption()
    }

    onChange = (e) => {
        this.props.onChange(e.target.value)
    }

    decideRenderPort = (portId) => {
        const portConnections = this.props.assembly.PortConnections
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
        const PORTDATAKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersion
        )
        const EXTERNALDEVICESData =
            ['0', '1'].includes(deviceVersion[0]) &&
            ['Hexapod', 'Humanoid'].includes(connectedDevice)
                ? EXTERNALDEVICES['v1']
                : EXTERNALDEVICES['v2']
        // console.log(PORTDATA[`${connectedDevice.toUpperCase()}`], portId)
        const portName = PORTDATA[PORTDATAKey][portId].name.split(' ')[1]
        if (portConnections[portName] == null) return { isRenderPort: false }

        const externalDevice = portConnections[portName].type
        if (externalDevice == 'pcAiTool') {
            return { isRenderPort: false }
        }

        if (EXTERNALDEVICESData[externalDevice].type == 'output')
            return { isRenderPort: false }

        // not render dual channel devices on [ABCD]2 ports
        // if (
        //     portName[1] == '2' &&
        //     portConnections[portName].type == portConnections[portName[0]].type
        // )
        //     return { isRenderPort: false }

        return {
            isRenderPort: true,
            btnText: `${EXTERNALDEVICESData[externalDevice].name} -> ${portName}`,
            portName: externalDevice,
        }
    }

    checkForOtherComp = (port) => {
        if (
            ['A', 'D', 'C'].includes(port[1].name.slice(-2, -1)) &&
            JSON.parse(
                sessionStorage.getItem(`${port[1].name.slice(-2, -1)}Ultra`)
            )
        ) {
            return `Ultrasonic -> ${port[1].name}`
        } else {
            return `${port[1].name} - ${port[1].type2}`
        }
    }

    getAiInputOption = () => {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
        let PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
        if (!['HUMANOID2', 'HEXAPOD2', 'KLAW1'].includes(PORTDATAKey)) {
            PORTDATAKey = 'PORTDATAKey'
        }
        const defaultPortData = PORTDATA[PORTDATAKey]

        let aiTrainList = {}
        try {
            aiTrainList = JSON.parse(localStorage.getItem('aiTrainList'))
        } catch (e) {
            console.log('ERROR', e)
        }

        let assemblyPageAiItems = this.props?.assembly?.assemblyPageAi?.aiItems
        availableAiInputPorts.map((portNumber) => {
            let portDetails = defaultPortData?.[portNumber]

            if (!assemblyPageAiItems?.[portDetails?.reduxName]) {
                return
            }

            if ([85, 86, 87, 89, 90, 91].includes(portNumber)) {
                //for read pa bytes ai color tracking do not show
                if (this.props.typeDropDown === 'readPa') {
                    return
                }

                let trainKey =
                    parseInt(portDetails?.reduxLogicName[0]?.slice(-1) || '1') -
                    1
                if (!aiTrainList?.colorTranList?.[trainKey]?.isUpload) {
                    return
                }
            }
            if ([95, 96, 97, 99, 100, 101].includes(portNumber)) {
                //for read pa bytes ai face tracking do not show
                if (this.props.typeDropDown === 'readPa') {
                    return
                }

                let trainKey =
                    parseInt(portDetails?.reduxLogicName[0]?.slice(-1) || '1') -
                    1
                if (!aiTrainList?.faceTranList?.[trainKey]?.isUpload) {
                    return
                }
            }

            let obj = { label: portDetails.name, value: portNumber }
            this.aiInputOptions.push(obj)
        })
    }
    getStartPanelOptionLabel(deviceName, ele) {
        if (['Bicounter1', 'Bicounter2', 'Biflag1', 'Biflag2'].includes(ele))
            return deviceName + ele.replace(/^Bi(.)/, (m, c) => c.toUpperCase())
        return deviceName + ele
    }

    render() {
        var style = {
            border: '2.1px solid #3C413E',
            borderRadius: '15px',
            color: '#000',
            background: '#FFF',
            height: '100%',
            width: '100%',
            outline: 'none',
            texAlign: 'center',
            fontFamily: 'Halcyon_Semibold !important',
        }

        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let connectedDevice = sessionStorage.getItem('connectedDevice')
        return (
            <>
                {this.props.typeDropDown !== 'trainList' && (
                    <select
                        style={style}
                        value={this.props.selected}
                        onChange={this.props.onChange}
                    >
                        <option selected value="battery">
                            {' '}
                            -- select an option --{' '}
                        </option>

                        {/* External Accessories */}
                        {connectedDevice == 'Klaw' &&
                            availableExternalPortsKlaw.map((portId) => {
                                const { isRenderPort, btnText, portName } =
                                    this.decideRenderPort(portId)
                                if (!isRenderPort) return
                                return (
                                    <>
                                        <option key={portName} value={portId}>
                                            {btnText}
                                        </option>
                                    </>
                                )
                            })}
                        {connectedDevice !== 'Klaw' &&
                            availableExternalPorts.map((portId) => {
                                const { isRenderPort, btnText, portName } =
                                    this.decideRenderPort(portId)
                                if (!isRenderPort) return
                                return (
                                    <>
                                        <option key={portName} value={portId}>
                                            {btnText}
                                        </option>
                                    </>
                                )
                            })}

                        {/* Internal Accessories sourceOptionsOrder*/}
                        {Object.entries(humanoidPortData).map((port) => {
                            // console.log('JJJJJJJJJJJ', port)
                            if (
                                port[1].type === 'input' &&
                                port[1].isPortSelected
                            )
                                return (
                                    <>
                                        {connectedDevice == 'Hexapod' ? (
                                            <option
                                                key={port[0]}
                                                value={port[0]}
                                            >
                                                {port[0] < 31 &&
                                                    `${port[1].name}`}
                                                {port[0] > 31 &&
                                                    this.checkForOtherComp(
                                                        port
                                                    )}
                                            </option>
                                        ) : (
                                            <option
                                                key={port[0]}
                                                value={port[0]}
                                            >
                                                {port[0] < 30 &&
                                                    `${port[1].name}`}
                                                {port[0] > 30 &&
                                                    this.checkForOtherComp(
                                                        port
                                                    )}
                                            </option>
                                        )}
                                    </>
                                )
                            return
                        })}

                        {/* ai accessories */}
                        {this.aiInputOptions &&
                            this.aiInputOptions.map((ele, ind) => {
                                return (
                                    <option key={ind} value={ele.value}>
                                        {ele.label}
                                    </option>
                                )
                            })}

                        {/* for start panel */}
                        {this.props.type !== 'read' &&
                            this.props.sourceOptionsOrder &&
                            this.props.sourceOptionsOrder.map((ele, key) => {
                                let deviceName = ''
                                switch (connectedDevice) {
                                    case 'Klaw':
                                        deviceName = 'Klaw-b '
                                        break
                                    case 'Hexapod':
                                        deviceName = 'Crawl-e '
                                        break
                                    case 'Humanoid':
                                        deviceName = 'Zing '
                                        break
                                    default:
                                        deviceName = 'PC '
                                }
                                return (
                                    <>
                                        <option
                                            key={key}
                                            value={startPanelPortObj[ele].port}
                                        >
                                            {/* {deviceName + ele} */}
                                            {this.getStartPanelOptionLabel(
                                                deviceName,
                                                ele
                                            )}
                                        </option>
                                    </>
                                )
                            })}
                    </select>
                )}

                {this.props.typeDropDown == 'trainList' && (
                    <select
                        style={style}
                        value={this.props.selected}
                        onChange={this.props.onChange}
                    >
                        {this.props?.tranListOption?.map((ele, ind) => {
                            return (
                                <option key={ind} value={ele.value}>
                                    {ele.label}
                                </option>
                            )
                        })}
                    </select>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(Select)
